
export default {
  notifications: 'Notifications',
  see_more: 'Se all notifications',
  plan_expires_today: 'Your license expires today, go to the update plan option and update your plan.',
  one_month_left: 'You have only one month left of your actual plan, go to the update plan pane ' +
    'to keep using our services.',
  one_week_left: 'You have only one week left of your actual plan, go to the update plan pane ' +
    'to keep using our services.',
  one_month_expired: 'A month ago your licence has expired, go to the update plan pane to keep using our services.',
  demo_expired_yesterday: 'Your demo plan has expired yesterday, go to the update plan pane to keep using our ' +
  'services.',

  pulse_month_ago: 'You have a month without create pulsdes. If you dont have the permissons to do this action ' +
    'please go to the extend plan pane.',
  engagement_closed: 'Your Engagement poll has been closed',
  pulse_closed: 'Your Pulse poll has been closed',
  admin_plan_expires_today: 'The curren plant of the enterprise {enterprise} expires today.',
  admin_one_month_left: 'The enterprise {enterprise} has one month left in his plan.',
  admin_one_week_left: 'The enterprise {enterprise} has one week left in his plan.',
  admin_one_month_expired: 'A month ago the plan of the enterprise {enterprise} expired.',
  admin_demo_expired_yesterday: 'The demo plan of the enterprise {enterprise} expired yesterday.',
  admin_enterprise_enabled: 'The enterprise {enterprise} has been enabled',
  admin_enterprise_disabled: 'The enterprise {enterprise} has been disabled',
  admin_engagement_closed: 'The Engagement poll {poll} of the enterprise {enterprise} has been closed.',
  admin_pulse_closed: 'The Pulse poll {poll} of the enterprise {enterprise} has been closed.',
};
