
<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6">
        <h4 class="display-1">{{ $t('questionnaires.add_questionnaire') }}</h4>
      </v-col>
      <v-col cols="12">
        <v-card class="px-4">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(create)">
              <v-row>
                <v-col cols="12" sm="6">
                  <x-inputs-input
                    :input="questionnaire.name"
                    @updateInput="($event) => questionnaire.name = $event"
                    :label="$t('input.name')"
                    rules="required"
                    autofocus
                    light
                    name="name"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <x-inputs-autocomplete
                    :items="questionnaires"
                    :input="questionnaire.questionnaireId"
                    @updateInput="($event) => questionnaire.questionnaireId = $event"
                    light
                    :label="$t('questionnaires.questionnaire_base')"
                    name="base_questionnaire"
                    rules="required"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <x-inputs-autocomplete
                    :items="assignationTypes"
                    :input="questionnaire.assignationType"
                    @updateInput="($event) => questionnaire.assignationType = $event"
                    clearable
                    light
                    :label="$t('questionnaires.assignation_type')"
                    name="assign_type"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <x-inputs-autocomplete
                    :items="assignations"
                    :input="questionnaire.assignationFor"
                    @updateInput="($event) => questionnaire.assignationFor = $event"
                    light
                    :label="$t('questionnaires.assignation_to')"
                    name="assign_for"
                    :rules="questionnaire.assignationType ? 'required' : ''"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col align="end">
                  <v-btn
                    :disabled="loadingCreate"
                    @click="$router.go(-1)"
                  >
                    {{ $t('input.cancel') }}
                  </v-btn>
                  <v-btn
                    type="submit"
                    color="primary"
                    class="ml-3"
                    :loading="loadingCreate"
                  >
                    {{ $t('input.save') }}
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import formatItems from '../../utils/form-format-items-list';
import resolver from '../../utils/resolver';

import questionnaireService from '../../services/questionnaires';
import customersService from '../../services/customers'
import sectorsService from '../../services/sectors'

// import keyMixin from './mixins/key';

export default {
  // mixins: [keyMixin],
  data() {
    return {
      loadingCreate: false,
      questionnaire: {
        name: '',
        questionnaireId: '',
        assignationType: '',
        assignationFor: ''
      },
      customersResponse: [],
      sectorsRespone: [],
      questionnaires: []
    }
  },
  computed: {
    assignationTypes () {
      return [
        { text: this.$t('questionnaires.customer'), value: 'customer' },
        { text: this.$t('questionnaires.enterprise'), value: 'enterprise' },
        { text: this.$t('questionnaires.sector'), value: 'sector' }
      ]
    },
    assignations () {
      switch (this.questionnaire.assignationType) {
        case 'customer':
          return this.customersResponse.map((customer) => ({
            text: customer.name,
            value: customer.id
          }))
        case 'enterprise':
          const assignations = []
          for (const customer of this.customersResponse) {
            assignations.push({ header: customer.name })
            for (const enterprise of customer.enterprises) {
              assignations.push({
                text: enterprise.name,
                value: enterprise.id
              })
            }
            assignations.push({ divider: true })
          }
          return assignations
        case 'sector':
          return this.sectorsRespone.map((sector) => {
            return {
              text: sector.translate ? sector.translate.label : sector.code,
              value: sector.id
            }
          })
      }
      return []
    }
  },
  methods: {
    create() {
      this.loadingCreate = true
      return questionnaireService.create(this.questionnaire)
        .then(() => {
          this.$store.dispatch('alert/success', this.$t('questionnaires.created_questionnaire'))
          this.$router.push({ path: '/questionnaires/' })
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
        .finally(() => {
          this.loadingCreate = false
        })
    },
  },
  created() {
    resolver
      .all({
        questionnaires: questionnaireService.listByType('engagement'),
        customers: customersService.shortListWithEnterprises(),
        sectors: sectorsService.list()
      })
      .then((res) => {
        this.questionnaires = formatItems(res.questionnaires.items, (item) => ({
          value: item.id,
          text: item.name,
        }))
        this.customersResponse = res.customers
        this.sectorsRespone = res.sectors.items
      })
  }
};
</script>
