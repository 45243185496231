
export default {
  add_enterprise: 'Agregar Empresa',
  admin_left_table_title: 'Empresas',
  admin_bottom_table_title: 'Encuestas recientes',
  admin_bottom_table_header: [
    { text: 'Nombre', value: '', sortable: false, align: 'left' },
    { text: 'Empresa', value: '', sortable: false, align: 'center' },
    { text: 'Estado', value: '', sortable: false, align: 'center' },
    { text: 'Fecha de lanzamiento', value: '', sortable: false, align: 'center' },
  ],
  admin_right_table_title: 'Empresas con planes próximos a caducar',
  admin_right_table_header: [
    { text: 'Empresa', value: '', sortable: false, align: 'left' },
    { text: 'Plan', value: '', sortable: false, align: 'center' },
    { text: 'Fecha de vencimiento', value: '', sortable: false, align: 'center' },
  ],
  create_poll: 'Crear encuesta',
  continue_poll: 'Continuar encuesta',
  continue: 'Continuar',
  employee_left_table_title: 'Encuestas en progreso',
  employee_progress: 'Completada al:',
  employee_right_table_title: 'Encuestas asignadas',
  enterprise_down_table_title: 'Encuestas',
  enterprise_left_table_title: 'Resumen de cuenta',
  enterprise_right_table_title: 'Plan actual',
  employee_right_table_header: [
    { text: 'Nombre', value: '', sortable: false, align: 'left' },
    { text: 'Periodo', value: '', sortable: false, align: 'center' },
  ],
  enterprise_right_table_header: [
    { text: 'Nombre', value: '', sortable: false, align: 'left' },
    { text: 'Tipo', value: '', sortable: false, align: 'center' },
    { text: 'Participantes', value: '', sortable: false, align: 'center' },
    { text: 'Estado', value: '', sortable: false, align: 'center' },
    { text: 'Detalles', value: '', sortable: false, align: 'center' },
  ],
  expired_plan: '(Plan vencido)',
  general_list: 'Listado general',
  general_title: 'Tablero',
  has_started: 'Comenzada',
  not_started: 'Por comenzar',
  completed: 'Completada',
  init: 'Inicio:',
  end: 'Finalización',
  ended: 'Finalizado el:',
  left_days: 'Días de suscripción restantes',
  left_questions: 'Preguntas restantes:',
  finished: 'Finalizado',
  progress: 'En progreso',
  period_divisor: 'al',
  poll: 'Encuesta:',
  poll_completed: 'Si',
  poll_incompleted: 'No',
  poll_init_date: 'Inicio:',
  poll_end_date: 'Válida hasta:',
  qty_enterprises: 'Empresas activas',
  qty_employees: 'Empleados',
  qty_licesenses: 'Licencias',
  team_trends: 'Tendencias de equipo',
  trend_chart: 'Gráfico de tendencias',
  update_plan: 'Actualizar Plan',
  dimension_leaders: 'Los líderes',
  dimension_inspiration: 'Mi inspiración',
  dimension_job: 'Mi trabajo',
  dimension_environment: 'Ambiente laboral positivo',
  dimension_team: 'Mi equipo',
  dimension_development: 'Mi desarrollo y aprendizaje',
  view_poll_details: 'Ver detalles de la encuesta',
  has_no_polls: 'Necesita crear al menos 2 encuestas en el mismo periodo para ver la gráfica.',
  start: 'Comenzar',
  start_poll: 'Comenzar encuesta',
};
