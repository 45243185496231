
import mapParameters from '../utils/map-parameters';
import Services from './base-services';

const service = new Services('enterprises');

interface IOptionsList {
  page?: number,
  rowsPerPage?: number,
  filter?: string,
  search?: string,
}

export default {
  list: (options: IOptionsList) => {
    const params = mapParameters({
      page: options && options.page ? options.page : null,
      rowsPerPage: options && options.rowsPerPage ? options.rowsPerPage : null,
      filter: options && options.filter ? options.filter : null,
      search: options && options.search ? options.search : null,
    });
    return service.get('list', params);
  },
  listNoGrouped: () => {
    return service.get('list-no-grouped');
  },
  listByGroupAndNoGrouped: (id: number) => {
    return service.get(`list-by-group-and-no-grouped/${id}`);
  },
  disable: (id: number) => {
    return service.put('disable', { id });
  },
  enable: (id: number) => {
    return service.put('enable', { id });
  },
  create: (data: object) => {
    return service.form('', data, {
      contact: 'object',
      license: 'object',
      demographicItems: 'array',
    });
  },
  getOne(id: number) {
    return service.get(`${id}`);
  },
  edit: (id: number, data: object) => {
    return service.form(`${id}/edit`, data, {
      contact: 'object',
      license: 'object',
      demographicItems: 'array',
    });
  },
  getProfile() {
    return service.suiteOperation(() => service.get('profile-by-id'));
  },
  editProfile() {
    return service.get('edit-profile');
  },
  selfEdit: (data: object) => {
    return service.form(`self-edit`, data, {
      demographicItems: 'array',
    });
  },
  getActivePlan: (id: number) => {
    return service.get(`get-active-plan/${id}`);
  },
  extendPlan: (id: number, data: object) => {
    return service.post(`extend-plan/${id}`, data);
  },
  getEntensionsRequest: (id: number) => {
    return service.get(`get-extension-requests/${id}`);
  },
  handleExtension: (id: number, data: object) => {
    return service.post(`handle-extension/${id}`, data);
  },
  getPlansForUpdate: (id: number) => {
    return service.get(`get-plans-update/${id}`);
  },
  updatePlan: (id: number, data: object) => {
    return service.post(`update-plan/${id}`, data);
  },
  getPlanUpdateRequest: (id: number) => {
    return service.get(`get-update-request/${id}`);
  },
  handleUpdate: (id: number, data: object) => {
    return service.post(`handle-update/${id}`, data);
  },
  getAllEntensionsRequest: (id: number) => {
    return service.get(`get-extensions-history/${id}`);
  },
  getDemoRequests: () => {
    return service.get('get-demo-requests');
  },
  createOutside: (data: object) => {
    return service.bPost('auth/create-enterprise', data);
  },
  handleDemo: (id: number, data: object) => {
    return service.post(`handle-demo/${id}`, data);
  },
  getDemographicsaItems: () => {
    return service.get('get-demographics-items');
  },
};
