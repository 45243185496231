
import auth from './auth';
import dashboard from './dashboard';
import demographicReport from './demographic-report';
import employees from './employees';
import engagement from './engagement';
import engagementMessages from './engagement-messages';
import engagementReport from './engagement-report';
import enterprises from './enterprises';
import errors from './errors';
import followupReport from './followup-report';
import groups from './groups';
import help from './help';
import input from './input';
import instructive from './instructive';
import languages from './languages';
import navigation from './navigation';
import notifications from './notifications';
import outside from './outside';
import pluralization from './pluralization';
import pulseMessages from './pulse-menssages';
import pulses from './pulses';
import questionnaires from './questionnaires';
import questions from './questions';
import settings from './settings';

import components from './components'

export default {
  components,
  /* General Labels */
  title: 'OCC Solutions - Pulse',
  active: 'Activo',
  inactive: 'Inactivo',
  no_data: 'No hay registros',
  registration_updated_successfully: 'Registro actualizado con éxito',
  record_saved_successfully: 'Registro guardado exitosamente',
  error_trying_update: 'Hubo un error al intentar actualizar',
  error_trying_save: 'Se produjo un error al intentar guardar',
  copyright: 'Todos los derechos reservados 2019',
  go_back: 'Volver',
  results_per_pages: 'Resultados por páginas',
  manual: 'Manuales de ayuda',
  go_to_manual: 'Haga click aquí para ir a la sección de manuales',
  download_requirement: 'Descargar Requerimientos Técnicos',
  /* Models */
  models: {
    enterprises: 'Empresas',
    groups: 'Grupos',
    engagements: 'Engagement',
    pulse: 'Pulse',
  },
  waiting: 'Por favor espere',
  maintenance: 'Página en construcción',
  // tslint:disable-next-line:max-line-length
  maintenance_text: 'Trabajamos cada día para lograr los objetivos<br/>y darle mejores resultados y experiencias.',
  are_sorry: 'Lo sentimos',
  this_survey_not: 'Esta encuesta aún no está lista para generar el reporte.',
  no_one_answered: 'Nadie respondio a la encuesta. Sin respuestas no se podrá generar el reporte.',
  selected: 'seleccionados',
  /* Labels grouped by modules or type */
  auth,
  dashboard,
  demographicReport,
  employees,
  engagement,
  engagementMessages,
  engagementReport,
  enterprises,
  errors,
  followupReport,
  help,
  input,
  instructive,
  navigation,
  notifications,
  outside,
  pluralization,
  questionnaires,
  languages,
  pulses,
  pulseMessages,
  settings,
  groups,
  questions,
  page_text: 'de',
  page_all: 'Todos',
};
