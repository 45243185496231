
<template>
  <v-stepper-content step="3">
    <v-col cols="12" class="pa-3 headline">
      {{ $t('engagement.questions') }}
      <v-divider></v-divider>
      <v-spacer></v-spacer>
    </v-col>

    <v-container>
      <v-row row align-space-between justify-center column fill-height
        v-for="(item,index) in engagement.questionnaires"
        v-bind:key="index"
      >
        <v-row align-center justify-start row fill-height>
          <v-col cols="7" sm="7" max-height="20px" class="pt-0">
            <v-radio-group
              v-model="engagement.radios"
              :mandatory="false"
              class="mt-0 mb-0"
            >
              <v-radio
                class="pt-3"
                :label="item.text"
                :value="item.value"
                :rules="'required'"
                :readonly="engagement.status != 'pending'"
              ></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="5" sm="5" class="pt-3">
            <v-btn small
              color="primary"
              class="white--text mr-4"
              :disabled="!item.langs.includes(user.lang)"
              @click="getPdf(item)"
            >
              {{ $t('engagement.download') }} {{ $t('engagement.questionnaire') }}
              <v-icon dark right small>mdi-file-pdf</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-row>

      <v-row row wrap>
        <v-col cols="12">
          <v-switch
            v-if="engagement.questionnaires.length"
            :readonly="engagement.status != 'pending'"
            v-model="engagement.switchQuestion"
            :label="$t('engagement.want_open_question')"
            :append-icon="$t('help.icon')"
            @click:append="$store.dispatch('help/display', $t('help.engagement.create.openQuestion'))"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <template v-if="engagement.switchQuestion">
            <v-row>
              <v-col cols="12" class="headline">
                {{ $t('engagement.open_question') }}
                <v-tooltip :disabled="engagement.opens.length === maxOpenQuestion || engagement.status !== 'pending'" bottom color="green lighten-3">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      fab small
                      color="#068ad5"
                      class="white--text mx-3"
                      :disabled="engagement.opens.length === maxOpenQuestion || engagement.status !== 'pending'"
                      @click="addOpenQuestion"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('engagement.add_open_question') }}</span>
                </v-tooltip>
                <v-tooltip :disabled="engagement.opens.length === minOpenQuestion || engagement.status !== 'pending'" bottom color="red">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      fab small
                      color="#f65871"
                      class="white--text"
                      :disabled="engagement.opens.length === minOpenQuestion || engagement.status !== 'pending'"
                      @click="removeOpenQuestion"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('engagement.remove_open_question') }}</span>
                </v-tooltip>
                <v-divider class="mt-3"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="pa-2" v-for="(open, $idx) in engagement.opens" :key="$idx">
                <v-container grid-list-md class="pa-3">
                  <v-row wrap justify="end">
                    <v-col cols="12" class="py-1">
                      <v-text-field
                        v-model="open.question"
                        light
                        :label="$t('engagement.insert_question', {n: $idx + 1})"
                        :name="`external_name${$idx}`"
                        :readonly="engagement.status !== 'pending'"
                        :disabled="engagement.status !== 'pending'"
                      >
                        <template #append>
                          <v-tooltip :disabled="open.options.length === maxOptionOpenQuestion || engagement.status !== 'pending'" bottom color="green lighten-3">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                fab small
                                color="#068ad5"
                                class="white--text mb-2"
                                :disabled="open.options.length === maxOptionOpenQuestion || engagement.status !== 'pending'"
                                @click="addOptionOpenQuestion(open)"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('engagement.add_question_option') }}</span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="10" offset-xs1 v-for="(option, $i) in open.options" :key="$i">
                      <v-text-field
                        v-model="open.options[$i]"
                        light
                        :label="$t('engagement.insert_question_option', {n: $idx + 1, o: $i + 1})"
                        :name="`external_name${$idx}${$i}`"
                        :readonly="engagement.status !== 'pending'"
                        :disabled="engagement.status !== 'pending'"
                      >
                        <template v-slot:append>
                          <v-tooltip :disabled="open.options.length === minOptionOpenQuestion || engagement.status !== 'pending'" bottom color="red">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                fab small
                                color="#f65871"
                                class="white--text mb-2"
                                :disabled="open.options.length === minOptionOpenQuestion || engagement.status !== 'pending'"
                                @click="removeOptionOpenQuestion(open, $i)"
                              >
                                <v-icon>mdi-minus</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('engagement.remove_question_option') }}</span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>

      <v-row row wrap>
        <v-col cols="12" sm="6">
          <v-btn
            block
            large
            @click="changeStep(+step - 1)"
          >{{ $t('input.back') }}</v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <x-async-btn
            :disabled="!engagement.questionnaires.length"
            class="white--text"
            :action="runValidate"
            large
            block
          >{{ $t(nextAction) }}
          </x-async-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="showDialog" width="600px" :persistent="true">
      <v-card>
        <v-card-title>
          <span class="headline"> {{ $t('engagement.no_questionnaries_title') }} </span>
        </v-card-title>
        <v-card-text>
          {{ $t('engagement.no_questionnaries_body') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="white--text"
            large
            block
            color="#3898d9"
            @click="showDialog = !showDialog"
          >
            {{ $t('input.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <img
      src="../../../../public/img/occ-logo-small.png"
      style="visibility:hidden;"
      id="occPulseLogo"
      width="0"
      height="0"
    />

    <x-loading
      :display="loading"
    ></x-loading>
  </v-stepper-content>
</template>

<script>

import { mapState } from 'vuex'
import is from 'is_js'
import resolver from '../../../utils/resolver'
import attributesService from '../../../services/attributes'
import dimensionsService from '../../../services/dimensions'
import questionnairesService from '../../../services/questionnaires'
import validate from '../../../utils/validate-form'

const pdfMake = require('pdfmake/build/pdfmake');
const pdffonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdffonts.pdfMake.vfs;

export default {
  props: {
    engagement: Object,
    step: String,
    nextAction: String,
    prevAction: String,
  },
  data() {
    return {
      dimensions: [],
      attributes: [],
      pulseLogoSrc: null,
      pulseLogoBase64: null,
      minOpenQuestion: 1,
      maxOpenQuestion: 3,
      minOptionOpenQuestion: 2,
      maxOptionOpenQuestion: 5,
      showDialog: false,
      loading: false,
    }
  },
  watch: {
    '$i18n.locale': {
      handler(val) {
        this.getData()
      }
    },
    pulseLogoSrc(newVal) {
      if (newVal) {
        this.toDataURL(this.pulseLogoSrc, (dataURL) => {
          this.pulseLogoBase64 = dataURL;
        });
      }
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user,
    }),
  },
  methods: {
    getData () {
      this.loading = true
      resolver
        .all({
          attributes: attributesService.listAll(this.user.lang),
          dimensions: dimensionsService.listAll(this.user.lang),
          questionnaires: questionnairesService.listByType('engagement')
        })
        .then(({ attributes, dimensions, questionnaires }) => {
          this.attributes = attributes.items.map((a) => {
            return {
              id: a.id,
              dimensionId: a.dimensionId,
              value: a.code,
              text: a.translate.label
            }
          })

          this.dimensions = dimensions.items.map((d) => {
            return {
              id: d.id,
              value: d.code,
              text: d.translate.label
            }
          })

          this.engagement.questionnaires = questionnaires.items.map((q) => ({
            value: q.id,
            text: q.name,
            detail: q.questionnaireDetail,
            langs: q.languages
          }))
          this.showDialog = !this.engagement.questionnaires.length
        })
        .finally(() => {
          this.loading = false
        })
    },
    changeStep(step) {
      this.$emit('changeStep', step);
    },
    validateForm(scope) {
      return validate(this, scope)
        .then(valid => valid ? this.changeStep(+this.step + 1) : undefined);
    },
    runValidate() {
      return new Promise((resolve) => {
        if (this.engagement.switchQuestion) {
          if (this.engagement.opens.length) {
            const isValid = this.engagement.opens.every(q => {
              return (q.question && q.options.every(r => !!r));
            });
            if (!isValid) {
              const error = this.$t('errors.required_open_question')
              this.$store.dispatch('alert/error', error)
              resolve();
              return
            }
          }
        }
        this.changeStep(+this.step + 1);
        resolve();
      });
    },
    removeOpenQuestion() {
      this.engagement.opens.pop();
    },
    addOpenQuestion() {
      this.engagement.opens.push({
        question: '',
        options: ['', ''],
      });
    },
    removeOptionOpenQuestion(open, idx) {
      open.options.splice(idx, 1);
    },
    addOptionOpenQuestion(open) {
      open.options.push('');
    },
    toDataURL(url, callback) {
      const xhr = new XMLHttpRequest();
      xhr.open('get', url);
      xhr.responseType = 'blob';

      xhr.onload = function() {
        const fr = new FileReader();

        fr.onload = function() {
          callback(this.result);
        };

        fr.readAsDataURL(xhr.response);
      };

      xhr.send();
    },
    writeRotatedText(text) {
      const canvas = document.createElement('canvas');
      canvas.width = 50;
      canvas.height = 845;

      const ctx = canvas.getContext('2d');

      // Genera color de fondo
      ctx.fillStyle = '#2196F3';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.save();

      // Posiciona el elemento al costado derecho de la página
      ctx.translate(50, 845);
      ctx.rotate(-0.5 * Math.PI);

      // Formatea el texto
      ctx.font = '20pt Roboto';
      ctx.fillStyle = 'white';
      ctx.fillText(text.toUpperCase(), 290, -15);
      ctx.restore();

      return canvas.toDataURL();
    },
    getPdf(questionnaire) {
      this.loading = true;
      const details = [];
      this.dimensions.forEach((d, dIterator) => {
        dIterator += 1;

        const dimObj = { text: d.text.toUpperCase(), bold: true, fontSize: 15 };

        if (dIterator % 2 !== 0 && dIterator !== 1) {
          dimObj.pageBreak = 'before';
          dimObj.margin = [0, 15, 0, 0]
        }

        details.push(dimObj);

        this.attributes
          .filter(a => {
            return a.dimensionId === d.id;
          })
          .forEach((a) => {
            const attObj = { text: a.text.toUpperCase(), bold: true, fontSize: 13, margin: [15, 5, 0, 0] };
            details.push(attObj);

            const questions = questionnaire.detail
              .filter(d => d.attributeId === a.id)
              .map((d) => d.translations.find(t => t.lang === this.user.lang).label);

            const qstObj = { ul: questions, margin: [15, 0, 0, 15] };
            details.push(qstObj);
          });
      });

      const configuration = {
        pageSize: 'A4',
        info: {
          title: this.$t('engagementReport.title'),
          author: 'OCC',
          subject: this.$t('engagementReport.title'),
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Roboto',
          lineHeight: 1.2,
        },
        header: () => {
          return [{
            image: this.pulseLogoBase64,
            height: 28,
            width: 117,
            margin: [15, 15, 25, 15],
          }];
        },
        footer: () => {
          return [
            {
              columns: [
                { width: '*', text: '' },
                {
                  width: 'auto',
                  text: this.$t('engagementReport.copyright'),
                  color: 'grey',
                  fontSize: 10,
                },
                { width: '*', text: '' },
              ],
            }
          ];
        },
        background: () => {
          const result = {
            image: this.writeRotatedText(questionnaire.text),
            aligment: 'center',
            absolutePosition: { x: 545, y: 0 },
          };

          return result;
        },
        content: [
          // Título
          {
            text: this.$t('engagement.questionnaire_e'),
            fontSize: 20,
            margin: [0, 20, 0, 10],
          },
          // Explicación
          {
            text: this.$t('engagement.pdf_explained', { name: questionnaire.text }),
            alignment: 'justify',
            margin: [0, 0, 20, 10],
          },
          // Cuestionario
          ...details,
        ],
      };

      if (is.edge() || is.ie()) {
        const pdfDocGenerator = pdfMake.createPdf(configuration);
        pdfDocGenerator.getBlob((blob) => {
          window.navigator.msSaveBlob(blob, 'questionnaire.pdf')
          this.loading = false
        });
      } else {
        pdfMake.createPdf(configuration).download('questionnaire.pdf');
        this.loading = false
      }
    },
  },
  created() {
    this.getData()
  },
  mounted() {
    this.pulseLogoSrc = document.getElementById('occPulseLogo').src;
  },
}
</script>

<style>
  .cut-overflow .v-select__selections {
    overflow: hidden !important;
  }
</style>
