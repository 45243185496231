
import Services from './base-services';

const service = new Services('headquarters');

export default {
  list: (country: number) => {
    return service.suiteOperation(() => (service.get(`list?country=${country}`)));
  },
  fetchHeadquartersByEnterprise: () => {
    return service.suiteOperation(() => (service.get(`fetch-by-enterprise`)));
  },
  fetchByEnterprise: () => {
    return service.suiteOperation(() => service.get('fetch-by-enterprise'))
  },
  create: (data: { enterpriseId: number, countryId: number, name: string }) => {
    return service.post('create', { data });
  },
  update: (id: number, data: { countryId: number, name: string }) => {
    return service.post(`update/${id}`, { data });
  },
  toggleActive: (id: number, active: boolean) => {
    return service.post(`toggle-active/${id}`, { active });
  },
  delete: (id: number) => {
    return service.get(`delete/${id}`);
  },
};
