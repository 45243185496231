
export default {
  copyright: 'Copyright by OCC - All Rights Reserved',
  introduction: 'Introduction',
  model: 'The Model',
  results: 'Results',
  ranking: 'Ranking',
  open_questions: 'Open questions',
  commitment_belonging: 'Commitment+Belonging',
  index: 'Index',
  topic: 'Topic',
  page: 'Page',
  our_recommendations: 'Our recommendations',
  
  index_stack_general: [
    '1.    Results - Answers rate',
    '2.    Results - Demographics cuts',
  ],
  recommendations_stack: [
    // tslint:disable-next-line:max-line-length
    '1. Be sure to give strategic importance to the ', { text: 'Engagement', italics: true }, ', as a lever to achieve outstanding and sustainable results.\n\n',
    // tslint:disable-next-line:max-line-length
    '2. Remember that the ', { text: 'Engagement', italics: true }, ' and the Organizational Culture complement each other; managing both of them is vital in order to bring and hold the talent.\n\n',
    // tslint:disable-next-line:max-line-length
    '3. Empower each leader with the responsibility of managing the ', { text: 'Engagement', italics: true }, ' and culture. The human management areas facilitate and support.\n\n',
    // tslint:disable-next-line:max-line-length
    '4. Be inclusive and participative at the time of defining and closing gaps. Mobilize the collective inteligence generates a higher level of commitment.\n\n',
    // tslint:disable-next-line:max-line-length
    '5. It\'s vital for the process tracking in short cycles. Search for creatives ways of closing gaps and measure frequently. Our Pulse tool is designed for it.\n\n',
    // tslint:disable-next-line:max-line-length
    '6. Always report the results with opening and transparency and include theem in the leaders performance management process.\n\n',
    // tslint:disable-next-line:max-line-length
    '7. Finally, remember the importance of keeping focus. It is better to have less actions with high impact rather than having lots of actions with less positive effects.',
  ],
  what_is_engagement: 'What is engagement and why measure it?',
  // tslint:disable-next-line:max-line-length
  what_is_engagement_text: ['At OCC Solutions we understand ', { text: 'Engagement', italics: true }, ' as high level emotional commiment and belonging sense combined with enthusiasm and passion for the job and the organization, which manifests itself in a permanent desire of giving the best the whole time.'],
  what_is_engagement_positive: [
    'Do the best everyday and having passion for the job.\n\n',
    'Emotional commitment with the objectives and the organization values.\n\n',
    'Motivation to contribute with the organization success with a bigger own well-being sensation.\n\n',
    'Confidence, integrity, bidirectional commitment and communication between the organization and its members.\n\n',
    'Hight level of enthusiasm and dedication which an employee feels about his job.',
  ],
  what_is_engagement_negative: [
    // tslint:disable-next-line:max-line-length
    'The happiness, like laughing with work collies or enjoy the company party, says nothing about how involved or commited are they with the company or how hard are they working in order to achieve the organization mission. It is a short-term meassure that quickly changes.\n\n',
    // tslint:disable-next-line:max-line-length
    'The satisfaction can be enough to retain employees but it does not guarantees productivity. This indicates how happy or glad are the colaborators but it does not address the level of motivation or emotional commitment.',
  ],
  // tslint:disable-next-line:max-line-length
  what_is_engagement_conclusion: ['The colaborators that are highly commitment achieve the best results, loyalty customers, rotate with less frequency and contribute to the culture alignment.\n\nThe organizations with a higher level of ', { text: 'Engagement', italics: true }, ' attract and retain the talent, achieving outstaning and sustainable results.'],
  how_does_our_model_works: 'How does our model works?',
  // tslint:disable-next-line:max-line-length
  how_does_our_model_works_text1: 'Our model allows us to measure six dependent variables or dimensions, which indicate the level of commitment and sense of belonging of people with the organization.\n\nEach dimension in turn has 3 attributes and each attribute has 3 questions, called variables.\n\nThe six dimensions are:',
  how_does_our_model_works_text2: 'Each of the 6 dimensions have three attributes:',
  my_inspiration: 'My inspiration',
  sense_of_purpose: 'Sense of purpose',
  transcendental_work: 'Transcendental work',
  they_know_me: 'They know me',
  my_job: 'My job',
  reputation_of_the_organization: 'Reputation of the organization',
  reputation_of_my_area: 'Reputation of my area',
  work_enviroment: 'Work environment',
  positive_work_enviroment: 'Positive work environment',
  relationships_quality: 'Relationships quality',
  peoples_treat: 'People\'s treat',
  equity_and_transparency: 'Equity and transparency',
  my_team: 'My team',
  team_dynamics: 'Team dynamics',
  communication_and_coordination: 'Communication and coordination',
  agility_and_decision_making: 'Agility and decision making',
  my_development_and_learning: 'My development and learning',
  opportunities_in_my_role: 'Opportunities of growth of my role',
  opportunities_in_the_organization: 'Opportunities of growth in the organization',
  learning_culture: 'Learning culture',
  the_leaders: 'The leaders',
  my_leader: 'My leader',
  support: 'Support',
  our_leaders: 'Our leaders',
  methodology: 'Methodology',
  // tslint:disable-next-line:max-line-length
  methodology_text: 'The survey contains 54 statements on a scale of 1 to 5 that presents the following options:',
  methodology_list: [
    'I totally disagree',
    'I partially disagree',
    'Neither disagree nor agree',
    'I partially agree',
    'I totally agree',
  ],
  // tslint:disable-next-line:max-line-length
  methodology_conclusion: 'Additionally, three open questions are asked in the final part of the survey, which are presented in the report in graphic form',
  results_text: 'Poll results are shown in this section.',
  total_obtained: 'Total obtained: ',
  total_sent: 'Total expected: ',
  answers_rate: 'Answers rate',
  demographic_answers_rate: 'Answers rate by demographics cuts',
  // tslint:disable-next-line:max-line-length
  answers_rate_text: 'The amount of people that were included in the process and which completed the questionnaire are display in this table.',
  results_by_dimension: 'Results by dimension',
  results_by_attribute: 'Results by attribute',
  results_by_dim_attr: 'Results by dimension - attribute',
  total: 'Total',
  attribute: 'Atributte',
  global_score: 'Global score',
  current_population: 'Current score',
  current_population_desc: 'Simple average of the total measurement result',
  current_population_items: 'Population score',
  weighted_score: 'Weighted score',
  weighted_score_desc: 'Weight of the model questionnaire (54 questions): 70%. - Engagement index weight: 30%.',
  engagement_index: 'Engagement index',
  engagement_index_desc: 'Simple average of the 6 commitment questions results',
  preview_population: 'Previous score',
  rate: 'Rate',
  actual_organization: 'Score Organization',
  gap: 'Gap',
  culture_alignment: 'Culture alignment',
  purpose: 'Purpose',
  future_vision: 'Future vision',
  autonomy: 'Autonomy',
  strengths_and_talents: 'Strengths and talents',
  my_contribution: 'My contribution',
  motivation: 'Motivation',
  they_value_me: 'They value me',
  performance: 'Performance',
  recognized_organization: 'Recognized organization',
  organization_performance: 'Organization performance',
  pride_of_belonging_organization: 'Pride of belonging to the organization',
  recognized_area: 'Recognized area',
  area_performance: 'Area performance',
  pride_of_being_part_area: 'Pride of being part of the area',
  materials_and_equipment: 'Materials and equipment',
  offices_and_dress_code: 'Offices and dress code',
  balance_personal_professional: 'Balance between personal and professional life',
  being_myself: 'Being myself',
  freedom_of_expression: 'Freedom of expression',
  interpersonal_relationships: 'Interpersonal relationships',
  care_for_people: 'Care for people',
  inclusive_workplace: 'Inclusive workspace',
  respectful_treatment: 'Respectful treatment',
  equal_opportunities: 'Equal opportunities',
  salary_and_benefits: 'Salary and benefits',
  recognition_culture: 'Recognition culture',
  trust_quality_relationships: 'Trust and quality in relationships',
  responsibility_meeting_goals: 'Responsibility for meeting the goals',
  workload_balance: 'Worload balance',
  team_network: 'Team network',
  communication_team: 'Communication in my team',
  diversity: 'Diversity',
  agility_processes: 'Agility in the processes',
  innovation: 'Innovation',
  access_transparency_information: 'Access and transparency of information',
  autonomous_learning: 'Autonomous learning',
  development_potential: 'Development of my potential',
  expectations_role: 'Expectations of my role',
  career_plan: 'Career plan',
  future_organization: 'Future in the organization',
  horizontal_mobility: 'Horizontal mobility',
  learning_tracking: 'Learning tracking',
  genuine_interest: 'Genuine interest',
  admiration: 'Admiration',
  transparency_honesty: 'Transparency and honesty',
  clear_transparent_objectives: 'Clear and transparent objectives',
  coaching_feedback: 'Coaching and feedback',
  leader_access: 'Leader access',
  systematic_thinking: 'Systematic thinking',
  strategic_planning: 'Strategic planning',
  social_intelligence_collaboration: 'Social intelligence and collaboration',
  ranking_of_dimensions: 'Ranking of dimensions',
  dimension: 'Dimension',
  current_organization: 'Current organization',
  dimensions_with_higher_score: 'Dimensions with highest score',
  dimensions_with_lower_score: 'Dimensions with lowest score',
  dimensions_with_higher_dispersion: 'Dimensions with highest dispersion',
  dimensions_with_lower_dispersion: 'Dimensions with lowest dispersion',
  ranking_of_the_attributes: 'Ranking of attributes',
  attributes_with_higher_score: 'Attributes with highest score',
  attributes_with_lower_score: 'Attributes with lowest score',
  ranking_of_the_variables: 'Ranking of the variables',
  variable: 'Variable',
  variables_with_higher_score: 'Variables with highest score',
  variables_with_lower_score: 'Variables with lowest score',
  dispersion: 'Dispersion',
  dispersion_by_attributes: 'Dispersion by attributes',
  dispersion_by_variables: 'Dispersion by variables',
  attributes_with_higher_dispersion: 'Attributes with highest dispersion',
  attributes_with_lower_dispersion: 'Attributes with lowest dispersion',
  preview_dispersion: 'Previous dispersion',
  current_dispersion: 'Current dispersion',
  current_dispersion_items: 'Population dispersion',
  variables_with_higher_dispersion: 'Variables with highest dispersion',
  variables_with_lower_dispersion: 'Variables with lowest dispersion',
  rates_by_dimension: 'Rates by dimension',
  variation: 'Variation',
  rates: 'Rates',
  highest_positive_rate_attribute: 'Highest positive rates by attribute',
  highest_negative_rate_attribute: 'Highest negative rates by attribute',
  highest_positive_rate_variable: 'Highest positive rates by attribute',
  highest_negative_rate_variable: 'Highest negative rates by attribute',
  rates_by_variables: 'Rates by variables',
  rates_by_attributes: 'Rates by attributes',
  open_questions_summary: 'Close questions charts',
  word_questions_summary: 'Open words cloud',
  the_leaders_text: 'THE LEADERS: the leaders\nin this organization\ninspire me',
  my_development_text: 'MY DEVELOPMENT AND\nLEARNING: I see myself growing\nin this organization',
  my_team_text: 'MY TEAM: I am part\nof a team committed to\nthe organization',
  // tslint:disable-next-line:max-line-length
  work_enviroment_text: 'POSITIVE WORK\nENVIRONMENT: I would recommend\na friend or relative to\nwork in this organization',
  my_job_text: 'MY JOB: I feel\ncommitted and proud to\nwork in this company',
  // tslint:disable-next-line:max-line-length
  my_inspiration_text: 'MY INSPIRATION:\nIf they offered me a\nsimilar job in another company, I would\nstay where I am',
  reports: 'Reports',
  general_title: 'General',
  general_desc: 'This report has the statistics of the entire population included in the survey.',
  general_button: 'Generate engagement report',
  items_button: 'Generate engagement report - population',
  demographic_population_button: 'Generate summary by demographics cuts',
  demographic_title: 'By demographic items',
  demographic_population_title: 'Summary by demographics cuts',
  demographic_population_desc: 'This report has the scores of the different dimensions segmented by demographic cuts.',
  // tslint:disable-next-line:max-line-length
  demographic_desc: 'This report has the statistics of the population filtered through certain demographic cuts; these items can be selected below:',
  demographic_button: 'Generate engagement report by demographic item',
  display_demographic: 'Display demographic items',
  population: 'Population',
  sent: 'Sent',
  obtained: 'Obtained',
  additionalDemographic1: 'Additional Demographics 1',
  additionalDemographic2: 'Additional Demographics 2',
  academicDegree: 'Academic degree',
  age: 'Age',
  antiquity: 'Antiquity',
  charge: 'Charge',
  country: 'Country',
  departments: 'Department',
  gender: 'Gender',
  jobTypes: 'Job type',
  headquarter: 'Headquarter',
  years_range: 'Between {min} and {max} years',
  dimention: 'Dimension',
  my_inspiration_chart: 'My\ninspiration',
  my_job_chart: 'My\njob',
  work_enviroment_chart: 'Positive work\nenvironment',
  my_team_chart: 'My       \nteam',
  my_development_and_learning_chart: 'My\ndevelopment\nand learning',
  the_leaders_chart: 'The     \nleaders',
  cover: 'Summary by demographics cuts',
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  active_employees: 'Active employees',
  participants: 'Participants',
  average: 'Sector average',
  people: 'people',
  note: 'Notes:',
  colors: 'Colors',
  dim_color: 'Dimension color',
  values: 'Values',
  meaning: 'Meaning',
  heatmap_text: [
    // tslint:disable-next-line:max-line-length
    'In the previous table you can see the colors, ranges of value and meaning for the',
    {
      text: ' heat map ',
      italics: true,
    },
    'that will be used in all the tables of the report.',
  ],
  previous_measurement: 'Previous masurement',
  strengths: 'Strengths',
  opportunities: 'Improvement opportunities',
  feelings: 'Feelings',
  what_is_it: 'What is it?',
  what_is_not: 'What is not?',
  critical_situation: 'Critical situation',
  requires_attention: 'Requires urgent attention',
  insufficient: 'Insufficient',
  aceptable: 'Acceptable',
  very_good: 'Very good',
  remarkable: 'Remarkable',
  how_to_read: 'How to read the report',
  title: 'OCC - Engagement Report',
  att_var: 'Attribute - Variable',
  operation_init: 'A download process "Summary Report by Demographic Cuts" has started, this may take several minutes, you can see your progress by refreshing the page',
  input_generating_report: 'Generating report',
  input_update_progress: 'Update progress',
  input_failed_generation: 'Generation failed',
  input_download_report: 'Download report',
  age_range: {
    '1': 'Less than 25 years',
    '2': 'From 25 to 35 years',
    '3': 'From 35 to 45 years',
    '4': 'From 45 to 50 years',
    '5': 'More than 50 years'
  },
  antiquity_range: {
    '1': 'Less than 6 months',
    '2': 'From 6 months to 1 year',
    '3': 'From 1 to 3 years',
    '4': 'From 3 to 5 years',
    '5': 'From 5 to 10 years',
    '6': 'From 10 to 20 years',
    '7': 'More than 20 years'
  }
};
