
export default {
  follow_up: 'Follow Up',
  followup_report: 'Follow Up Report',
  followup_report_desc: 'Select up to two demographics to be able to generate the tracking report.',
  total_participants: 'Total Participants',
  total_completed: 'Completed',
  total_remaining: 'Remaining',
  totals: 'Totals'
};
