
import auth from './auth';
import dashboard from './dashboard';
import demographicReport from './demographic-report';
import employees from './employees';
import engagement from './engagement';
import engagementMessages from './engagement-messages';
import engagementReport from './engagement-report';
import enterprises from './enterprises';
import errors from './errors';
import followupReport from './followup-report';
import groups from './groups';
import help from './help';
import input from './input';
import instructive from './instructive';
import languages from './languages';
import navigation from './navigation';
import notifications from './notifications';
import outside from './outside';
import pluralization from './pluralization';
import pulses from './pulses';
import pulseMessages from './pulse-messages';
import questionnaires from './questionnaires';
import questions from './questions';
import settings from './settings';

import components from './components'

export default {
  components,
  /* Gerenal labels */
  title: 'OCC Solutions - Pulse',
  active: 'Active',
  inactive: 'Inactive',
  no_data: 'No records',
  registration_updated_successfully: 'Registration updated successfully',
  record_saved_successfully: 'Record saved successfully',
  error_trying_update: 'There was an error trying to update',
  error_trying_save: 'An error occurred while trying to save',
  copyright: 'All rights reserved 2019',
  go_back: 'Go Back',
  results_per_pages: 'Results per pages',
  manual: 'Help manuals',
  go_to_manual: 'Click here to go to the manuals section',
  download_requirement: 'Download Technical Requirements',
  /* Models */
  models: {
    enterprises: 'Enterprises',
    groups: 'Groups',
    engagements: 'Engagement',
    pulse: 'Pulse',
  },
  waiting: 'Please wait',
  maintenance: 'Page under construction',
  // tslint:disable-next-line:max-line-length
  maintenance_text: 'We work each day to achieve the objectives<br/>and give you the best results and experiences.',
  are_sorry: 'We are sorry',
  this_survey_not: 'This survey is not yet ready to generate the report.',
  no_one_answered: 'No one answered the survey. Without answers, the report cannot be generated.',
  selected: 'selected',
  /* Labels grouped by modules or type */
  auth,
  dashboard,
  demographicReport,
  employees,
  engagement,
  engagementMessages,
  engagementReport,
  enterprises,
  errors,
  followupReport,
  help,
  input,
  instructive,
  navigation,
  notifications,
  outside,
  pluralization,
  questionnaires,
  languages,
  pulses,
  pulseMessages,
  settings,
  groups,
  questions,
  page_text: 'of',
  page_all: 'All',
};
