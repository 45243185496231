
import store from '../../store';

export default (to: any, from: any, next: any) => {
  if (store.state.session.token) {
    next('/dashboard');
    return;
  }

  next();
};
