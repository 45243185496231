
<template>
  <v-app>
    <v-main class="white">
      <v-container fluid fill-height>
        <v-row>
          <v-col cols="7" sm="4" md="3" lg="2" class="ml-auto py-0">
            <v-select dense hide-details
              v-model="language"
              :items="languages"
              class="styled-select mr-3"
              prepend-icon="translate"
              @change="changeLocale"
            ></v-select>
          </v-col>
        </v-row>
        <v-layout justify-center row wrap>
          <v-flex xs12>
            <router-view
              :language="language"
              :languages="languages"
              @questionnaireLangs="($event) => qLangs = $event"
              @langChanged="changeLocale"
            ></router-view>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import { mapState } from 'vuex';

export default {
  data() {
    return {
      languages: [],
      language: 'es',
      qLangs: ''
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user,
      alert: (state) => state.alert,
    }),
  },
  watch: {
    '$i18n.locale': {
      handler (val) {
        const languages = this.$t('languages')
        this.languages = []
        for (const key in languages) {
          this.languages.push({ value: key, text: languages[key] })
        }
        this.language = val
      },
      immediate: true
    },
    qLangs (val) {
      if (val) {
        this.languages.forEach(l => {
          if (this.disableLang(val, l.value)) {
            l['disabled'] = true
          }
        })
        if (this.disableLang(val, this.language)) {
          this.language = 'es'
        }
        this.changeLocale(this.language)
      }
    }
  },
  methods: {
    disableLang (available, lng) {
      return !available.includes(lng)
    },
    changeLocale (e) {
      this.$i18n.locale = e
      localStorage.setItem('outsideLangSelected', e)
    }
  }
};
</script>
