
export default {
  no_data: 'There is no data available for this demographic item.',
  // tslint:disable-next-line:max-line-length
  master_references_section: 'This section is reserved for the configuration of demographic cuts. We recommend selecting them according to your needs as this depends on the generation of high value reports.',
  // tslint:disable-next-line:max-line-length
  master_references_action: 'These actions are sensitive, the improper use of a master reference is subject to your responsibility.',
  // tslint:disable-next-line:max-line-length
  master_references_enable_des: 'The item will be available again in the selection listings. Information with this item can be registered or updated.',
  // tslint:disable-next-line:max-line-length
  master_references_disable_des: 'This item will not be available in the selection lists until it is activated again. Information previously registered with this item will not change.',
  // tslint:disable-next-line:max-line-length
  master_references_delete_des: 'This item will not be available in the selection lists and cannot be accessed in any way. Information previously registered with this value will not change.',
  actions: 'Actions',
  label: 'Label or name',
  description: 'Description.',
  no_translation: 'There is no translation for this language.',
  academic_degree: 'Configuration of academic degrees',
  academic_degree_read: 'Configured academic degrees',
  academic_degrees: 'Academic degrees',
  academic_degree_info: 'Indicate the academic grades that will be assigned to employees of all enterprises',
  academic_degree_add: 'Add academic degrees',
  academic_degree_enable: 'Enable academic degree',
  academic_degree_disable: 'Disable academic degree',
  academic_degree_delete: 'Delete academic degree',
  language: 'Language settings',
  languages: 'Languages',
  language_info: 'Indicate the languages that will be assigned to employees and enterprises',
  language_add: 'Add language',
  language_enable: 'Enable language',
  language_disable: 'Disable language',
  language_delete: 'Delete language',
  department: 'Configuration of areas or departments.',
  departments: 'Areas or departments.',
  department_info: 'Assign all areas or departments that make up the organization.',
  department_add: 'Add area or department',
  department_enable: 'Enable area or department',
  department_disable: 'Disable area or department',
  department_delete: 'Delete area or department',
  sector: 'Configuration of enterprise sectors',
  sectors: 'Enterprise sectors',
  sector_info: 'Assign all enterprise sectors that will be avalaible in the system',
  sector_add: 'Add sector',
  sector_enable: 'Enable sector',
  sector_disable: 'Disable sector',
  sector_delete: 'Delete sector',
  gender: 'Gender settings',
  genders: 'Genres',
  gender_info: 'Assign all the genres that make up the organization.',
  gender_add: 'Add gender',
  gender_enable: 'Enable gender',
  gender_disable: 'Disable gender',
  gender_delete: 'Delete gender',
  job_type: 'Configuration of the types of contracting.',
  job_types: 'Types of contracting',
  job_type_info: 'Assign the types of hiring that make up the organization.',
  job_type_add: 'Add hiring types',
  job_type_enable: 'Enable hiring types',
  job_type_disable: 'Disable hiring types',
  job_type_delete: 'Remove hiring types',
  country: 'Country Configuration',
  country_read: 'Configured countries',
  countries: 'Countries',
  country_info: 'Assign all countries that will be available in the system',
  country_add: 'Add countries',
  country_enable: 'Enable countries',
  country_disable: 'Disable countries',
  country_delete: 'Delete countries',
  enterprise_size: 'Enterprise sizes Configuration',
  enterprise_sizes: 'Enterprise sizes',
  enterprise_size_info: 'Assign all enterprise sizes that will be available in the system',
  enterprise_size_add: 'Add enterprise sizes',
  enterprise_size_enable: 'Enable enterprise sizes',
  enterprise_size_disable: 'Disable enterprise sizes',
  enterprise_size_delete: 'Delete enterprise sizes',
  headquarters_title: 'Headquarters configuration',
  headquarters_country: 'Country',
  headquarters_head: 'Headquarter',
  headquarters_info: 'Assign all headquarters owned by the enterprise',
  headquarters_countries: 'Countries',
  headquarters_heads: 'Headquarters',
  headquarters_actions: 'Actions',
  headquarters_enable: 'Enable',
  headquarters_disable: 'Disable',
  headquarters_delete: 'Delete',
  headquarters_updated: 'Headquarter successfully updated!',
  headquarters_deleted: 'Headquarter deleted!',
  headequarters_delete_title: 'Delete headquarter',
  headequarters_toggle_true_title: 'Disable headquarter',
  headequarters_toggle_false_title: 'Enable headquarter',
  headquarters_add: 'Add new headquarter',
  headquarters_created: 'Headquarter succesfully created!',
  charges_title: 'Charges levels configuration',
  charges_department: 'Department',
  charges_head: 'Charge',
  charges_info: 'Add the levels of charges that make up the enterprise',
  charges_departments: 'Departments',
  charges_heads: 'Charges',
  charges_create: 'Create charge',
  charges_actions: 'Actions',
  charges_enable: 'Enable',
  charges_disable: 'Disable',
  charges_delete: 'Delete',
  charges_updated: 'Charge successfully updated!',
  charges_deleted: 'Charge deleted!',
  charges_delete_title: 'Delete charge',
  charges_toggle_true_title: 'Disable charge',
  charges_toggle_false_title: 'Enable charge',
  charges_add: 'Add new charge',
  charges_created: 'Charge created successfully!',
  antiquity_title: 'Antiquity ranges',
  antiquity_subtitle: 'Available antiquity ranges',
  ages_title: 'Age ranges',
  ages_subtitle: 'Available age ranges',
  field_not_obligatory: 'This field is not mandatory.',
};
