
import pdfUtils from '../utils/pdf';

const echarts = require('echarts');
require('echarts-wordcloud');

export default {
  methods: {
    $generateWordsCloud() {
      this.wordsChart.idx.forEach(idx => {
        const canvas = document.createElement('canvas');
        canvas.width = 960;
        canvas.height = 960;
        const chartWordsLocal = echarts.init(canvas);

        chartWordsLocal.setOption({
          textStyle: {
            fontFamily: 'Bowlby One'
          },
          series: [{
            type: 'wordCloud',
            shape: 'circle',
            gridSize: 9,
            sizeRange: [14, 47],
            rotationRange: [-90, 90],
            rotationStep: 90,
            width: '56%',
            height: '94%',
            drawOutOfBound: true,
            textStyle: {
              normal: {
                color: () => this.wordColors[Math.floor(Math.random() * this.wordColors.length)]
              }
            },
            data: this.wordsChart.words[`w${idx}`]
          }],
        });

        let timer;
        chartWordsLocal.on('finished', () => {
          if (timer) clearTimeout(timer);
          timer = setTimeout(() => {
            this[`wordsChart${idx}`] = chartWordsLocal.getDataURL();
            this.renderPart[`wordsCloud${idx}`] = true;
          }, 1500);
        });
      });
    },
    $generateWordQuestions() {
      const wordQuestion = this.currentPoll.questions.filter(question => question.type === 'words');
      return [
        pdfUtils.generateTitle(this.$t('engagementReport.word_questions_summary'), [0, 0, -100, 0], 'before', 30, '#111111', true),
        // 1
        {
          text: wordQuestion[0].label[this.user.lang],
          margin: [0, 5, 0, 0],
          fontSize: 14,
          color: '#222222'
        },
        {
          image: this.wordsChart1,
          margin: [20, 0, 0, 0],
          width: 472,
          height: 600,
        },
        // 2
        {
          pageBreak: 'before',
          text: wordQuestion[1].label[this.user.lang],
          fontSize: 14,
          color: '#222222'
        },
        {
          image: this.wordsChart2,
          margin: [20, 0, 0, 0],
          width: 472,
          height: 600,
        },
        // 3
        {
          pageBreak: 'before',
          text: wordQuestion[2].label[this.user.lang],
          fontSize: 14,
          color: '#222222'
        },
        {
          image: this.wordsChart3,
          margin: [20, 0, 0, 0],
          width: 472,
          height: 600,
        },
      ];
    },
  },
};
