
<template>
  <v-app>
    <x-navigation ref="navigation"></x-navigation>

    <v-app-bar
      color="#3899da"
      dark
      style="border-bottom:1px solid rgba(0,0,0,.12) !important;"
      app
      fixed
      clipped-left
    >
      <v-app-bar-nav-icon @click.stop="toggleNavigation" text></v-app-bar-nav-icon>

      <img src="/img/20200304_x_occ_pulse_logo.svg"
        class="ml-2 responsive-nav web-nav"
        alt="OCC - Solutions logo"
      />

      <v-spacer></v-spacer>
      <v-select dense hide-details
        v-model="language"
        :items="languages"
        :menu-props="{ top: false, offsetY: true }"
        class="styled-select mt-2 mb-2 mr-3"
        prepend-icon="translate"
        @change="changeLocale"
      ></v-select>
      <div class="text-xs-center">
        <v-menu
          v-model="openMenu"
          :nudge-width="296"
          :nudge-bottom="40"
          :nudge-left="295"
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn text
              v-on="on"
              class="mr-1"
            >
              <v-icon large>mdi-apps</v-icon>
            </v-btn>
          </template>

          <v-card style="max-width: 22.7em">
            <v-row no-gutters v-if="loadingProducts">
              <v-col cols="12" class="py-12 text-center">
                <v-progress-circular indeterminate
                  :width="4"
                  :size="54"
                  color="primary"
                  class="mx-auto"
                ></v-progress-circular>
                <p class="mt-1 mb-0 pl-3 caption grey--text text--darken-2">
                  {{ $t('waiting') }}...
                </p>
              </v-col>
            </v-row>
            <v-list v-else style="max-height: 77vh" class="py-0 overflow-y-auto">
              <v-list-item>
                <v-list-item-content>
                  <v-row>
                    <template v-for="item in products">
                      <v-col md="6" :key="item.name" style="cursor: pointer">
                        <a :href="item.url">
                        <img :src="item.logo || item.defaultLogo"
                            alt="System Logo"
                            :style="item.name === 'occ'
                              ? 'max-height: 50px; max-width: 150px; margin-left: 1em;margin-top: 1.5em;'
                              : item.name.includes('LIDERAZGO')
                                ? 'margin-top: 0.5em; max-width: 160px'
                                : 'max-width: 150px'
                            "
                          />
                        </a>
                      </v-col>
                    </template>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <v-tooltip left v-if="['customer', 'enterprise_admin'].includes(user.role)">
          <template v-slot:activator="{ on }">
            <v-btn href="https://occ-solutions.com/wp-content/uploads/2022/01/Manual-de-Uso-OCC-Solutions_compressed.pdf" class="elevation-0" text fab  target="_blank" v-on="on">
              <v-icon>mdi-help-circle</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('manual') }}</span>
        </v-tooltip>
      </div>

      <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="100" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" class="elevation-0" fab color="white">
            <span class="headline primary--text">{{ user.email | initials }}</span>
          </v-btn>
        </template>

        <v-card>
          <v-divider></v-divider>
          <v-list dense>
            <template v-if="user.role !== 'employee'">
              <v-list-item>
                <v-row>
                  <v-col md="3">
                    <div style="background-color: #3899da; width: 52px; height: 52px; padding-left: 2px; padding-top: 2px; border-radius: 50%">
                      <v-avatar color="white" size="48">
                        <span class="headline">{{ user.email | initials }}</span>
                      </v-avatar>
                    </div>
                  </v-col>
                  <v-col md="9">
                    <p>
                      <span class="text-capitalize">
                        {{
                          user.role === 'customer'
                            ? user.customer.name
                            : user.role === 'enterprise_admin'
                              ? 'Co-Admin.'
                              : 'Super Admin.'
                        }}
                      </span><br>
                      <span style="font-size: .9em">{{user.email}}</span>
                    </p>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-divider></v-divider>
            </template>
            <v-list-item @click="confirmLogout = true" class="text-center">
              <v-list-item-title style="color: #3899dac7">
                <v-icon color="#3899dac7" small>mdi-logout-variant</v-icon>
                {{ $t('input.logout') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container fluid fill-height :class="{'pa-0': $vuetify.breakpoint.xs}">
        <router-view />
      </v-container>
    </v-main>

    <v-footer style="border-top:1px solid rgba(0,0,0,.12) !important;" color="white" app inset>
      <span class="black--text">&nbsp;&nbsp;{{ $t('copyright') }}.</span>
    </v-footer>

    <x-alert :alert="alert"></x-alert>

    <x-help-modal></x-help-modal>
    <v-dialog v-model="showSnackbarDialog" width="40em">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ $t('auth.close_session') }}
        </v-card-title>
        <v-card-text>{{ $t(snackMsg) }}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="flex-wrap: wrap">
          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
          <template v-if="doubleOrigin">
            <v-btn color="#eb604c" text :href="`${this.suiteWeb}/wp/cfs/customer`">
              <strong>{{ $t('auth.close_session_as_customer') }} </strong>
            </v-btn>
            <v-btn class="ml-0" color="#eb604c" text :href="`${this.suiteWeb}/wp/cfs/enterprise`">
              <strong>{{ $t('auth.close_session_as_enterprise') }} </strong>
            </v-btn>
          </template>
          <v-btn v-else color="#eb604c" text :href="`${this.suiteWeb}/wp/cfs`">
            <strong>{{ $t('auth.close_session') }} </strong>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      v-if="showSnackbar"
      color="#424242"
      dark fixed bottom right fab
      @click="showSnackbarDialog = true"
      class="mb-10 mr-3"
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>
    <!-- Logout Confirmation -->
    <x-confirm-logout
      :show.sync="confirmLogout"
      @close="confirmLogout = false"
    ></x-confirm-logout>
  </v-app>
</template>

<script>

import { mapState } from "vuex";
// import authService from '../services/auth'
import ProductsService from '../services/products'

import { localize } from 'vee-validate'

function loadLocale (code) {
  return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
    locale.messages.required = code === 'es'
      ? 'Este campo es obligatorio'
      : 'This field is required'
    localize(code, locale)
  })
}

export default {
  data() {
    return {
      openMenu: false,
      confirmLogout: false,
      menu: false,
      languages: [],
      language: 'es',
      showSnackbar: false,
      showSnackbarDialog: false,
      doubleOrigin: false,
      loadingProducts: true,
      products: [],
      host: null,
      suiteWeb: '',
      suiteWeb: '',
      snackMsg: '',
    };
  },
  computed: {
    ...mapState({
      user: state => state.session.user,
      alert: state => state.alert,
      help: state => state.help
    })
  },
  watch: {
    '$i18n.locale': {
      handler () {
        const languages = this.$t('languages')
        this.languages = []
        for (const key in languages) {
          this.languages.push({ value: key, text: languages[key] })
        }
        this.user.lang = this.language
        const localUsr = JSON.parse(localStorage.getItem('occ-key-user'))
        localUsr.lang = this.user.lang
        localStorage.setItem('occ-key-user', JSON.stringify(localUsr))
        // Store language in Suite
        /*
        authService.changeSuiteLocale(this.language, JSON.stringify(localUsr))
          .then(res => {
            this.$store.dispatch('session/changeLang', { token: res.token, user: localUsr})
          })
        */
      }
    },
    openMenu (val) {
      if (val && !this.products.length) {
        this.loadProducts()
      }
    }
  },
  methods: {
    toggleNavigation() {
      this.$refs.navigation.toggle();
    },
    changeLocale() {
      this.$i18n.locale = this.language
      loadLocale(this.$i18n.locale)
    },
    getProducts () {
      if (this.user.role === 'admin' && this.user.role === this.user.view) {
        return ProductsService.listActive()
          .then(res => { this.products = res })
      } else if (['customer', 'enterprise_admin'].includes(this.user.role) || ['customer', 'enterprise_admin'].includes(this.user.view)) {
        return ProductsService.listByCustomer()
          .then(res => { this.products = res })
      }
      return Promise.resolve()
    },
    loadProducts () {
      this.getProducts()
        .then(() => {
          this.products.unshift({
            name: 'OCC SUITE',
            logo: `${this.suiteWeb}/img/20200301_occ_solution_logo.png`,
            url: this.suiteWeb
          })
          if (this.host && this.host.name) {
            const pulse = this.products.find(prod => prod.name === this.host.name)
            if (pulse) {
              this.products.splice(this.products.indexOf(pulse), 1)
            }
          }
          let imageLoaded = 0
          for (const prod of this.products) {
            const img = new Image()
            img.src = prod.logo || prod.defaultLogo
            img.onload = () => {
              imageLoaded++
              if (imageLoaded === this.products.length) {
                setTimeout(() => {
                  this.loadingProducts = false
                }, 400)
              }
            }
          }
        })
    }
  },
  created() {
    const languages = this.$t('languages')
    for (const key in languages) {
      this.languages.push({ value: key, text: languages[key] })
    }
    this.language = this.user.lang;
    this.$i18n.locale = this.language;
    loadLocale(this.$i18n.locale)
    if (this.user.origin) {
      this.showSnackbar = true
      this.doubleOrigin = !!this.user.origin.origin
      if (this.user.role === 'customer' && this.user.customer.type === 'commercial' && this.user.enterprise) {
        this.snackMsg = this.doubleOrigin ? 'auth.logged_as_enterprise_superadmin' : 'auth.logged_as_enterprise'
      } else {
        this.snackMsg = 'auth.logged_as_customer'
      }
    }

    this.$store.dispatch('session/getPulseHost')
      .then(res => {
        this.host = res
        return this.$store.dispatch('session/getSuiteWebHost')
      })
      .then(res => {
        if (res) {
          this.$set(this, 'suiteWeb', res)
        }
      })
  },
  mounted () {
    const urlString = window.location.href
    const url = new URL(urlString)
    const updatedAt = url.searchParams.get('updatedAt')
    if (updatedAt) {
      // eslint-disable-next-line
      console.log('Updated at 2024-10-31 12:00')
    }
  }
};
</script>

<style scoped>

.styled-select,
.styled-select label[for] {
  max-width: 140px;
  font-size: 10pt;
}

#nav-badge {
    top: 7px !important;
    right: -15px !important;
    z-index: 1 !important;
}

.unread {
  background-color: #3899da47;
}

.unread:hover {
  background-color: white;
}

.seemore {
  text-align: center;
}

.no-decoration {
  text-decoration: none;
}

.font-unread {
  color: midnightblue;
}

.see-more-btn {
  background-color: #e0e0e087;
}

.see-more-btn:hover {
  background-color: aliceblue;
}

.read {
  cursor: auto;
}

@media (max-width: 500px) {
  .responsive-nav {
    width: 100px !important;
  }
}

@media (min-width: 500px) {
  .web-nav {
    height: 80%;
  }
}

</style>
