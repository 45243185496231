
import Services from './base-services'

const service = new Services('countries')

export default {
  list: () => {
    return service.get('list')
  },
  listByEnterprise: () => {
    return service.suiteOperation(() => service.get('list-by-enterprise'));
  }
}
