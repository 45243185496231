
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// import '@babel/polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import VueI18n from 'vue-i18n';
import registerComponents from './components';
import registerFilters from './filters';
import registeri18n from './i18n';
import registerInterceptors from './interceptor';
import vuetify from './plugins/vuetify';
import registerValidators from './validators';
import { VueAcl } from './acl';

import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.config.productionTip = false;

registerFilters(Vue);
registerComponents(Vue);
registerInterceptors();

Vue.use(VueAcl);
Vue.use(VueI18n);
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'es',
  messages: registeri18n,
});

registerValidators(Vue);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
