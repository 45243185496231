
import mapParameters from '../utils/map-parameters';
import Services from './base-services';

const service = new Services('engagements');
interface IOptionsList {
  page?: number,
  itemsPerPage?: number,
  filter?: string,
  search?: string,
}

export default {
  getBalance: () => {
    return service.get('get-balance');
  },
  checkBalance: () => {
    return service.get('check-balance');
  },
  create: (data: object) => {
    return service.post('create', data);
  },
  list: (options: IOptionsList) => {
    const params = mapParameters({
      page: options.page,
      rowsPerPage: options.itemsPerPage,
      filter: options.filter,
      search: options.search,
    });
    return service.get('list', params);
  },
  verifyPollToken: (token: string) => {
    return service.post('verify-poll-token', { token });
  },
  saveAnswers: (answers: any, pollId: number, token: string) => {
    return service.post('save-answers', { answers, pollId, token });
  },
  saveEmployeeAnswers: (answers: string, token: string) => {
    return service.post('save-employee-answers', { answers, token });
  },
  fetchEmployeeAnswers: (token: string) => {
    return service.post('fetch-employee-answers', { token });
  },
  listByEmployee: () => {
    return service.get('list-by-employee');
  },
  getOneById: (id: number) => {
    return service.get(`${id}`);
  },
  update: (id: number, data: any) => {
    return service.put(`${id}`, data);
  },
  getAllPollEmployeeById: (pollId: number, type: string) => {
    return service.post(`poll-employee-all/${pollId}`, { type });
  },
  resend: (pollId: number) => {
    return service.post('resend-poll', { pollId });
  },
  getCalcData: (pollId: number, demographics: any[], listOfDemographics: any[], filters?: any[]) => {
    const data = filters
      ? { pollId, demographics, listOfDemographics, filters } : { pollId, demographics, listOfDemographics };
    return service.post('get-calc-data', data);
  },
  findNameById: (pollId: number) => {
    return service.post('find-name-by-id', { pollId });
  },
  getParticipantsByPollId: (pollId: number) => {
    return service.post('get-participants-by-poll-id', { pollId });
  },
  getTotalReceivers: (data: object) => {
    return service.post('get-total-receivers', data);
  },
  checkCreatedEngagements: (id: number) => {
    return service.get(`check-created-engagements/${id}`);
  },
  getOpenAnswers: (id: number, data: object) => {
    return service.post(`get-open-answers/${id}`, data);
  },
  close: (id: number) => {
    return service.get(`close/${id}`);
  },
  sendInvitationFiles: (id: number, data: object) => {
    return service.form(`save-invitation-files/${id}`, data);
  },
  sendReminderFiles: (id: number, data: object) => {
    return service.form(`save-reminder-files/${id}`, data);
  },
  getPollDataByDemographicsCuts: (pollId: number) => {
    return service.get(`get-poll-data-by-demographics-cuts/${pollId}`);
  },
  addReport: (data: any) => {
    return service.post('add-report', data);
  },
  reportGetAnswers: (reportId: any) => {
    return service.get(`report-get-answers/${reportId}`);
  },
  getSummaryReportAnswers: (reportRequestId: any) => {
    return service.get(`get-summary-report-results/${reportRequestId}`);
  },
  reportStatus: (pollId: number, type: string) => {
    const params = mapParameters({pollId, type});
    return service.get('report-status', params);
  },
  getAnswersCount (id: string) {
    return service.get(`get-total-answers/${id}`)
  },
  getAnswersWithParticipant (id: string, skip: number) {
    return service.get(`answers-with-participant/${id}/${skip}`)
  },
};
