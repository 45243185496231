
export default {
  email: 'Email',
  password: 'Password',
  password_confirm: 'Confirm password',
  new_password: 'New password',
  new_password_confirm: 'New password confirm',
  logout: 'Logout',
  first_name: 'First name',
  last_name: 'Last name',
  identify_document: 'Identify document',
  gender: 'Gender',
  department: 'Department',
  employment: 'Employment',
  academic_degree: 'Academic degree',
  admission: 'Admission date',
  age_range: 'Age range',
  antiquity_range: 'Antiquity range',
  jobType: 'Job type',
  job_type: 'Job type',
  charge: 'Charge',
  country: 'Country',
  headquarter: 'Headquarter',
  optionalDemo1: 'Additional demographic 1',
  optionalDemo2: 'Additional demographic 2',
  phone_number: 'Phone number',
  accept: 'Accept',
  cancel: 'Cancel',
  save: 'Save',
  next: 'Next',
  back: 'Go back',
  enable: 'Enable',
  disable: 'Disable',
  add: 'Add',
  trash: 'Delete',
  import: 'Import',
  file: 'File',
  save_changes: 'Save changes',
  error_fix: 'Fix errors',
  confirm_changes: 'Confirm changes',
  back_home: 'Back home',
  back_list: 'Back list',
  name: 'Name',
  sector: 'Sector',
  address: 'Address',
  enterprise: 'Enterprise',
  enterprise_size: 'Enterprise Size',
  language: 'Language',
  employees_number: 'Employees number',
  demographic_cuts: 'Demographic cuts',
  license: 'License',
  duration: 'Duration',
  start_date: 'Start date',
  edit: 'Edit',
  update: 'Update',
  edit_questions: 'Edit questions',
  view_details: 'View details',
  birthdate: 'Birthdate',
  confirm_save: 'Confirm and create',
  confirm_update: 'Confirm and update',
  confirm_generate: 'Confirm and generate',
  continue: 'Continue',
  start_poll: 'Start poll',
  answer_poll: 'Answer poll',
  finish: 'Finish',
  back_to_dashboard: 'Back to dashboard',
  select_image: 'Select company logo',
  continue_filling: 'Continue filling',
  languages: 'Languages',
  begin: 'Begin',
  answer: 'Answer',
  resend: 'Resend',
  extend_plan: 'Extend plan',
  enterprise_name: 'Enterprise name',
  pulses_qty_add: 'Pulse quantity to add:',
  engagements_qty_add: 'Engagement quantity to add:',
  admin_extension: 'Admin extension requests',
  reject: 'Reject',
  see_requests: 'View sent requests',
  identify_type: 'Identify document type',
  exist_identification: 'Entered identification number already exists.',
  identify_type_s: 'ID',
  newplan: 'New plan',
  admin_update: 'Adminupdate requests',
  update_plan: 'Update plan',
  no_permissions: 'You do not have permissions to perfom this action.',
  want_request_extend: 'If you want to request an improvement in your license click on extend plan button.',
  group: 'Group',
  engagementsQty: 'Engagement quantity',
  pulsesQty: 'Pulse quantity',
  view_extension_history: 'View requests history',
  plan_extend_info: 'Plan extend',
  demo_duration: '15 days',
  order: 'Orden',
  required_field: 'Required field.',
  valid_email: 'Enter a valid email.',
  close: 'Close',
  invitation_url: 'Invitation url',
  url_copied: 'Url copied',
  copy_invitation_url_title: 'Link for collaborators without email',
  copy_invitation_url: 'You can copy this link and send it to the collaborators who are participating in the measurement and do not have an email or have not received the invitation email.',
  send_invitation_url: 'Send invitation url',
  modal_title: 'Edit employee data',
  preview: 'Preview',
  close_poll: 'Close poll',
  poll_delete_title: 'Close poll',
  // tslint:disable-next-line:max-line-length
  master_references_delete_poll: 'After closing this poll no one of your employees will be able to make the poll and this action can not be changed. All the information for reports will be saved till this point.',
  select_video: 'Select video for the email',
  go_suite: 'Go Suite',
  send_message: 'Send message',
  refresh: 'Refresh',
  generic_link_btn: 'Link for Collaborators without Email',
  the_reminders: 'The reminders',
  may_take_while: 'This action may take a few minutes',
  export: 'Export',
  please_wait: 'Please wait'
};
