
<template>
  <v-row wrap>
    <v-col cols="12">
      <v-card>
        <v-card-text class="pa-0 pt-8">
          <v-data-table
            :headers="$t('dashboard.enterprise_right_table_header')"
            :items="info.polls"
            :hide-default-footer="true"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 25, {'text': $t('page_all'), 'value':-1}],
              itemsPerPageText: $t('results_per_pages'),
            }"
          >
            <!--
            <template v-slot:top>
              <v-toolbar flat class="rounded-t">
                <v-toolbar-title>
                  {{$t('dashboard.enterprise_down_table_title')}}
                </v-toolbar-title>
              </v-toolbar>
            </template>
            -->
            <template v-slot:body="{ items }">
              <tbody v-if="items.length > 0">
                <tr v-for="(item, $index) in items" :key="$index">
                  <td class="text-left padding-left-name pr-0 pl-4 break-td"> {{ item.name }}</td>
                  <td class="text-center px-0"> {{ item.isPulse ? 'Pulse' : 'Engagement' }}</td>
                  <td class="text-center px-0"> {{ item.totalReceivers }}</td>
                  <td class="text-center px-0">
                    <v-chip
                      outlined
                      label
                      :color="getColor(item.status)"
                    >{{ $t(`engagement.${item.status}`) }}</v-chip>
                  </td>

                  <td class="text-center px-0" v-if="item.isPulse">
                    <v-tooltip bottom v-if="item.status === 'creating' || item.status === 'editing' || item.status === 'launching'">
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on"
                          @click="refreshPage"
                        >
                          <v-icon small>refresh</v-icon>
                        </v-btn>
                      </template>
                      <span> {{ $t('input.refresh') }} </span>
                    </v-tooltip>
                    <v-tooltip bottom v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" :to="'/pulse/'+item.id+'/show'">
                          <v-icon small>remove_red_eye</v-icon>
                        </v-btn>
                      </template>
                      <span> {{ $t('dashboard.view_poll_details') }} </span>
                    </v-tooltip>
                  </td>
                  <td class="text-center px-0" v-else>
                    <v-tooltip bottom v-if="item.status === 'creating' || item.status === 'editing' || item.status === 'launching'">
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on"
                          @click="refreshPage"
                        >
                          <v-icon small>refresh</v-icon>
                        </v-btn>
                      </template>
                      <span> {{ $t('input.refresh') }} </span>
                    </v-tooltip>
                    <v-tooltip bottom v-else>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" :to="'/engagement/'+item.id+'/show'">
                          <v-icon small>remove_red_eye</v-icon>
                        </v-btn>
                      </template>
                      <span> {{ $t('dashboard.view_poll_details') }} </span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="v-data-table__empty-wrapper">
                  <td :colspan="$t('dashboard.enterprise_right_table_header').length">
                    <v-alert :value="true" text dense prominent type="error" icon="mdi-shield-remove" class="text-center mt-3">
                      <h3>{{ $t('no_data') }}</h3>
                    </v-alert>
                  </td>
                </tr>
              </tbody>
            </template>
            <template v-slot:pageText="props">
              {{ props.pageStart }} - {{ props.pageStop }} {{ $t('page_text') }} {{ props.itemsLength }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

export default {
  props: {
    info: Object,
    // user: Object,
  },
  data() {
    return {
      //
    }
  },
  mounted () {
    //
  },
  methods: {
    refreshPage (value) {
      document.location.reload()
    },
    getColor(status) {
      switch (status) {
        case "pending":
          return "gray";
        case "cancelled":
          return "red";
        case "completed":
          return "green";
        case "progress":
          return "blue";
      }
    },
  }
}
</script>

<style lang="css" scoped>
  .padding-left-name{
    padding-left: .4em !important;
  }
  .padding-right-name{
    padding-right: .4em !important;
  }
  .no-polls{
    font-size: 1.8em;
    color: lightgray;
  }
  .padding-card{
    padding: 3em;
  }
  .no-color{
    color: #ffffff00;
  }
 .break-td {
    max-width: 242px;
    overflow-wrap: break-word;
 }
</style>
