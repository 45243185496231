
import Services from './base-services';

const service = new Services('questionnaires');

export default {
  create: (data: any) => {
    return service.post('create', data);
  },
  listByType: (type: string) => {
    return service.get(`list/${type}`);
  },
  getOneById: (id: number) => {
    return service.get(`${id}`);
  },
  updateQuestion: (data: object) => {
    return service.put('editQuestion', data);
  },
  toggleStatus: (id: number, active: boolean) => {
    return service.put('toggle-status', { id, active });
  },
};
