var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"wrap":"","row":""}},[_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12","md":"3"}},[_c('h4',{staticClass:"display-1"},[_vm._v(_vm._s(_vm.$t('models.engagements')))])]),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","md":"9"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0 text-right",attrs:{"cols":"12","md":"8"}},[_c('v-btn',{attrs:{"outlined":"","large":"","color":"primary"},on:{"click":function($event){_vm.showModal = !_vm.showModal}}},[_vm._v(" "+_vm._s(_vm.$t('input.generic_link_btn'))+" ")])],1),_c('v-col',{staticClass:"pa-0 text-right pt-5 pt-md-0",attrs:{"cols":"12","md":"4"}},[(_vm.$can('engagement.create'))?_c('v-btn',{staticClass:"ml-2",attrs:{"large":"","color":"primary","to":"/engagement/create"}},[_vm._v(" "+_vm._s(_vm.$t('engagement.add_engagement'))+" ")]):_vm._e()],1)],1)],1),_c('v-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('v-card',[_c('v-row',{staticClass:"ml-3",attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"xs":"11","sm":"3"}},[_c('v-select',{attrs:{"label":_vm.$t('employees.filter_by'),"items":_vm.filters,"prepend-inner-icon":"mdi-filter-outline"},on:{"change":_vm.filterItems}})],1)],1),_c('x-data-table',{attrs:{"headers":_vm.headers,"parent-fetch-data":_vm.getEngagements,"options":_vm.options,"no-data":"engagement.no_data"},scopedSlots:_vm._u([{key:"structure",fn:function(prop){return [_c('td',{staticClass:"text-xs-left text-center"},[(prop.item.displayName)?_c('v-tooltip',{attrs:{"bottom":"","color":"blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(_vm._s(prop.item.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(prop.item.displayName))])]):_c('div',[_vm._v(_vm._s(prop.item.name))])],1),_c('td',{staticClass:"text-xs-center px-0 break-td",attrs:{"align":"center"}},[_c('v-tooltip',{attrs:{"top":"","color":"blue","disabled":prop.item.status !== 'creating'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"outlined":"","label":"","color":_vm.getColor(prop.item.status)}},on),[_vm._v(" "+_vm._s(_vm.$t(("engagement." + (prop.item.status))))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('input.may_take_while')))])])],1),_c('td',{staticClass:"break-td text-center px-0"},[_vm._v(_vm._s(prop.item.totalReceivers))]),_c('td',{staticClass:"text-xs-center px-0 break-td text-center"},[_vm._v(_vm._s(_vm.formatDate(prop.item.deliveredAt))+" "+_vm._s(_vm.$t('engagement.at_the'))+" "+_vm._s(_vm.formatDate(prop.item.validUntil)))]),(prop.item.status === 'creating' || prop.item.status === 'editing' || prop.item.status === 'launching')?_c('td',{staticClass:"text-xs-center px-0 text-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":"blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.refreshPage}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("refresh")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('input.refresh')))])])],1):_c('td',{staticClass:"text-xs-center px-0 text-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":"blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":("/engagement/" + (prop.item.id) + "/show"),"text":"","icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("visibility")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('input.view_details')))])]),_c('v-tooltip',{attrs:{"disabled":_vm.notEditable(prop.item.status),"bottom":"","color":"green"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":("/engagement/" + (prop.item.id) + "/edit"),"text":"","icon":"","disabled":_vm.notEditable(prop.item.status)}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-square-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('input.edit')))])]),_c('v-tooltip',{attrs:{"disabled":prop.item.status !== 'completed',"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":("/engagement/reports/" + (prop.item.id)),"text":"","icon":"","disabled":prop.item.status !== 'completed'}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chart-bar-stacked")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('engagement.go_to_report')))])])],1)]}}])})],1)],1)],1),_c('x-url-invitation',{attrs:{"show":_vm.showModal},on:{"close":function () { return this$1.showModal = false; }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }