
export default {
  follow_up: 'Seguimiento',
  followup_report: 'Reporte de Seguimiento',
  followup_report_desc: 'Selecciona hasta dos demográficos para poder generar el reporte de seguimiento.',
  total_participants: 'Total Participantes',
  total_completed: 'Completados',
  total_remaining: 'Faltantes',
  totals: 'Totales'
};
