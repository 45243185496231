
export default {
  'not_found': 'La página que busca no ha sido encontrada.',
  'forbidden': 'Usted no está autorizado para acceder a esta información.',
  'questionnaire_no_lang': 'El cuestionario no está disponible en el idioma seleccionado',
  'auth/invalid-credentials': 'Los datos ingresados son incorrectos.',
  // tslint:disable-next-line:max-line-length
  'auth/user-is-disabled': 'El usuario ingresado se encuentra deshabilitado.<br/>Por favor comunicarse con el administrador.',
  'auth/employee-no-exist': 'Empleado no encontrado.',
  'auth/demo-request-exist': 'Ya existe una solitud de cuenta demo con el correo solicitado.',
  'validator/verify_fields': 'Por favor, verifique los campos del formulario.',
  'employees/enterprise-is-not-active': 'Esta empresa no se encuentra activa.',
  'validator/invalid-input': 'Uno o más campos no son válidos.',
  'validator/academicDegreeId-invalid-input': 'El campo nivel académico es obligatorio por corte demográfico.',
  'validator/countryId-invalid-input': 'El campo país es obligatorio por corte demográfico.',
  'validator/headquarterId-invalid-input': 'El campo sede es obligatorio por corte demográfico.',
  'validator/chargeId-invalid-input': 'El campo cargo es obligatorio por corte demográfico.',
  'validator/departmentId-invalid-input': 'El campo área/departamento es obligatorio por corte demográfico.',
  'validator/genderId-invalid-input': 'El campo género es obligatorio por corte demográfico.',
  'validator/jobTypeId-invalid-input': 'El campo tipo de contratación es obligatorio por corte demográfico.',
  'validator/birthdate-invalid-input': 'El campo fecha de nacimiento es obligatorio por corte demográfico.',
  'validator/admission-invalid-input': 'El campo fecha de ingreso es obligatorio por corte demográfico.',
  // tslint:disable-next-line:max-line-length
  'employees/identify-document-not-in-headers': 'La cabecera para el documento de identificación no está presente en el archivo.',
  'employees/missing-required-headers-in-csv': 'El archivo debe poseer todas las cabeceras obligatorias.',
  'employees/empty-fields': 'Todos los campos del formulario deben ser completados.',
  'employees/employee-already-exists': 'Este empleado ya existe.',
  'employees/employee-already-exists-another-enterprise': 'Este empleado ya existe en otra empresa.',
  'employees/employee-already-active-another-enterprise': 'Este empleado ya existe activo en otra empresa.',
  'email/unique:User,email,user_id': 'El correo especificado ya se encuentra en uso.',
  'validator/reminders': 'Solo puede seleccionar cinco recordatorios.',
  'validator/delete_reminder': 'No puede eliminar este recordatorio.',
  'engagements/token-not-found': 'El token suministrado no pudo encontrarse.',
  // tslint:disable-next-line:max-line-length
  'engagements/token-poll-completed': 'Esta encuesta ya fue completada.<br/><br/>Recuerde que, una vez finalizado el llenado de la encuesta, no es posible acceder otra vez a ella.',
  'engagements/engagement-does-not-exist': 'Esta medición Engagement no existe.',
  'engagements/poll-not-found': 'La encuesta solicitada no existe.<br/><br/>Verifique su token de acceso.',
  // tslint:disable-next-line:max-line-length
  'engagements/poll-has-ended': 'La encuesta solicitada finalizó.<br/><br/>Una vez alcanzada la fecha de finalización, no podrá acceder a la encuesta.',
  'engagements/poll-ended': 'La encuesta ya finalizó.',
  // tslint:disable-next-line:max-line-length
  'engagements/poll-employee-not-found': 'La encuesta a la que intenta acceder no existe.<br/><br/>Por favor verifique su participación e intente nuevamente.',
  'engagements/missing-questions': 'Todas las preguntas deben ser respondidas.',
  'engagements/missing-extra-questions': 'Todas las preguntas adicionales deben ser respondidas.',
  'engagements/employee-not-found': 'La encuesta no pertenece a un empleado de la empresa identificada.',
  'engagements/no-pending-employee': 'No existen empleados pendientes por responder.',
  'engagements/population-report/spend-fail': 'Hubo un error al procesar su pago',
  'engagements/enterprise-unauthorized': 'Acceso a medición no autorizado',
  'engagements/assessment-closed': 'Esta medición Engagement ha sido cerrada',
  'engagements/assessment-still-progress': 'Esta medición Engagement aún esta en progreso',
  'pulses/token-not-found': 'El token suministrado no pudo encontrarse.',
  // tslint:disable-next-line:max-line-length
  'pulses/token-poll-completed': 'Esta encuesta ya fue completada.<br/><br/>Recuerde que, una vez finalizado el llenado de la encuesta, no es posible acceder otra vez a ella.',
  'pulses/pulse-does-not-exist': 'Esta medición Pulse no existe',
  'pulses/poll-not-found': 'La encuesta solicitada no existe.<br/><br/>Verifique su token de acceso.',
  // tslint:disable-next-line:max-line-length
  'pulses/poll-has-ended': 'La encuesta solicitada finalizó.<br/><br/>Una vez alcanzada la fecha de finalización, no podrá acceder a la encuesta.',
  // tslint:disable-next-line:max-line-length
  'pulses/poll-employee-not-found': 'La encuesta a la que intenta acceder no existe.<br/><br/>Por favor verifique su participación e intente nuevamente.',
  'pulses/missing-extra-questions': 'Todas las preguntas adicionales deben ser respondidas.',
  'pulses/no-active-employees': 'No existen empleados activos para esta encuesta.',
  'pulses/enterprise-unauthorized': 'Acceso a medición no autorizado',
  'pulses/assessment-closed': 'Esta medición Pulse ha sido cerrada',
  'pulses/assessment-still-progress': 'Esta medición Pulse aún esta en progreso',
  'wait': '¡Espera!',
  'undefined': 'Ha ocurrido un error en el sistema.',
  'timezone': 'La fecha y hora seleccionada no es válida para esa zona horaria.',
  'question_emply': 'La pregunta no puede estar vacía.',
  'validator/select_one': 'Seleccione al menos un idioma.',
  'validator/empty-field': 'El campo no puede estar vacío.',
  'validator/no_receivers': 'La encuesta debe poseer al menos un encuestado.',
  'validator/no_less_receivers': 'La encuesta no debe poseer menor población de encuestados que antes.',
  'image/size': 'El tamaño de la imagen debe ser inferior a 2MB.',
  'employees_limit': 'Ya ha alcanzado el limite de empleados permitidos por su licencia.',
  'engagements_limit': 'Ya ha alcanzado el limite de Engagement permitidos por su licencia.',
  'pulses_limit': 'Ya ha alcanzado el limite de Pulses permitidos por su licencia.',
  'modal_identify_type': 'Seleccione un tipo de identidad válida.',
  'modal_identify_document': 'Ingrese un número de identificación válido.',
  'modal_first_name': 'Ingrese un nombre válido.',
  'modal_last_name': 'Ingrese un apellido válido.',
  'modal_exists_email': 'El correo electrónico ingresado ya existe.',
  'modal_user_not_employee': 'El correo electrónico ingresado existe como otro tipo de usuario',
  'modal_exist_identification': 'El número de identificación ingresado ya existe.',
  'modal_email': 'Ingrese un correo electrónico válido.',
  'modal_gender': 'Seleccione un género válido.',
  'modal_antiquity_range': 'Ingrese una fecha de ingreso válida.',
  'modal_department': 'Seleccione un departamento válido.',
  'modal_charge': 'Seleccione un cargo válido.',
  'modal_job_type': 'Seleccione un tipo de contratación válida.',
  'modal_age_range': 'Ingrese una fecha de nacimiento válida.',
  'modal_country': 'Seleccione un país válido.',
  'modal_headquarter': 'Seleccione una sede válida.',
  'modal_academic_degree': 'Seleccione un nivel académico válido.',
  'modal_validation_error': 'Quedan registros por corregir.',
  'email_already_taken': 'El correo electrónico ingresado ya está en uso.',
  'groups/updated-plan-some': 'No se aplicó el cambio para todas las empresas.',
  'groups/updated-plan-none': 'No se aplicó el cambio debido a un error.',
  'generic-error/retry': '¡Hemos tenido un problema cargando tu encuesta, por favor recarga la página!',
  'video/size': 'El tamaño del video debe ser inferior a 20MB.',
  'error_disable_questionnaires': 'No se pueden desactivar todos los cuestionarios',
  'auth/enterprise_disabled': 'La empresa solicitada se encuentra inactiva',
  'auth/employee_disabled': 'El empleado solicitado se encuentra inactivo',
  'enterprise/no_active_plan': 'Esta empresa no posee plan activo',
  'engagements/enterprise-not-found': 'La empresa solicitada no existe',
  'engagements/max-counter': 'El nombre no puede exceder el número máximo de caracteres',
  'required_enterprise_p1': 'Para continuar debe seleccionar una empresa.',
  'required_enterprise_p2': 'En breves momentos será redirigido a Suite.',
  'required_open_question': 'Debe llenar los datos de las preguntas abiertas.',
  'engagement/questionnaire-base-not-found': 'El cuestionario base seleccionado no existe',
  'no_balance': 'Sin balance suficiente',
  'no_balance_msg': 'Empresa sin balance suficiente, si continua el proceso, el sistema intentará consumir saldos autorizados.',
  'no_permissions': 'No posee los permisos para ingresar a este producto',
  'ask_permissions': 'Si desea solicitar la activación de este producto o ya la ha solicitado y aún no lo posee disponible'
};
