
<template>
  <v-container fluid>
    <v-row wrap row>
      <v-col cols="12" md="3" class="pt-4">
        <h4 class="display-1">{{ $t('models.pulse') }}</h4>
      </v-col>

      <v-col cols="12" md="9" class="pl-0">
        <v-row no-gutters>
          <v-col cols="12" md="8" class="ml-auto pa-0 text-right">
            <v-btn outlined large
              color="primary"
              @click="showModal = !showModal"
            >
              {{ $t('input.generic_link_btn') }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="3" class="pa-0 text-right pt-5 pt-md-0">
            <v-btn large
              v-if="$can('pulse.create')"
              color="primary"
              class="ml-2"
              to="/pulse/create"
            >
              {{ $t('pulses.add_pulse') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="mt-1">
        <v-card>
          <v-row row wrap class="ml-3">
            <v-col xs="11" sm="3">
              <v-select
                :label="$t('employees.filter_by')"
                :items="filters"
                prepend-inner-icon="mdi-filter-outline"
                @change="filterItems"
              ></v-select>
            </v-col>
          </v-row>

          <x-data-table
            :headers="headers"
            :parent-fetch-data="getPulses"
            :options="options"
            no-data="pulses.no_data"
          >
            <template v-slot:structure="prop">
              <td class="text-xs-left text-center">
                <v-tooltip bottom color="blue" v-if="prop.item.displayName">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">{{ prop.item.name }}</div>
                  </template>
                  <span>{{ prop.item.displayName }}</span>
                </v-tooltip>
                <div v-else>{{ prop.item.name }}</div>
              </td>
              <td class="text-xs-center px-0 text-center">
                <v-tooltip top color="blue" :disabled="prop.item.status !== 'creating'">
                  <template v-slot:activator="{ on }">
                    <v-chip outlined label
                      v-on="on"
                      :color="getColor(prop.item.status)"
                    >
                      {{ $t(`pulses.${prop.item.status}`) }}
                    </v-chip>
                  </template>
                  <span>{{ $t('input.may_take_while') }}</span>
                </v-tooltip>
              </td>
              <td class="text-xs-center text-center">{{ prop.item.totalReceivers }}</td>
              <td class="text-xs-center px-0 text-center">
                {{ formatDate(prop.item.deliveredAt) }} {{ $t('engagement.at_the') }}
                {{ formatDate(prop.item.validUntil) }}
              </td>
              <td
                v-if="prop.item.status === 'creating' || prop.item.status === 'editing' || prop.item.status === 'launching'"
                class="text-xs-center px-0 text-center"
              >
                <v-tooltip bottom color="blue">
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on"
                      @click="refreshPage"
                    >
                      <v-icon small>refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.refresh') }}</span>
                </v-tooltip>
              </td>
              <td v-else class="text-xs-center px-0 text-center">
                <v-tooltip bottom color="blue">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :to="`/pulse/${prop.item.id}/show`" text icon>
                      <v-icon small>visibility</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.view_details') }}</span>
                </v-tooltip>

                <v-tooltip :disabled="notEditable(prop.item.status)" bottom color="green">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`pulse/${prop.item.id}/edit`"
                      v-on="on"
                      text
                      icon
                      :disabled="notEditable(prop.item.status)"
                    >
                      <v-icon small>mdi-square-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.edit') }}</span>
                </v-tooltip>

                <v-tooltip :disabled="prop.item.status !== 'completed'" bottom color="blue">
                  <template v-slot:activator="{ on }">
                    <v-btn text icon
                      v-on="on"
                      :disabled="prop.item.status !== 'completed'"
                      @click="goToReport(prop.item)"
                    >
                      <v-icon small>mdi-chart-bar-stacked</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('pulses.go_to_report') }}</span>
                </v-tooltip>
              </td>
            </template>
          </x-data-table>
        </v-card>
      </v-col>
    </v-row>
    <x-url-invitation
      :show="showModal"
      @close="() => this.showModal = false"
    ></x-url-invitation>
  </v-container>
</template>

<script>

import { mapState } from "vuex";

import PulseService from "../../services/pulses";

export default {
  data() {
    return {
      headers: [
        "pulses.name",
        "pulses.status",
        "pulses.participants",
        "pulses.period",
        "pulses.action"
      ],
      pulses: [],
      modalDis: {
        open: false,
        item: {}
      },
      modalEna: {
        open: false,
        item: {}
      },
      options: {
        filter: null,
        search: null,
      },
      showModal: false,
      tokenUrl: "",
      showTooltip: false
    }
  },
  computed: {
    filters () {
      return [
        { text: this.$t('page_all'), value: null },
        { text: this.$t('pulses.pending'), value: 'pending' },
        { text: this.$t('pulses.progress'), value: 'progress' },
        { text: this.$t('pulses.completed'), value: 'completed' }
      ]
    },
    ...mapState({
      user: state => state.session.user
    })
  },
  methods: {
    refreshPage (value) {
      document.location.reload()
    },
    goToReport (item) {
      // Old Report path
      /*
      item.isNewModel ?
        this.$router.push(`/pulse/report/${item.id}`) :
        this.$router.push(`/pulse/report/selection/${item.id}`)
      */
      this.$router.push(`/pulse/${item.id}/report`)
    },
    getColor(status) {
      switch (status) {
        case "pending":
          return "gray";
        case "cancelled":
          return "red";
        case "completed":
          return "green";
        case "progress":
          return "blue";
      }
    },
    getPulses(options) {
      return PulseService.list(options);
    },
    formatDate(date) {
      if (!date) return null;
      const dateAux = date.split("T")[0];
      const [year, month, day] = dateAux.split("-");
      return `${day}/${month}/${year}`;
    },
    notEditable(status) {
      return status === "completed" || status === "cancelled";
    },
    filterItems(value) {
      this.options = {
        ...this.options,
        filter: value,
      };
    },
  }
};
</script>
<style>
 .break-td {
    max-width: 242px;
    overflow-wrap: break-word;
 }
 .btn-mg {
   margin: 6px 8px;
 }
</style>
