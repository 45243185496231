
const examplesWords = ['Cuerda', 'Limpieza', 'Esmalte', 'Propina', 'Bailarina', 'Micro', 'Venecia', 'Chinche', 'Alzar', 'Casado', 'trasnocha', 'Cabina', 'Nombre', 'Malo', 'Pesadilla', 'Vegetal', 'Buzo', 'Tragaluz', 'Detenerse', 'Judas', 'Morder', 'Trigo', 'Alquiler', 'Kamikaze', 'Cordón', 'Oasis', 'Biplano', 'Fingir', 'Insensible', 'Lucifer', 'Calentador', 'Camping', 'Pasamanos', 'Nacimiento', 'Adoptar', 'Tierras', 'Soldar', 'Indios', 'Picotear', 'Visera', 'Lanza', 'Remolcador', 'Manubrio', 'Velero', 'Papelera', 'Reno', 'Ovillo', 'Transparente', 'Calculadora', 'China', 'Sastre', 'Altavoz', 'Consistencia', 'Granjero', 'Decir', 'Negocios', 'Revocar', 'Estatua', 'Cometa', 'Sentado', 'Acupuntura', 'Momia', 'Perla', 'Ciego', 'Fecha', 'Hincha', 'Involuntario', 'Diagrama', 'Escuchar', 'Bailarina', 'Moverse', 'Abrigo', 'Coma']

function getIdx (max, min) {
  const num = Math.floor(Math.random() * 10)
  if (num > max) {
    return max
  } else if (num < min) {
    return min
  }
  return num
}

export default (vueInstance, options) => {
  options = options || {}
  const min = options.min && typeof options.min === 'number' ? options.min - 1 : 0
  const max = options.max && typeof options.max === 'number' ? options.max - 1 : 4
  const words = options.words && typeof options.words === 'object' ? options.words : examplesWords
  const devSelected = {}
  const devSelectedWordsAux = {}
  for (const page of vueInstance.pages) {
    for (const question of page) {
      const key = `${question.questionId}-${question.type}`
      if (question.type !== 'words') {
        devSelected[key] = getIdx(max, min)
      } else {
        const wordsArr = []
        devSelectedWordsAux[key] = []
        for (const field in [0,1,2]) {
          const randomWord = words[getIdx(words.length - 1, 0)]
          wordsArr.push(randomWord)
          devSelectedWordsAux[key][field] = randomWord
        }
        devSelected[key] = wordsArr
      }
    }
  }
  vueInstance.selected = devSelected
  vueInstance.selectedWordsAux = devSelectedWordsAux

  vueInstance.saveCurrentAnswers(true)
  vueInstance.currentPage = vueInstance.pages.length - 1
}
