
<template>
  <v-row wrap>
    <v-col cols="12">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            {{$t('dashboard.admin_bottom_table_title')}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-card>
      <v-card>
        <v-card-text>
          <v-data-table
            :hide-default-footer="true"
            :headers="$t('dashboard.admin_bottom_table_header')"
            :items="info.polls"
            :footer-props="{
                itemsPerPageOptions: [5, 10, 25, {'text': $t('page_all'), 'value':-1}],
                itemsPerPageText: $t('results_per_pages'),
              }"
          >
            <template v-slot:body="{ items }">
              <tbody>
              <tr v-for="item in items" :key="`${item.id}-${item.type}`">
                <td class="text-left padding-left-name px-0 break-td"> {{ item.name }}</td>
                <td class="text-center px-0 break-td"> {{ item.enterpriseName }}</td>
                <td class="text-center px-0">
                  <v-chip
                    outlined
                    label
                    :color="getColor(item.status)"
                  >{{ $t(`engagement.${item.status}`) }}</v-chip>
                </td>
                <td class="text-center px-0"> {{ new Date(item.deliveredAt).toLocaleDateString('es-CO') }}</td>
              </tr>
              </tbody>
            </template>
            <template v-slot:pageText="props">
              {{ props.pageStart }} - {{ props.pageStop }} {{ $t('page_text') }} {{ props.itemsLength }}
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" text dense prominent type="error" icon="mdi-shield-remove" class="text-xs-center mt-3" v-if="!loading">
                <h3>{{ $t('no_data') }}</h3>
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    info: Object,
  },
  methods: {
    getColor(status) {
      switch (status) {
        case "pending":
          return "gray";
        case "cancelled":
          return "red";
        case "completed":
          return "green";
        case "progress":
          return "blue";
      }
    }
  }
};
</script>

<style lang="css" scoped>
.padding-left-name{
  padding-left: .4em !important;
}
.padding-right-name{
  padding-right: .4em !important;
}
.break-td {
  max-width: 242px;
  overflow-wrap: break-word;
}
</style>
