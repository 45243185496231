
export default {
  create: 'Crear pulse',
  poll_title: 'Encuesta de Pulse',
  // tslint:disable-next-line:max-line-length
  poll_text: 'Lea cada una de las afirmaciones presentadas abajo y seleccione la opción que mejor representa su opinión según la siguiente escala: Totalmente en desacuerdo, Parcialmente en desacuerdo, Ni en desacuerdo ni de acuerdo, Parcialmente de acuerdo, Totalmente de acuerdo.',
  totally_disagree: 'Totalmente<br/> en desacuerdo',
  parcially_disagree: 'Parcialmente<br/> en desacuerdo',
  nor_agree_disagree: 'Ni en desacuerdo<br/> ni de acuerdo',
  parcially_agree: 'Parcialmente<br/> de acuerdo',
  totally_agree: 'Totalmente<br/> de acuerdo',
  entry_title: 'Bienvenido a su encuesta Pulse',
  // tslint:disable-next-line:max-line-length
  entry_text: 'Te invitamos a responder la encuesta de Pulse, seguros que con tus respuestas construiremos un mejor ambiente laboral. Cuentas con un enlace propio para tu encuesta, recuerda no compartirlo.',
  questions_n: '{n} Preguntas',
  minutes_30: '30 Minutos',
  complete_anonymity: 'Anónimato total',
  finish_title: 'Muchas gracias por haber completado la encuesta',
  finish_text: 'Su percepción es muy importante para nosotros.',
  new: 'Nueva encuesta Pulse',
  overview: 'Visión general',
  receptors: 'Encuestados',
  date: 'Fecha',
  questions: 'Preguntas',
  revition: 'Revisión & Personalización',
  poll_name: 'Nombre de la encuesta',
  want_external_name: '¿Desea colocar un nombre externo para los encuestados?',
  external_name: 'Nombre externo para los encuestados',
  want_to_send: '¿A quien desea enviar esta encuesta?',
  hours: 'Hora de envío',
  minutes: 'Minuto',
  date_delivery: 'Fecha de lanzamiento',
  poll_valid_until: 'Encuesta válida hasta',
  time_zone: 'Zona horaria',
  want_send_reminders: '¿Desea enviar recordatorios?',
  reminders: 'Enviar recordatorio',
  questionnaire: 'Cuestionario',
  committal_question: 'Preguntas de compromiso',
  want_committal_question: '¿Desea añadir preguntas de compromiso?',
  want_questionnaire_question: '¿Desea añadir preguntas de un cuestionario?',
  want_open_question: '¿Desea añadir preguntas adicionales?',
  add_open_question: 'Añadir otra pregunta',
  remove_open_question: 'Elimina última pregunta',
  min_open_question: 'Debes tener al menos una pregunta adicional disponible',
  max_open_question: 'Solamente puedes {n} preguntas adicionales por encuesta',
  open_question: 'Preguntas adicionales',
  insert_question: 'Agregar enunciado de la pregunta {n}',
  insert_question_option: 'Agregar opción {o} para pregunta {n}',
  add_question_option: 'Añadir otra opción ',
  remove_question_option: 'Elimina última opción ',
  min_question_option: 'Debes tener al menos {n} opciones disponibles',
  max_question_option: 'Sólo puede indicar {n} opciones para una pregunta adicional',
  total_receptors: 'Total de encuestados: {n}',
  percent_total: 'Porcentaje del total a tomar',
  general_revition: 'Revisión general',
  settings: 'Ajustes',
  status: 'Estado',
  inter_name: 'Nombre interno',
  send_reminders: 'Envio de recordatorios',
  personalization: 'Personalización',
  poll_invitation: 'invitación a la encuesta',
  reminder_mail: 'Correo de recordatorio',
  tk_message: 'Mensaje de agradecimiento',
  message_subject: 'Asunto del mensaje',
  body_message: 'Cuerpo del mensaje',
  message_from_the_company: 'Mensaje por la empresa',
  created_pulse: '¡Encuesta creada exitosamente!',
  id: 'Id',
  name: 'Nombre',
  participants: 'Participantes',
  period: 'Período',
  action: 'Acciones',
  filter_by: 'Filtrar por...',
  look_for: 'Buscar una encuesta',
  no_data: 'No hay encuestas para mostrar',
  pulse_realized: 'Pulse realizados',
  add_pulse: 'Crear Pulse',
  pending: 'Pendiente',
  completed: 'Completada',
  cancelled: 'Cancelada',
  progress: 'En Progreso',
  creating: 'Creando',
  editing: 'Editando',
  launching: 'Lanzamiento',
  at: 'A las',
  download: 'Descargar',
  aprogress: 'Progreso',
  edit: '¡Encuesta editada exitosamente!',
  tooltip_name: 'Nombre interno de la encuesta',
  tooltip_display_name: 'Nombre de la encuesta para los encuestados',
  summary: 'Resumen de la encuesta',
  to_edit: 'Editar encuesta',
  total_progress: 'Progreso total',
  end_date: 'Fecha cierre',
  poll_completed: 'Encuestas completadas',
  poll_no_completed: 'Encuestas no completadas',
  resend: 'Reenviar encuestas no completadas',
  resend_success: '¡Encuestas reenviadas exitosamente!',
  demographic_items: 'Item demográficos',
  select_age_range: 'Seleccione un rango de edades',
  selection_dimension: 'Seleccione las dimensiones',
  selection_question_committal: 'Seleccione pregunta de compromiso',
  to_all_my_organization: 'A toda mi organización',
  to_demographic_cuts: 'A cortes demográficos',
  to_receivers_randomly: 'A encuestados de manera aleatoria',
  countries: 'Seleccione algún país.',
  additional_demographics1: 'Demográfico Adicional 1',
  additional_demographics2: 'Demográfico Adicional 2',
  select_antiquity_range: 'Seleccione el rango de antigüedad',
  genders: 'Géneros',
  charges: 'Cargos',
  job_types: 'Tipo de contratación',
  departments: 'Áreas / Departamentos',
  academic_degrees: 'Cargo académico',
  others: '+{n} otros',
  age_low: 'Menos de {n} años',
  age_range: 'De {n} a {p} años',
  age_upper: 'Más de {n} años',
  antiquity_low: 'Menos de {n} meses',
  antiquity_range_single: 'De {n} meses a {p} año',
  antiquity_range_one: 'De {n} año a {p} años',
  antiquity_range: 'De {n} años a {p} años',
  antiquity_upper: 'Más de {n} años',
  generate: 'Generar',
  display_options: 'Mostrar opciones',
  charts_select: {
    table_results_by_dimension: 'Tabla de resultados por dimensión',
    comparative_by_dimensions: 'Comparativo por dimensiones',
    dimensions_ranking: 'Ranking de dimensiones',
    attributes_ranking: 'Ranking de atributos',
    rate_monthly: 'Histórico por dimensiones en los últimos 12 meses.',
    rate_monthly_attr: 'Histórico por atributos en los últimos 12 meses',
    rate_monthly_variables: 'Histórico por variables en los últimos 12 meses',
    table_results_by_attribute: 'Tabla de resultados por atributos',
    comparative_by_attributes: 'Comparativo por atributos',
    table_results_by_variable: 'Tabla de resultados por variable',
    comparative_by_variables: 'Comparativo por variables',
    highest_attributes_ranking: 'Ranking de atributos más altos',
    lowest_attributes_ranking: 'Ranking de atributos más bajos',
    highest_variables_ranking: 'Ranking de variables más altas',
    lowest_variables_ranking: 'Ranking de variables más bajas',
  },
  select_placeholder: 'Seleccione un gráfico a generar',
  start_date: 'Fecha de inicio',
  go_to_report: 'Ir al reporte',
  pulse_report: 'Reporte Pulse',
  no_charts: 'No hay gráficos disponibles',
  select_comparition: 'Seleccione otra encuesta para la comparación',
  pulse: 'Pulse',
  engagement: 'Engagement',
  previous_poll: 'Encuesta anterior',
  current_pulse: 'Pulse actual',
  dimension: 'Dimensión',
  dimensions: 'Dimensiones',
  attribute: 'Atributo',
  attributes: 'Atributos',
  variable: 'Variable',
  variables: 'Variables',
  rate: 'Tendencia',
  percent: 'Porcentaje',
  text: ['Alto', 'Bajo'],
  change_dimensions: 'Cambiar dimensión',
  change_attributes: 'Cambiar atributo',
  change_variables: 'Cambiar variable',
  // tslint:disable-next-line:max-line-length
  report_alert: 'Únicamente aquellas mediciones Pulse/Engagement que tengan los mismos parámetros de medición y sean anteriores a la medición Pulse actual, estarán disponibles en el campo de “Seleccione otra encuesta para la comparación”.',
  want_to_compare: '¿Desea comparar esta medición con una anterior?',
  filter_by_demographic_cuts: 'Filtrar por Cortes Demográficos',
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  questionnaire_p: 'Cuestionario Pulse',
  questionnaire_table_legend: 'Puedes seleccionar de 1 hasta 6 atributos para tu medición',
  // tslint:disable-next-line:max-line-length
  pdf_explained: 'En este archivo se puden observar las preguntas pertenecientes al cuestionario "{name}" agrupadas por dimensión y atributo.',
  open_question_info: 'Puede indicar preguntas personalizadas adicionales, indicando las posibles respuestas.',
  question: '[Pregunta]:',
  // tslint:disable-next-line:max-line-length
  congratulations: '¡Felicitaciones, completaste el <span class="headline" style="color: #44C156">{progress}%</span> de la encuesta!',
  all_right: 'Todos los derechos reservados 2019. ©',
  automatic_message: 'Este mensaje fue automáticamente enviado desde',
  rate_chart_types: [
    { text: 'Dimensiones', value: false },
    { text: 'Atributos', value: true },
  ],
  no_questionnaries_title: 'Solo puede crear esta encuesta con preguntas de compromiso',
  // tslint:disable-next-line:max-line-length
  no_questionnaries_body: 'No existen cuestionarios activos para su empresa, por esta razón solo puede elegir preguntas de comporomiso, por favor elija preguntas de comproimiso solamente para continuar con la creación de su encuesta. Por favor pongase en contacto con el administrador del sistema para solventar este problema.',
  connection_interrupted: 'Conexión interrumpida, continuaremos completando sus respuestas restableciendo la conexión.',
  connection_interrupted_trying: 'Intentando conectar en {seconds} segundos',
  connection_interrupted_out: 'Lo sentimos, por favor verifique su conexión a internet y refresque la página',
  connection_interrupted_connecting: 'Conectando',
  no_balance: 'Sin balance suficiente',
  no_balance_msg: 'Empresa sin saldo suficiente, si continua el proceso, el sistema intentará consumir saldos autorizados.',
  confirm_create_title: 'Confirme la creación de la medición',
  workshop_cost: 'Costo de la Medición',
  poll_paid: 'Medición ya ha sido pagada.',
  your_balance: 'Su saldo:',
  token_unit: 'OCC Tokens',
  balance_after: 'Saldo luego de crear la valoración:',
  non_active_employees: 'Debe tener al menos un colaborador activo para esta encuesta.',
  operation_failed: 'Operación no exitosa, se recomienda:',
  acquire_tokens: 'Adquirir más OCC Tokens',
  invitation_mail_content: {
    subject: 'Bienvenido a la medición Pulse',
    body: `Bienvenido(a) al proceso de valoración de Pulse, la cual nos permitirá obtener información valiosa acerca de los niveles de compromiso y pertenencia en  nuestra compañía.
    <br/>Información clave sobre esta encuesta:
    <ul><li>La información entregada será manejada de manera confidencial y será utilizada con fines estadísticos.</li></ul>
    <ul><li>Por favor contestar con total sinceridad.</li></ul>
    <ul><li>Para una mejor experiencia use Google Chrome, recuerde que debe estar conectado a internet.</li></ul>
    <ul><li>Si tiene alguna duda o evidencia algún problema con la encuesta, por favor no dude en contactarnos en el correo: <a href="mailto:info@occsolutions.org">info@occsolutions.org</a>.</li></ul>
    <ul><li>Recuerde que este link es personal y no se debe compartir.</li></ul>
    <br/>De antemano le agradecemos por su tiempo y sus valiosos aportes en este importante proceso.`
  },
  reminder_mail_content: {
    subject: 'Recordatorio medición Pulse',
    body: `Te recordamos culminar el proceso de valoración de Pulse, la cual nos permitirá obtener información valiosa acerca de los niveles de compromiso y pertenencia de nuestra compañía.
    <br/>Información clave sobre esta encuesta:
    <ul><li>La información entregada será manejada de manera confidencial y será utilizada con fines estadísticos.</li></ul>
    <ul><li>Por favor contestar con total sinceridad.</li></ul>
    <ul><li>Para una mejor experiencia use Google Chrome, recuerde que debe estar conectado a internet.</li></ul>
    <ul><li>Si tiene alguna duda o evidencia algún problema con la encuesta, por favor no dude en contactarnos en el correo: <a href="mailto:info@occsolutions.org">info@occsolutions.org</a>.</li></ul>
    <ul><li>Recuerde que este link es personal y no se debe compartir.</li></ul>
    <br/>De antemano le agradecemos por su tiempo y sus valiosos aportes en este importante proceso.`
  }
};
