
<template>
  <v-app>
    <v-content class="background">
      <v-container fill-height>
        <v-layout align-center justify-center row>
          <v-flex class="text-xs-center" xs12 sm10 md8 :class="changeClass ? 'lg8' : 'lg6'">
            <img src="public/img/occ-logo.png" style="width: 85%; display:block; margin:auto;" />
            <v-card class="elevation-12 mt-4">
              <v-card-text>
                <router-view />
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
    <x-alert :alert="alert"></x-alert>
  </v-app>
</template>

<script>

import { mapState } from "vuex";

export default {
  data() {
    return {
      changeClass: false
    };
  },
  computed: {
    ...mapState({
      alert: state => state.alert
    })
  },
  created() {
    if (this.$route.params.identification) {
      this.changeClass = true;
    }
  }
};
</script>
