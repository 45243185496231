<template>
  <v-dialog v-model="showModalConfirm" width="600px" persistent>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" class="text-center">
            <p class="headline font-weight-black" v-html="confirmText"></p>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-4 text-center">
          <v-col cols="6">
            <span class="headline" style="font-weight: bold;">{{ costText }}: </span>
          </v-col>
          <v-col cols="6">
            <span class="headline" style="font-weight: bold;">{{ $t('pulses.your_balance') }}</span>
          </v-col>
        </v-row>
        <v-row class="text-center mb-4">
          <v-col cols="6">
            <v-chip
              class="ma-2"
              color="red"
              text-color="white"
            >
              {{price}}
            </v-chip>
            <span class="title">
              {{ $t('pulses.token_unit') }}
            </span>
          </v-col>
          <v-col cols="6">
            <v-chip
              class="ma-2"
              color="green"
              text-color="white"
            >
              {{balance}}
            </v-chip>
            <span class="title">
              {{ $t('pulses.token_unit') }}
            </span>
          </v-col>
        </v-row>
        <hr>
        <v-row class="mt-2">
          <v-col cols="12" v-if="!isEnoughBalance" class="title text-center text-uppercase red--text">
            {{ noBalanceMsg }}
          </v-col>
          <v-col cols="12" v-else>
            <v-col cols="12" style="font-weight: bold;" class="title text-center text-uppercase">
              {{ balanceAfterMsg || $t('pulses.balance_after') }}
            </v-col>
            <v-col cols="12" class="title text-center text-uppercase">
              <v-chip
                class="ma-2"
                color="teal"
                text-color="white"
              >
                {{ (Number(balance) - Number(price) ) }}
              </v-chip>
              {{ $t('pulses.token_unit') }}
            </v-col>
          </v-col>
          <v-col cols="12" v-if="noActiveEmployee" class="title text-center text-uppercase red--text">
            {{ $t('pulses.non_active_employees') }}
          </v-col>
        </v-row>
        <v-row v-if="noBalanceResponse">
          <v-col cols="12" class="title text-center text-uppercase blue--text mt-2">
            <div> {{ $t('pulses.operation_failed') }} </div>
            <a :href="operationsLink" rel="noopener noreferrer" target="_blank">
              {{ $t('pulses.acquire_tokens') }}
            </a>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="pa-2 ma-0">
          <v-col cols="6">
            <v-btn block large @click="closeModal">{{ $t('input.cancel') }}</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block large class="white--text" color="primary" :disabled="!canCreate" @click="create">{{ confirmBtn || $t('input.confirm_save') }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    confirmText: String,
    costText: String,
    showModalConfirm: Boolean,
    balance: Number,
    price: Number,
    noBalanceMsg: String,
    operationsLink: String,
    noBalanceResponse: Boolean,
    noActiveEmployee: Boolean,
    canCreate: Boolean,
    balanceAfterMsg: String,
    confirmBtn: String
  },
  methods: {
    create () {
      this.$emit('result', 1)
    },
    closeModal () {
      this.$emit('result', 0)
    }
  },
  computed: {
    isEnoughBalance () {
      return this.balance >= this.price;
    }
  }
})
</script>
