
<template>
  <v-card class="mt-1" flat>
    <v-container class="pt-1">
      <v-row row wrap justify="center">
        <v-col cols="12">
          <section class="enterprise-logo">
            <img :src="occLogo" />
          </section>
        </v-col>

        <v-col cols="12" sm="8" class="text-center mt-3">
          <h4 class="display-1 break-name">{{ pollName }}</h4>
          <p class="mt-3">{{ $t('pulses.poll_text') }}</p>
        </v-col>

        <v-col cols="12" sm="8" class="mb-5 my-3">
          <v-progress-linear
            :value="progress"
            background-color="grey lighten-2"
            :color="percentColor"
            height="20"
            style="border-radius: 10px;"
          ></v-progress-linear>

          <h5 class="text-center headline font-weight-bold grey--text text--darken-1">
            {{ progress }}%
          </h5>
        </v-col>

        <v-col cols="12" v-for="(question, i) in dimentionsFiltered" :key="question.id">
          <v-card-text>
            <h5 class="text-center headline mb-3">
              {{ question.count }}.-
              <template>
                {{ question.label[langSelected] }}
              </template>
            </h5>
            <v-row justify="center" class="my-3 mb-4" v-if="question.type === 'committal' || question.type === 'questionnaire'">
              <template v-for="(face, $index) in faces">
                <v-col cols="6" sm="2" class="text-center" :key="`f${$index}`">
                  <v-btn large icon
                    :class="selectedOption(question.questionId, question.type, face.value)"
                    @click="selectAnswer(question.questionId, question.type, face.value)"
                  ><v-icon color="white" x-large>{{ face.icon }}</v-icon>
                  </v-btn>
                  <div class="cursor-pointer" v-html="$t(`engagement.${face.label}`)"
                    @click="selectAnswer(question.questionId, question.type, face.value)"></div>
                </v-col>
              </template>
            </v-row>
          </v-card-text>
        </v-col>

        <v-col cols="12">
          <v-card-actions>
            <div style="margin: 0 auto;">
              <v-btn large
                v-if="currentPage !== 0"
                @click="handlepaginate(0)"
              >
                {{ $t('input.back') }}
              </v-btn>

              <v-btn large
                v-if="currentPage !== pages.length - 1"
                :disabled="!pageCompleted"
                class="primary ml-2"
                @click="handlepaginate(1)"
              >
                {{ $t('input.next') }}
              </v-btn>

              <v-btn large
                v-if="currentPage === pages.length - 1 && progress === 100"
                class="primary ml-2"
                @click="finishPoll"
              >
                {{ $t('input.finish') }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-container>

    <x-dialog-entry
      :logo="occLogo"
      :display.sync="entryDialog"
      :countQuestion="questionTotal"
      :count-minutes="Math.floor(questionTotal / 3)"
      :language="language"
      :languages="languages"
      @changeLang="$emit('langChanged', $event)"
      @continue-entry="continueEntry"
    ></x-dialog-entry>

    <x-dialog-random
      :display.sync="rndDialog"
      @continue-random="continueRandom"
      :image="dialogContent.image"
      :text="dialogContent.text"
      :progress="progress"
    ></x-dialog-random>

    <x-dialog-finish
      :logo="occLogo"
      :name="pollName"
      :display.sync="finishDialog"
      :back-button="$route.path.startsWith('/outside') ? false : true"
      Ccode="enterprise.invitationCode"
    ></x-dialog-finish>

    <x-loading
      :display.sync="loading"
    ></x-loading>

    <x-alert :alert="alert"></x-alert>

    <v-btn fab dark small fixed bottom right large
      :color="percentColor"
      class="mb-1"
      style="cursor: default!important;"
    >
      {{ progress }}%
    </v-btn>
  </v-card>
</template>

<script>

import { mapState } from 'vuex'
import authService from '../../services/auth'
import XDialogEntry from './dialogs/entry.vue'
import XDialogRandom from './dialogs/random.vue'
import XDialogFinish from './dialogs/finish.vue'

export default {
  components: {
    XDialogEntry,
    XDialogRandom,
    XDialogFinish,
  },
  props: {
    language: String,
    languages: Array
  },
  data() {
    return {
      langSelected: null,
      occLogo: '/img/20200301_occ_solution_logo.png',
      progress: 0,
      selected: {},
      currentPage: 0,
      scrollOptions: {
        duration: 1000,
        easing: 'easeInOutCubic',
      },
      questions: [],
      pages: [],
      questionTotal: 0,
      loading: false,
      entryDialog: false,
      rndDialog: false,
      rndOnce: true,
      finishDialog: false,
      pollName: 'Pulse Demo',
      colors: {
        0: 'angry',
        1: 'sad',
        2: 'neutral',
        3: 'happy',
        4: 'veryhappy',
      },
      dialogContent: {},
      faces: [
        { value: 0, label: 'totally_disagree', icon: 'mdi-emoticon-angry-outline' },
        { value: 1, label: 'parcially_disagree', icon: 'mdi-emoticon-sad-outline' },
        { value: 2, label: 'nor_agree_disagree', icon: 'mdi-emoticon-neutral-outline' },
        { value: 3, label: 'parcially_agree', icon: 'mdi-emoticon-happy-outline' },
        { value: 4, label: 'totally_agree', icon: 'mdi-emoticon-outline' },
      ]
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    dimentionsFiltered() {
      return this.pages[this.currentPage]
    },
    pageCompleted() {
      if (!this.pages[0]) {
        return false
      }

      let count = this.pages[this.currentPage].length
      for (let i = 0; i < this.currentPage; i++) {
        count += this.pages[i].length
      }
      return Object.values(this.selected).length >= count
    },
    percentColor() {
      if (this.progress === 0) return 'grey'
      else if (this.progress <= 24) return '#BB3E3E'
      else if (this.progress <= 40) return '#B8663D'
      else if (this.progress <= 74) return '#C2B147'
      else if (this.progress <= 99) return '#B6C144'
      else if (this.progress === 100) return '#44C156'
      return '#44C156'
    }
  },
  watch: {
    '$i18n.locale': {
      handler (val) {
        this.langSelected = val
      },
      immediate: true
    },
    selected() {
      this.calcPercent()
    },
    entryDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    rndDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    finishDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    }
  },
  methods: {
    initDemo() {
      authService.getPulseDemo()
        .then((res) => {
          this.afterVerifyPollToken(res)
        })
        .catch((error) => {
          console.log('init', error)
        })
    },
    afterVerifyPollToken(res) {
      this.questionTotal = res.totalQuestions

      // Available Questionnaire languages
      this.$emit('questionnaireLangs', 'en|es')
      this.pages = res.pages
      let cnt = 1
      this.pages.map(p => {
        p.map(pc => {
          pc.count = cnt
          cnt++
        })
      })
      this.stopLoading()
      this.afterFetchEmployeeAnswers()
    },
    afterFetchEmployeeAnswers() {
      const localAnswers = localStorage.getItem('pulseDemoAnswers')
      if (!localAnswers) {
        this.entryDialog = true
      } else {
        this.selected = JSON.parse(localAnswers)
        this.entryDialog = false
        this.calcPercent()
      }
    },
    handlepaginate(action) {
      if (action && this.currentPage !== this.pages.length - 1) {
        this.$vuetify.goTo(0, this.scrollOptions)
        this.currentPage += 1
      } else if (!action && this.currentPage !== 0) {
        this.$vuetify.goTo(0, this.scrollOptions)
        this.currentPage -= 1
      }
    },
    continueEntry() {
      this.entryDialog = false
    },
    continueRandom() {
      this.rndDialog = false
    },
    selectedOption(questionId, questionType, opt) {
      return this.selected[`${questionId}-${questionType}`] === opt ? this.colors[opt] : 'grey'
    },
    selectAnswer(questionId, questionType, opt) {
      const temp = JSON.parse(JSON.stringify(this.selected))
      temp[`${questionId}-${questionType}`] = opt

      const local = JSON.stringify(temp)
      localStorage.setItem('pulseDemoAnswers', local)
      this.selected = JSON.parse(local)
    },
    randomDialogContent() {
      const index = Math.floor(Math.random() * 2)
      this.dialogContent.image = `/img/engagement/img${index}.png`
    },
    calcPercent() {
      this.progress = Math.ceil((Object.keys(this.selected).length / this.questionTotal) * 100)
      if (this.progress >= 50 && !this.rndOnce) {
        this.rndDialog = true
        this.rndOnce = true
      } else if (this.progress < 50) {
        this.rndOnce = false
      }
    },
    finishPoll() {
      if (Object.keys(this.selected).length < this.questionTotal) {
        return this.$store.dispatch('alert/error', this.$t('errors.engagements/missing-questions'))
      } else {
        this.finishDialog = true
      }
    },
    stopLoading () {
      if (this.loading) {
        setTimeout(() => this.loading = false, 240)
      }
    }
  },
  created() {
    // Clear localStorage because page reload brings 18 new random questions
    localStorage.removeItem('pulseDemoAnswers')
    this.randomDialogContent();
    this.initDemo()
  }
}
</script>

<style>
  .break-name {
    max-width: 100%;
    overflow-wrap: break-word;
  }
  .angry {
    background-color: #BB3E3E !important;
  }
  .sad {
    background-color: #B8663D !important;
  }
  .neutral {
    background-color: #C2B147 !important;
  }
  .happy {
    background-color: #B6C144 !important;
  }
  .veryhappy {
    background-color: #44C156 !important;
  }
</style>
