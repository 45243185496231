
export default {
  add_employee: 'Agregar empleado',
  employee_fields: 'Datos del empleado',
  birthdate: 'Fecha de nacimiento',
  personal_data: 'Datos personales',
  employment_data: 'Datos laborales',
  created_employee: 'Empleado creado exitosamente',
  import_employees: 'Importar empleados',
  download_template: 'Descargar plantilla',
  download_docs: 'Descargar instructivo',
  // tslint:disable-next-line:max-line-length
  upload_description: 'Cargue un archivo excel (.xls/.xlsx) o csv (.csv) que contenga los datos de sus empleados. Esta acción actualizará su base total de empleados, por lo que debe agregar la base completa de empleados disponibles.',
  select_file_to_upload: 'Seleccionar archivo a cargar',
  select_file: 'Seleccionar archivo',
  summary: 'Resumen del proceso',
  fix_errors: 'Corrección de errores',
  total_updated: 'Se actualizarán {total} empleados.',
  total_inserted: 'Se agregarán {total} empleados.',
  total_deleted: 'Se desactivarán {total} empleados.',
  total_failed: 'Se han encontrado {total} registros con errores.',
  file_updated: 'Archivo actualizado',
  save_changes: 'Guardar cambios',
  error_fix: 'Corregir errores',
  if_any: 'De existir',
  process_cancelled: 'Proceso cancelado',
  massive_success: 'Carga masiva ejecutada exitosamente.',
  massive_upload: 'Carga masiva',
  download_log: 'Descargar log',
  id: 'Id',
  name: 'Nombre',
  last_name: 'Apellido',
  city: 'Ciudad',
  email: 'Correo',
  action: 'Acción',
  employment: 'Empleo',
  employees: 'Empleados',
  list_employees: 'Listado de empleados',
  look_for: 'Buscar un empleado',
  filter_by: 'Filtrar por...',
  no_data: 'No hay empleados para mostrar',
  modal_enable: '¿Desea habilitar el empleado?',
  modal_disable: '¿Desea Inhabilitar el empleado?',
  modal_action: 'Realizar acción',
  status: 'Estado',
  edit_employee: 'Editar empleado',
  enable_title: 'Habilitar empleado',
  disable_title: 'Deshabilitar empleado',
  // tslint:disable-next-line:max-line-length
  enable_text: '¿Seguro que desea habilitar a este empleado? Tras ello, el empleado tendrá acceso al sistema.',
  // tslint:disable-next-line:max-line-length
  disable_text: '¿Seguro que desea deshabilitar a este empleado? Tras ello, el empleado no tendrá acceso al sistema.',
  employment_at: '{title} en',
  updated_employee: 'Empleado actualizado exitosamente',
};
