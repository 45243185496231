
<template>
  <v-row>
    <v-col cols="12">
      <h4 class="display-1 mb-3">{{ $t('questionnaires.engagement', {name: questionnaire.name}) }}</h4>
      <v-divider></v-divider>
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          <div class="title">{{ $t('questionnaires.questions') }}</div>
        </v-card-title>
        <v-card-subtitle>
          <v-col cols="12" class="pa-3">
            {{ $t('questionnaires.open_dimension') }}
          </v-col>
        </v-card-subtitle>
        <v-expansion-panels class="pa-3">
          <v-expansion-panel
            class="mb-2 headline"
            v-for="(item, i) in questionsByDimensions"
            :key="i"
          >
            <v-expansion-panel-header>
              <div v-text="item.name"></div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12">
                  <v-tooltip bottom color="blue">
                    <template v-slot:activator="{ on }" >
                      <v-text-field
                        v-on="on"
                        v-for="(question, index) in item.questions"
                        v-bind:key="index"
                        v-model="question.label"
                        outlined
                        :append-icon="question.icon || 'mdi-content-save-edit-outline'"
                        name="name"
                        @keyup.enter="editQuestion(question)"
                        @blur="editQuestion(question)"
                        :color="question.color || 'blue'"
                      ></v-text-field>
                    </template>
                    <span>
                      {{ $t('questionnaires.tooltip_edit') }}
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-col cols="12" sm6 offset-sm6 class="pa-2">
          <v-btn
            block
            large
            color="secondary"
            @click="$router.go(-1)"
          >{{ $t('input.cancel') }}</v-btn>
        </v-col>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';

import questionnaireService from '../../services/questionnaires';
import dimensionService from '../../services/dimensions';
import resolver from '../../utils/resolver';

// import keyMixin from './mixins/key';
export default {
  data() {
    return {
      questionnaire: {},
      questionnaires: [],
      dimensions: [],
      questionsByDimensions: {},
      language: '',
      color: 'blue',
    };
  },
  watch: {
    language() {
      this.groupQuestionByDimensions();
    },
    '$i18n.locale': {
      handler (val) {
        this.language = val
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user,
    }),
    dimensionsByLanguage: function() {
      const auxDimension = []
      this.dimensions.forEach(element => {
        if (element.lang === this.language) { auxDimension.push(element) }
      });
      return auxDimension
    },
  },
  methods: {
    questionByDimension(dimensionId) {
      const questions = []
      this.questionnaire.questionnaireDetail.forEach(element => {
        if (element.dimensionId === dimensionId) { questions.push(element) }
      });
      return questions
    },
    validateForm(scope) {
      return this.$validator.validateAll(scope).then(valid => {
        if (!valid) {
          this.$store.dispatch('alert/error', this.$t('errors.validator/verify_fields'));
        }
        return valid;
      });
    },
    changeColor(item, success) {
      item.icon = success ? 'mdi-check-bold' : 'mdi-cancel';
      item.color = success ? 'green' : 'red';
    },
    defaultColors(item) {
      item.color = 'blue'
      item.icon = 'mdi-content-save-edit-outline'
    },
    questionEmply(item) {
      this.$store.dispatch('alert/error', this.$t('errors.question_emply'))
      this.changeColor(item);
    },
    editQuestion(item) {
      item.questionnaireId = this.questionnaire.id
      item.label === '' ? this.questionEmply(item)
        : questionnaireService.updateQuestion({ ...item, lang: this.language })
          .then(() => {
            this.$store.dispatch('alert/success', this.$t('questionnaires.update_questionnaire'))
            this.changeColor(item, true);
          }).catch((err) => {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
            this.changeColor(item);
          });
    },
    groupQuestionByDimensions() {
      if (!this.questionnaire.languages.includes(this.language)) {
        this.$store.dispatch('alert/error', this.$t('errors.questionnaire_no_lang'))
        this.$router.back()
        return
      }
      const questions = this.questionnaire.questionnaireDetail.map(question => ({
        id: question.id,
        dimensionId: question.dimensionId,
        color: 'blue',
        icon: 'mdi-content-save-edit-outline',
        label: question.translations.find(qt => qt.lang === this.language).label,
      }));

      this.dimensions.forEach(dimension => {
        this.$set(this.questionsByDimensions, `d${dimension.id}`, {
          name: (dimension.translations.find(dt => dt.lang === this.language) || {}).label,
          questions: questions.filter(q => q.dimensionId === dimension.id),
        });
      });
    },
  },
  created() {
    resolver.all({
      questionarie: questionnaireService.getOneById(this.$route.params.id),
      dimensions: dimensionService.list(),
    })
      .then(resp => {
        this.dimensions = resp.dimensions.items;
        this.questionnaire = resp.questionarie.item;
        this.language = this.user.lang;
      });
  },
};
</script>
