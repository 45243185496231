
import Services from './base-services';

const service = new Services('open-questions');

export default {
  list: (type: string, id: number) => {
    return service.get(`list/${type}/${id}`);
  },
};
