
<template>
  <div style="display:inline;">
    <span class="d-none">
      {{pollDate}}
    </span>
    <v-btn large
      v-if="!thread"
      :disabled="lockPdfButton || !disableButton"
      :loading="lockPdfButton"
      color="primary"
      class="showRepBtn mb-7"
      @click="evalRequest"
    >
      <v-icon>mdi-file-pdf</v-icon>
      {{ buttonText }}
    </v-btn>
    <v-btn large
      v-else
      color="success"
      :loading="lockPdfButton"
      :disabled="thread.status !== 'completed'"
      class="mb-6"
      @click="openPdf(thread.id)"
    >
      <v-icon>mdi-file-pdf</v-icon>
      <span>{{ btnText }}</span>
    </v-btn>
    <img
      src="/img/occ-logo-small.png"
      style="visibility:hidden;"
      id="pulseLogo"
      width="0"
      height="0"
    />
    <img
      src="/img/occ-reporte-portada.jpg"
      style="visibility:hidden;"
      id="portada"
      width="0"
      height="0"
    />
    <img
      src="/img/occ-cover-general.jpg"
      style="visibility:hidden;"
      id="portadaGeneral"
      width="0"
      height="0"
    />
    <img
      src="/img/20200301_occ_solution_logo.png"
      style="visibility:hidden;"
      id="occLogo"
      width="0"
      height="0"
    />
    <img
      src="/img/occ-grafico.jpg"
      style="visibility:hidden;"
      id="occGrafico"
      width="0"
      height="0"
    />
    <img
      src="/img/colors-occ.png"
      style="visibility:hidden;"
      id="occColors"
      width="0"
      height="0"
    />
    <img
      src="/img/20200301_occ_solution_logo.png"
      style="visibility:hidden;"
      id="enterpriseLogo"
      width="0"
      height="0"
    />
    <div v-show="false">
      <img
        :src="fontPreload1"
        :width="preloadWidth"
        :height="preloadHeight"
      />
      <img
        :src="fontPreload2"
        :width="preloadWidth"
        :height="preloadHeight"
      />
      <img
        :src="wCloudPreload"
        :width="preloadWidth"
        :height="preloadHeight"
      />
    </div>

    <x-loading
      :display="loading"
    ></x-loading>

    <x-confirm-spend-dialog
      :confirmText="$t('engagementReport.confirm_create_title')"
      :costText="$t('engagementReport.report_cost')"
      :balanceAfterMsg="$t('engagementReport.balance_after')"
      :showModalConfirm="showModalConfirm"
      :balance="balance"
      :price="price"
      :noActiveEmployee="noActiveEmployee"
      :enoughBalance="enoughBalance"
      :operationsLink="operationsLink"
      :noBalanceResponse="noBalanceResponse"
      :noBalanceMsg="noBalanceMsg()"
      :canCreate="canCreate()"
      :disableButtonModal="disableButtonModal"
      :userType="user.customer.type"
      :confirmBtn="$t('input.confirm_generate')"
      @result="verifySpend"
      @update="checkBalance"
    ></x-confirm-spend-dialog>

    <!-- Dialog already Generated Report -->
    <x-dialog-already-generated-report
      :show.sync="alreadyGeneratedModal"
      :report-type="reportType"
      @close="alreadyGeneratedModal = false"
    ></x-dialog-already-generated-report>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import is from 'is_js';

import engagementsService from "@/services/engagements";

import pulseSmallLogoBase64 from './base64files/1b-pulse-logo-sm'

import XDialogAlreadyGeneratedReport from './components/dialog-already-generated-report'

import initialCalcs from './mixins/00-initial-calcs';
import cover from './mixins/01-cover';
import index from './mixins/02-index';
import whatIs from './mixins/03-what-is';
import recommendations from './mixins/04-recommendations';
import howWorks from './mixins/05-how-works';
import indicators from './mixins/06-indicators';
import methodology from './mixins/07-methodology';
import responseRate from './mixins/08a-response-rate';
import responseRateDetails from './mixins/08b-response-rate-details';
import gralResults from './mixins/09a-gral-results';
import gralDimResults from './mixins/09b-gral-dim-results';
import renderChartPie from './mixins/09c-chart-pie';
import gralAttrResults from './mixins/09d-gral-attr-results';
import specificAttrResults from './mixins/10a-specific-attr-results';
import specificAttrResultsPop from './mixins/10b-specific-attr-results';
import dimensionRanking from './mixins/11a-ranking-dim';
import attributeRanking from './mixins/11b-ranking-attr';
import variableRanking from './mixins/11c-ranking-var';
import dimensionScatter from './mixins/12a-scatter-dim';
import attributeScatter from './mixins/12b-scatter-attr';
import variableScatter from './mixins/12c-scatter-var';
import dimensionTrend from './mixins/13a-trend-dim';
import attributeTrend from './mixins/13b-trend-attr';
import variableTrend from './mixins/13c-trend-var';
import wordQuestions from './mixins/14-word-questions';

const pdfmake = require('pdfmake/build/pdfmake');
const pdffonts = require('pdfmake/build/vfs_fonts.js');
const echarts = require('echarts');
require('echarts-wordcloud');

const origin = window.location.origin
pdfMake.fonts = {
  'League Spartan': {
    normal: `${origin}/fonts/LeagueSpartan/LeagueSpartan-Regular.ttf`,
    bold: `${origin}/fonts/LeagueSpartan/LeagueSpartan-Bold.ttf`,
    italics: `${origin}/fonts/LeagueSpartan/LeagueSpartan-Regular.ttf`,
    bolditalics: `${origin}/fonts/LeagueSpartan/LeagueSpartan-Bold.ttf`,
  },
  Montserrat: {
    normal: `${origin}/fonts/Montserrat/Montserrat-Regular.ttf`,
    bold: `${origin}/fonts/Montserrat/Montserrat-Bold.ttf`,
    italics: `${origin}/fonts/Montserrat/Montserrat-Italic.ttf`,
    bolditalics: `${origin}/fonts/Montserrat/Montserrat-BoldItalic.ttf`,
  },
  Roboto: {
    normal: `${origin}/fonts/Roboto/Roboto-Regular.ttf`,
    bold: `${origin}/fonts/Roboto/Roboto-Medium.ttf`,
    italics: `${origin}/fonts/Roboto/Roboto-Italic.ttf`,
    bolditalics: `${origin}/fonts/Roboto/Roboto-MediumItalic.ttf`,
  }
}

pdfmake.vfs = pdffonts.pdfMake.vfs;

const renderPart = {};
for (let i = 1; i <= 18; i++) {
  [1, 2, 3].forEach(j => {
    renderPart[`curAttr${i}${j}`] = false;
  })
  renderPart[`totAttr${i}`] = false;
}

export default {
  name: 'engagement-report',
  components: {
    XDialogAlreadyGeneratedReport
  },
  mixins: [
    initialCalcs,
    cover,
    index,
    whatIs,
    recommendations,
    howWorks,
    indicators,
    methodology,
    responseRate,
    responseRateDetails,
    gralResults,
    gralDimResults,
    renderChartPie,
    gralAttrResults,
    specificAttrResults,
    specificAttrResultsPop,
    dimensionRanking,
    attributeRanking,
    variableRanking,
    dimensionScatter,
    attributeScatter,
    variableScatter,
    dimensionTrend,
    attributeTrend,
    variableTrend,
    wordQuestions
  ],
  props: {
    pollId: Number,
    disableButton: Boolean,
    demographicsSelected: Array,
    demographicsSelects: Object,
    listOfDemographics: Object,
    buttonText: String,
    filters: null,
    alreadyGenerated: Boolean,
    checkGenerated: Function,
    reportType: String,
    thread: Object,
    poll: Object
  },
  data() {
    return {
      downloadPdf: true,
      executeResultRutine: false,
      showModalConfirm: false,
      alreadyGeneratedModal: false,
      balance: 0,
      price: 0,
      enoughBalance: false,
      operationsLink: '',
      noBalanceResponse: false,
      disableButtonModal: true,
      noActiveEmployee: false,
      lockPdfButton: false,
      loading: false,
      renderPart: {
        chart: false,
        wordsCloud1: false,
        wordsCloud2: false,
        wordsCloud3: false,
        ...renderPart
      },
      bars: {},
      wordColors: [
        '#1a98da',
        '#51c7af',
        '#eb5f4c',
      ],
      pulseLogo: '',
      portada: '',
      portadaGeneral: '',
      occLogo: '',
      occGrafico: '',
      occColors: '',
      enterpriseLogo: '',
      pulseLogoBase64: '',
      portadaBase64: '',
      portadaGeneralBase64: '',
      occLogoBase64: '',
      occGraficoBase64: '',
      occColorsBase64: '',
      enterpriseLogoBase64: '',
      rtype: '',
      preloadWidth: 300,
      preloadHeight: 140,
      fontPreload1: null,
      fontPreload2: null,
      wCloudPreload: null,
      wordsChart1: null,
      wordsChart2: null,
      wordsChart3: null
    };
  },
  mounted() {
    this.pulseLogo = document.getElementById('pulseLogo').src;
    this.portada = document.getElementById('portada').src;
    this.portadaGeneral = document.getElementById('portadaGeneral').src;
    this.occLogo = document.getElementById('occLogo').src;
    this.occGrafico = document.getElementById('occGrafico').src;
    this.occColors = document.getElementById('occColors').src;
    this.enterpriseLogo = document.getElementById('enterpriseLogo').src;
    this.preloadFont1()
    this.preloadFont2()
    this.preloadWordCloudFont()
  },
  watch: {
    renderPart: {
      handler() {
        const hasFalses = Object.values(this.renderPart).includes(false);
        if (!hasFalses) this.renderPdf();
      },
      deep: true,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user,
    }),
    pollDate () {
      if (this.poll && this.poll.deliveredAt) {
        const date = new Date(this.poll.deliveredAt)
       return `${this.$t(`engagementReport.months[${date.getMonth()}]`)} - ${date.getFullYear()}`
      }
      return ''
    },
    btnText() {
      switch (this.thread.status) {
        case 'pending':
          return this.$t('engagementReport.input_generating_report');
        case 'in_progress':
          return this.$t('engagementReport.input_generating_report') + ` ${this.thread.progressPercentage}%`;
        case 'failed':
          return this.$t('engagementReport.input_failed_generation');
        case 'completed':
          return this.$t('engagementReport.input_download_report');
      }
    }
  },
  created () {
    this.$store.dispatch('session/getSuiteWebHost')
      .then((res) => {
        if (res) {
          this.operationsLink = `${res}/tokens/operations`
        } else {
          next('/404')
        }
      })
  },
  methods: {
    canCreate () {
      if (this.balance - this.price < 0) {
        return this.user.customer.type === 'commercial';
      }
      return !this.noActiveEmployee;
    },
    noBalanceMsg () {
      if (this.user.customer.type === 'personal') {
        return this.$t('pulses.no_balance')
      } else {
        return this.$t('pulses.no_balance_msg')
      }
    },
    verifySpend (data) {
      if (data === 1) {
        this.requestPdf()
      } else {
        this.lockPdfButton = false
      }
      this.showModalConfirm = false
    },
    async evalRequest() {
      if (this.reportType === 'by_demographic') {
        const alreadyGenerated = await this.checkGenerated(this.filters)
        if (alreadyGenerated) {
          this.alreadyGeneratedModal = true
          return
        }
        this.checkBalance()
      } else {
        if (this.alreadyGenerated) {
          this.alreadyGeneratedModal = true
          return
        }
        this.requestPdf()
      }
    },
    requestPdf() {
      this.loading = true;
      this.lockPdfButton = true;
      const criteria = {
        pollId: this.pollId,
        demographics: this.demographicsSelected,
        listOfDemographics: this.listOfDemographics
      };
      if (this.filters) {
        criteria.filters = this.filters;
      }
      engagementsService.addReport({
        type: "Engagement",
        pollId: this.pollId,
        lang: this.user.lang,
        reportType: this.reportType,
        criteria
      }).then(() => {
        if (this.reportType === 'general') {
          this.$store.dispatch('alert/success', this.$t('engagementReport.operation_init'));
        } else {
          this.$store.dispatch('alert/success', this.$t('engagementReport.operation_init_items'));
        }
        setTimeout(this.refreshPage, 3000);
      })
      .catch((err) => {
        this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
      })
      .finally(() => {
        this.loading = false
      })
    },
    checkBalance () {
      this.loading = true
      this.lockPdfButton = true
      engagementsService.checkBalance()
        .then((res) => {
          this.balance = res.balance
          this.price = res.productService
          this.enoughBalance = this.balance >= this.price
          this.showModalConfirm = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    openPdf(id) {
      this.loading = true;
      this.lockPdfButton = true;
      engagementsService.reportGetAnswers(id)
        .then((res) => {
          this.$getCalcData(res.data);
          this.generateChart()
          this.$generateVariablesTableGroupBars()
          this.$generateWordsCloud()
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`));
          this.lockPdfButton = false;
          this.loading = false;
        })
    },
    refreshPage() {
      window.location.reload()
    },
    preloadFont1 () {
      const canvas = document.createElement('canvas')
      canvas.width = 800
      canvas.height = 440
      const preload = echarts.init(canvas)

      preload.setOption({
        textStyle: {
          fontFamily: 'League Spartan'
        },
        series: [{
          type: 'wordCloud',
          shape: 'circle',
          gridSize: 9,
          sizeRange: [14, 60],
          rotationRange: [-90, 90],
          rotationStep: 90,
          left: 'center',
          top: 'center',
          drawOutOfBound: true,
          textStyle: {
            normal: {
              color: '#ff9b8e'
            }
          },
          data: [
            { name: 'spartan', value: 4 },
            { name: 'league', value: 3 },
            { name: 'font', value: 2 },
            { name: 'preload', value: 1 }
          ]
        }]
      })

      preload.on('finished', () => {
        this.fontPreload1 = preload.getDataURL()
      })
    },
    preloadFont2 () {
      const canvas = document.createElement('canvas')
      canvas.width = 800
      canvas.height = 440
      const preload = echarts.init(canvas)

      preload.setOption({
        textStyle: {
          fontFamily: 'Montserrat'
        },
        series: [{
          type: 'wordCloud',
          shape: 'circle',
          gridSize: 9,
          sizeRange: [14, 60],
          rotationRange: [-90, 90],
          rotationStep: 90,
          left: 'center',
          top: 'center',
          drawOutOfBound: true,
          textStyle: {
            normal: {
              color: '#a1e4d7'
            }
          },
          data: [
            { name: 'Montserrat4', value: 4 },
            { name: 'Montserrat3', value: 3 },
            { name: 'Montserrat2', value: 2 },
            { name: 'Montserrat1', value: 1 }
          ]
        }]
      })

      preload.on('finished', () => {
        this.fontPreload2 = preload.getDataURL()
      })
    },
    preloadWordCloudFont () {
      const canvas = document.createElement('canvas')
      canvas.width = 800
      canvas.height = 440
      const preload = echarts.init(canvas)

      preload.setOption({
        textStyle: {
          fontFamily: 'Bowlby One'
        },
        series: [{
          type: 'wordCloud',
          shape: 'circle',
          gridSize: 9,
          sizeRange: [14, 60],
          rotationRange: [-90, 90],
          rotationStep: 90,
          left: 'center',
          top: 'center',
          drawOutOfBound: true,
          textStyle: {
            normal: {
              color: '#68baf0'
            }
          },
          data: [
            { name: 'cloud4', value: 4 },
            { name: 'cloud3', value: 3 },
            { name: 'cloud2', value: 2 },
            { name: 'cloud1', value: 1 }
          ]
        }]
      })

      preload.on('finished', () => {
        this.wCloudPreload = preload.getDataURL()
      })
    },
    writeRotatedText(text) {
      const canvas = document.createElement('canvas');
      canvas.width = 50;
      canvas.height = 845;

      const ctx = canvas.getContext('2d');

      // Genera color de fondo
      ctx.fillStyle = '#2196F3';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.save();

      // Posiciona el elemento al costado derecho de la página
      ctx.translate(50, 845);
      ctx.rotate(-0.5 * Math.PI);

      // Formatea el texto
      ctx.font = '20pt Roboto';
      ctx.fillStyle = 'white';
      ctx.fillText(text.toUpperCase(), 290, -15);
      ctx.restore();

      return canvas.toDataURL();
    },
    async generateChart() {
      const canvas = document.createElement('canvas');
      canvas.width = 1040;
      canvas.height = 740;
      const chartPieLocal = echarts.init(canvas);

      const base = {
        type: 'bar',
        coordinateSystem: 'polar',
        stack: 'a',
      };

      const baseOld = {
        ...base,
        name: this.previousPoll && this.previousPoll.name ? this.previousPoll.name : '',
        color: this.previousPoll && this.previousPoll.name ? '#000' : 'rgba(0,0,0,0)',
      };

      const baseOrg = {
        ...base,
        name: this.$t('engagementReport.current_organization'),
        color: '#FFEB3B',
      };

      const baseBlank = {
        ...base,
        name: 'blank',
        color: 'rgba(0,0,0,0)',
      };

      const headerData = [
        {value: this.$t('engagementReport.my_inspiration_chart'), textStyle: {fontSize: 25}},
        {value: this.$t('engagementReport.my_job_chart'), textStyle: {fontSize: 25}},
        {value: this.$t('engagementReport.work_enviroment_chart'), textStyle: {fontSize: 25}},
        {value: this.$t('engagementReport.my_team_chart2'), textStyle: {fontSize: 25}},
        {value: this.$t('engagementReport.my_development_and_learning_chart'), textStyle: {fontSize: 25}},
        {value: this.$t('engagementReport.the_leaders_chart2'), textStyle: {fontSize: 25}},
      ];

      const colorData = [
        'rgba(255, 155, 142, 0.8)',
        'rgba(235, 96, 77, 0.8)',
        'rgba(161, 228, 215, 0.8)',
        'rgba(81, 199, 175, 0.8)',
        'rgba(104, 186, 240, 0.8)',
        'rgba(56, 153, 218, 0.8)',
      ];

      const getData = (crt, lst, org = 0, idx) => {
        const data = [];

        if (org == 0) {
          if (!lst) {
            data.push(setCurrent(idx, crt));
          } else if ((crt - 1) > lst) {
            const rest = crt - lst;
            data.push(setCurrent(idx, crt - 1 - rest));
            data.push(setOld(idx));
            data.push(setCurrent(idx, rest));
          } else if ((crt + 1) < lst) {
            data.push(setCurrent(idx, crt));
            data.push(setBlank(idx, lst - (crt + 1)));
            data.push(setOld(idx));
          } else {
            data.push(setCurrent(idx, crt));
            data.push(setOld(idx));
          }
        } else {
          const results = {crt, lst, org};
          const ordered = Object.entries(results).sort((a, b) => {
            const numeric = b[1] - a[1];
            const ctx = b[0] === 'crt';
            if (numeric === 0 && ctx) {
              return 1;
            }
            return numeric;
          });

          if (ordered[2][0] === 'lst' && ordered[2][1] == 0) {
            const topValue = ordered[0][1];
            const midValue = ordered[1][1];
            let botArea;
            let topArea = 0;
            if (ordered[0][0] === 'crt') {
              botArea = midValue - 1;
              topArea = topValue - midValue;
              data.push(setCurrent(idx, botArea));
              data.push(setOrg(idx))
              data.push(setCurrent(idx, topArea));
            } else {
              botArea = midValue;
              const blank = topValue - midValue - 1;
              data.push(setCurrent(idx, botArea));
              data.push(setBlank(idx, blank));
              data.push(setOrg(idx))
            }

          } else {
            const topValue = ordered[0][1];
            const midValue = ordered[1][1];
            const botValue = ordered[2][1];

            if (ordered[0][0] === 'crt') {
              const botIndex = ordered[2][0];
              const botArea = botValue - 1;
              const midArea = midValue - botValue - 1;
              const topArea = topValue - midValue;

              data.push(setCurrent(idx, botArea));
              data.push(botIndex === 'lst' ? setOld(idx) : setOrg(idx))
              data.push(setCurrent(idx, midArea));
              data.push(botIndex === 'lst' ? setOrg(idx) : setOld(idx))
              data.push(setCurrent(idx, topArea));
            } else if (ordered[1][0] === 'crt') {
              const botIndex = ordered[2][0];
              const botArea = botValue - 1;
              const midArea = midValue - botValue;
              const blank = topValue - midValue;

              data.push(setCurrent(idx, botArea));
              data.push(botIndex === 'lst' ? setOld(idx) : setOrg(idx));
              data.push(setCurrent(idx, midArea));
              data.push(setBlank(idx, blank));
              data.push(botIndex === 'lst' ? setOrg(idx) : setOld(idx));
            } else {
              const topIndex = ordered[0][0];
              const botArea = botValue;
              const firstBlank = midValue - botValue;
              const secondBlank = topValue - midValue;

              data.push(setCurrent(idx, botArea));
              data.push(setBlank(idx, firstBlank));
              data.push(topIndex === 'lst' ? setOld(idx) : setOrg(idx));
              data.push(setBlank(idx, secondBlank));
              data.push(topIndex === 'lst' ? setOrg(idx) : setOld(idx));
            }
          }
        }
        return data;
      }

      const setCurrent = (idx, value) => ({
        ...base,
        data: setData(idx, value),
        name: headerData[idx],
        color: colorData[idx],
      });

      const setBlank = (idx, value) => ({
        ...baseBlank,
        data: setData(idx, value),
      });

      const setOld = (idx) => ({
        ...baseOld,
        data: setData(idx, 1),
      });

      const setOrg = (idx) => ({
        ...baseOrg,
        data: setData(idx, 1),
      });

      const setData = (idx, value) => {
        const baseData = [0, 0, 0, 0, 0, 0];
        baseData[idx] = value;
        return baseData;
      }
      if (!this.previous.dimensionsResults) {
        this.previous.dimensionsResults = [0, 0, 0, 0, 0, 0, 0];
      }
      const option = {
        angleAxis: {
          type: 'category',
          data: headerData,
          z: 10
        },
        radiusAxis: {
          min: 0,
          max: 100,
          interval: 5,
          axisLabel: {
            show: true,
            fontWeight: 'bold',
            fontSize: 18,
            formatter: (value) => +value % 10 === 0 ? value : '',
          },
          splitLine: {
            interval: 5,
            show: true,
          },
          splitArea: {
            interval: 5,
            show: true,
          },
        },
        polar: {},
        series: [
          ...getData(this.current.dimensionsResults[1], this.previous.dimensionsResults[1], this.reportType === 'by_demographic' ? this.current.wholesDimensionsResults[1] : 0, 0),
          ...getData(this.current.dimensionsResults[2], this.previous.dimensionsResults[2], this.reportType === 'by_demographic' ? this.current.wholesDimensionsResults[2] : 0, 1),
          ...getData(this.current.dimensionsResults[3], this.previous.dimensionsResults[3], this.reportType === 'by_demographic' ? this.current.wholesDimensionsResults[3] : 0, 2),
          ...getData(this.current.dimensionsResults[4], this.previous.dimensionsResults[4], this.reportType === 'by_demographic' ? this.current.wholesDimensionsResults[4] : 0, 3),
          ...getData(this.current.dimensionsResults[5], this.previous.dimensionsResults[5], this.reportType === 'by_demographic' ? this.current.wholesDimensionsResults[5] : 0, 4),
          ...getData(this.current.dimensionsResults[6], this.previous.dimensionsResults[6], this.reportType === 'by_demographic' ? this.current.wholesDimensionsResults[6] : 0, 5),
        ],
        barWidth: '100%',
      };
      chartPieLocal.setOption(option);
      chartPieLocal.on('finished', () => {
        this.chartPie = chartPieLocal.getDataURL();
        this.renderPart.chart = true;
      });
    },
    async renderPdf() {
      const configuration = {
        pageSize: 'Letter',
        pageMargins: [ 40, 40, 40, 20 ],
        info: {
          title: this.$t('engagementReport.title'),
          author: 'OCC',
          subject: this.$t('engagementReport.title'),
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Montserrat',
          lineHeight: 1.2,
        },
        header: (currentPage) => {
          return
        },
        footer: (currentPage, totalePages, pageData) => {
          const isHorizontal = pageData.orientation === 'landscape'
          const verticalFooter = (text) => {
            const canvas = document.createElement('canvas');
            canvas.width = 40;
            canvas.height = 100;
            const ctx = canvas.getContext('2d');
            ctx.font = '10.5px Montserrat';
            ctx.save();
            ctx.translate(canvas.width, canvas.height);
            ctx.rotate(-0.5 * Math.PI);
            ctx.fillStyle = '#AAAAAA';
            ctx.fillText(text, 1, -4);
            ctx.restore();
            return canvas.toDataURL();
          }

          const horizontalFooter = (text) => {
            const canvas = document.createElement('canvas');
            canvas.width = 100;
            canvas.height = 14;
            const ctx = canvas.getContext('2d');
            ctx.font = '10.5px Montserrat';
            ctx.fillStyle = '#AAAAAA';
            ctx.fillText(text, 0, 10);
            return canvas.toDataURL();
          }

          return [
            {
              text: currentPage > 2 ? currentPage.toString() : '',
              fontSize: 9,
              margin: [28, -7, 0, 0]
            },
            {
              image: isHorizontal ? horizontalFooter(this.pollDate) : verticalFooter(this.pollDate),
              absolutePosition: {
                x: isHorizontal ? 670 : 554,
                y: isHorizontal ? -14 : -101
              }
            }
          ]
        },
        background: (currentPage, pageSize) => {
          return
        },
        content: [
          // Cover
          await this.$generateCover(),
          // TOC
          await this.$generateTableOfContents(),
          // What is Engagement
          await this.$generateWhatIs(),
          // Our Recommendations
          await this.$ourRecommendations(),
          // How it works
          await this.$generateHowItWorks(),
          // Indicators
          await this.$generateIndicators(),
          // Methodology
          await this.$generateMetodology(),
          // Response Rate
          await this.$generateResponseRate(),
          this.rtype === 'byitems' ? await this.$generatePopultionRateDetails() : '',
          // General Results
          await this.$generateGeneralResults(),
          await this.$generateDimensionResults(),
          await this.$generateChartPie(),
          await this.$generateAttributesResults(),
          // Specific Results
          this.rtype === 'byitems'
            ? await this.$generateSpecificResultsPop()
            : await this.$generateSpecificResults(),
          // Ranking
          await this.$generateDimensionRanking(),
          await this.$generateAttributeRanking(),
          await this.$generateVariableRanking(),
          // Scatter
          await this.$generateDimensionScatter(),
          await this.$generateAttributeScatter(),
          await this.$generateVariableScatter(),
          // Trend
          await this.$generateDimensionTrend(),
          await this.$generateAttributeTrend(),
          await this.$generateVariableTrend(),
          // Word Clouds
          await this.$generateWordQuestions()
        ]
      }

      if (this.downloadPdf) {
        if (is.edge() || is.ie()) {
          const pdfDocGenerator = pdfMake.createPdf(configuration);
          pdfDocGenerator.getBlob((blob) => {
            window.navigator.msSaveBlob(blob, `${this.nameReport}.pdf`)
            this.finishPdfDownload()
          })
        } else {
          pdfmake.createPdf(configuration).download(`${this.nameReport}.pdf`);
          this.finishPdfDownload()
        }
      } else {
        this.finishPdfDownload()
      }
    },
    finishPdfDownload () {
      this.loading = false
      this.lockPdfButton = false
    }
  }
}
</script>
