
import Services from './base-services'

const service = new Services('auth')

export default {
  signOut: () => {
    return service.post('sign-out')
  },
  checkTokenStatus: (token: string) => {
    return service.post('check-reset-password', { token })
  },
  verifyIdentification: (identification: object) => {
    return service.post('verify-identification', identification)
  },
  getEnterpriseByInvitationCode: (token: string) => {
    return service.post('get-enterprise-by-token', { token })
  },
  verifyPollToken: (token: string) => {
    return service.post('verify-poll-token', { token })
  },
  fetchEmployeeAnswers: (token: string, type: string) => {
    return service.post('fetch-employee-answers', { token, type })
  },
  saveEmployeeAnswers: (answers: string, token: string, type: string) => {
    return service.post('save-employee-answers', { answers, token, type })
  },
  saveAnswers: (answers: any, pollId: number, token: string, type: string) => {
    return service.post('save-answers', { answers, pollId, token, type })
  },
  verifyPollTokenEngagement: (token: string) => {
    return service.post('verify-poll-token-engagement', { token })
  },
  getPulseDemo: () => {
    return service.post('get-pulse-demo')
  },
  isValid: (ssoAttempts: number) => {
    return service.get(`validate/${ssoAttempts}`)
  },
  sso: (ssoAttempts: number) => {
    return service.get(`sso/${ssoAttempts}`)
  },
  changeSuiteLocale: (locale: string, user: any) => {
    return service.suiteOperation(() => service.post('change-locale', { locale, user }))
  },
  checkActivity: (id: number) => {
    return service.suiteOperation(() => service.get(`check-activity/${id}`))
  }
}
