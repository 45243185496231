
export default {
  previewEmail: {
    subject: 'Message subject',
    sent_automatic: 'This message was automatically sent from',
    support: 'Please do not respond directly to this email. The answers will arrive in an automated mailbox and it will not be possible for us to read or answer your mail. To contact us send an email to',
    rights_reserved: 'All rights reserved 2020.',
    attached_video: 'Attached video',
    greeting: 'Hello',
    invited_to_poll: 'You were invited to participate in the survey',
    link_to_poll: 'The following link will send you to the survey:',
    click_here: 'click here'
  }
}
