
export default {
  copyright: 'Copyright por OCC - Todos los derechos reservados',
  introduction: 'Introducción',
  model: 'El Modelo',
  results: 'Resultados',
  ranking: 'Ranking',
  open_questions: 'Preguntas abiertas',
  commitment_belonging: 'Compromiso+Pertenencia',
  index: 'Índice',
  topic: 'Tema',
  page: 'Página',
  our_recommendations: 'Nuestras recomendaciones',

  index_stack_general: [
    '1.    Resultados - Tasa de respuesta',
    '2.    Resultados - Cortes demográficos',
  ],
  recommendations_stack: [
    // tslint:disable-next-line:max-line-length
    '1. Asegúrese que se da la importancia estratégica al', { text: 'Engagement', italics: true }, 'como una palanca para alcanzar resultados sobresalientes y sostenibles.\n\n',
    // tslint:disable-next-line:max-line-length
    '2. Recuerde que el ', { text: 'Engagement', italics: true }, ' y la Cultura Organizacional se complementan; gestionar ambos es vital para atraer y retener al talento.\n\n',
    // tslint:disable-next-line:max-line-length
    '3. Empodere a cada líder con la responsabilidad de gestionar el ', { text: 'Engagement', italics: true }, ' y la Cultura. Las áreas de gestión humana facilitan y apoyan.\n\n',
    // tslint:disable-next-line:max-line-length
    '4. A la hora de definir los planes para cerrar brechas, sea inclusivo y participativo. Movilizar la inteligencia colectiva genera mayor nivel de compromiso.\n\n',
    // tslint:disable-next-line:max-line-length
    '5. Es vital el seguimiento al proceso en ciclos cortos. Busque formas creativas de cerrar las brechas y mida con frecuencia. Nuestra herramienta Pulse está diseñada para esto.\n\n',
    // tslint:disable-next-line:max-line-length
    '6. Divulgue siempre los resultados con apertura y transparencia e inclúyalos en el proceso de gestión del desempeño de los líderes.\n\n',
    // tslint:disable-next-line:max-line-length
    '7. Finalmente, recuerde la importancia de mantener foco. Es mejor menos acciones de alto impacto que muchas acciones sin efectos positivos.',
  ],
  what_is_engagement: '¿Qué es Engagement y para qué medirlo?',
  // tslint:disable-next-line:max-line-length
  what_is_engagement_text: ['En OCC Solutions entendemos ', { text: 'Engagement', italics: true }, ' como alto nivel de compromiso emocional, sentido de pertenencia combinado con entusiasmo y pasión por el trabajo y por la Organización, que se manifiesta en un permanente deseo de dar siempre lo mejor.'],
  what_is_engagement_positive: [
    'Dar lo mejor de sí todos los días y tener pasión por el trabajo.\n\n',
    'Compromiso emocional con el propósito, los objetivos y valores de la Organización.\n\n',
    'Motivación para contribuir con el éxito de la Organización con una mayor sensación de bienestar propio.\n\n',
    'Confianza, integridad, compromiso bidireccional y comunicación entre la Organización y sus miembros.\n\n',
    'Alto nivel de entusiasmo y dedicación que una persona siente hacia su trabajo.',
  ],
  what_is_engagement_negative: [
    // tslint:disable-next-line:max-line-length
    'La felicidad, como reírse con compañeros del trabajo o disfrutar de una fiesta de la empresa, no dice nada de qué tan involucrados o comprometidos están con la empresa o qué tan duro están trabajando para lograr la misión de la Organización. Es una medida a corto plazo que cambia rápidamente.\n\n',
    // tslint:disable-next-line:max-line-length
    'La satisfacción puede ser suficiente para retener a los empleados pero no garantiza la productividad. Esto solo indica qué tan feliz o contentos están los colaboradores pero no aborda el nivel de motivación o compromiso emocional.',
  ],
  // tslint:disable-next-line:max-line-length
  what_is_engagement_conclusion: ['Los colaboradores que están altamentes comprometidos logran mejores resultados, fidelizan clientes, rotan con menos frecuencia y contribuyen con la alineación de la cultura.\n\nLas organizaciones con mayor nivel de ', { text: 'Engagement', italics: true }, ' atraen y mantienen el mejor talento, logrando así resultados sobresalientes y sostenibles.'],
  how_does_our_model_works: '¿Cómo funciona nuestro modelo?',
  // tslint:disable-next-line:max-line-length
  how_does_our_model_works_text1: 'Nuestro modelo permite medir seis dimensiones, que indican el nivel de compromiso y sentido de pertenencia de las personas con la Organización.\n\nCada dimensión a su vez tiene 3 atributos y cada atributo tiene 3 preguntas, llamadas variables.\n\nLas seis dimensiones son:',
  how_does_our_model_works_text2: 'Cada una de las 6 dimensiones tienen tres atributos:',
  my_inspiration: 'Mi inspiración',
  sense_of_purpose: 'Sentido de propósito',
  transcendental_work: 'Trabajo trascendental',
  they_know_me: 'Me conocen',
  my_job: 'Mi trabajo',
  reputation_of_the_organization: 'Reputación de la Organización',
  reputation_of_my_area: 'Reputación de mi área',
  work_enviroment: 'Entorno de trabajo',
  positive_work_enviroment: 'Ambiente laboral positivo',
  relationships_quality: 'Calidad de las relaciones',
  peoples_treat: 'Trato de la gente',
  equity_and_transparency: 'Equidad y transparencia',
  my_team: 'Mi equipo',
  team_dynamics: 'Dinámicas de equipo',
  communication_and_coordination: 'Comunicación y coordinación',
  agility_and_decision_making: 'Agilidad y toma de decisiones',
  my_development_and_learning: 'Mi desarrollo y aprendizaje',
  opportunities_in_my_role: 'Oportunidades de crecimiento en mi rol',
  opportunities_in_the_organization: 'Oportunidades de crecimiento en la Organización',
  learning_culture: 'Cultura de aprendizaje',
  the_leaders: 'Los líderes',
  my_leader: 'Mi líder',
  support: 'Apoyo',
  our_leaders: 'Nuestros líderes',
  methodology: 'Metodología',
  // tslint:disable-next-line:max-line-length
  methodology_text: 'La encuesta contiene 54 afirmaciones en una escala de 1 a 5 que presenta las siguientes opciones:',
  methodology_list: [
    'Estoy totalmente en desacuerdo',
    'Estoy parcialmente en desacuerdo',
    'Ni en desacuerdo, ni de acuerdo',
    'Estoy parcialmente de acuerdo',
    'Estoy totalmente de acuerdo',
  ],
  // tslint:disable-next-line:max-line-length
  methodology_conclusion: 'Adicionalmente en la parte final de la encuesta se hacen tres preguntas abiertas, las cuales se\npresentan en el reporte en forma gráfica',
  results_text: 'En esta sección se encuentran los resultados de la encuesta.',
  total_obtained: 'Total obtenidas: ',
  total_sent: 'Total esperadas: ',
  answers_rate: 'Tasa de respuestas',
  demographic_answers_rate: 'Tasa de respuestas por cortes demográficos',
  // tslint:disable-next-line:max-line-length
  answers_rate_text: 'Esta tabla presenta la cantidad de personas que se incluyeron en el proceso y de ese grupo cuáles completaron la medición.',
  results_by_dimension: 'Resultados por dimensión',
  results_by_attribute: 'Resultados por atributo',
  results_by_dim_attr: 'Resultados por dimensión - atributo',
  total: 'Total',
  attribute: 'Atributo',
  global_score: 'Puntaje general',
  current_population: 'Puntaje actual',
  current_population_desc: 'Resultado del promedio simple del total de la medición',
  current_population_items: 'Puntaje población',
  weighted_score: 'Puntaje ponderado',
  weighted_score_desc: 'Peso del cuestionario del modelo (54 preguntas): 70%. - Peso índice de Engagement: 30%.',
  engagement_index: 'Índice de engagement',
  engagement_index_desc: 'Resultado del promedio simple de las 6 preguntas de compromiso',
  preview_population: 'Puntaje anterior',
  rate: 'Tendencia',
  actual_organization: 'Puntaje Organización',
  gap: 'Brecha',
  culture_alignment: 'Alineación con la cultura',
  purpose: 'Propósito',
  future_vision: 'Visión futuro',
  autonomy: 'Autonomía',
  strengths_and_talents: 'Fortalezas y talentos',
  my_contribution: 'Mi aporte',
  motivation: 'Motivación',
  they_value_me: 'Me valoran',
  performance: 'Realización',
  recognized_organization: 'Organización reconocida',
  organization_performance: 'Desempeño de la Organización',
  pride_of_belonging_organization: 'Orgullo de pertenecer a la Organización',
  recognized_area: 'Área reconocida',
  area_performance: 'Desempeño del área',
  pride_of_being_part_area: 'Orgullo de hacer parte del área',
  materials_and_equipment: 'Materiales y equipos',
  offices_and_dress_code: 'Oficinas y códigos de vestimenta',
  balance_personal_professional: 'Balance entre la vida personal y profesional',
  being_myself: 'Ser yo mismo',
  freedom_of_expression: 'Libertad de expresión',
  interpersonal_relationships: 'Relaciones interpersonales',
  care_for_people: 'Cuidado por las personas',
  inclusive_workplace: 'Lugar de trabajo inclusivo',
  respectful_treatment: 'Trato respetuoso',
  equal_opportunities: 'Hay igualidad de oportunidades',
  salary_and_benefits: 'Salario y beneficios',
  recognition_culture: 'Cultura de reconocimiento',
  trust_quality_relationships: 'Confianza y calidad en la relaciones',
  responsibility_meeting_goals: 'Responsabilidad por cumplir las metas',
  workload_balance: 'Balance en la carga de trabajo',
  team_network: 'Red de equipos',
  communication_team: 'Comunicación en mi equipo',
  diversity: 'Diversidad',
  agility_processes: 'Agilidad en los procesos',
  innovation: 'Innovación',
  access_transparency_information: 'Acceso y transparencia de la información',
  autonomous_learning: 'Aprendizaje autónomo',
  development_potential: 'Desarrollo de mi potencial',
  expectations_role: 'Expectativas de mi rol',
  career_plan: 'Plan de carrera',
  future_organization: 'Futuro en la Organización',
  horizontal_mobility: 'Movilidad horizontal',
  learning_tracking: 'Seguimiento al aprendizaje',
  genuine_interest: 'Interés genuino',
  admiration: 'Admiración',
  transparency_honesty: 'Transparencia y honestidad',
  clear_transparent_objectives: 'Objetivos claros y transparentes',
  coaching_feedback: 'Coaching y retroalimentación',
  leader_access: 'Acceso al líder',
  systematic_thinking: 'Pensamiento sistemático',
  strategic_planning: 'Planeación estratégica',
  social_intelligence_collaboration: 'Inteligencia social y colaboración',
  ranking_of_dimensions: 'Ranking de las dimensiones',
  dimension: 'Dimensión',
  current_organization: 'Organización actual',
  dimensions_with_higher_score: 'Dimensiones con puntaje más alto',
  dimensions_with_lower_score: 'Dimensiones con puntaje más bajo',
  dimensions_with_higher_dispersion: 'Dimensiones con dispersión más alta',
  dimensions_with_lower_dispersion: 'Dimensiones con dispersión más baja',
  ranking_of_the_attributes: 'Ranking de los atributos',
  attributes_with_higher_score: 'Atributos con puntaje actual más alto',
  attributes_with_lower_score: 'Atributos con puntaje actual más bajo',
  ranking_of_the_variables: 'Ranking de las variables',
  variable: 'Variable',
  variables_with_higher_score: 'Variables con puntaje actual más alto',
  variables_with_lower_score: 'Variables con puntaje actual más bajo',
  dispersion: 'Dispersión',
  dispersion_by_attributes: 'Dispersión por atributos',
  dispersion_by_variables: 'Dispersión por variables',
  attributes_with_higher_dispersion: 'Atributos con dispersión más alta',
  attributes_with_lower_dispersion: 'Atributos con dispersión más baja',
  preview_dispersion: 'Dispersión anterior',
  current_dispersion: 'Dispersión actual',
  current_dispersion_items: 'Dispersión población',
  variables_with_higher_dispersion: 'Variables con dispersión más alta',
  variables_with_lower_dispersion: 'Variables con dispersión más baja',
  rates_by_dimension: 'Tendencias por dimensión',
  variation: 'Variación',
  rates: 'Tendencias',
  highest_positive_rate_attribute: 'Tendencias positivas más altas por atributo',
  highest_negative_rate_attribute: 'Tendencias negativas más altas por atributo',
  highest_positive_rate_variable: 'Tendencias positivas más altas por variable',
  highest_negative_rate_variable: 'Tendencias negativas más altas por variable',
  rates_by_variables: 'Tendencias por variables',
  rates_by_attributes: 'Tendencias por atributos',
  open_questions_summary: 'Gráficos de preguntas cerradas',
  word_questions_summary: 'Resumen de preguntas abiertas',
  the_leaders_text: 'LOS LÍDERES: Los líderes\nen esta Organización\nme inspiran',
  my_development_text: 'MI DESARROLLO Y\nAPRENDIZAJE: Me veo creciendo\nen esta Organización\nen el futuro',
  my_team_text: 'MI EQUIPO: Hago parte\nde un equipo comprometido\ncon la Organización',
  // tslint:disable-next-line:max-line-length
  work_enviroment_text: 'AMBIENTE LABORAL\nPOSITIVO: Le recomendaría a\namigo o familiar que trabaje\nen esta Organización',
  my_job_text: 'MI TRABAJO: Me\nsiento comprometido\ny orgulloso de trabajar\nen esta empresa',
  // tslint:disable-next-line:max-line-length
  my_inspiration_text: 'MI INSPIRACIÓN: Si me\nofrecieran un trabajo similar en\notra empresa, me quedaría\ndonde estoy',
  reports: 'Reportes',
  general_title: 'General',
  general_desc: 'Este reporte cuenta con las estadísticas de toda la población incluida en la encuesta.',
  general_button: 'Generar reporte Engagement',
  items_button: 'Generar reporte Engagement - Población',
  demographic_population_button: 'Generar resumen por cortes demográficos',
  demographic_title: 'Por cortes demográficos',
  demographic_population_title: 'Resumen por cortes demográficos',
  demographic_population_desc: 'Este reporte cuenta con los puntajes de las diferentes dimensiones segmentadas por cortes demográficos.',
  // tslint:disable-next-line:max-line-length
  demographic_desc: 'Este reporte cuenta con las estadísticas de la población filtrada a través de ciertos cortes demográficos; dichos cortes pueden seleccionarse a continuación:',
  demographic_button: 'Generar reporte Engagement por cortes demográficos',
  display_demographic: 'Mostrar cortes demográficos',
  population: 'Población',
  sent: 'Enviadas',
  obtained: 'Obtenidas',
  additionalDemographic1: 'Demográfico Adicional 1',
  additionalDemographic2: 'Demográfico Adicional 2',
  academicDegree: 'Título académico',
  age: 'Edad',
  antiquity: 'Antigüedad',
  charge: 'Cargo',
  country: 'País',
  departments: 'Área / Departamento',
  gender: 'Género',
  jobTypes: 'Tipo de contratación',
  headquarter: 'Sede',
  years_range: 'Entre {min} y {max} años',
  dimention: 'Dimensión',
  my_inspiration_chart: 'Mi\ninspiración',
  my_job_chart: 'Mi\ntrabajo',
  work_enviroment_chart: 'Ambiente laboral\npositivo',
  my_team_chart: 'Mi       \nequipo',
  my_development_and_learning_chart: 'Mi desarrollo \ny aprendizaje',
  the_leaders_chart: 'Los     \nlíderes',
  cover: 'Resumen por cortes demográficos',
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  active_employees: 'Empleados activos',
  participants: 'Participantes',
  average: 'Promedio sector',
  people: 'personas',
  note: 'Notas:',
  colors: 'Colores',
  dim_color: 'Color dimensión',
  values: 'Valores',
  meaning: 'Significado',
  heatmap_text: [
    // tslint:disable-next-line:max-line-length
    'En la siguiente tabla se pueden apreciar los colores, rangos de valor y significado para el',
    {
      text: ' mapa de calor ',
      italics: true,
    },
    'que será utilizado en todas las tablas del reporte.',
  ],
  previous_measurement: 'Medición anterior',
  strengths: 'Fortalezas',
  opportunities: 'Oportunidades de mejoras',
  feelings: 'Sentimientos',
  what_is_it: '¿Qué es?',
  what_is_not: '¿Qué no es?',
  critical_situation: 'Situación crítica',
  requires_attention: 'Requiere atención urgente',
  insufficient: 'Insuficiente',
  aceptable: 'Aceptable',
  very_good: 'Muy bien',
  remarkable: 'Sobresaliente',
  how_to_read: 'Cómo leer el reporte',
  title: 'OCC - Engagement por corte demográfico',
  att_var: 'Atributo - Variable',
  operation_init: 'Se ha dado inicio a un proceso de descarga "Reporte Resumen por Cortes Demográficos", este proceso puede tardar varios minutos, puede ver su progreso al refrescar la página',
  input_generating_report: 'Generando reporte',
  input_update_progress: 'Actualizar progreso',
  input_failed_generation: 'Generación fallida',
  input_download_report: 'Descargar reporte',
  age_range: {
    '1': 'Menos de 25 años',
    '2': 'De 25 a 35 años',
    '3': 'De 35 a 45 años',
    '4': 'De 45 a 50 años',
    '5': 'Más de 50 años'
  },
  antiquity_range: {
    '1': 'Menos de 6 meses',
    '2': 'De 6 meses a 1 año',
    '3': 'De 1 a 3 años',
    '4': 'De 3 a 5 años',
    '5': 'De 5 a 10 años',
    '6': 'De 10 a 20 años',
    '7': 'Más de 20 años'
  },
};
