<template>
  <v-container fluid>
    <v-row justify="start" dense no-gutters>
        <img src="/img/engagement/engagement-icon.png" width="30px" height="30px" class="ml-2 mt-1">
      <v-col>
        <h4 style="'float:left;'" class="display-1 mb-3 ml-3">{{ $t('engagement.new') }}</h4>
      </v-col>

      <v-col cols="12">
        <v-card class="mt-4">
          <ValidationObserver ref="form">
            <v-form @submit.prevent>
              <x-stepper
                :step="step"
                :headers="stepperHeaders"
                :max-sm="1"
                :max-md="2"
                :max-lg="3"
                :max-xl="4"
                @step="step = $event"
              >
                <ValidationObserver ref="step1">
                  <x-poll-steps-overview
                    :engagement="engagement"
                    step="1"
                    nextAction="input.next"
                    prevAction="input.cancel"
                    @changeStep="verifyStepChanged"
                  ></x-poll-steps-overview>
                </ValidationObserver>

                <ValidationObserver ref="step2">
                  <x-poll-steps-date
                    :engagement="engagement"
                    step="2"
                    nextAction="input.next"
                    prevAction="input.back"
                    @changeStep="verifyStepChanged"
                  ></x-poll-steps-date>
                </ValidationObserver>

                <ValidationObserver ref="step3">
                  <x-step-question
                    :engagement="engagement"
                    step="3"
                    nextAction="input.next"
                    prevAction="input.back"
                    @changeStep="verifyStepChanged"
                  ></x-step-question>
                </ValidationObserver>

                <ValidationObserver ref="step4">
                  <x-step-revition
                    :engagement="engagement"
                    step="4"
                    nextAction="input.next"
                    prevAction="input.back"
                    @changeStep="verifyStepChanged"
                    :edit="edit"
                  ></x-step-revition>
                </ValidationObserver>
              </x-stepper>
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-col>
      <x-loading v-if="loading" :display="loading"/>
      <x-confirm-spend-dialog
        :confirmText="$t('engagement.confirm_create_title')"
        :costText="$t('engagement.workshop_cost')"
        :showModalConfirm="showModalConfirm"
        :balance="balance"
        :price="engagement.totalPrice"
        :noActiveEmployee="noActiveEmployee"
        :operationsLink="operationsLink"
        :noBalanceResponse="noBalanceResponse"
        :noBalanceMsg="noBalanceMsg()"
        :canCreate="canCreate()"
        :disableButtonModal="disableButton"
        @result="verifySpend"
        :userType="user.customer.type"
        @update="update">
      </x-confirm-spend-dialog>
    </v-row>
  </v-container>
</template>

<script>

import { mapState } from 'vuex';
import engagementsService from '../../services/engagements';
import resolver from '../../utils/resolver';

import XStepQuestion from './steps/question.vue';
import XStepRevition from './steps/revition.vue';
import balanceService from '../../services/token-account-detail'
import servicePriceService from '../../services/product-services'
import EmployeesServices from '../../services/employees'

export default {
  components: {
    XStepQuestion,
    XStepRevition,
  },
  data() {
    return {
      stepperHeaders: [
        'engagement.poll_name',
        'engagement.date',
        'engagement.questions',
        'engagement.revition',
      ],
      engagement: {
        lang: 'es',
        name: '',
        displayName: '',
        selection_type: '',
        selection_details: '',
        timeZone: '(UTC-05:00) Bogotá, Lima, Quito',
        deliveredAt: {
          value: '',
          hour: '23:00',
        },
        validUntil: {
          value: '',
          hour: '23:00',
        },
        emailData: '',
        total_employees: [],
        status: 'pending',
        reminders: [{
          value: '',
          hour: '23:00',
        }],
        switchName: false,
        switchQuestion: false,
        switchDate: false,
        radios: 1,
        questionnaires: [],
        percent_total: 0,
        opens: [{
          question: '',
          options: ['', ''],
        }],
        pollInvitation: {
          subject: this.$t(`engagement.poll_invitation_content.subject`),
          body: this.$t(`engagement.poll_invitation_content.body`),
          file: '',
        },
        reminderMail: {
          subject: this.$t(`engagement.reminder_mail_content.subject`),
          body: this.$t(`engagement.reminder_mail_content.body`),
          file: '',
        },
        thankMessage: '',
        active: null,
        offset: '',
        price: 0,
        totalPrice: 0,
        totalReceivers: 0,
        newReceivers: 0,
      },
      step: 1,
      dialog: {
        show: false,
        msg: '',
      },
      enterpriseId: null,
      price: 0,
      balance: 0,
      showModalConfirm: false,
      noActiveEmployee: false,
      noBalanceResponse: false,
      operationsLink: '',
      loading: false,
      disableButton: true,
      edit: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user,
    })
  },
  methods: {
    toConfirm () {
      this.showModalConfirm = true
    },
    initForm () {
      if (this.user.enterprise) {
        this.engagement.enterprise = this.user.enterprise
        this.engagement.enterpriseId = this.user.enterprise.id
        resolver.all({
          populationCount: EmployeesServices.getParticipantsCount().then(res => res.cid),
          // balance: balanceService.getBalanceFromSuite().then(res => res.balance),
          // servicePrice: servicePriceService.getOne('engagement').then(res => res)
          balance: engagementsService.getBalance()
        })
          .then((res) => {
            this.balance = res.balance.balance
            this.engagement.price = res.balance.productService
            this.engagement.totalReceivers = res.populationCount
            this.engagement.newReceivers = this.engagement.totalReceivers
            this.noActiveEmployee = !this.engagement.totalReceivers
            this.engagement.totalPrice = this.engagement.price * this.engagement.totalReceivers
            this.loading = false
          })
          .catch((err) => {
            this.dialog.show = true
            this.dialog.msg = this.$t(`errors.${err.code}`)
          })
      }
    },
    update () {
      this.showModalConfirm = false
      this.loading = true
      this.initForm()
      this.showModalConfirm = true
    },
    verifySpend (data) {
      if (data === 1) {
        return this.create()
      }
      this.showModalConfirm = false
    },
    canCreate () {
      if (this.balance - this.engagement.totalPrice < 0) {
        return this.user.customer.type === 'commercial'
      }
      return !this.noActiveEmployee;
    },
    noBalanceMsg () {
      if (this.user.customer.type === 'personal') {
        return this.$t('engagement.no_balance')
      } else {
        return this.$t('engagement.no_balance_msg')
      }
    },
    onSubmit(step) {
      if (step && step < this.step) {
        this.step = step
        return
      }
      const onValidate = success => {
        if (!success) {
          const error = this.$t('errors.validator/invalid-input')
          this.$store.dispatch('alert/error', error)
          return
        }
        if (step === 5) {
          return this.toConfirm()
        } else {
          this.step = step
        }
      }
      switch (this.step) {
        case 1: this.$refs.step1.validate().then(onValidate); break;
        case 2: this.$refs.step2.validate().then(onValidate); break;
        case 3: this.$refs.step3.validate().then(onValidate); break;
        case 4: this.$refs.step4.validate().then(onValidate); break;
      }
    },
    verifyStepChanged(step) {
      if (step === 0) {
        return this.$router.push('/engagement')
      }
      this.onSubmit(step)
    },
    create() {
      this.loading = true;
      const pollInvitationFile = this.engagement.pollInvitation.file
      const reminderMailFile = this.engagement.reminderMail.file

      this.engagement.pollInvitation.file = this.engagement.pollInvitation.file.name
      this.engagement.reminderMail.file = this.engagement.reminderMail.file.name
      this.engagement.lang = this.user.lang

      return engagementsService.create(this.engagement)
        .then((res) => {
          if (!res.engagement) {
            if (res.status === 401) {
              this.$store.dispatch('alert/error', this.$t('errors.no_balance'))
              this.noBalanceResponse = true
              return Promise.reject(this.$t('errors.no_balance'))
            } else {
              this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
              return Promise.reject(this.$t(`errors.${err.code}`))
            }
          }
          return pollInvitationFile ? engagementsService.sendInvitationFiles(res.engagement.id, { pollInvitationFile }).then(() => res) : Promise.resolve(res)
        })
        .then((res) => {
          return reminderMailFile ? engagementsService.sendReminderFiles(res.engagement.id, { reminderMailFile }) : Promise.resolve()
        })
        .then(() => {
          this.$store.dispatch('alert/success', this.$t('engagement.created_engagement'))
          this.$router.push('/engagement')
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.initForm()
    this.$store.dispatch('session/getSuiteWebHost')
      .then((res) => {
        if (res) {
          this.operationsLink = `${res}/tokens/operations`
        } else {
          next('/404')
        }
      })
  }
};
</script>
<style>
  #enterprise-create div.v-stepper,
  #enterprise-create div.v-stepper div.v-stepper__items,
  #enterprise-create div.v-stepper div.v-stepper__items div.v-stepper__wrapper {
    overflow: visible !important;
  }
</style>
