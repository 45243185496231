
import Services from './base-services';

const service = new Services('dimensions');

export default {
  list: () => {
    return service.post('list');
  },
  listAll: (lang?: string) => {
    return service.get(lang ? `list-all?lang=${lang}` : 'list-all');
  },
};
