
<template>
  <v-row>
    <v-col cols="12">
      <h4 class="display-1 mb-3">{{ $t('questions.edit_committal') }}</h4>
    </v-col>

    <v-col cols="12">
      <v-card>
        <v-card-title>
          <div class="title">{{ $t('questions.questions') }}</div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-for="(question, index) in formatedQuestions"
                v-bind:key="index"
                v-model="question.label"
                :append-icon="question.icon || 'mdi-content-save-edit-outline'"
                name="name"
                outlined
                @keydown.enter="editQuestion(question)"
                @blur="editQuestion(question)"
                :color="question.color || 'blue'"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import { mapState } from 'vuex';
import committalQuestionsService from '../../services/committal-questions';

export default {
  data() {
    return {
      language: '',
      questions: [],
      formatedQuestions: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user,
    }),
  },
  watch: {
    language() {
      this.formatQuestionsByLanguage();
    },
    '$i18n.locale': {
      handler (val) {
        this.language = val
      }
    }
  },
  created() {
    committalQuestionsService.list()
      .then((res) => {
        this.language = this.user.lang;
        this.questions = res.items;
      })
      .catch((error) => {
        this.$store.dispatch('alert/error', this.$t(`errors.${error.code}`));
      });

  },
  methods: {
    validateForm(scope) {
      return this.$validator.validateAll(scope).then(valid => {
        if (!valid) {
          this.$store.dispatch('alert/error', this.$t('errors.validator/verify_fields'));
        }
        return valid;
      });
    },
    changeColor(item, success) {
      item.icon = success ? 'mdi-check-bold' : 'mdi-cancel';
      item.color = success ? 'green' : 'red';
      setTimeout(() => this.defaultColors(item), 2000)
    },
    defaultColors(item) {
      item.color = 'blue'
      item.icon = 'mdi-content-save-edit-outline'
    },
    questionEmply(item) {
      this.$store.dispatch('alert/error', this.$t('errors.question_emply'))
      this.changeColor(item);
    },
    editQuestion(item) {
      item.label === '' ? this.questionEmply(item)
        : committalQuestionsService.updateQuestion({ ...item, lang: this.language })
          .then(() => {
            this.$store.dispatch('alert/success', this.$t('questions.update_question'))
            this.changeColor(item, true);
          }).catch((err) => {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
            this.changeColor(item);
          });
    },
    formatQuestionsByLanguage() {
      this.formatedQuestions = this.questions.map((question) => ({
        id: question.id,
        dimensionId: question.dimensionId,
        color: 'blue',
        icon: 'mdi-content-save-edit-outline',
        label: question.translations.find(t => t.lang === this.language).label,
      }));
    },
  },
};
</script>
