
import Services from './base-services';

const service = new Services('notifications');

export default {
  list: () => {
    return service.get('list');
  },
  readNotification: (id: number) => {
    return service.get(`read/${id}`);
  },
  listAll: () => {
    return service.get('list-all');
  },
};
