var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0 pt-8"},[_c('v-data-table',{attrs:{"headers":_vm.$t('dashboard.enterprise_right_table_header'),"items":_vm.info.polls,"hide-default-footer":true,"footer-props":{
            itemsPerPageOptions: [5, 10, 25, {'text': _vm.$t('page_all'), 'value':-1}],
            itemsPerPageText: _vm.$t('results_per_pages'),
          }},scopedSlots:_vm._u([{key:"body",fn:function(ref){
          var items = ref.items;
return [(items.length > 0)?_c('tbody',_vm._l((items),function(item,$index){return _c('tr',{key:$index},[_c('td',{staticClass:"text-left padding-left-name pr-0 pl-4 break-td"},[_vm._v(" "+_vm._s(item.name))]),_c('td',{staticClass:"text-center px-0"},[_vm._v(" "+_vm._s(item.isPulse ? 'Pulse' : 'Engagement'))]),_c('td',{staticClass:"text-center px-0"},[_vm._v(" "+_vm._s(item.totalReceivers))]),_c('td',{staticClass:"text-center px-0"},[_c('v-chip',{attrs:{"outlined":"","label":"","color":_vm.getColor(item.status)}},[_vm._v(_vm._s(_vm.$t(("engagement." + (item.status)))))])],1),(item.isPulse)?_c('td',{staticClass:"text-center px-0"},[(item.status === 'creating' || item.status === 'editing' || item.status === 'launching')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.refreshPage}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("refresh")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('input.refresh'))+" ")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":'/pulse/'+item.id+'/show'}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("remove_red_eye")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('dashboard.view_poll_details'))+" ")])])],1):_c('td',{staticClass:"text-center px-0"},[(item.status === 'creating' || item.status === 'editing' || item.status === 'launching')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.refreshPage}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("refresh")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('input.refresh'))+" ")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":'/engagement/'+item.id+'/show'}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("remove_red_eye")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('dashboard.view_poll_details'))+" ")])])],1)])}),0):_c('tbody',[_c('tr',{staticClass:"v-data-table__empty-wrapper"},[_c('td',{attrs:{"colspan":_vm.$t('dashboard.enterprise_right_table_header').length}},[_c('v-alert',{staticClass:"text-center mt-3",attrs:{"value":true,"text":"","dense":"","prominent":"","type":"error","icon":"mdi-shield-remove"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('no_data')))])])],1)])])]}},{key:"pageText",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" "+_vm._s(_vm.$t('page_text'))+" "+_vm._s(props.itemsLength)+" ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }