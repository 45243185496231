
import mapParameters from '../utils/map-parameters';
import Services from './base-services';

const service = new Services('employees');

export default {
  getByEnterpriseId: () => {
    return service.get('by-enterprise');
  },
  getEmployees: () => {
    return service.suiteOperation(() => service.get('by-enterprise'));
  },
  getParticipantsCount: () => {
    return service.suiteOperation(() => service.get('participants-count'));
  },
  getDemographicParticipants: (data: object) => {
    return service.suiteOperation(() => service.post('by-demographic-filter', data));
  },
  listActive: () => {
    return service.suiteOperation(() => service.get('participants-list'))
  }
};
