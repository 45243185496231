
<template>
  <v-container fluid>
    <v-layout wrap row>
      <v-flex xs12 sm6>
        <h4 class="display-1 mb-3">{{ $t('models.engagements') }}</h4>
      </v-flex>

      <v-flex xs12 sm6 class="text-xs-right">
        <v-btn
          large
          color="primary"
          to="engagement/create"
          v-if="$can('engagement.create')"
        >{{ $t('engagement.add_engagement') }}</v-btn>
      </v-flex>

      <v-flex xs12>
        <v-divider></v-divider>

        <v-card>
          <x-data-table
            :headers="headers"
            :parent-fetch-data="getEngagements"
            no-data="engagement.no_data"
          >
            <template v-slot:structure="prop">
              <td class="text-xs-left pl-4">{{ prop.item.id }}</td>
              <td class="text-xs-left pl-4">{{ prop.item.displayName || prop.item.name }}</td>
              <td class="text-xs-left px-0">
                <v-chip outline label
                  :color="getColor(prop.item.onePollEmployee.status)"
                >{{  $t(`engagement.${prop.item.onePollEmployee.status}`) }}
                </v-chip>
              </td>
              <td>
                {{ prop.item.percent }}%
                <span v-if="prop.item.percent === 100 && prop.item.onePollEmployee.status === 'progress'  ">
                  ({{ $t('engagement.unfinished') }})
                </span>
              </td>
              <td>{{ formatDate(prop.item.deliveredAt) }} -- {{ formatDate(prop.item.validUntil)}}</td>
              <td class="text-xs-center px-0">
                <v-tooltip
                  bottom
                  color="green"
                  v-if="prop.item.status === 'progress' && prop.item.onePollEmployee.status === 'progress'"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      :to="`/engagement/fill/${prop.item.onePollEmployee.token}`"
                      flat icon>
                      <v-icon small v-if="prop.item.percent !== 0">mdi-restore</v-icon>
                      <v-icon small v-else>mdi-play</v-icon>
                    </v-btn>
                  </template>

                  <span v-if="prop.item.percent !== 0">{{ $t('input.continue_filling') }}</span>
                  <span v-else>{{ $t('input.begin') }}</span>
                </v-tooltip>
                <span v-else>{{ $t('engagement.no_actions') }}</span>
              </td>
            </template>
          </x-data-table>
        </v-card>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import EngagementService from '../../services/engagements';

export default {
  data() {
    return {
      headers: [
        'engagement.id',
        'engagement.name',
        'engagement.status',
        'engagement.aprogress',
        'engagement.period',
        'engagement.action',
      ],
      filters: [
        'Filtro 1',
        'Filtro 2',
        'Filtro 3',
      ],
      engagements: [],
      modalDis: {
        open: false,
        item: {},
      },
      modalEna: {
        open: false,
        item: {},
      },
    };
  },
  methods: {
    getColor(status) {
      switch (status) {
        case 'completed':
          return 'green'
        case 'progress':
          return 'blue'
      }
    },
    getEngagements() {
      return EngagementService.listByEmployee()
        .then(res => ({
          items: res.items.map(item => {
            if (item.onePollEmployee.status === 'completed') {
              item.percent = 100;
              return item;
            }

            const JSONquestions = (item.pollQuestionReference || { data: "[]" }).data;
            const JSONanswers = (item.answers || { data: "{}" }).data;
            const questions = JSON.parse(JSONquestions);
            let answers = JSON.parse(JSONanswers);
            answers = typeof answers === 'string' ? JSON.parse(answers) : answers;
            item.percent = Math.ceil((Object.keys(answers).length / questions.length) * 100);
            return item;
          }),
        }));
    },
    formatDate(date) {
      if (!date) return null;
      const dateAux = date.split('T')[0]
      const [year, month, day] = dateAux.split('-')
      return `${day}/${month}/${year}`;
    },
  },
};
</script>
