
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="8">
        <h4 class="display-1 mt-1">{{ $t(`questionnaires.questionnaires`) }}</h4>
      </v-col>

      <v-col cols="12" sm="4" class="text-xs-right">
        <v-btn
          large
          color="primary"
          v-if="$can('questionnaires.create')"
          to="/questionnaires/create"
        >{{ $t('questionnaires.add_questionnaire') }}</v-btn>
      </v-col>

      <v-col cols="12">
        <v-divider></v-divider>

        <v-card>
          <v-card-title>
            <div class="title">{{ $t('questionnaires.questionnaire_list') }}</div>
          </v-card-title>

          <v-divider class="my-3"></v-divider>
          <x-data-table
            :headers="headers"
            :reload="reload"
            :parent-fetch-data="getQuestionnaires"
            no-data="questionnaires.no_data"
            @offReload="offReload"
          >
            <template v-slot:structure="prop">
              <td class="pr-0" style="width:70%">
                {{ prop.item.name }}
              </td>
              <td class="text-center px-0">
                <v-switch hide-details
                  v-model="prop.item.active"
                  :loading="loadingSwitch[prop.item.id]"
                  class="mt-n1 ml-12 mr-0"
                  @click="openModal(prop.item, prop.item.active)"
                />
              </td>
              <td class="text-center">
                <v-tooltip bottom color="green">
                  <template v-slot:activator="{ on }">
                    <v-btn icon text
                      v-on="on"
                      :to="`/questionnaires/edit/${prop.item.id}/questions`"
                    >
                      <v-icon>mdi-tooltip-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('input.edit_questions') }}</span>
                </v-tooltip>
              </td>
            </template>
          </x-data-table>
        </v-card>
      </v-col>
    </v-row>

    <x-confirmation-modal
      :show.sync="modal.activate"
      :reversible="true"
      :title="$t('questionnaires.activate')"
      :action="enableQuestionnaire"
      @close="modal.activate = false"
    >
      <template v-slot:question>{{ $t('questionnaires.activate_des') }}</template>
    </x-confirmation-modal>

    <x-confirmation-modal
      :show.sync="modal.deactivate"
      :reversible="true"
      :title="$t('questionnaires.deactivate')"
      :action="disableQuestionnaire"
      @close="modal.deactivate = false"
    >
      <template v-slot:question>{{ $t('questionnaires.deactivate_des') }}</template>
    </x-confirmation-modal>
  </v-container>
</template>

<script>
import questionnaireService from '../../services/questionnaires';

// import keyMixin from './mixins/key';

export default {
  // mixins: [keyMixin],
  data() {
    return {
      loadingSwitch: {},
      currentLoadingSwitch: 0,
      headers: [
        'input.first_name',
        'enterprises.status',
        'enterprises.actions',
      ],
      reload: false,
      modal: {
        deactivate: false,
        activate: false,
        questionnaire: {},
      },
      questionnaries: [],
    };
  },
  beforeRouteUpdate (to, from, next) {
    // this.checkKeyChange(to.params.key)
    // this.key = to.params.key;
    // next();
    // this.reload = true;
  },
  methods: {
    getQuestionnaires() {
      return questionnaireService.listByType('engagement')
        .then(resp => {
          this.questionnaries = resp.items;
          return resp;
        });
    },
    enableQuestionnaire() {
      return this.toggleStatus(true);
    },
    disableQuestionnaire() {
      let actives = 0;
      this.questionnaries.forEach((q) => {
        if (q.active) {
          actives++;
        }
      })
      if (!actives) {
        this.$store.dispatch('alert/error', this.$t('errors.error_disable_questionnaires'))
        this.modal.deactivate = false;
      } else {
        return this.toggleStatus(false);
      }
    },
    toggleStatus(active) {
      return questionnaireService.toggleStatus(this.modal.questionnaire.id, active)
        .then(() => {
          this.modal.questionnaire.active = active
          this.modal[active ? 'activate' : 'deactivate'] = false
          this.reload = true
        });
    },
    offReload() {
      this.reload = false;
    },
    openModal(item, activate) {
      this.modal.activate = Boolean(activate);
      this.modal.deactivate = !activate;
      this.modal.questionnaire = item;
      this.currentLoadingSwitch = item.id
    },
    toggleActive (id) {
      const matchQ = this.questionnaries.find(q => q.id === id)
      matchQ.active = !matchQ.active
    }
  },
  watch: {
    'modal.activate': {
      handler (val) {
        if (val) {
          this.loadingSwitch[this.currentLoadingSwitch] = true
        } else {
          this.toggleActive(this.currentLoadingSwitch)
          this.loadingSwitch[this.currentLoadingSwitch] = false
          this.currentLoadingSwitch = 0
        }
      }
    },
    'modal.deactivate': {
      handler (val) {
        if (val) {
          this.loadingSwitch[this.currentLoadingSwitch] = true
        } else {
          this.toggleActive(this.currentLoadingSwitch)
          this.loadingSwitch[this.currentLoadingSwitch] = false
          this.currentLoadingSwitch = 0
        }
      }
    }
  }
}
</script>
