<template>
  <v-dialog
    v-model="display"
    fullscreen
    hide-overlay
  >
    <v-card>
      <v-container fluid>
        <v-row row wrap>
          <v-col
            cols="12"
            md="6"
            class="offset-md3 text-center grey--text text--darken-1"
            style="margin: 170px auto!important;"
          >
            <img :src="image" width="220"/>
            <h5 class="headline mt-5" v-html="$t('engagement.congratulations', { progress })"></h5>
            <v-btn
              @click="() => { $emit('continue-random') }"
              class="mt-4"
              color="primary"
              large
            >{{ $t('input.continue') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    display: Boolean,
    image: String,
    text: String,
    progress: Number,
  },
};
</script>
