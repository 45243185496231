
export default {
  create: 'Crear pulse',
  poll_title: 'Pulse Poll',
  // tslint:disable-next-line:max-line-length
  poll_text: 'Read each one of the assertions presented down bellow and select the one that fits the best with your opinion based on the next scale: Totally disagree, Parcially disagree, Nor disagree nor agree, Parcially agree, Totally agree.',
  totally_disagree: 'Totally<br/> disagree',
  parcially_disagree: 'Parcially<br/> disagree',
  nor_agree_disagree: 'Nor disagree<br/> nor agree',
  parcially_agree: 'Parcially<br/> agree',
  totally_agree: 'Totally<br/> agree',
  entry_title: 'Welcome to your Pulse Poll',
  // tslint:disable-next-line:max-line-length
  entry_text: 'We invite you to answer the Pulse survey, we are sure that with your answers we will build a better work environment. You have your own link for your survey, remember not to share it.',
  questions_n: '{n} Questions',
  minutes_30: '30 Minutes',
  complete_anonymity: 'Complete anonymity',
  finish_title: 'Thanks a lot for completing the poll',
  finish_text: 'Your perception is very important for us.',
  new: 'New poll pulse',
  overview: 'Overview',
  receptors: 'Receivers',
  date: 'Date',
  questions: 'Questions',
  questionnaire: 'Questionnaire',
  revition: 'Review & Personalization',
  poll_name: 'Name of poll',
  want_external_name: 'Do you want to place an external name for the receivers?',
  external_name: 'External name for recipients',
  want_to_send: 'Who do you want to send this poll to?',
  hours: 'Hour',
  minutes: 'Minute',
  date_delivery: 'Date of delivery',
  poll_valid_until: 'Poll valid until',
  time_zone: 'Time zone',
  want_send_reminders: '¿Do you want to send reminders?',
  reminders: 'Send reminder',
  committal_question: 'Committal questions',
  want_committal_question: 'Do you want to include committal questions?',
  want_questionnaire_question: 'Do you want to include questionnaire questions?',
  want_open_question: 'Do you want to include additional questions?',
  open_question: 'Additional questions',
  add_open_question: 'Add another question',
  remove_open_question: 'Remove last question',
  min_open_question: 'You must have at least one additional question available',
  max_open_question: 'You can only {n} additional questions per survey',
  insert_question: 'Add statement  of question {n}',
  insert_question_option: 'Add option {o} for question {n}',
  add_question_option: 'Add another option',
  remove_question_option: 'Remove last option ',
  min_question_option: 'You must have at least {n} options available',
  max_question_option: 'You can only indicate {n} options for an additional question',
  total_receptors: 'Total receivers: {n}',
  percent_total: 'Percentage of the total to be taken',
  general_revition: 'General Review',
  settings: 'Settings',
  status: 'Status',
  inter_name: 'Inter name',
  send_reminders: 'Send reminders',
  personalization: 'Personalization',
  poll_invitation: 'Poll invitation',
  reminder_mail: 'Reminder email',
  tk_message: 'Thank you message',
  message_subject: 'Message subject',
  body_message: 'Message body',
  message_from_the_company: 'Message from the company',
  created_pulse: 'Poll created successfully!',
  id: 'Id',
  name: 'Name',
  participants: 'Participants',
  period: 'Period',
  action: 'Actions',
  filter_by: 'Filter by...',
  look_for: 'Look for an poll',
  no_data: 'There are none polls to display',
  pulse_realized: 'Realized pulse',
  add_pulse: 'Add Pulse',
  pending: 'Pending',
  completed: 'Completed',
  cancelled: 'Cancelled',
  progress: 'In Progress',
  creating: 'Creating',
  editing: 'Editing',
  launching: 'Launching',
  at: 'at',
  download: 'Download',
  aprogress: 'Progress',
  edit: 'Poll edited successfully!',
  tooltip_name: 'Internal name of the poll',
  tooltip_display_name: 'Name of the poll for receivers',
  summary: 'Poll Summary',
  to_edit: 'Edit poll',
  total_progress: 'Total progress',
  end_date: 'Closing date',
  poll_completed: 'Completed polls',
  poll_no_completed: 'Polls not completed',
  resend: 'Resend polls not completed',
  resend_success: 'Successfully forwarded polls!',
  demographic_items: 'Demographic items',
  select_age_range: 'Select an age range',
  selection_dimension: 'Select dimensions',
  selection_question_committal: 'Select question committal',
  to_all_my_organization: 'To all my organization',
  to_demographic_cuts: 'To demographic cuts',
  to_receivers_randomly: 'To receivers randomly',
  countries: 'Select some country',
  select_antiquity_range: 'Select antiquity range',
  genders: 'Genders',
  charges: 'Charges',
  job_types: 'Type of contract',
  departments: 'Departments',
  academic_degrees: 'Academic charge',
  additional_demographics1: 'Additional Demographics 1',
  additional_demographics2: 'Additional Demographics 2',
  others: '+{n} others',
  age_low: 'Less than {n} years',
  age_range: 'From {n} to {p} years',
  age_upper: 'More than {n} years',
  antiquity_low: 'Less than {n} months',
  antiquity_range_single: 'From {n} months to {p} year',
  antiquity_range_one: 'From {n} year to {p} years',
  antiquity_range: 'From {n} years to {p} years',
  antiquity_upper: 'More than {n} years',
  generate: 'Generate',
  display_options: 'Display options',
  charts_select: {
    table_results_by_dimension: 'Results by dimension table',
    comparative_by_dimensions: 'Comparative by dimensions',
    dimensions_ranking: 'Dimensions ranking',
    attributes_ranking: 'Attributes ranking',
    rate_monthly: 'Historical by dimensions in the last 12 months',
    rate_monthly_attr: 'Historical by attributes in the last 12 months',
    rate_monthly_variables: 'Historical by variables in the last 12 months',
    table_results_by_attribute: 'Results by attribute table',
    comparative_by_attributes: 'Comparative by attributes',
    table_results_by_variable: 'Results by variable table',
    comparative_by_variables: 'Comparative by variables',
    highest_attributes_ranking: 'Highest attributes ranking',
    lowest_attributes_ranking: 'Lowest attributes ranking',
    highest_variables_ranking: 'Highest variables ranking',
    lowest_variables_ranking: 'Lowest variables ranking',
  },
  select_placeholder: 'Select a chart to generate',
  start_date: 'Start date',
  go_to_report: 'Go to report',
  pulse_report: 'Pulse report',
  no_charts: 'No charts available',
  select_comparition: 'Select poll to compare with',
  pulse: 'Pulse',
  engagement: 'Engagement',
  previous_poll: 'Previous poll',
  current_pulse: 'Actual pulse',
  dimension: 'Dimension',
  dimensions: 'Dimensions',
  attribute: 'Attribute',
  attributes: 'Attributes',
  variable: 'Variable',
  variables: 'Variables',
  rate: 'Rate',
  percent: 'Porcentaje',
  text: ['High', 'Low'],
  change_dimensions: 'Change dimension',
  change_attributes: 'Change attribute',
  change_variables: 'Change variables',
  // tslint:disable-next-line:max-line-length
  report_alert: 'Only those Pulse / Engagement measurements that have the same measurement parameters and prior to the measurement Press current, they will be available in the “Select another survey for comparison” field.',
  want_to_compare: 'Do you want to compare this assessment with a previous one?',
  filter_by_demographic_cuts: 'Filter by Demographic Cuts',
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  questionnaire_p: 'Pulse Questionnaire',
  questionnaire_table_legend: 'You can select from 1 to 6 attributes for your assessment',
  // tslint:disable-next-line:max-line-length
  pdf_explained: 'In this file you can see the questions belonging to the questionnaire "{name}" grouped by dimension and attribute',
  open_question_info: 'You can indicate additional custom questions, indicating possible answers.',
  question: '[Question]:',
  // tslint:disable-next-line:max-line-length
  congratulations: 'Contratulations, you have completed the <span class="headline" style="color: #44C156">{progress}%</span> of the poll!',
  all_right: 'All rights reserved 2019. ©',
  automatic_message: 'This message was sent automatically from',
  rate_chart_types: [
    { text: 'Dimensions', value: false },
    { text: 'Attributes', value: true },
  ],
  no_questionnaries_title: 'You only can create this poll with commital questions',
  // tslint:disable-next-line:max-line-length
  no_questionnaries_body: 'There is not questionnaires active for your enterprise, for this reason you only can select commital questions, please check only commital questions to continue creating this poll. Please contact the system administrator to solve this problem.',
  // tslint:disable-next-line:max-line-length
  connection_interrupted: 'Connection interrupted, we will continue to complete your responses by reestablishing the connection.',
  connection_interrupted_trying: 'Trying to connect in {seconds} seconds',
  connection_interrupted_out: 'Sorry, please check your internet connection and refresh the page',
  connection_interrupted_connecting: 'Connecting',
  no_balance: 'Not enough balance',
  no_balance_msg: 'Company with not enough balance, if you continue the process, the system will try to consume authorized balances.',
  confirm_create_title: 'Confirm measurement creation',
  workshop_cost: 'Measurement cost',
  poll_paid: 'Measurement has been paid already.',
  your_balance: 'You balance:',
  token_unit: 'OCC Tokens',
  balance_after: 'Balance after creating the measurement:',
  non_active_employees: 'There must be at least one active contributor for this survey.',
  operation_failed: 'Unsuccessful operation, recommended:',
  acquire_tokens: 'Get more OCC Tokens',
  invitation_mail_content: {
    subject: 'Welcome to Pulse poll',
    body: `Welcome to te Pulse valuation process, that which will allow us get valuable information about different commitment levels and belonging of our company.
    <br/>Keys about this poll:
    <ul><li>The information provided will be kept confidential and used for statistical purposes. </li></ul>
    <ul><li>Please answer with total sincerity.</li></ul>
    <ul><li>For a better experience use Google Chrome, remember that you must be connected to the internet.</li></ul>
    <ul><li>If you have any questions or evidence of a problem with the survey, please do not hesitate to contact us in this email: <a href="mailto:info@occsolutions.org">info@occsolutions.org</a>.</li></ul>
    <ul><li>Remember that this link is personal and should not be shared.</li></ul>
    <br/>We thank you in advance for your time and valuable contributions in this important process.`
  },
  reminder_mail_content: {
    subject: 'Pulse poll reminder',
    body: `We remind you to complete the Pulse assessment process, that which will allow us get valuable information about different commitment levels and belonging of our company.
    <br/>Keys about this poll:
    <ul><li>The information provided will be kept confidential and used for statistical purposes. </li></ul>
    <ul><li>Please answer with total sincerity.</li></ul>
    <ul><li>For a better experience use Google Chrome, remember that you must be connected to the internet.</li></ul>
    <ul><li>If you have any questions or evidence of a problem with the survey, please do not hesitate to contact us in this email: <a href="mailto:info@occsolutions.org">info@occsolutions.org</a>.</li></ul>
    <ul><li>Remember that this link is personal and should not be shared.</li></ul>
    <br/>We thank you in advance for your time and valuable contributions in this important process.`
  }
};
