
export default {
  questionnaire_base: 'Base questionnaire',
  add_questionnaire: 'Add questionnaire',
  es_co: 'es-co',
  en_us: 'en-us',
  created_questionnaire: 'Questionnaire created with success!',
  questionnaires: 'Questionnaires',
  questionnaires_engagement: 'Questionnaires Engagement',
  questionnaires_pulse: 'Questionnaires Pulse',
  questionnaire_list: 'List of questionnaires',
  look_for: 'Search a questionnaire',
  filter_by: 'Filter by...',
  no_data: 'There are none questionnaires to display',
  language: 'Language',
  questions: 'Questions',
  engagement: 'Poll engagement: {name}',
  pulse: 'Poll Pulse: {name}',
  open_dimension: 'Open each of the dimensions to edit the corresponding questions.',
  update_questionnaire: 'Question modified successfully!',
  tooltip_edit: 'Press ENTER key to edit',
  want_assign_questionnaire: 'You want to assign the questionnaire?',
  want_assign_questionnaire_info: 'An assigned questionnaire will be available only to the specified recipient',
  assign_to_sector: 'Assign questionnaire to a business sector',
  // tslint:disable-next-line:max-line-length
  assign_to_sector_info: 'Indicates the business sector to which this questionnaire will be assigned; This questionnaire will be available to all companies that belong to this business sector',
  assign_to_enterprise: 'Assign questionnaire to a company',
  // tslint:disable-next-line:max-line-length
  assign_to_enterprise_info: 'Indicates the company to which this questionnaire will be assigned; This questionnaire will be available only for the selected company',
  deactivate: 'Deactivate questionnaire',
  // tslint:disable-next-line:max-line-length
  deactivate_des: 'The questionnaire will not be available in the system until it is activated again, it cannot be used to create surveys.',
  activate: 'Activate questionnaire',
  activate_des: 'The questionnaire will be available in the system and can be used in the creation of a survey.',
  customer: 'Customer',
  enterprise: 'Enterprise',
  sector: 'Sector',
  assignation_type: 'Assignation type',
  assignation_to: 'Assign to'
};
