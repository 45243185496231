
import { AxiosError, AxiosResponse } from 'axios'

import router from '../router'
import store from '../store'

export default {
  success: (config: AxiosResponse) => config,
  error: (err: AxiosError) => {
    if (err.response && err.response.status === 503) {
      store.dispatch('alert/error', 'Hubo un error al comunicarse con la central de datos! Intentando nuevamente...')
    }

    return Promise.reject(err)
  }
}
