<template>
  <v-container fluid>
    <v-row justify="start" dense no-gutters>
      <img src="/img/pulse/pulse-icon.png" width="30px" height="30px" class="ml-2 mt-1">
      <v-col>
        <h4 style="'float:left;'" class="display-1 mb-3 ml-3">{{ $t('pulses.new') }}</h4>
      </v-col>

      <v-col cols="12">
        <v-card class="mt-4">
          <ValidationObserver ref="form">
            <v-form @submit.prevent>
              <x-stepper
                :step="step"
                :headers="[
                  'pulses.poll_name',
                  'pulses.receptors',
                  'pulses.date',
                  'pulses.questions',
                  'pulses.revition',
                ]"
                :max-xs="1"
                :max-sm="2"
                :max-md="3"
                :max-lg="3"
                :max-xl="4"
                @step="step = parseInt($event)"
              >
                <ValidationObserver ref="step1">
                  <x-poll-steps-overview
                    :engagement="pulse"
                    step="1"
                    nextAction="input.next"
                    prevAction="input.cancel"
                    @changeStep="verifyStepChanged"
                  ></x-poll-steps-overview>
                </ValidationObserver>

                <ValidationObserver ref="step2">
                  <x-step-receptors
                    v-if="employeesCount != null"
                    :pulse="pulse"
                    :employeesCount="employeesCount"
                    step="2"
                    nextAction="input.next"
                    prevAction="input.cancel"
                    @parentLoading="parentLoading"
                    @changeStep="verifyStepChanged"
                  ></x-step-receptors>
                </ValidationObserver>

                <ValidationObserver ref="step3">
                  <x-poll-steps-date
                    :engagement="pulse"
                    step="3"
                    nextAction="input.next"
                    prevAction="input.back"
                    @changeStep="verifyStepChanged"
                  ></x-poll-steps-date>
                </ValidationObserver>

                <ValidationObserver ref="step4">
                  <x-step-question
                    :pulse="pulse"
                    step="4"
                    nextAction="input.next"
                    prevAction="input.cancel"
                    @changeStep="verifyStepChanged"
                    @changeAttributes="attrsChanged"
                    @baseAttributes="setBaseAttributes"
                    :isEdit="false"
                  ></x-step-question>
                </ValidationObserver>

                <ValidationObserver ref="step5">
                  <x-step-revition
                    :pulse="pulse"
                    :base-attrs="baseAttributes"
                    step="5"
                    nextAction="input.next"
                    prevAction="input.cancel"
                    @changeStep="verifyStepChanged"
                  ></x-step-revition>
                </ValidationObserver>
              </x-stepper>
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-col>
      <x-loading v-if="loading" :display="loading"/>
      <x-confirm-spend-dialog
        :confirmText="$t('pulses.confirm_create_title')"
        :costText="$t('pulses.workshop_cost')"
        :showModalConfirm="showModalConfirm"
        :balance="balance"
        :price="pulse.totalPrice"
        :enoughBalance="enoughBalance"
        :noActiveEmployee="noActiveEmployee"
        :operationsLink="operationsLink"
        :noBalanceResponse="noBalanceResponse"
        :noBalanceMsg="noBalanceMsg()"
        :canCreate="canCreate()"
        :disableButtonModal="disableButton"
        @result="verifySpend"
        :userType="user.customer.type"
        @update="update">
      </x-confirm-spend-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

import resolver from '../../utils/resolver';

import pulseService from '../../services/pulses';
import balanceService from '../../services/token-account-detail'
import servicePriceService from '../../services/product-services'
import EmployeesServices from '../../services/employees'

import XStepReceptors from './steps/receptors.vue';
import XStepQuestion from './steps/question.vue';
import XStepRevition from './steps/revition.vue';

export default {
  components: {
    XStepReceptors,
    XStepQuestion,
    XStepRevition,
  },
  data() {
    return {
      baseAttributes: [],
      pulse: {
        lang: 'es',
        name: '',
        displayName: '',
        selectionType: '',
        selectionDetails: '',
        timeZone: '(UTC-05:00) Bogotá, Lima, Quito',
        deliveredAt: {
          value: '',
          hour: '23:00',
        },
        model: null,
        validUntil: {
          value: '',
          hour: '23:00',
        },
        emailData: '',
        min: 0,
        max: 100,
        rangeAge: [],
        rangeAntiquity: [],
        totalReceivers: 0,
        switchName: false,
        switchOpenQuestion: false,
        switchCommittalQuestion: true,
        switchQuestionnaireQuestion: true,
        radios: 3,
        questionnaires: [],
        switchDate: false,
        status: 'pending',
        reminders: [{
          value: '',
          hour: '23:00',
        }],
        genders: [],
        departments: [],
        charges: [],
        jobTypes: [],
        genderId: '',
        headquarters: [],
        jobTypeIds: [],
        chargeIds: [],
        departmentIds: [],
        academicDegreeIds: [],
        dimensions: [],
        questionsCommittal: [],
        questionsAttributesIds: [],
        percentTotal: 0,
        opens: [{
          question: '',
          options: ['', ''],
        }],
        pollInvitation: {
          subject: this.$t(`pulses.invitation_mail_content.subject`),
          body: this.$t(`pulses.invitation_mail_content.body`),
          file: '',
        },
        reminderMail: {
          subject: this.$t(`pulses.reminder_mail_content.subject`),
          body: this.$t(`pulses.reminder_mail_content.body`),
          file: '',
        },
        thankMessage: '',
        active: null,
        offset: '',
        demographicItems: {},
        countrySelect: [],
        headquarterSelect: [],
        totalPrice: 0
      },
      employeesCount: null,
      step: 1,
      dialog: {
        show: false,
        msg: '',
      },
      enterpriseId: null,
      loading: false,
      balance: 0,
      showModalConfirm: false,
      enoughBalance: false,
      noActiveEmployee: false,
      noBalanceResponse: false,
      operationsLink: '',
      disableButton: true
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user,
    }),
  },
  methods: {
    setBaseAttributes (val) {
      this.baseAttributes = val
    },
    attrsChanged (val) {
      this.pulse.questionsAttributesIds = val
    },
    toConfirm () {
      this.showModalConfirm = true;
    },
    initForm () {
      if (this.user.enterprise) {
        this.pulse.enterprise = this.user.enterprise
        this.pulse.enterpriseId = this.user.enterprise.id
        pulseService.getBalance()
          .then((res) => {
            this.balance = res.balance
            this.pulse.price = res.productService
            this.noActiveEmployee = !this.employeesCount
            this.pulse.totalPrice = res.productService * this.pulse.totalReceivers
            this.enoughBalance = this.balance >= this.pulse.totalPrice
            this.loading = false
          })
          .catch((err) => {
            this.dialog.show = true
            this.dialog.msg = this.$t(`errors.${err.code}`)
          })
      }
    },
    update () {
      this.showModalConfirm = false
      this.loading = true
      this.initForm()
      this.showModalConfirm = true
    },
    verifySpend (data) {
      if (data === 1) {
        return this.create()
      }
      this.showModalConfirm = false
    },
    canCreate () {
      if (this.balance - this.pulse.totalPrice < 0) {
        return this.user.customer.type === 'commercial';
      }
      return !this.noActiveEmployee;
    },
    noBalanceMsg () {
      if (this.user.customer.type === 'personal') {
        return this.$t('pulses.no_balance')
      } else {
        return this.$t('pulses.no_balance_msg')
      }
    },
    onSubmit(step) {
      if (step && step < this.step) {
        this.step = step
        return
      }
      const onValidate = success => {
        if (!success) {
          const error = this.$t('errors.validator/invalid-input')
          this.$store.dispatch('alert/error', error)
          return
        }
        if (step === 6) {
          return this.toConfirm()
        } else {
          this.step = step
        }
        if (step === 5) this.initForm();
      };
      switch (this.step) {
        case 1:
          this.$refs.step1.validate().then(onValidate);
          break;
        case 2:
          this.$refs.step2.validate().then(onValidate);
          break;
        case 3:
          this.$refs.step3.validate().then(onValidate);
          break;
        case 4:
          this.$refs.step4.validate().then(onValidate);
          break;
        case 5:
          this.$refs.step5.validate().then(onValidate);
          break;
      }
    },
    verifyStepChanged(step) {
      if (step === 0) {
        return this.$router.push('/pulse')
      }
      this.onSubmit(step)
    },
    create() {
      this.loading = true
      this.pulse.lang = this.user.lang
      const pollInvitationFile = this.pulse.pollInvitation.file
      if (pollInvitationFile && pollInvitationFile.name) {
        this.pulse.pollInvitation.file = this.pulse.pollInvitation.file.name
      } else {
        delete this.pulse.pollInvitation.file
      }

      const reminderMailFile = this.pulse.reminderMail.file
      if (reminderMailFile && reminderMailFile.name) {
        this.pulse.reminderMail.file = this.pulse.reminderMail.file.name
      } else {
        delete this.pulse.reminderMail.file
      }

      const payload = Object.assign({}, this.pulse)
      delete payload.dimensions // Old model, no longer needed
      delete payload.questionnaires
      delete payload.demographicItems

      return pulseService.create(payload)
        .then((res) => {
          return pollInvitationFile ? pulseService.sendInvitationFiles(res.pulse.id, { pollInvitationFile }).then(() => res) : Promise.resolve(res);
        })
        .then((res) => {
          return reminderMailFile ? pulseService.sendReminderFiles(res.pulse.id, { reminderMailFile }) : Promise.resolve();
        })
        .then(() => {
          this.$store.dispatch('alert/success', this.$t('pulses.created_pulse'));
          this.$router.push('/pulse');
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    parentLoading (val) {
      this.loading = val
    }
  },
  created () {
    this.loading = true;
    EmployeesServices.getParticipantsCount()
      .then((res) => {
        this.employeesCount = res.cid
      })
      .finally(() => {
        this.loading = false
      })
    this.$store.dispatch('session/getSuiteWebHost')
      .then((res) => {
        if (res) {
          this.operationsLink = `${res}/tokens/operations`
        } else {
          next('/404')
        }
      })
  }
};
</script>
<style>
  #enterprise-create div.v-stepper,
  #enterprise-create div.v-stepper div.v-stepper__items,
  #enterprise-create div.v-stepper div.v-stepper__items div.v-stepper__wrapper {
    overflow: visible !important;
  }
</style>
