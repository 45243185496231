
export default {
  questionnaire_base: 'Cuestionario base',
  add_questionnaire: 'Agregar cuestionario',
  es_co: 'es',
  en_us: 'en',
  created_questionnaire: '¡Cuestionario creado con éxito!',
  questionnaires: 'Cuestionarios',
  questionnaires_engagement: 'Cuestionarios Engagement',
  questionnaires_pulse: 'Cuestionarios Pulse',
  questionnaire_list: 'Lista de Cuestionarios',
  look_for: 'Buscar un cuestionario',
  filter_by: 'Filtrar por...',
  no_data: 'No hay cuestionarios para mostrar',
  language: 'Idiomas',
  questions: 'Preguntas',
  engagement: 'Encuesta Engagement: {name}',
  pulse: 'Encuesta Pulse: {name}',
  open_dimension: 'Abre cada una de las dimensiones para editar las preguntas correspondientes.',
  update_questionnaire: 'Pregunta modificada exitosamente!',
  tooltip_edit: 'Debe presionar ENTER para editar',
  want_assign_questionnaire: '¿Desea asignar el cuestionario?',
  want_assign_questionnaire_info: 'Un cuestionario asignado estará disponible solo para el destinatario especificado',
  assign_to_sector: 'Asignar cuestionario a un sector empresarial',
  // tslint:disable-next-line:max-line-length
  assign_to_sector_info: 'Indica el sector empresarial al que se asignará este cuestionario. Este cuestionario estará disponible para todas las empresas que pertenecen a este sector empresarial',
  assign_to_enterprise: 'Asignar cuestionario a una empresa',
  // tslint:disable-next-line:max-line-length
  assign_to_enterprise_info: 'Indica la empresa a la que se asignará este cuestionario. Este cuestionario estará disponible solo para la empresa seleccionada',
  deactivate: 'Desactivar cuestionario',
  // tslint:disable-next-line:max-line-length
  deactivate_des: 'El cuestionario no estará disponible en el sistema hasta que se active nuevamente y, por tanto, no podrá utilizarse en la creación encuestas.',
  activate: 'Activar cuestionario',
  activate_des: 'El cuestionario estará disponible en el sistema y podrá utilizarse en la creación de encuestas.',
  customer: 'Cliente',
  enterprise: 'Empresa',
  sector: 'Sector',
  assignation_type: 'Tipo de asignación',
  assignation_to: 'Asignar a'
};
