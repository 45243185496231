
export default {
  titles: [
    'Did you know?',
    'Random title 2',
    'Random title 3',
  ],
  texts: [
    // tslint:disable-next-line:max-line-length
    'An engaged employee has a high motivation, which will be reflected in a decrease in his absenteeism, in an improved quality of his work, and will give more than what is expected of him to achieve the goals of the company.',
    // tslint:disable-next-line:max-line-length
    'This is the random description number 2. The messages that will be display in here are randomly generated.',
    // tslint:disable-next-line:max-line-length
    'This is the random description number 3. The messages that will be display in here are randomly generated.',
  ],
};
