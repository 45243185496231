<template>
  <v-dialog
    v-model="display"
    fullscreen
    hide-overlay
  >
    <v-card>
      <v-container fluid>
        <v-row>
          <v-col cols="7" sm="4" md="3" lg="2" class="ml-auto pt-0">
            <v-select dense hide-details
              v-model="lang"
              :items="languages"
              class="styled-select mr-3"
              prepend-icon="translate"
              @change="$emit('changeLang', $event)"
            ></v-select>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="7" class="justify-center text-center grey--text text--darken-1 pt-2 pt-sm-0">
            <section class="enterprise-logo">
              <img :src="logo" />
            </section>

            <h3 class="display-2 mt-3">{{ $t('engagement.entry_title') }}</h3>

            <p class="headline mt-5 text-center" v-html="$t('engagement.entry_text')"></p>

            <v-row row wrap class="mt-5">
              <v-col xs="12" sm="6">
                <v-icon x-large>mdi-comment-question-outline</v-icon>
                <div class="headline mt-3">{{ $t('engagement.question_n', { n: countQuestion }) }}</div>
              </v-col>
              <v-col xs="12" sm="6">
                <v-icon x-large>mdi-clock-outline</v-icon>
                <div class="headline mt-3">{{ $t('engagement.minutes_n', { n: countMinutes }) }}</div>
              </v-col>
              <!--
              <v-col xs="12" sm="4">
                <v-icon x-large>mdi-lock-outline</v-icon>
                <div class="headline mt-3">{{ $t('engagement.complete_anonymity') }}</div>
              </v-col>
              -->
            </v-row>

            <v-btn
              @click="() => { $emit('continue-entry') }"
              class="mt-5"
              color="primary"
              large
            >{{ $t('input.answer_poll') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    display: Boolean,
    logo: String,
    countQuestion: Number,
    countMinutes: Number,
    language: String,
    languages: Array
  },
  data() {
    return {
      lang: this.language
    };
  }
};
</script>
