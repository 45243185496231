
import Services from './base-services';

const service = new Services('word-questions');

export default {
  list: () => {
    return service.get('list');
  },
  updateQuestion: (data: object) => {
    return service.put('editQuestion', data);
  },
};
