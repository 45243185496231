<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500"
  >
    <v-card>
      <v-card-text class="text-center pa-4">
        <v-icon class="mb-3" style="font-size: 4em;">mdi-emoticon-sad-outline</v-icon>
        <p class="headline ma-0 grey--text text--darken-3">{{ $t('are_sorry') }}</p>
        <p class="mb-3 grey--text text--darken-3">{{ progress ? $t('this_survey_not') : $t('no_one_answered') }}</p>
        <v-btn class="pt-1" color="primary" small @click="close">{{ $t('input.close') }}</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    progress: Boolean,
  },
  data () {
    return {
      dialog: false
    }
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.dialog = true;
        }
      }
    },
    immediate: true
  },
  methods: {
    close () {
      this.$emit('close');
      this.dialog = false;
    }
  }
}
</script>
