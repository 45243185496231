
export default {
  dashboard: 'Tablero',
  engagements: 'Engagement',
  questionnaires: 'Cuestionarios',
  questionnairesEngagement: 'Cuestionario Engagement',
  questionnairesPulse: 'Cuestionario Pulse',
  engagement: 'Engagement',
  pulse: 'Pulse',
  committalQuestions: 'Preguntas de compromiso',
  wordQuestions: 'Preguntas abiertas',
  suite: 'Suite'
};
