
<template>
  <v-stepper-content step="5">
    <x-technical-requirements></x-technical-requirements>
    <v-row>
      <v-col x12>
        <v-card>
          <v-card-text class="pa-0">
            <v-row class="mt-4">
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-card-account-details</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('pulses.poll_name') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ pulse.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-card-account-details-outline</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('pulses.external_name') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ pulse.displayName || pulse.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-account-search</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('pulses.receptors') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ receptors[pulse.selectionType - 1] }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-card-account-details-outline</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('pulses.total_receptors', { n: '' }) }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ pulse.totalReceivers }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>
            <v-row class="mt-4">
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-calendar-star</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('pulses.date_delivery') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ formatDate(pulse.deliveredAt) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-calendar-minus</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('pulses.poll_valid_until') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ formatDate(pulse.validUntil) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-camera-timer</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('pulses.time_zone') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ pulse.timeZone }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-calendar-clock</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('pulses.send_reminders') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ pulse.switchDate ? 'Si' : 'No' }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>
            <v-row class="mt-4">
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-comment-question</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title wsnormal">{{ $t('pulses.want_questionnaire_question') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{
                        pulse.switchQuestionnaireQuestion
                          ? $t('dashboard.poll_completed')
                          : $t('dashboard.poll_incompleted')
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-file-question</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('pulses.questionnaire') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading" v-if="pulse.switchQuestionnaireQuestion">
                      {{ (pulse.questionnaires.find(e => e.value === pulse.radios) || {}).text }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="grey--text subheading" v-else>
                      --
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-comment-question</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title wsnormal">{{ $t('pulses.want_committal_question') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ pulse.switchCommittalQuestion ? 'Si' : 'No' }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-comment-question</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title wsnormal">{{ $t('pulses.want_open_question') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ pulse.switchOpenQuestion ? 'Si' : 'No' }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6" class="mb-3 my-2">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>fa-coins</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">
                        {{ `${ isEdit ? 0 : pulse.totalPrice} ${$t('pulses.token_unit')}` }}
                      </h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ `${ isEdit ? $t('pulses.poll_paid') : $t('pulses.workshop_cost') + ' ' + (pulse.totalReceivers) +' '+ $t('pulses.participants') }`}}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" v-if="pulse.switchQuestionnaireQuestion" class="mt-1">
                <h6 class="title">{{ $t('pulses.attributes') }}</h6>
                <span class="d-none">{{ pulse.questionsAttributesIds }}</span>
                <template v-for="(item, $index) in attributesLabels">
                  <v-chip :key="$index">{{ item.text }}</v-chip>
                </template>
              </v-col>
              <v-col cols="12" v-if="pulse.switchCommittalQuestion" class="mt-1">
                <h6 class="title">{{ $t('pulses.committal_question') }}</h6>
                <template v-for="(item, $index) in questionsCommittalLabels">
                  <v-chip :key="$index">{{ item }}</v-chip>
                </template>
              </v-col>
              <v-col cols="12" v-if="pulse.switchOpenQuestion">
                <h6 class="title">{{ $t('pulses.open_question') }}</h6>
                <v-list>
                  <v-list-group
                    v-for="(item, $idx) in pulse.opens"
                    :key="$idx"
                    v-model="item.active"
                    prepend-icon="mdi-comment-question"
                    no-action
                  >
                    <template v-slot:activator>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.question }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>

                    <v-list-item
                      v-for="(subItem, $i) in item.options"
                      :key="$i"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ subItem }}</v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-icon>mdi-radiobox-blank</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-col>
            </v-row>

            <v-divider class="my-3"></v-divider>
            <v-row class="mt-4">
              <v-col cols="12" class="mb-3">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-email-open-outline</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('pulses.personalization') }}</h6>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12">
                <v-row  align="center" justify="center">
                  <v-col lg="10" sm="9" class="d-none-xs-only">
                    <v-tabs v-model="pulse.active" class="fitTabs" show-arrows fixed-tabs>
                      <v-tab v-for="it in tabItems" :key="it.value" :href="`#`+it.value" @click="changeTab(it.value)">
                        {{ it.text }}
                      </v-tab>
                    </v-tabs>
                  </v-col>
                  <v-col cols="12"  class="d-none-sm-and-up">
                    <v-select
                      v-model="pulse.active"
                      :items="tabItems"
                      @change="changeTab"
                    ></v-select>
                  </v-col>
                  <v-col cols="4" v-if="tabSelected != 3" class="d-flex justify-center">
                    <v-btn class="primary" right @click="displayPreview">{{ $t('input.preview') }}</v-btn>
                  </v-col>
                </v-row>

                <v-container grid-list-md text-xs-center>
                  <v-tabs-items v-model="pulse.active">

                    <v-tab-item :value="`tab-1`">
                      <v-container grid-list-md text-xs-center>
                        <v-col cols="12" >
                          <v-text-field
                            v-model="pulse.pollInvitation.subject"
                            light
                            :label="$t('pulses.message_subject')"
                            name="message_subject"
                            :append-outer-icon="$t('help.icon')"
                            @click:append-outer="$store.dispatch('help/display', $t('help.engagement.create.subject'))"
                            :disabled="pulse.status !== 'pending'"
                            :filled="pulse.status !== 'pending'"
                            v-bind:style="pulse.status !== 'pending' ? 'cursor: not-allowed !important;' : ''"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" >
                          <quill-editor ref="pollInvitationBody"
                              v-model="pulse.pollInvitation.body"
                              :options="editorOption"
                              :disabled="pulse.status !== 'pending'"
                              :filled="pulse.status !== 'pending'"
                              v-bind:style="pulse.status !== 'pending' ? 'cursor: not-allowed !important;' : ''">
                          </quill-editor>
                          <!--
                          <v-text-field
                            :label="$t('input.select_video')"
                            @click="pickFile"
                            v-model="pollInvitationVideo.name"
                            prepend-icon="attach_file"
                            :color="videoColor"
                            :append-outer-icon="$t('help.icon')"
                            @click:append-outer="$store.dispatch('help/display', $t('help.enterprise.create.video'))"
                            :disabled="pulse.status !== 'pending'"
                            :filled="pulse.status !== 'pending'"
                            v-bind:style="pulse.status !== 'pending' ? 'cursor: not-allowed !important;' : ''"
                          ></v-text-field>
                          <input
                            type="file"
                            style="display: none"
                            ref="videoInput"
                            accept="video/*"
                            @change="onFilePicked"
                          >
                          -->
                        </v-col>
                      </v-container>
                    </v-tab-item>

                    <v-tab-item :value="`tab-2`">
                      <v-container grid-list-md text-xs-center>
                        <v-col cols="12" >
                          <x-inputs-input
                            :input="pulse.reminderMail.subject"
                            @updateInput="($event) => pulse.reminderMail.subject = $event"
                            light
                            :label="$t('pulses.message_subject')"
                            name="message_subject"
                            :append-outer-icon="$t('help.icon')"
                            :help-message="$t('help.engagement.create.subject')"
                          ></x-inputs-input>
                        </v-col>

                        <v-col cols="12" >
                          <quill-editor ref="pollReminderBody"
                            v-model="pulse.reminderMail.body"
                            :options="editorOption">
                          </quill-editor>
                          <v-text-field
                            :label="$t('input.select_video')"
                            @click="pickFile2"
                            v-model="pollReminderVideo.name"
                            prepend-icon="attach_file"
                            :color="videoColor2"
                            :append-outer-icon="$t('help.icon')"
                            @click:append-outer="$store.dispatch('help/display', $t('help.enterprise.create.logo'))"
                          ></v-text-field>
                          <input
                            type="file"
                            style="display: none"
                            ref="videoInput2"
                            accept="video/*"
                            @change="onFilePicked2"
                          >
                        </v-col>
                      </v-container>
                    </v-tab-item>

                    <!--
                    <v-tab-item :value="`tab-3`">
                      <v-container grid-list-md text-xs-center>
                        <v-col cols="12" >
                          <v-textarea
                            v-model="pulse.thankMessage"
                            light
                            :label="$t('pulses.tk_message')"
                            name="thankMessage"
                            :append-icon="$t('help.icon')"
                            @click:append="$store.dispatch('help/display', $t('help.engagement.create.thankMessage'))"
                          ></v-textarea>
                        </v-col>
                      </v-container>
                    </v-tab-item>
                    -->
                  </v-tabs-items>
                </v-container>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-btn
          block
          large
          @click="changeStep(+step - 1)"
        >{{ $t('input.back') }}</v-btn>
      </v-col>

      <v-col cols="12" sm="6">
        <x-async-btn
            class="white--text"
            :action="runValidate"
            large
            block
          >{{ isEdit ? $t('input.edit') : $t('input.confirm_save') }}
        </x-async-btn>
      </v-col>
      <x-poll-preview-email
        :show-preview="showModalPreview"
        :poll-name="pulse.displayName || pulse.name"
        :subject="message.title"
        :body="message.body"
        :attachments="message.attachments"
        @close="$event => showModalPreview = $event"
      ></x-poll-preview-email>
    </v-row>
  </v-stepper-content>
</template>

<script>
import resolver from '../../../utils/resolver';
import dimensionService from '../../../services/dimensions';
import committalQuestionsService from '../../../services/committal-questions';
import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    quillEditor
  },
  data: () => ({
    dimensions: [],
    dimensionsLabels: [],
    questionsCommittal: [],
    questionsCommittalLabels: [],
    receptors: [],
    tabSelected: 1,
    showModalPreview: false,
    message: {
      title: '',
      body: '',
      attachments: ''
    },
    editorOption: {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'align': [] }],
          ['clean'],
          ['link']
          // ['link', 'image', 'video']
        ],
      },
      placeholder: ''
    },
    pollInvitationVideo: {
      src: '',
      name: '',
    },
    videoColor: 'primary',
    pollReminderVideo: {
      src: '',
      name: '',
    },
    videoColor2: 'primary'
  }),
  props: {
    baseAttrs: Array,
    pulse: Object,
    step: String,
    nextAction: String,
    prevAction: String,
    isEdit: Boolean
  },
  watch: {
    'pulse.questionsCommittal': {
      handler() {
        this.questionsCommittalLabels = this.setSelectionsIds(this.questionsCommittal, 'questionsCommittal');
      },
      immediate: true,
    },
    'pulse.dimensions': {
      handler() {
        this.dimensionsLabels = this.setSelectionsIds(this.dimensions, 'dimensions');
      },
      immediate: true,
    },
    'pulse.questionsAttributesIds': {
      handler (val) {
        this.attributesLabels = this.baseAttrs.filter(a => val.includes(a.id))
      },
      immediate: true,
    }
  },
  computed: {
    tabItems () {
      return [
        { text: this.$t('pulses.poll_invitation').toUpperCase(), value: 'tab-1' },
        { text: this.$t('pulses.reminder_mail').toUpperCase(), value: 'tab-2' }
      ]
    },
    pollReminderEditor() {
      return this.$refs.pollReminderBody.quill;
    },
    pollInvitationEditor() {
      return this.$refs.pollInvitationBody.quill;
    }
  },
  methods: {
    changeStep(step) {
      this.$emit('changeStep', step);
    },
    runValidate() {
      return new Promise((resolve) => {
        this.changeStep(+this.step + 1);
        resolve();
      });
    },
    formatDate (data) {
      const [year, month, day] = data.value.split('-')
      return `${day}/${month}/${year} a las ${data.hour}:00`
    },
    setSelectionsIds(data, context) {
      const arr = [];
      data.forEach(element => {
        if (this.pulse[context].includes(element.id)) {
          arr.push(element.translate.label);
        }
      });
      return arr;
    },
    displayPreview() {
      if (this.tabSelected === 1) {
        this.message.title = this.pulse.pollInvitation.subject;
        this.message.body = this.pulse.pollInvitation.body;
        this.message.attachments = this.pulse.pollInvitation.file ? this.pulse.pollInvitation.file.name : ''
      } else {
        this.message.title = this.pulse.reminderMail.subject;
        this.message.body = this.pulse.reminderMail.body;
        this.message.attachments = this.pulse.reminderMail.file ? this.pulse.reminderMail.file.name : ''
      }
      this.showModalPreview = true;
    },
    pickFile() {
      this.$refs.videoInput.click();
    },
    onFilePicked($event) {
      const files = $event.target.files;

      if (files[0] !== undefined) {
        if (files[0].size > 50000000) {
          this.$store.dispatch('alert/error', this.$t('errors.video/size'));
          this.videoColor = 'error';
        } else {
          this.videoColor = 'primary';
          this.pollInvitationVideo.name = files[0].name;
          return this.pollInvitationVideo.name.lastIndexOf('.') > 0 ? this.readFile(files) : this.resetVideo();
        }
      } else this.resetVideo();
    },
    resetVideo() {
      this.pollInvitationVideo.src = '';
      this.pollInvitationVideo.name = '';
      this.pulse.pollInvitation.file = '';
    },
    readFile(files) {
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener('load', () => {
        this.pollInvitationVideo.src = fr.result;
        this.pulse.pollInvitation.file = files[0];
      });
    },
    pickFile2() {
      this.$refs.videoInput2.click();
    },
    onFilePicked2($event) {
      const files = $event.target.files;

      if (files[0] !== undefined) {
        if (files[0].size > 50000000) {
          this.$store.dispatch('alert/error', this.$t('errors.video/size'));
          this.videoColor2 = 'error';
        } else {
          this.videoColor2 = 'primary';
          this.pollReminderVideo.name = files[0].name;
          return this.pollReminderVideo.name.lastIndexOf('.') > 0 ? this.readFile2(files) : this.resetVideo2();
        }
      } else this.resetVideo2();
    },
    resetVideo2() {
      this.pollReminderVideo.src = '';
      this.pollReminderVideo.name = '';
      this.pulse.reminderMail.file = '';
    },
    readFile2(files) {
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener('load', () => {
        this.pollReminderVideo.src = fr.result;
        this.pulse.reminderMail.file = files[0];
      });
    },
    changeTab(val) {
      this.tabSelected = parseInt(val.substr(val.length - 1, 1));
    }
  },
  created() {
    this.receptors.push(this.$t('pulses.to_all_my_organization'));
    this.receptors.push(this.$t('pulses.to_demographic_cuts'));
    this.receptors.push(this.$t('pulses.to_receivers_randomly'));
    this.editorOption.placeholder = this.$t('pulses.body_message')
  },
  mounted () {
    this.$root.$on('passRevitionData', (dimensions, commital) => {
      this.dimensions = dimensions
      this.dimensionsLabels = this.setSelectionsIds(dimensions, 'dimensions')

      this.questionsCommittal = commital
      this.questionsCommittalLabels = this.setSelectionsIds(commital, 'questionsCommittal')
    })
  }
}
</script>

<style scoped>
.wsnormal {
  white-space: normal;
}
.font-less {
  font-size: .8em !important;
  height: 36px !important;
  padding: 0 16px !important;
}
.ql-container {
  min-height: 170px !important;
}
@media only screen and (min-width: 710px) {
  .d-none-sm-and-up {
    display: none!important;
  }
}
@media only screen and (max-width: 709px) {
  .d-none-xs-only {
    display: none!important;
  }
}
</style>

<style>
  .fitTabs div.v-tabs-bar__content {
    width: fit-content;
  }
</style>
