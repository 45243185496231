<template>
  <v-dialog
    v-model="display"
    fullscreen
    hide-overlay
  >
    <v-card>
      <v-container>
        <v-row row wrap justify="center">
          <v-col cols="12" sm="6" md="8" class="offset-ms3 offset-md2 text-center grey--text text--darken-1 mt-5">
            <section class="enterprise-logo">
              <img :src="logo" />
            </section>

            <h2 class="display-3 mt-5 break-name">{{ name }}</h2>

            <h4 class="display-1 mt-3">{{ $t('engagement.finish_title') }}</h4>

            <p class="headline mt-3" v-html="$t('engagement.finish_text')"></p>

          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    display: Boolean,
    logo: String,
    backButton: Boolean,
    name: String,
    code: null,
  },
};
</script>
<style>
  .break-name {
    max-width: 100%;
    overflow-wrap: break-word;
  }
</style>
