
<template>
  <v-stepper-content :step="step">
    <v-col cols="12" class="pa-3 headline">
      {{ $t('engagement.date') }}
      <v-divider></v-divider>
      <v-spacer></v-spacer>
    </v-col>
    <v-container>
      <v-row row wrap>
        <v-col cols="12" sm="3">
          <x-date-picker
            :readonly="engagement.status != 'pending'"
            v-model="engagement.deliveredAt.value"
            @date-picked="engagement.deliveredAt.value = $event"
            :label="$t('engagement.date_delivery')"
            :rules="engagement.status === 'pending' ? 'required|dateNowAfter' : ''"
            ref="delivered"
            :help="$t('help.engagement.create.deliveredAt')"
            name="customDate"
          ></x-date-picker>
        </v-col>

        <v-col cols="12" sm="3">
          <x-inputs-autocomplete
            :filled="engagement.status != 'pending'"
            v-bind:style="engagement.status != 'pending' ? 'cursor: not-allowed;' : ''"
            :readonly="engagement.status != 'pending'"
            :items="hours"
            persistent-hint
            :input="engagement.deliveredAt.hour"
            @updateInput="($event) => engagement.deliveredAt.hour = $event"
            light
            :label="$t('engagement.hours')"
            autofocus
            :rules="'required'"
            name="hours"
          ></x-inputs-autocomplete>
        </v-col>

        <v-col cols="12" sm="3">
          <x-date-picker
            v-if="engagement.status === 'progress'"
            v-model="engagement.validUntil.value"
            @date-picked="engagement.validUntil.value = $event"
            :label="$t('engagement.poll_valid_until')"
            name="customDate"
            ref="valid_until"
            :rules="'required'"
            :help="$t('help.engagement.create.validUntil')"
          ></x-date-picker>

          <x-date-picker
            v-else
            :readonly="engagement.status !== 'pending'"
            v-model="engagement.validUntil.value"
            @date-picked="engagement.validUntil.value = $event"
            :label="$t('engagement.poll_valid_until')"
            name="customDate"
            ref="valid_until"
            :rules="engagement.status === 'pending' ?
              `required|dateNowAfter:@${$t('engagement.date_delivery')}` : ''"
            :help="$t('help.engagement.create.validUntil')"
          ></x-date-picker>
        </v-col>

        <v-col cols="12" sm="3">
          <x-inputs-autocomplete
            v-if="engagement.status === 'progress'"
            :items="hours"
            persistent-hint
            :input="engagement.validUntil.hour"
            @updateInput="($event) => engagement.validUntil.hour = $event"
            light
            :label="$t('engagement.hours_valid_until')"
            autofocus
            :rules="'required'"
            name="hours_valid_until"
          ></x-inputs-autocomplete>

          <x-inputs-autocomplete
            v-else
            :filled="engagement.status != 'pending'"
            v-bind:style="engagement.status != 'pending' ? 'cursor: not-allowed;' : ''"
            :readonly="engagement.status != 'pending'"
            :items="hours"
            persistent-hint
            :input="engagement.validUntil.hour"
            @updateInput="($event) => engagement.validUntil.hour = $event"
            light
            :label="$t('engagement.hours_valid_until')"
            autofocus
            :rules="'required'"
            name="hours_valid_until"
          ></x-inputs-autocomplete>
        </v-col>

        <v-col cols="12">
          <x-inputs-autocomplete
            :filled="engagement.status != 'pending'"
            v-bind:style="engagement.status != 'pending' ? 'cursor: not-allowed;' : ''"
            persistent-hint
            :readonly="engagement.status != 'pending'"
            :items="timezones"
            :input="engagement.timeZone"
            @updateInput="($event) => engagement.timeZone = $event"
            light
            :label="$t('engagement.time_zone')"
            :rules="'required'"
            name="time_zone"
            :appendOuterIcon="$t('help.icon')"
            :helpMessage="$t('help.engagement.create.timezone')"
          ></x-inputs-autocomplete>
        </v-col>
      </v-row>

      <v-row row wrap>
        <v-col cols="12" sm="6">
          <v-switch
            v-model="engagement.switchDate"
            :label="$t('engagement.want_send_reminders')"
            :append-icon="$t('help.icon')"
            @click:append="$store.dispatch('help/display', $t('help.engagement.create.reminders'))"
          ></v-switch>
        </v-col>
      </v-row>

      <v-row
        row wrap
        v-bind:key="index" v-for="(reminder,index) in engagement.reminders"
        v-show="engagement.switchDate"
      >
        <v-col cols="12" sm="7" md="3" class="mr-2" >
          <x-date-picker
            v-model="engagement.reminders[index].value"
            @date-picked="engagement.reminders[index].value = $event"
            :label="$t('engagement.date')"
            :rules="engagement.switchDate?
              `required|dateBetween:${validateUntil.ref},${validateUntil.finish},${engagement.reminders[index].hour}` :
              ''"
            name="reminders"
            :reff="'child_picker'+index"
            ref="parent_picker"
          ></x-date-picker>
        </v-col>

        <v-col cols="12" sm="4" md="3">
          <x-inputs-autocomplete
            :items="hours"
            persistent-hint
            :input="engagement.reminders[index].hour"
            @updateInput="($event) => engagement.reminders[index].hour = $event"
            light
            :label="$t('engagement.hours')"
            autofocus
            :rules="'required'"
            name="hours"
          ></x-inputs-autocomplete>
        </v-col>

        <v-col cols="12" sm="12" md="5" v-if="engagement.reminders.length == index + 1" class="pt-5">
          <v-btn outlined rounded
            color="red"
            class="mr-2"
            @click="engagement.status !== 'completed' ? deleteReminder(index) : {}"
          >
            {{ $t('input.trash') }}
          </v-btn>

          <v-btn outlined rounded
            color="green"
            @click="engagement.status !== 'completed' ? addReminder(index) : {}"
          >
            {{ $t('input.add') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row row wrap class="pt-4">
        <v-col cols="12" sm="6">
          <v-btn
            block
            large
            @click="changeStep(+step - 1)"
          >{{ $t('input.back') }}</v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-btn
            color="primary"
            block
            large
            @click="validateForm()"
          >{{ $t('input.next') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-stepper-content>
</template>

<script>

import timeZoneService from '../../services/time-zones';

export default {
  props: {
    engagement: Object,
    step: String,
    nextAction: String,
    prevAction: String
  },
  data() {
    return {
      edit: Boolean(this.$route.params.id),
      hours: [],
      timezones: []
    }
  },
  computed: {
    validateUntil: function () {
      const { deliveredAt, validUntil } = this.engagement
      return {
        ref: new Date(`${deliveredAt.value} ${deliveredAt.hour}:00`).getTime(),
        finish: new Date(`${validUntil.value} ${validUntil.hour}:00`).getTime()
      }
    }
  },
  methods: {
    getOffsetObject() {
      const text = this.engagement.timeZone
      const arr = []
      this.timezones.forEach(element => {
        if (element.text === `${text}`) {
          arr.push(element)
        }
      })
      return arr
    },
    validTimeZone() {
      if (!this.engagement.timeZone) {
        return false;
      }
      const offsetObj = this.getOffsetObject()
      const date = new Date()
      const utc = date.getTime() + (date.getTimezoneOffset() * 60000)
      const newDate = new Date(utc + (3600000 * (parseFloat(offsetObj[0].offset))))
      if (this.edit && this.engagement.status === 'progress') {
        return this.validateUntil.finish > newDate.getTime();
      } else {
        return this.edit || newDate.getTime() < this.validateUntil.ref
      }
    },
    changeStep(step) {
      this.$emit('changeStep', step);
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        if (this.validTimeZone()) {
          this.changeStep(+this.step + 1)
          resolve()
        } else {
          this.$store.dispatch('alert/error', this.$t('errors.timezone'))
          resolve()
        }
      })
    },
    getHours() {
      for (let i = 0; i < 24; i++) {
        i < 10 ? this.hours.push('0' + i + ':00') : this.hours.push(i + ':00')
      }
    },
    addReminder(i) {
      this.$refs.parent_picker[i].$refs['child_picker'+i].validate()
        .then(res => {
          if (res.valid) {
            this.engagement.reminders.length == 5 ?
              this.$store.dispatch('alert/error', this.$t('errors.validator/reminders')) :
              this.engagement.reminders.push({
                value: '',
                hour: '23:00'
              })
          }
        })
    },
    deleteReminder(index) {
      if (this.engagement.reminders.length == 1) {
        this.$store.dispatch('alert/error', this.$t('errors.validator/delete_reminder'))
      } else {
        this.engagement.reminders.splice(index, 1)
      }
    }
  },
  created() {
    this.getHours();
    timeZoneService.list()
      .then(res => {
        this.timezones = res.items.map((item) => ({
          value: item.text,
          text: item.text,
          offset: item.offset,
        }));
      });
  },
}
</script>
