
export default {
  add_group: 'Add Group',
  groups_list: 'Groups List',
  no_data: 'There are no groups to show',
  // Headers
  id: 'Id',
  group_name: 'Group name',
  enterprises_no: 'N° Enterprises',
  status: 'Status',
  actions: 'Actions',
  modal_enable: 'Confirm you want to enable the group?',
  modal_disable: 'Confirm you want to disable the group?',
  // tslint:disable-next-line:max-line-length
  modal_enable_des: 'Once the group is enabled, the group administrator can manage the licensing of the group companies',
  // tslint:disable-next-line:max-line-length
  modal_disable_des: 'Once the group is disabled, the group administrator cannot manage the licensing of the group companies',
  modal_save_btn: 'Accept',
  created_group: 'Group created',
  updated_group: 'Group updated successfully!',
  edit_group: 'Edit Group',
  main_enterprise: 'Leader enterprise',
  current_plan: 'Current plan',
  associated_enterprises: 'Associated enterprises',
  want_to_select_enterprises: 'Do you wish to select the enterprises to which apply this update?',
  want_other_start: 'Do you wish to select a start date?',
  must_select_enterprises: 'You must select the enterprises to update from the list',
  must_select_plan: 'You must select the new plan',
  no_update_requests: 'There is no pending update requests for this group.',
  plan_updated: 'This request has been applied sucessfully.',
  plan_rejected: 'This request has been rejected sucessfully.',
};
