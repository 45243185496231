
export default {
  groups: {
    list: ['admin', 'customer', 'enterprise_admin'],
    create: ['admin'],
    getOne: ['admin', 'customer', 'enterprise_admin'],
    getOneByLeader: ['customer', 'enterprise_admin'],
    edit: ['admin'],
    applyUpdatePlan: ['admin'],
    rejectUpdatePlan: ['admin'],
    applyExtendPlan: ['admin'],
    rejectExtendPlan: ['admin'],
  },
  enterprises: {
    list: ['admin'],
    getOne: ['admin'],
    profile: ['admin.customer', 'customer', 'enterprise_admin'],
    create: ['admin'],
    edit: ['admin'],
  },
  employees: {
    list: ['admin.customer', 'customer', 'enterprise_admin'],
    create: ['admin.customer', 'customer', 'enterprise_admin'],
    massiveUpload: ['admin.customer', 'customer', 'enterprise_admin'],
  },
  questionnaires: {
    list: ['admin'],
    create: ['admin'],
    edit: ['admin'],
  },
  engagement: {
    create: ['customer', 'enterprise_admin'],
    list: ['customer', 'enterprise_admin'],
    fill: ['employee'],
  },
  pulse: {
    create: ['customer', 'enterprise_admin'],
    list: ['customer', 'enterprise_admin'],
    fill: ['employee'],
  },
  genders: {
    list: ['customer', 'enterprise_admin'],
    settings: ['admin.customer', 'customer', 'enterprise_admin'],
  },
  headquarters: {
    list: ['customer', 'enterprise_admin'],
    settings: ['admin.customer', 'customer', 'enterprise_admin'],
  },
  jobTypes: {
    list: ['customer', 'enterprise_admin'],
    settings: ['admin.customer', 'customer', 'enterprise_admin'],
  },
  countries: {
    settings: ['admin'],
    read: ['admin', 'admin.customer', 'customer', 'enterprise_admin'],
  },
  departments: {
    list: ['admin.customer', 'customer', 'enterprise_admin'],
    settings: ['admin.customer', 'customer', 'enterprise_admin'],
  },
  charges: {
    list: ['customer', 'enterprise_admin'],
    settings: ['admin.customer', 'customer', 'enterprise_admin'],
  },
  academicDegrees: {
    list: ['admin', 'customer', 'enterprise_admin'],
    settings: ['admin'],
    read: ['admin', 'admin.customer', 'customer', 'enterprise_admin'],
  },
  sectors: {
    list: ['admin', 'customer', 'enterprise_admin'],
    settings: ['admin'],
  },
  languages: {
    list: ['admin', 'customer', 'enterprise_admin'],
    settings: ['admin'],
  },
  enterpriseSizes: {
    list: ['admin'],
    settings: ['admin'],
  },
  committalQuestions: {
    list: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin'],
    settings: ['admin'],
  },
  wordQuestions: {
    list: ['admin', 'customer', 'enterprise_admin'],
    edit: ['admin'],
  },
  identifyTypes: {
    list: ['admin', 'customer', 'enterprise_admin'],
    settings: ['admin'],
  },
};
