
export default {
  add_group: 'Agregar Grupo',
  groups_list: 'Listado de Grupos',
  no_data: 'No hay grupos para mostrar',
  // Headers
  id: 'Id',
  group_name: 'Nombre grupo',
  enterprises_no: 'N° Empresas',
  status: 'Estado',
  actions: 'Acciones',
  modal_enable: '¿Desea habilitar el grupo?',
  modal_disable: '¿Desea Inhabilitar el grupo?',
  // tslint:disable-next-line:max-line-length
  modal_enable_des: 'Una vez habilitado el grupo, el administrador del grupo podrá gestionar el licenciamiento de las empresas del grupo.',
  // tslint:disable-next-line:max-line-length
  modal_disable_des: 'Una vez sea inhabilitado el grupo, el administrador del grupo no podrá gestionar el licenciamiento de las empresas del grupo.',
  modal_save_btn: 'Aceptar',
  created_group: 'Grupo creado',
  updated_group: 'Grupo modificado exitosamente!',
  edit_group: 'Editar Grupo',
  main_enterprise: 'Empresa líder',
  current_plan: 'Plan actual',
  associated_enterprises: 'Empresas asociadas',
  want_to_select_enterprises: '¿Desea seleccionar las empresas del grupo a las cuales aplicar este cambio?',
  want_other_start: '¿Desea especificar una fecha de inicio?',
  must_select_enterprises: 'Debe seleccionar las empresas para actualizar del listado',
  must_select_plan: 'Debe seleccionar el nuevo plan',
  no_update_requests: 'Este grupo no posee solicitudes de actualización pendientes.',
  plan_updated: 'Esta solicitud ha sido aplicada exitosamente.',
  plan_rejected: 'Esta solicitud ha sido rechazada exitosamente.',
};
