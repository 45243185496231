
<template>
  <v-container fluid grid-list-xl>
    <template v-if="loading">
      <div class="text-center">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </div>
    </template>
    <template v-else>
      <v-col cols="12">
        <h4 class="display-1">{{ $t('dashboard.general_title') }}</h4>
      </v-col>
      <template v-if="user.role === 'admin'">
        <dashboard-admin :info="info"/>
      </template>
      <template v-else-if="['customer', 'enterprise_admin'].includes(user.role)">
        <dashboard-enterprise :info="info" :user="user"/>
      </template>
      <template v-else-if="user.role === 'employee'">
        <dashboard-employee :info="info"/>
      </template>
    </template>
  </v-container>
</template>

<script>

import { mapState } from 'vuex';
import DashboardService from '../services/dashboard'
import DashboardAdmin from './dashboard/dashboard-admin.vue'
import DashboardEmployee from './dashboard/dashboard-employee.vue'
import DashboardEnterprise from './dashboard/dashboard-enterprise.vue'

export default {
  components: {
    DashboardAdmin,
    DashboardEmployee,
    DashboardEnterprise
  },
  data() {
    return {
      loading: true,
      info: {},
      today: new Date(),
      adminPolls: [],
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user,
    }),
  },
  watch: {
    paginationPolls: {
      handler() {
        this.loadingPolls = true;
        this.loadingPolls = false;
      },
      deep: true,
    }
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo: function() {
      DashboardService.getInfo(this.user)
        .then(resp => {
          this.loading = false;
          this.info = resp;
          if (this.user.role === 'employee') {
            this.getLastPollProgress(this.info.lastPoll);
          } else if (this.user.role !== 'customer') {
            this.info.polls = this.info.polls.sort((a, b) => {
              return new Date(b.deliveredAt) > new Date(a.deliveredAt) ? 1 : -1;
            });
          }
        });
    },
    getLastPollProgress: function(lastPolls) {
      lastPolls.forEach((lastPoll) => {
        const JSONquestions = (lastPoll.pollQuestionReference || { data: "[]" }).data;
        let JSONanswers = null;
        if (lastPoll.isPulse) {
          JSONanswers = (lastPoll.onePollEmployee.pollAnswerEmployeePulse || { data: "{}" }).data;
        } else {
          JSONanswers = (lastPoll.onePollEmployee.pollAnswerEmployee || { data: "{}" }).data;
        }
        const questions = JSON.parse(JSONquestions);
        let answers = JSON.parse(JSONanswers);
        answers = typeof answers === 'string' ? JSON.parse(answers) : answers;
        lastPoll.percent = Math.ceil((Object.keys(answers).length * 100) / questions.length);
        lastPoll.leftQuestions = (questions.length - Object.keys(answers).length);
      });
    }
  }
}
</script>

<style lang="css">
.no-polls-msg {
  font-size: 1.8em;
  margin-left: 0.6em;
  color: gray;
}

@media (max-width: 500px) {
  .responsive {
    width: auto !important;
  }

}
</style>
