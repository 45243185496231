
import Services from './base-services';

const service = new Services('committal-questions');

export default {
  listAll: (lang?: string) => {
    return service.get(lang ? `list-all?lang=${lang}` : 'list-all');
  },
  list: () => {
    return service.get('list');
  },
  updateQuestion: (data: object) => {
    return service.put('editQuestion', data);
  },
};
