<template>
  <v-dialog v-model="showModal" width="650px" persistent>
    <v-card>
      <v-card-title style="word-break: break-word;">
        <span class="headline">{{ $t('input.copy_invitation_url_title') }}</span>
      </v-card-title>
      <v-card-subtitle>
        <p class="mt-4 mb-0 text-justify body-2">
          {{ $t('input.copy_invitation_url') }}
        </p>
      </v-card-subtitle>
      <v-card-text>
        <v-col cols="12">
          <v-tooltip v-model="showTooltip" right>
            <template v-slot:activator="{ on }">
              <v-text-field outlined
                v-model="tokenUrl"
                id="tokenUrl"
                :label="$t('input.invitation_url')"
                :readonly="true"
                :append-icon="'file_copy'"
                @click:append="copyUrl"
              ></v-text-field>
            </template>
            <span>{{$t('input.url_copied')}}</span>
          </v-tooltip>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-btn block large @click="closeModal">{{ $t('input.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { mapState } from "vuex";

export default Vue.extend({
  props: {
    show: Boolean
  },
  data () {
    return {
      showModal: false,
      showTooltip: false,
      suiteBaseClient: process.env.VUE_APP_SUITE_BASE_CLIENT || 'http://localhost:8080/',
    }
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    })
  },
  watch: {
    show () {
      if (this.show) {
        this.showModal = this.show
      }
    }
  },
  methods: {
    copyUrl() {
      const input = document.getElementById("tokenUrl")
      input.select()
      document.execCommand("copy")
      this.showTooltip = !this.showTooltip
    },
    closeModal() {
      this.showTooltip = false;
      this.showModal = false;
      this.$emit('close')
    }
  },
  created () {
    this.tokenUrl = `${this.suiteBaseClient}auth/verify-polls/${this.user.enterprise.invitationCode}`;
  }
})
</script>
