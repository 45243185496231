
import ICommit from '../contracts/commit'

import auth from '../../services/auth'
import hosts from '../../services/hosts'
import router from '../../router'

const TOKEN_KEY = 'occ-key-token'
const USER_KEY = 'occ-key-user'
const SSO_ATTEMPTS = 'occ-sso-attempts'
const TO_PATH = 'to-path'

const baseState = {
  user: JSON.parse(localStorage.getItem(USER_KEY) || '{}'),
  token: localStorage.getItem(TOKEN_KEY),
  ssoAttempts: Number(localStorage.getItem(SSO_ATTEMPTS)) || 0
}

const persistAndCommitSession = (commit: any, session: any) => {
  localStorage.setItem(TOKEN_KEY, session.token)
  localStorage.setItem(USER_KEY, JSON.stringify(session.user))

  commit('setSession', session)
  return session
}
const actions = {
  changeLang ({ commit }: ICommit, session: any) {
    persistAndCommitSession(commit, session)
  },
  signOut ({ commit }: ICommit) {
    return auth.signOut()
      .then(() => {
        localStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(USER_KEY)
        localStorage.removeItem(SSO_ATTEMPTS)
        commit('clearSession')
      })
  },
  sso ({ commit }: ICommit, session: any) {
    persistAndCommitSession(commit, session)
    return auth.sso(baseState.ssoAttempts)
      .then((res: any) => {
        if (!res.id) {
          localStorage.removeItem(TOKEN_KEY)
          localStorage.removeItem(USER_KEY)
          commit('clearSession')
          return
        }
        session.user = res
        persistAndCommitSession(commit, session)
        commit('setSsoAttempts', res.ssoAttempts)
      })
  },
  isValid ({ dispatch, commit }: ICommit, toPath?: string) {
    if (toPath) {
      localStorage.setItem(TO_PATH, toPath)
    }
    return auth.isValid(baseState.ssoAttempts)
      .then((res) => {
        commit('setSsoAttempts', 1)
        if (!toPath) {
          const pendingPath = localStorage.getItem(TO_PATH) || '/'
          router.push({ path: pendingPath })
        } else if (res && res.id) {
          return true
        }
      })
      .catch(err => {
        if (err && err.code) {
          if (typeof err.code === 'object' && err.code.response && err.code.response.text) {
            err.code = JSON.parse(err.code.response.text).code
            console.log(err.code)
          }
          if (err.code.includes('auth/invalid-token')) {
            dispatch('signOut')
              .then(() => {
                dispatch('getSuiteWebHost')
                  .then((res: any) => {
                    if (res) {
                      location.replace(`${res}/auth/sso?product=pulse`)
                    } else {
                      router.push({ path: '/404' })
                    }
                  })
              })
          }
          if (err.code.includes('sso/retry')) {
            const attempt = err.code.split('/').pop()
            commit('setSsoAttempts', attempt)
            if (attempt < 5) {
              setTimeout(() => {
                dispatch('isValid')
              }, 5000)
            } else {
              dispatch('signOut')
                .then(() => {
                  dispatch('getSuiteWebHost')
                    .then((res: any) => {
                      if (res) {
                        location.replace(`${res}/auth/sso?product=pulse`)
                      } else {
                        router.push({ path: '/404' })
                      }
                    })
                })
            }
          }
        }
        return false
      })
  },
  changeSsoAttempt ({ commit }: ICommit, attemptCount: number) {
    commit('setSsoAttempts', attemptCount)
  },
  getPulseHost () {
    return hosts.getOne('OCC PULSE')
      .catch(err => {
        if (err) return false
      })
  },
  getSuiteHost () {
    return hosts.getOne('OCC SUITE')
      .catch(err => {
        if (err) return false
      })
  },
  getSuiteWebHost () {
    return hosts.getOne('OCC SUITE')
      .then(res => {
        if (res && res.webHostUrl) {
          let urlSuite = res.webHostUrl
          if (res.webHostPort && (res.webHostPort !== 80 && res.webHostPort !== 443)) {
            urlSuite = `${urlSuite}:${res.webHostPort}`
          }
          return urlSuite
        }
      })
      .catch(err => {
        if (err) return false
      })
  },
  updateEnterprise({ commit }: ICommit, user: any) {
    const token = localStorage.getItem(TOKEN_KEY);
    return persistAndCommitSession(commit, { user, token });
  }
};

const mutations = {
  setSsoAttempts (state: any, attempts: number) {
    if (attempts !== undefined) {
      state.ssoAttempts = attempts
      localStorage.setItem(SSO_ATTEMPTS, attempts.toString())
    }
  },
  setSession (state: any, session: any) {
    state.user = session.user
    state.token = session.token
    // state.wolf = session.wolf
  },
  clearSession (state: any) {
    state.user = {}
    // state.wolf = {}
    state.token = null
    state.ssoAttempts = 0
  }
}

export default {
  namespaced: true,
  state: baseState,
  actions,
  mutations
}
