
export default {
  previewEmail: {
    subject: 'Asunto del mensaje',
    sent_automatic: 'Este mensaje fue automáticamente enviado desde',
    support: 'Por favor no responda directamente a este correo. Las respuestas llegarán a un buzón automatizado y no nos será posible leer o contestar su correo. Para ponerse en contacto con nosotros envíe un correo a',
    rights_reserved: 'Todos los derechos reservados 2020.',
    attached_video: 'Vídeo adjunto',
    greeting: 'Hola',
    invited_to_poll: 'Fuiste invitado a participar en la encuesta',
    link_to_poll: 'El siguiente enlace le enviará hacia la encuesta:',
    click_here: 'click aquí'
  }
}
