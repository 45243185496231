<template>
  <v-container fluid>
    <v-row justify="start" dense no-gutters>
      <img src="/img/pulse/pulse-icon.png" width="30px" height="30px" class="ml-2 mt-1">
      <v-col>
        <h4 style="'float:left;'" class="display-1 mb-3 ml-3">{{ $t('pulses.to_edit') }}</h4>
      </v-col>

      <v-col cols="12">
        <v-card class="mt-4">
          <ValidationObserver ref="form">
            <v-form @submit.prevent>
              <x-stepper
                :step="step"
                :headers="stepperHeaders"
                :max-xs="1"
                :max-sm="2"
                :max-md="3"
                :max-lg="3"
                :max-xl="4"
                @step="step = parseInt($event)"
              >
                <ValidationObserver ref="step1">
                  <x-poll-steps-overview
                    :engagement="pulse"
                    step="1"
                    nextAction="input.next"
                    prevAction="input.cancel"
                    @changeStep="verifyStepChanged"
                  ></x-poll-steps-overview>
                </ValidationObserver>

                <ValidationObserver ref="step2">
                  <x-step-receptors
                    :pulse="pulse"
                    :employeesCount="employeesCount"
                    step="2"
                    nextAction="input.next"
                    prevAction="input.cancel"
                    @changeStep="verifyStepChanged"
                    :is-edit="true"
                  ></x-step-receptors>
                </ValidationObserver>

                <ValidationObserver ref="step3">
                  <x-poll-steps-date
                    :engagement="pulse"
                    step="3"
                    nextAction="input.next"
                    prevAction="input.back"
                    @changeStep="verifyStepChanged"
                  ></x-poll-steps-date>
                </ValidationObserver>

                <ValidationObserver ref="step4">
                  <x-step-question
                    :pulse="pulse"
                    step="4"
                    nextAction="input.next"
                    prevAction="input.cancel"
                    @changeStep="verifyStepChanged"
                    @changeAttributes="attrsChanged"
                    @baseAttributes="setBaseAttributes"
                    :isEdit="true"
                  ></x-step-question>
                </ValidationObserver>

                <ValidationObserver ref="step5">
                  <x-step-revition
                    v-if="pulse.totalReceivers"
                    :pulse="pulse"
                    :base-attrs="baseAttributes"
                    step="5"
                    nextAction="input.next"
                    prevAction="input.cancel"
                    @changeStep="verifyStepChanged"
                    :isEdit="true"
                  ></x-step-revition>
                </ValidationObserver>
              </x-stepper>
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>
    <x-loading v-if="loading" :display="loading"/>
  </v-container>
</template>
<script>

import pulseService from "../../services/pulses";

import XStepReceptors from "./steps/receptors.vue";
import XStepQuestion from "./steps/question.vue";
import XStepRevition from "./steps/revition.vue";
import {mapState} from "vuex";

export default {
  components: {
    XStepReceptors,
    XStepQuestion,
    XStepRevition
  },
  data() {
    return {
      baseAttributes: [],
      stepperHeaders: [
        'pulses.poll_name',
        'pulses.receptors',
        'pulses.date',
        'pulses.questions',
        'pulses.revition',
      ],
      pulse: {
        name: '',
        displayName: '',
        selectionType: '',
        selectionDetails: '',
        timeZone: '',
        deliveredAt: {
          value: '',
          hour: '23:00',
        },
        model: null,
        validUntil: {
          value: '',
          hour: '23:00',
        },
        emailData: '',
        min: 0,
        max: 100,
        rangeAge: [],
        rangeAntiquity: [],
        totalReceivers: 0,
        switchName: false,
        switchOpenQuestion: false,
        switchCommittalQuestion: false,
        switchQuestionnaireQuestion: false,
        radios: 2,
        questionnaires: [],
        switchDate: false,
        status: 'pending',
        reminders: [{
          value: '',
          hour: '23:00',
        }],
        genders: [],
        departments: [],
        charges: [],
        jobTypes: [],
        genderId: '',
        headquarters: [],
        jobTypeIds: [],
        chargeIds: [],
        departmentIds: [],
        academicDegreeIds: [],
        dimensions: [],
        questionsCommittal: [],
        questionsAttributesIds: [],
        percentTotal: 0,
        opens: [],
        pollInvitation: {
          subject: '',
          body: '',
          file: '',
        },
        reminderMail: {
          subject: '',
          body: '',
          file: '',
        },
        thankMessage: '',
        active: null,
        offset: '',
        demographicItems: {},
        countrySelect: [],
        headquarterSelect: [],
      },
      loading: false,
      step: 1,
      employeesCount: 0
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user,
    }),
  },
  methods: {
    setBaseAttributes (val) {
      this.baseAttributes = val
    },
    attrsChanged (val) {
      this.pulse.questionsAttributesIds = val
    },
    initForm () {
      if (this.user.enterprise) {
        this.pulse.enterprise = this.user.enterprise
        this.pulse.enterpriseId = this.user.enterprise.id
      }
    },
    getPulse () {
      this.loading = true
      pulseService.getOneById(this.$route.params.id)
        .then(res => {
          this.reponseParse(res.item);
          const status = res.item.status;
          this.employeesCount = res.item.totalReceivers
          const invalidStatuses = ['completed', 'cancelled', 'creating', 'editing', 'launching'];
          if (invalidStatuses.includes(status)) { this.$router.go(-1); }
        })
        .finally(() => {
          this.loading = false
        });
    },
    reponseParse (res) {
      const emailData = JSON.parse(res.emailData);
      this.$set(this.pulse, "pollInvitation", emailData.pollInvitation);
      this.$set(this.pulse, "reminderMail", emailData.reminderMail);
      this.$set(this.pulse, "thankMessage", emailData.thankMessage);

      this.$set(this.pulse, 'selectionType', res.selectionType == "everybody" ? 1 : (res.selectionType == "demographic_items" ? 2 : 3));
      const details = JSON.parse(res.selectionDetails);
      if (res.selectionType == "demographic_items") {
        this.$set(this.pulse, "genderId", details.genderId ? details.genderId : "")
        this.$set(this.pulse, "rangeAge", details.rangeAge ? details.rangeAge : [])
        this.$set(this.pulse, "rangeAntiquity", details.rangeAntiquity ? details.rangeAntiquity : [])
        this.$set(this.pulse, "departmentIds", details.departmentIds ? details.departmentIds : [])
        this.$set(this.pulse, "chargeIds", details.chargeIds ? details.chargeIds : [])
        this.$set(this.pulse, "jobTypeIds", details.jobTypeIds ? details.jobTypeIds : [])
        this.$set(this.pulse, "countrySelect", details.countrySelect ? details.countrySelect : [])
        this.$set(this.pulse, "headquarterSelect", details.headquarterSelect ? details.headquarterSelect : [])
        this.$set(this.pulse, "academicDegreeIds", details.academicDegreeIds ? details.academicDegreeIds : [])
        this.$set(this.pulse, "additionalDemographic1Ids", details.additionalDemographic1Ids ? details.additionalDemographic1Ids : [])
        this.$set(this.pulse, "additionalDemographic2Ids", details.additionalDemographic2Ids ? details.additionalDemographic2Ids : [])
      } else if (res.selectionType == "random") {
        this.$set(this.pulse, "percentTotal", details.percent ? details.percent : 0)
      }
      this.$set(this.pulse, "name", res.name)
      this.$set(this.pulse, "displayName", res.displayName)
      this.$set(this.pulse, "switchName", Boolean(this.pulse.displayName))
      this.$set(this.pulse, "timeZone", res.timeZone)
      this.$set(this.pulse, "status", res.status)
      this.$set(this.pulse, "deliveredAt", this.getDatePulse(res.deliveredAt))
      this.$set(this.pulse, "validUntil", this.getDatePulse(res.validUntil))
      this.$set(this.pulse, "switchOpenQuestion", Boolean(res.openQuestion.length))

      if (this.pulse.switchOpenQuestion) {
        res.openQuestion.forEach(open => {
          this.pulse.opens.push(JSON.parse(open.question));
        })
      } else {
        this.pulse.opens.push({question: "", options: ["", ""]})
      }

      const questions = JSON.parse(res.questionDetails)
      if (questions.questionnaire_id) {
        this.$set(this.pulse, "radios", questions.questionnaire_id)
        this.$set(this.pulse, "switchQuestionnaireQuestion", Boolean(questions.questionnaire_id))
      }
      if (questions.dimentions) {
        this.pulse.dimensions = questions.dimentions
      }
      if (questions.attributes) {
        this.pulse.questionsAttributesIds = questions.attributes
      }
      if (questions.committal_questions) {
        this.pulse.questionsCommittal = questions.committal_questions
        this.$set(this.pulse, "switchCommittalQuestion", Boolean(this.pulse.questionsCommittal.length))
      }

      res.reminders.forEach((element, i) => {
        this.pulse.switchDate = true
        this.pulse.reminders[i] = this.getDatePulse(element.date)
      })
    },
    getDatePulse(ref) {
      if (!ref) {
        return {
          value: "",
          hour: "23:00"
        };
      }
      const date = ref.split("T");
      const time = date[1].split(":");
      return {
        value: date[0],
        hour: time[0] + ":00"
      };
    },
    onSubmit(step) {
      if (step && step < this.step) {
        this.step = step
        return
      }
      const onValidate = success => {
        if (!success) {
          const error = this.$t('errors.validator/invalid-input')
          this.$store.dispatch('alert/error', error)
          return
        }
        if (step === 5) {
          this.initForm();
        }
        if (step === 6) {
          return this.update()
        } else {
          this.step = step
        }
      };
      switch (this.step) {
        case 1:
          this.$refs.step1.validate().then(onValidate);
          break;
        case 2:
          this.$refs.step2.validate().then(onValidate);
          break;
        case 3:
          this.$refs.step3.validate().then(onValidate);
          break;
        case 4:
          this.$refs.step4.validate().then(onValidate);
          break;
        case 5:
          this.$refs.step5.validate().then(onValidate);
          break;
      }
    },
    verifyStepChanged(step) {
      if (step === 0) {
        return this.$router.push('/pulse')
      }
      this.onSubmit(step)
    },
    update() {
      this.loading = true

      let pollInvitationFile = null
      let reminderMailFile = null

      if (this.pulse.pollInvitation.file && typeof this.pulse.pollInvitation.file === 'object') {
        pollInvitationFile = this.pulse.pollInvitation.file
        this.pulse.pollInvitation.file = this.pulse.pollInvitation.file.name
      }
      if (this.pulse.reminderMail.file && typeof this.pulse.reminderMail.file === 'object') {
        reminderMailFile = this.pulse.reminderMail.file
        this.pulse.reminderMail.file = this.pulse.reminderMail.file.name
      }

      return pulseService.update(this.$route.params.id, this.pulse)
        .then(() => {
          return pollInvitationFile ? pulseService.sendInvitationFiles(this.$route.params.id, { pollInvitationFile }) : Promise.resolve();
        })
        .then(() => {
          return reminderMailFile ? pulseService.sendReminderFiles(this.$route.params.id, { reminderMailFile }) : Promise.resolve();
        })
        .then(() => {
          this.$store.dispatch('alert/success', this.$t('pulses.edit'))
          this.$router.push('/pulse')
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`));
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  created() {
    this.getPulse()
  }
};
</script>

<style>

#enterprise-create div.v-stepper,
#enterprise-create div.v-stepper div.v-stepper__items,
#enterprise-create div.v-stepper div.v-stepper__items div.v-stepper__wrapper {
  overflow: visible !important;
}
</style>
