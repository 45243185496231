
<template>
  <v-stepper-content step="4" class="cut-overflow">
    <v-col
      cols="12"
      class="pa-3 headline"
    >
      {{ $t('pulses.questions') }}
      <v-divider></v-divider>
      <v-spacer></v-spacer>
    </v-col>
    <v-container>
      <v-row align-space-between justify-center column fill-height>
        <v-col cols="12">
          <v-switch
            :readonly="pulse.status !== 'pending'"
            v-model="pulse.switchCommittalQuestion"
            :label="$t('pulses.want_committal_question')"
            :append-icon="$t('help.icon')"
            @click:append="$store.dispatch('help/display', $t('help.pulse.create.committal'))"
          ></v-switch>
        </v-col>
        <v-col cols="12" v-if="pulse.switchCommittalQuestion">
          <x-inputs-select
            :input="pulse.questionsCommittal"
            @updateInput="($event) => pulse.questionsCommittal = $event"
            :items="questionsCommittal"
            chips
            name="selection_question_committal"
            :rules="'required'"
            small-chips
            :label="$t('pulses.selection_question_committal')"
            multiple solo
            :disabled="pulse.status !== 'pending'"
            v-bind:style="pulse.status !== 'pending' ? 'cursor: not-allowed !important;' : ''"
          ></x-inputs-select>
        </v-col>
      </v-row>

      <v-row align-space-between justify-center column fill-height>
        <v-col cols="12"  class="pb-0">
          <v-switch
            :readonly="pulse.status !== 'pending'"
            v-model="pulse.switchQuestionnaireQuestion"
            :label="$t('pulses.want_questionnaire_question')"
            :append-icon="$t('help.icon')"
            @click:append="$store.dispatch('help/display', $t('help.pulse.create.questionnaire'))"
          ></v-switch>
        </v-col>
        <v-col cols="12" v-if="pulse.switchQuestionnaireQuestion" class="pt-0">
          <v-row align-center justify-start row fill-height
            v-for="(item,index) in pulse.questionnaires" :key="index">
              <v-col xs="7" sm="7" max-height="20px" class="pt-0">
                <v-radio-group hide-details
                  v-model="pulse.radios"
                  :mandatory="false"
                  class="mt-0 mb-0 ml-4"
                  @change="radiosChanged"
                >
                  <v-radio
                    class="pt-4"
                    :label="item.text"
                    :value="item.value"
                    :rules="'required'"
                    :readonly="pulse.status !== 'pending'"
                  ></v-radio>
                </v-radio-group>
                <!--
                <x-inputs-select
                  v-if="pulse.radios === item.value"
                  :input="pulse.dimensions"
                  @updateInput="($event) => pulse.dimensions = $event"
                  :items="dimensions"
                  chips
                  name="selection_dimension"
                  :rules="'required'"
                  small-chips
                  :label="$t('pulses.selection_dimension')"
                  multiple solo
                  :disabled="pulse.status !== 'pending'"
                  v-bind:style="pulse.status !== 'pending' ? 'cursor: not-allowed !important;' : ''"
                ></x-inputs-select>
                -->
              </v-col>
              <v-col xs="5" sm="5" class="pt-0 text-right">
                <v-btn small
                  color="primary"
                  class="white--text mt-4 mr-4"
                  style="margin-right=100%;"
                  :disabled="!item.langs.includes(user.lang)"
                  @click="getPdf(item)"
                >
                  {{ $t('pulses.download') }} {{ $t('pulses.questionnaire') }}
                  <v-icon dark right small>mdi-file-pdf</v-icon>
                </v-btn>
              </v-col>
              <!--------------------------- Selection Table --------------------------->
              <v-col cols="12"
                v-if="pulse.radios === item.value"
                class="pt-0 pl-8 pr-7"
              >
                <p class="mb-0 body-2 text-capitalize text-right font-weight-bold">
                  {{ $t('selected') }}: {{ selectedAttrsCount }}
                </p>
                <v-simple-table dense
                  fixed-header
                  height="397px"
                  class="attributes-table"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ $t('pulses.dimension') }}
                        </th>
                        <th colspan="2" class="text-left">
                          {{ $t('pulses.attribute') }}
                        </th>
                        <th class="text-left">
                          Variables
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="dimension in dimensions">
                        <tr
                          v-for="(attrs, i) in getAttrsByDimension(dimension.id)"
                          :key="attrs.id"
                        >
                          <td v-if="i === 0" rowspan="3">
                            {{ dimension.text }}
                          </td>
                          <td class="pr-0">
                            <v-checkbox dense hide-details
                              v-model="selectedAttributes[attrs.id].selected"
                              :ripple="false"
                              :disabled="selectedAttrsCount > 5 && !selectedAttributes[attrs.id].selected"
                              :readonly="pulse.status !== 'pending'"
                              class="mt-0 mb-1"
                              @click="selectedAttributesCounter()"
                            ></v-checkbox>
                          </td>
                          <td class="pl-0">
                            {{ attrs.text }}
                          </td>
                          <td
                            v-html="attrs.variables"
                            class="caption"
                          ></td>
                        </tr>
                      </template>
                    </tbody>
                  </template>
                </v-simple-table>
                <p class="mb-0 ml-1 caption primary--text font-weight-bold">
                  {{ $t('pulses.questionnaire_table_legend') }}
                </p>
              </v-col>
           </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-switch
            :readonly="pulse.status !== 'pending'"
            v-model="pulse.switchOpenQuestion"
            :label="$t('pulses.want_open_question')"
            :append-icon="$t('help.icon')"
            @click:append="$store.dispatch('help/display', $t('help.pulse.create.open'))"
          ></v-switch>
          <x-info :text="$t('pulses.open_question_info')" v-if="pulse.switchOpenQuestion"></x-info>
        </v-col>
        <template v-if="pulse.switchOpenQuestion">
          <v-col cols="12" class="headline">
            {{ $t('pulses.open_question') }}
            <v-tooltip :disabled="pulse.opens.length === maxOpenQuestion || pulse.status !== 'pending'" bottom color="green lighten-3">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  fab small
                  color="#068ad5"
                  class="white--text ml-4"
                  :disabled="pulse.opens.length === maxOpenQuestion || pulse.status !== 'pending'"
                  @click="addOpenQuestion"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('pulses.add_open_question') }}</span>
            </v-tooltip>
            <v-tooltip :disabled="pulse.opens.length === minOpenQuestion || pulse.status !== 'pending'" bottom color="red">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  fab small
                  color="#f65871"
                  class="white--text ml-4"
                  :disabled="pulse.opens.length === minOpenQuestion || pulse.status !== 'pending'"
                  @click="removeOpenQuestion"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('pulses.remove_open_question') }}</span>
            </v-tooltip>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" sm="6" v-for="(open, $idx) in pulse.opens" :key="$idx">
            <v-container grid-list-md>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="open.question"
                    light
                    :label="$t('pulses.insert_question', {n: $idx + 1})"
                    :name="`external_name${$idx}`"
                    :readonly="pulse.status !== 'pending'"
                    :disabled="pulse.status !== 'pending'"
                  >
                    <template #append>
                      <v-tooltip :disabled="open.options.length === maxOptionOpenQuestion || pulse.status !== 'pending'" bottom color="green lighten-3">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            fab small
                            color="#068ad5"
                            class="white--text"
                            :disabled="open.options.length === maxOptionOpenQuestion || pulse.status !== 'pending'"
                            @click="addOptionOpenQuestion(open)"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('pulses.add_question_option') }}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" xs="11" offset-xs="1" v-for="(option, $i) in open.options" :key="$i">
                  <v-text-field
                    v-model="open.options[$i]"
                    light
                    :label="$t('pulses.insert_question_option', {n: $idx + 1, o: $i + 1})"
                    :name="`external_name${$idx}${$i}`"
                    :readonly="pulse.status !== 'pending'"
                    :disabled="pulse.status !== 'pending'"
                  >
                    <template v-slot:append>
                      <v-tooltip :disabled="open.options.length === minOptionOpenQuestion || pulse.status !== 'pending'" bottom color="red">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            fab small
                            color="#f65871"
                            class="white--text"
                            :disabled="open.options.length === minOptionOpenQuestion || pulse.status !== 'pending'"
                            @click="removeOptionOpenQuestion(open, $i)"
                          >
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('pulses.remove_question_option') }}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </template>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-btn
            block
            large
            @click="changeStep(+step - 1)"
          >{{ $t('input.back') }}</v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <x-async-btn
            :disabled="(pulse.switchQuestionnaireQuestion && (!pulse.questionnaires.length || !pulse.radios || !selectedAttrsCount)) || (!pulse.switchCommittalQuestion && !pulse.switchQuestionnaireQuestion) "
            class="white--text"
            :action="runValidate"
            large
            block
          >{{ $t(nextAction) }}
          </x-async-btn>
        </v-col>
      </v-row>
    </v-container>
    <!-- 
    <v-dialog v-model="showDialog" width="600px" :persistent="true">
      <v-card>
        <v-card-title>
          <span class="headline"> {{ $t('pulses.no_questionnaries_title') }} </span>
        </v-card-title>
        <v-card-text>
          {{ $t('pulses.no_questionnaries_title') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="white--text"
            large
            block
            color="#3898d9"
            @click="showDialog = !showDialog"
          >
            {{ $t('input.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    -->

    <x-loading v-if="loading" :display="loading"/>

    <img
      src="/img/occ-logo-small.png"
      style="visibility:hidden;"
      id="occPulseLogo"
      width="0"
      height="0"
    />
  </v-stepper-content>
</template>

<style lang="scss" scoped>
  .attributes-table {
    border-radius: 4px 0 0 4px;
    border: 1px solid #CCCCCC;
    tbody {
       tr:hover {
          cursor: default;
          background-color: transparent !important;
       }
    }
  }
</style>

<script>

import { mapState } from 'vuex';
import is from 'is_js'
import questionnairesService from '../../../services/questionnaires';
import committalQuestionsService from '../../../services/committal-questions';
import attributesService from '../../../services/attributes';
import dimensionsService from '../../../services/dimensions';
import resolver from '../../../utils/resolver';

const pdfmake = require('pdfmake/build/pdfmake');
const pdffonts = require('pdfmake/build/vfs_fonts.js');
pdfmake.vfs = pdffonts.pdfMake.vfs;

const formatItemsQuestionnaire = items => {
  return items.map((item) => {
    return {
      value: item.id,
      text: item.name,
      detail: item.questionnaireDetail,
      langs: item.languages
    };
  });
};

const formatItems = items => {
  return items.map((item) => {
    return {
      value: item.id,
      text: item.translate.label,
    };
  });
};

const variablesToString = items => {
  let label = ''
  items.map((item) => {
    label += item.translate.label + '<br>';
  });
  return label.replace(/<br>\s*$/, '');
};

export default {
  data() {
    return {
      loading: false,
      selectedAttributes: [],
      selectedAttrsCount: 0,
      finalAttributes: [],
      attributes: [],
      dimensions: [],
      questionsCommittal: [],
      force: false,
      pulseLogoSrc: null,
      pulseLogoBase64: null,
      minOpenQuestion: 1,
      maxOpenQuestion: 3,
      minOptionOpenQuestion: 2,
      maxOptionOpenQuestion: 5
      // showDialog: false,
    };
  },
  props: {
    pulse: Object,
    step: String,
    nextAction: String,
    prevAction: String,
    isEdit: Boolean,
  },
  created() {
    this.getData()
  },
  mounted() {
    this.pulseLogoSrc = document.getElementById('occPulseLogo').src;
  },
  watch: {
    '$i18n.locale': {
      handler(val) {
        this.getData()
      }
    },
    'pulse.switchCommittalQuestion': {
      handler() {
        // this.showDialog = !(this.pulse.questionnaires.length || (this.pulse.switchCommittalQuestion && !this.pulse.switchQuestionnaireQuestion))
        if (!this.pulse.switchCommittalQuestion && !this.pulse.switchQuestionnaireQuestion) {
          this.pulse.switchQuestionnaireQuestion = true;
        }
      },
    },
    'pulse.switchQuestionnaireQuestion': {
      handler(val) {
        if (!val) {
          this.selectedAttributes.map(sa => sa.selected = false)
          this.selectedAttrsCount = 0
          this.finalAttributes = []
        }
        // this.showDialog = !(this.pulse.questionnaires.length || (this.pulse.switchCommittalQuestion && !this.pulse.switchQuestionnaireQuestion))
        if (!this.pulse.switchQuestionnaireQuestion && !this.pulse.switchCommittalQuestion) {
          this.pulse.switchCommittalQuestion = true;
        }
      },
    },
    pulseLogoSrc(newVal) {
      if (newVal) {
        this.toDataURL(this.pulseLogoSrc, (dataURL) => {
          this.pulseLogoBase64 = dataURL;
        });
      }
    },
    selectedAttrsCount (val) {
      if (val) {
        const selectedAttrs = this.selectedAttributes.filter(sa => sa.selected === true)
        if (selectedAttrs) {
          this.finalAttributes = selectedAttrs.map(x => x.id)
        } else {
          this.finalAttributes = []
        }
      } else {
        if (this.finalAttributes.length) {
          this.finalAttributes = []
        }
      }
    },
    finalAttributes (val) {
      this.$emit('changeAttributes', val)
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user,
    }),
  },
  methods: {
    getData () {
      this.loading = true
      resolver
        .all({
          attributes: attributesService.listAll(this.user.lang),
          dimensions: dimensionsService.listAll(this.user.lang),
          questionnaires: questionnairesService.listByType('pulse'),
          questionsCommittal: committalQuestionsService.listAll(this.user.lang)
        })
        .then((res) => {
          let editCountSelectedAttrs = 0
          this.attributes = res.attributes.items.map((a) => {
            if (!this.isEdit) {
              this.selectedAttributes[a.id] = { id: a.id, selected: false }
            } else {
              const isSelected = this.pulse.questionsAttributesIds.includes(a.id)
              this.selectedAttributes[a.id] = { id: a.id, selected: isSelected }
              if (isSelected) {
                editCountSelectedAttrs++
              }
            }
            return {
              id: a.id,
              dimensionId: a.dimensionId,
              value: a.code,
              text: a.translate.label,
              variables: a.variables ? variablesToString(a.variables) : '----'
            };
          });
          this.selectedAttrsCount = editCountSelectedAttrs
          // Pass Attributes to parent component
          this.$emit('baseAttributes', this.attributes)

          // Pass Dimensions & Commital Questions to Revision component
          this.$root.$emit('passRevitionData', res.dimensions.items, res.questionsCommittal.items)

          this.dimensions = res.dimensions.items.map((d) => {
            return {
              id: d.id,
              value: d.id,
              text: d.translate.label
            }
          })

          this.pulse.questionnaires = formatItemsQuestionnaire(res.questionnaires.items)
          this.questionsCommittal = formatItems(res.questionsCommittal.items)

          this.pulse.dimensions = this.dimensions
          if (!this.isEdit) {
            this.pulse.radios = this.pulse.questionnaires[0].value
            this.setSelectionsIds(res.dimensions.items, 'dimensions')
            this.setSelectionsIds(res.questionsCommittal.items, 'questionsCommittal')
          } else {
            this.setSelectionsIds(this.pulse.dimensions, 'dimensions')
            this.setSelectionsIds(this.pulse.questionsCommittal, 'questionsCommittal')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    radiosChanged (event) {
      this.selectedAttributes.map(sa => sa.selected = false)
      this.selectedAttrsCount = 0
      this.finalAttributes = []
    },
    getAttrsByDimension (dimensionId) {
      return this.attributes.filter(attr => attr.dimensionId === dimensionId)
    },
    selectedAttributesCounter () {
      const cnt = this.selectedAttributes.filter(sa => sa.selected === true)
      this.selectedAttrsCount = cnt.length
    },
    setSelectionsIds(data, context) {
      const ids = [];
      data.forEach(element => {
        const e = typeof element === 'object' ? element.id : element;
        ids.push(e);
      });
      // this.pulse[context] = ids;
      this.$set(this.pulse, context, ids);
    },
    changeStep(step) {
      this.$emit('changeStep', step);
    },
    runValidate() {
      return new Promise((resolve) => {
        if (this.pulse.switchOpenQuestion) {
          if (this.pulse.opens.length) {
            const isValid = this.pulse.opens.every(q => {
              return (q.question && q.options.every(r => !!r));
            });
            if (!isValid) {
              const error = this.$t('errors.required_open_question')
              this.$store.dispatch('alert/error', error)
              resolve();
              return
            }
          }
        }
        this.changeStep(+this.step + 1);
        resolve();
      });
    },
    removeOpenQuestion() {
      this.pulse.opens.pop();
    },
    addOpenQuestion() {
      this.pulse.opens.push({
        question: '',
        options: ['', ''],
      });
    },
    removeOptionOpenQuestion(open) {
      open.options.pop();
    },
    addOptionOpenQuestion(open) {
      open.options.push('');
    },
    toDataURL(url, callback) {
      const xhr = new XMLHttpRequest();
      xhr.open('get', url);
      xhr.responseType = 'blob';

      xhr.onload = function() {
        const fr = new FileReader();

        fr.onload = function() {
          callback(this.result);
        };

        fr.readAsDataURL(xhr.response);
      };

      xhr.send();
    },
    writeRotatedText(text) {
      const canvas = document.createElement('canvas');
      canvas.width = 50;
      canvas.height = 845;

      const ctx = canvas.getContext('2d');

      // Genera color de fondo
      ctx.fillStyle = '#2196F3';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.save();

      // Posiciona el elemento al costado derecho de la página
      ctx.translate(50, 845);
      ctx.rotate(-0.5 * Math.PI);

      // Formatea el texto
      ctx.font = '20pt Roboto';
      ctx.fillStyle = 'white';
      ctx.fillText(text.toUpperCase(), 290, -15);
      ctx.restore();

      return canvas.toDataURL();
    },
    getPdf(questionnaire) {
      this.loading = true;
      const details = [];
      this.pulse.dimensions.forEach((id, dIterator) => {
        const d = this.dimensions.find(dim => dim.id === id);

        dIterator += 1;

        const dimObj = { text: d.text.toUpperCase(), bold: true, fontSize: 15 };

        if (dIterator % 2 !== 0 && dIterator !== 1) {
          dimObj.pageBreak = 'before';
          dimObj.margin = [0, 15, 0, 0]
        }

        details.push(dimObj);

        this.attributes
          .filter(a => {
            return a.dimensionId === d.id;
          })
          .forEach((a) => {
            const attObj = { text: a.text.toUpperCase(), bold: true, fontSize: 13, margin: [15, 5, 0, 0] };
            details.push(attObj);

            const questions = questionnaire.detail
              .filter(d => d.attributeId === a.id)
              .map((d) => d.translations.find(t => t.lang === this.user.lang).label);

            const qstObj = { ul: questions, margin: [15, 0, 0, 15] };
            details.push(qstObj);
          });
      });

      const configuration = {
        pageSize: 'A4',
        info: {
          title: this.$t('engagementReport.title'),
          author: 'OCC',
          subject: this.$t('engagementReport.title'),
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Roboto',
          lineHeight: 1.2,
        },
        header: () => {
          return [{
            image: this.pulseLogoBase64,
            height: 28,
            width: 117,
            margin: [15, 15, 25, 15],
          }];
        },
        footer: () => {
          return [
            {
              columns: [
                { width: '*', text: '' },
                {
                  width: 'auto',
                  text: this.$t('engagementReport.copyright'),
                  color: 'grey',
                  fontSize: 10,
                },
                { width: '*', text: '' },
              ],
            }
          ];
        },
        background: () => {
          const result = {
            image: this.writeRotatedText(questionnaire.text),
            aligment: 'center',
            absolutePosition: { x: 545, y: 0 },
          };

          return result;
        },
        content: [
          // Título
          {
            text: this.$t('pulses.questionnaire_p'),
            fontSize: 20,
            margin: [0, 20, 0, 10],
          },
          // Explicación
          {
            text: this.$t('pulses.pdf_explained', { name: questionnaire.text }),
            alignment: 'justify',
            margin: [0, 0, 20, 10],
          },
          // Cuestionario
          ...details,
        ]
      };

      if (is.edge() || is.ie()) {
        const pdfDocGenerator = pdfMake.createPdf(configuration);
        pdfDocGenerator.getBlob((blob) => {
          window.navigator.msSaveBlob(blob, 'questionnaire.pdf')
          this.loading = false
        });
      } else {
        pdfMake.createPdf(configuration).download('questionnaire.pdf');
        this.loading = false
      }
    }
  }
}
</script>
