
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import AppLayout from '../views/app-layout.vue';
import AuthLayout from '../views/auth/auth-layout.vue';
// import SignIn from '../views/auth/sign-in.vue';
import Dashboard from '../views/dashboard.vue';
import EngagementCreate from '../views/engagement/create.vue';
import EngagementEdit from '../views/engagement/edit.vue';
import EngagementFill from '../views/engagement/fill.vue';
import EngagementListByEmployee from '../views/engagement/list-by-employee.vue';
import EngagementList from '../views/engagement/list.vue';
import EngagementShow from '../views/engagement/show.vue';
import Forbidden from '../views/errors/forbidden.vue';
import NotFound from '../views/errors/not-found.vue';
import InvalidEnterprise from '../views/errors/invalid-enterprise.vue'
import RequireProductView from '../views/errors/require-product.vue'
import Maintenance from '../views/maintenance.vue';
import Notifications from '../views/notifications/notifications.vue';
import PollLayout from '../views/poll-layout.vue';
import PulseCreate from '../views/pulse/create.vue';
import PulseEdit from '../views/pulse/edit.vue';
import PulseFill from '../views/pulse/fill.vue';
import PulseFillDemo from '../views/pulse/fill-demo.vue';
import PulseListByEmployee from '../views/pulse/list-by-employee.vue';
import PulseList from '../views/pulse/list.vue';
// import PulseReportNewModel from '../views/pulse/report/newModel/selection.vue';
// import PulseReportSelection from '../views/pulse/report/selection.vue';
import PbiReport from '../views/pbiReport/view.vue';
import PulseShow from '../views/pulse/show.vue';
import QuestionnairesCreate from '../views/questionnaires/create.vue';
import QuestionnairesEditQuestions from '../views/questionnaires/edit-questions.vue';
import QuestionnairesList from '../views/questionnaires/list.vue';
import EditCommittals from '../views/questions/edit-committal.vue';
import EditWords from '../views/questions/edit-word.vue';
import EngagementReports from '../views/report/engagement.vue';

import RequireProduct from './guards/require-product'
import RequireAuthentication from './guards/require-authentication';
import RequireCustomer from './guards/require-customer'
import RequireEnterprise from './guards/require-enterprise'
import RequireGuest from './guards/require-guest';
// import RequireGuestAndEnterprise from './guards/require-guest-and-enterprise';
import SsoAuthentication from './guards/sso-authentication'

import FollowUps from '../views/followUps/index.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: 'sso',
        beforeEnter: SsoAuthentication
      },
      {
        path: 'sign-in',
        beforeEnter: RequireAuthentication
      }
    ],
  },
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: '',
        component: Dashboard,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/dashboard',
        component: Dashboard,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/questionnaires/',
        component: QuestionnairesList,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/questionnaires/create',
        component: QuestionnairesCreate,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/questionnaires/edit/:id/questions',
        component: QuestionnairesEditQuestions,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/engagement',
        component: EngagementList,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/engagement/fill/:token',
        component: EngagementFill,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/engagement/employee',
        component: EngagementListByEmployee,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/engagement/:id/show',
        component: EngagementShow,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/engagement/create',
        component: EngagementCreate,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/engagement/:id/edit',
        component: EngagementEdit,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/engagement/reports/:id',
        component: EngagementReports,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/pulse',
        component: PulseList,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/pulse/:id/show',
        component: PulseShow,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/pulse/create',
        component: PulseCreate,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/pulse/:id/edit',
        component: PulseEdit,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/pulse/fill/:token',
        component: PulseFill,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/pulse/employee',
        component: PulseListByEmployee,
        beforeEnter: RequireAuthentication,
      },
      /*
      {
        path: '/pulse/report/:id',
        component: PulseReportNewModel,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/pulse/report/selection/:id',
        component: PulseReportSelection,
        beforeEnter: RequireAuthentication,
      },
      */
      {
        path: '/:type/:pollId/report',
        component: PbiReport,
        beforeEnter: RequireEnterprise,
      },
      {
        path: '/:type/:pollId/followup',
        component: FollowUps,
        beforeEnter: RequireEnterprise,
      },
      {
        path: '/notifications',
        component: Notifications,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/questions/edit/committal',
        component: EditCommittals,
        beforeEnter: RequireAuthentication,
      },
      {
        path: '/questions/edit/word',
        component: EditWords,
        beforeEnter: RequireAuthentication,
      },
    ],
  },
  {
    path: '/outside',
    component: PollLayout,
    children: [
      {
        path: 'engagement/fill/:token',
        component: EngagementFill,
      },
      {
        path: 'pulse/fill/:token',
        component: PulseFill,
      },{
        path: 'pulse/demo',
        component: PulseFillDemo,
        beforeEnter: RequireGuest
      },
    ],
  },
  {
    path: '/forbidden',
    component: Forbidden,
  },
  {
    path: '/maintenance',
    component: Maintenance,
  },
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/404',
    component: NotFound,
  },
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/invalid-enterprise',
    component: InvalidEnterprise,
    beforeEnter: RequireCustomer
  },
  {
    path: '/ask-product',
    component: RequireProductView,
    beforeEnter: RequireProduct
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
