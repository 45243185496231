
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="mb-1">
        <h4 class="display-1 left break-name d-inline">{{ engagement.name }}</h4>
        <v-chip disabled
          v-if="engagement.displayName"
          color="primary"
          class="mt-n3 ml-4 white--text right break-name"
        >
          {{ engagement.displayName }}
        </v-chip>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title> {{ $t('engagement.summary') }} </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <div class="justify-h" v-if="engagement.status === 'progress'">
                <v-btn outlined
                  color="primary"
                  class="mr-4"
                  :to="`/engagement/${$route.params.id}/followup`"
                >
                  {{ $t('followupReport.follow_up')}} <v-icon size="21" class="ml-2">mdi-eye</v-icon>
                </v-btn>
                <v-btn color="primary" @click="closingPoll = true"> {{$t('input.close_poll')}} </v-btn>
              </div>
              <div class="justify-h" v-if="engagement.status === 'completed' && user.origin">
                <v-btn outlined
                  color="success darken-1"
                  @click="showJsonModal = true"
                >
                  <v-icon size="19" class="mr-2">mdi-file-excel</v-icon> Sábana de Datos
                </v-btn>
              </div>
            </v-toolbar-items>
          </v-toolbar>

          <v-container fluid>
            <v-row align="center" justify="space-around" row fill-height>
              <v-col cols="6" class="mb-3">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-progress-clock</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('engagement.total_progress') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ progress }} ({{ completed }} {{ $t('engagement.poll_completed') }})
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col xs="6">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-calendar-import</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('pulses.start_date') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ engagement.deliveredAt }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-row align-center justify="space-around" row fill-height>
              <v-col cols="6" class="mb-3">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-progress-alert</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('engagement.poll_no_completed') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ noCompleted }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="6">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>mdi-calendar-remove</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      <h6 class="title">{{ $t('engagement.end_date') }}</h6>
                    </v-list-item-title>
                    <v-list-item-subtitle class="grey--text subheading">
                      {{ engagement.validUntil }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-container>

          <v-divider></v-divider>
          <v-card-actions>
            <v-row class="ma-2">
              <v-btn large
                to="/engagement"
              >
                {{ $t('input.back') }}
              </v-btn>

              <v-btn large
                v-if="engagement.status === 'completed' && isAllResponse"
                class="ml-3"
                color="info"
                :to="`/engagement/reports/${$route.params.id}`"
                :disabled="engagement.status !== 'completed'"
              >
                <v-icon class="white--text mr-1">
                  mdi-chart-bar-stacked
                </v-icon>
                {{ $t('engagement.download') }} {{ $t('engagementReport.reports') }}
              </v-btn>

              <x-async-btn large
                v-if="engagement.status === 'progress'"
                class="ml-3"
                color="success"
                :action="resend"
              >{{ $t('engagement.resend') }}
              </x-async-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <x-confirmation-modal
      :show.sync="closingPoll"
      :reversible="true"
      :title="$t('input.poll_delete_title')"
      :action="closePoll"
      @close="closingPoll = false"
    >
      <template v-slot:question>{{ $t('input.master_references_delete_poll') }}</template>
    </x-confirmation-modal>

    <x-json-modal
      :showDialog="showJsonModal"
      :evaluationId="engagement.id"
      :questionnaire="engagement.questionnaire"
      Copen-question="engagement.openQuestion"
      @close="showJsonModal = false"
    >
    </x-json-modal>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import XJsonModal from '@/components/dialogs/spreadsheet-json'
import engagementsService from '../../services/engagements';

export default {
  components: {
    XJsonModal
  },
  data() {
    return {
      showJsonModal: false,
      engagement: {
        status: '',
        name: '',
        validUntil: '',
        pollEmployee: [],
      },
      completed: '',
      noCompleted: '',
      progress: '',
      closingPoll: false,
      isAllResponse: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    getPercent(data) {
      const aux = []
      data.forEach(element => {
        if (element.status === 'completed') { aux.push(element) }
      });
      this.completed = `${aux.length ? aux.length : 0} de ${data.length}`;
      this.progress = `${aux.length ? ((aux.length / data.length) * 100).toFixed(2) : 0}%`;
      this.noCompleted = data.length - aux.length;
      this.isAllResponse = !!aux.length;
    },
    getEngagement() {
      engagementsService.getOneById(this.$route.params.id)
        .then((res) => {
          this.reponseParse(res.item)
          this.engagement.pollEmployee = res.item.pollEmployee
          this.getPercent(this.engagement.pollEmployee)
        })
    },
    reponseParse(res) {
      this.engagement.id = res.id;
      this.engagement.questionnaire = JSON.parse(res.pollQuestionReference.data);
      // this.engagement.openQuestion = res.openQuestion;
      this.engagement.status = res.status;
      this.engagement.name = res.name;
      this.engagement.displayName = res.displayName
      this.engagement.validUntil = this.formatDate(this.getDateEngagement(res.validUntil));
      this.engagement.deliveredAt = this.formatDate(this.getDateEngagement(res.deliveredAt));
    },
    formatDate (data) {
      const [year, month, day] = data.value.split('-')
      return `${day}/${month}/${year} a las ${data.hour}:${data.minut}`
    },
    getDateEngagement(ref) {
      if (!ref) {
        return {
          value: '',
          hour: 23,
          minut: 59,
        }
      }
      const date = ref.split('T')
      const time = date[1].split(':')
      return {
        value: date[0],
        hour: time[0],
        minut: time[1],
      }
    },
    resend() {
      return engagementsService.resend(this.$route.params.id)
        .then(() => {
          this.$store.dispatch('alert/success', this.$t('engagement.resend_success'))
          this.$router.push('/engagement')
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        });
    },
    closePoll() {
      return engagementsService.close(this.$route.params.id)
        .then(() => {
          this.getEngagement();
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    }
  },
  created() {
    this.getEngagement()
  },
};
</script>

<style lang="css" scoped>
  .justify-h {
    margin-top: 1em;
  }
  .break-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
</style>
