<template>
  <v-row row wrap>
    <v-col cols="12">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title> {{ $t('dashboard.employee_left_table_title') }} </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
          <template v-if="info.allPolls.length > 0 && info.allPolls !== null">
            <v-row ma-0 wrap v-for="(item, i) in info.allPolls" :key="i"  class="bottom-divisor">
              <v-col cols="12" class="break-td">
                <span class="padding-left-name survey-title"> {{ $t('dashboard.poll') }} </span>
                <span v-if="item.displayName != null && item.displayName != ''"> {{ item.displayName }} </span>
                <span v-else> {{ item.name }} </span>
              </v-col>
              <v-col cols="6">
                <v-hover>
                  <v-card-text
                    class="text-center"
                    slot-scope="{ hover }"
                  >
                    <span>
                      {{ $t('dashboard.employee_progress') }}
                    </span>
                    <br>
                    <v-progress-circular
                      :rotate="-90"
                      :size="100"
                      :width="8"
                      :value="item.percent"
                      color="rgb(56, 153, 218)"
                      class="pulse"
                    >
                      <span class="justify-center minipercentage" v-if="!hover"> {{ item.percent }}% </span>
                      <router-link :to="item.isPulse ? '/pulse/fill/'+item.onePollEmployee.token : '/engagement/fill/'+item.onePollEmployee.token" class="no-decoration" v-else>
                        <small color="rgb(56, 153, 218)" v-if="item.percent > 0"> {{ $t('dashboard.continue') }} </small>
                        <small color="rgb(56, 153, 218)" v-else> {{ $t('dashboard.start') }} </small>
                        <v-icon size="35" color="rgb(56, 153, 218)">play_arrow</v-icon>
                      </router-link>
                    </v-progress-circular>
                  </v-card-text>
                </v-hover>
              </v-col>
              <v-col cols="6">
                <v-card-text class="text-center">
                  <span>
                    {{ $t('dashboard.left_questions') }}
                  </span>
                  <br>
                  <span class="info-data justify-center"> {{ item.leftQuestions }} </span>
                </v-card-text>
              </v-col>
              <v-col cols="5">
                <span class="padding-left-name"> {{ $t('dashboard.poll_init_date') }} </span> <span> {{new Date(item.deliveredAt).toLocaleDateString('en-US') }} </span>
              </v-col>
              <v-col cols="7" class="text-xs-right">
                <span> {{ $t('dashboard.poll_end_date') }} </span> <span class="padding-right-name"> {{new Date(item.validUntil).toLocaleDateString('en-US') }} </span>
              </v-col>
              <v-col cols="12">
                <div class="text-xs-center text-center">
                  <v-btn round color="primary" dark :to="`/${item.type}/fill/${item.onePollEmployee.token}`">
                    {{ item.percent > 0 ? $t('dashboard.continue_poll') : $t('dashboard.start_poll') }}
                  </v-btn>
                </div>
                <v-divider v-if="(i + 1 ) < info.allPolls.length"></v-divider>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row row wrap>
              <v-col cols="12">
                <v-card-text class="text-center padding-card">
                  <span class="no-polls">No tienes encuestas en progreso</span>
                </v-card-text>
              </v-col>
            </v-row>
          </template>
        </v-card>
      </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    info: Object
  },
  data() {
    return {

    }
  },
}
</script>

<style lang="css" scoped>
.text-center{
  text-align: center !important;
}
.info-data{
  font-size: 3em;
}
.minipercentage{
  font-size: 2em !important;
}
.padding-left-name{
  padding-left: .4em !important;
}
.padding-right-name{
  padding-right: .4em !important;
}
.text-success{
  color: #4caf50 !important
}
.text-error{
  color: #ff5252 !important
}
.no-polls{
  font-size: 1.8em;
  color: lightgray;
}
.padding-card{
  padding: 3em;
}
.pulse {
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgb(56, 153, 218);
  animation: pulse 2s infinite;
}
.no-decoration{
  text-decoration: none;
}
.survey-title{
  font-size: 1.2em;
  font-weight: bold;
}
 .break-td {
    max-width: 242px;
    overflow-wrap: break-word;
 }
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(56, 153, 218);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgb(56, 153, 218);
    box-shadow: 0 0 0 0 rgb(56, 153, 218);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
</style>
