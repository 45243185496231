
<template>
  <x-error-page>
    <template v-slot:image>
      <v-img
        src="img/404.png"
        max-width="270"
      ></v-img>
    </template>
    <template v-slot:content>
      <h2>{{ $t('errors.not_found') }}</h2>
      <v-btn class="mt-3" color="primary" to="/dashboard">{{ $t('input.back_home') }}</v-btn>
    </template>
  </x-error-page>
</template>

<script>

export default { };
</script>
