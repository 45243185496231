
import Services from './base-services';

const service = new Services('follow-ups');

export default {
  getResults: (type: string, id: number, codes: Array<string>, lang?: string) => {
    return service.post(`${type}/${id}/get-results`, {codes, lang});
  },
};
