
import config from './config';

class ACL {
  private aclConfig: any

  constructor(aclConfig: any) {
    this.aclConfig = aclConfig
  }

  public can(permission: string, session: any) {
    const accessCondition = this.getAccessCondition(permission);
    if (accessCondition === true) {
      return true;
    }

    return accessCondition
      .map((rolesStr: string) => this.getRoles(rolesStr))
      .find((roles: {user: string, view: string}) => {
        const viewRoleMatch = !roles.view || session.user.view === roles.view;
        const userRoleMath = session.user.role === roles.user;
        return viewRoleMatch && userRoleMath;
      }) !== undefined;
  }

  private getAccessCondition(permissionStr: string): any {
    let accessCondition = this.aclConfig;
    for (const permission of permissionStr.split('.')) {
      accessCondition = accessCondition[permission];
      if (accessCondition === undefined) {
        throw new Error(`permission '${permissionStr}' not defined`);
      }
    }

    return accessCondition;
  }

  private getRoles(roles: string): {user: string, view: string} {
    const splittedRoles = roles.split('.');
    return { user: splittedRoles[0], view: splittedRoles[1] };
  }
}

export { ACL, config };
