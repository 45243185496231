
import Services from './base-services';

const service = new Services('dashboard');

export default {
  getInfo: (user: object) => {
    return service.post('get-info', { user });
  },
};
