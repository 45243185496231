
export default {
  enterprises_list: 'Enterprises list',
  add_enterprise: 'Add enterprise',
  edit_enterprise: 'Edit enterprise',
  filter_by: 'Filter by...',
  look_for: 'Look for an enterprise',
  no_data: 'There are none enterprises to display',
  id: 'Id',
  enterprise_name: 'Enterprise name',
  group: 'Group',
  license: 'License',
  expiration_date: 'Expiration date',
  status: 'Status',
  actions: 'Actions',
  modal_enable: 'Do you want to enable the enterprise?',
  modal_disable: 'Do you want to disable the enterprise?',
  modal_enable_des: 'Once enabled, the company can access the system again',
  modal_disable_des: 'Once the company is disabled, it will not be able to access the system until it is enabled again',
  action: 'Take action',
  accept: 'Accept',
  close: 'Close',
  enterprise_information: 'Enterprise information',
  contact_employee: 'Employee Contact ',
  licensing: 'Licensing',
  demographic_cuts: 'Demographic Cuts',
  tool_customization: 'Tool customization',
  primary_color: 'Primary color',
  secondary_color: 'Secondary color',
  // tslint:disable-next-line:max-line-length
  informative_cuts: 'The following data are informative and do not represent actions in the system. These data are mandatory to identify employees.',
  select_demographic_cuts: 'Please, select the categories of the demographic cuts that you want to measure.',
  obligatories_demographic_cuts: 'The categories {obligatories} are required.',
  created_enterprise: 'The enterprise was created successfully.',
  section_edited: 'The section successfully edited.',
  enterprise_edited: 'The enterprise successfully edited.',
  profile_title: '{size} enterprise of the {sector} sector.',
  profile_title_addres: 'Located in: {contry} - {address}.',
  extend_plan: 'Extend plan',
  extend_plan_requests: 'Extend plan requests',
  info_extend: 'After submitting your plan extension request, you will receive notification with your acceptance.',
  extend_request_table: [
    { text: 'Requested Engagement', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Requested Pulse', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Plan', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Plan expiration', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Actions', value: '', sortable: false, class: 'text-xs-center' },
  ],
  no_extend_requests: 'This enterprise does not have extend request pending.',
  extend_request_done: 'Plan extension done',
  extend_request_sent: 'Request sent',
  extend_request_accepted: 'Request accepted',
  extend_request_rejected: 'Request rejected',
  active_plan: 'Active plan',
  plan_expire_date: 'Plan expires at',
  active_engagements: 'Left Engagement',
  active_pulses: 'Left Pulse',
  info_update: 'Warning: Warning: We save the plan update as request. ' +
    'Plan updates must be accepted by system adminitrator, so you will get a notification with the status of ' +
    'your request. You can ask for plan update only once at time.',
  info_request_extend: 'Warning: We save the plan extension as request. ' +
  'Plan extension must be accepted by system adminitrator, so you will get a notification with the status of ' +
  'your request. You can ask for plan update only once at time.',
  update_plan_request: 'Update plan request',
  no_update_requests: 'The enterpise does not have plan update request pending.',
  active_license: 'Active plan',
  license_expire_at: 'Expire date of your actual plan',
  active_plan_pulses: 'Active Pulse',
  active_plan_engagements: 'Active Engagement',
  new_plan_pulses: 'New plan Pulse',
  new_plan_engagements: 'new plan Engagement',
  new_plan_requested: 'New plan requested:',
  new_plan_duration: 'Plan requested duration:',
  old_plan_end: 'Actual plan end date:',
  old_plan_duration: 'Actual plan duration:',
  want_other_start: 'Would you like start your plan on a dae different of your cu8rrent plan expire date?',
  other_start_warning: 'Warning: Select a date different than yor actual plan expire date has conditions.',
  before_start_warning: 'If you request an update before than your active plan expire date ' +
    'and it is accepted it will automatically start and you will lose all the beneficts of your current plan.',
  after_start_warning: 'If you request an update after than your active plan expire date ' +
    'and it is accepted you will not be able to login into the system in the date range between your actual plan ' +
    'expire date and the start date of your plan requested.',
  new_plan_start: 'Requested plan start date',
  update_plan: 'Update plan.',
  update_type: 'Update',
  extend_type: 'Extension',
  enterprise_extend_request_table: [
    { text: 'Request type', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Requested Engagement', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Requested Pulse', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Plan', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Plan expiration', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Actions', value: '', sortable: false, class: 'text-xs-center' },
  ],
  extensions_history_header: [
    { text: 'Request type', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Requested Engagement', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Requested Pulse', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Plan', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Request start at', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Duration', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Status', value: '', sortable: false, class: 'text-xs-center' },
  ],
  status_pending: 'Pending',
  status_accepted: 'Accepted',
  status_rejected: 'Rejected',
  status_cancelled: 'Cancelled',
  no_demo_requests: 'There is not pending demo requests',
  demo_plan_request: 'Demo requests',
  demo_requests_table: [
    { text: 'Enterprise', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Address', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Request date', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Actions', value: '', sortable: false, class: 'text-xs-center' },
  ],
  created_demo_enterprise: 'Your demo Plan has been created successfully! You will be notified with its status soon',
  profile_warning: 'Remember to set up your demographic items in order to use the system rightly.',
  first_time_title: 'Welcome to OCC - Pulse',
  // tslint:disable-next-line:max-line-length
  first_time_text: 'To get a better experience with the system is recomended to follow the following steps:',
  first_time_step: 'Step',
  // tslint:disable-next-line:max-line-length
  first_time_step_1: 'Verify the enterprise information, as well select the demographic items that the enterprise will use to genrate the reports.',
  // tslint:disable-next-line:max-line-length
  first_time_step_2: 'Go to the employees section and register your payroll before create any poll.',
  // tslint:disable-next-line:max-line-length
  first_time_step_3: 'Create your first Engagement or Pulse measurement acording to the your license.',
  requirements_title: 'OCC Pulse - Technical Requirements',
  // tslint:disable-next-line:max-line-length
  requirements_subtitle: 'Review and implement these requirements with the area in charge, before the launch of the measurement. With this we prevent emails from reaching spam or blocking access to the platform for respondents.',
  // tslint:disable-next-line:max-line-length
  requirements_text: 'The OCC Pulse tool requires several technical conditions that must be guaranteed in your company\'s network and equipment to offer an adequate level of service, operation and maintenance.',
  requirements_title_1: 'Access to URLs',
  requirements_title_list_1: 'Allow access on the computers of the organization to the URLs:',
  requirements_title_listitems_1: [
    'https://occ-solutions.com/',
    'http://pulse.occ-solutions.com/',
    'https://pulse.occ-solutions.com/',
  ],
  requirements_title_2: 'Incoming mail servers',
  // tslint:disable-next-line:max-line-length
  requirements_title_list_2: 'Enable at the entrance to the mail server of your mail company sent massively from the following addresses:',
  requirements_title_listitems_2: [
    'sendgrid.info',
    'sendgrid.com',
    'sendgrid.net',
  ],
  requirements_title_3: 'Mail domains',
  // tslint:disable-next-line:max-line-length
  requirements_title_list_3: 'Enable entry to your company\'s mail server emails sent with the respective domains and subdomains:',
  requirements_title_listitems_3: [
    '@occ-solutions.com',
    '@occsolutions.org',
    '@pulse.occ-solutions.com',
    '@pulse.occsolutions.org',
  ],
  requirements_title_4: 'Compatible browsers:',
  requirements_title_list_4: 'The web application works properly in the following browsers:',
  requirements_title_listitems_4: [
    'Google Chrome (recommended).',
    'Mozilla Firefox.',
    'Opera.',
    'Microsoft Edge.'
    // 'Internet Explorer (version 11 onwards).',
  ]
}
