<template>
  <v-expansion-panels tile>
    <v-expansion-panel
      v-for="(thread, $index) in threads"
      :key="$index"
    >
      <v-expansion-panel-header disable-icon-rotate>
        <span class="expansion-title text-uppercase grey--text text--darken-3">
          <span v-if="thread.reportType === 'general'">
            {{ $t('engagementReport.cover.general') }}
          </span>
          <span v-else-if="thread.reportType === 'summary'">
            {{ $t('engagementReport.demographic_population_title') }}
          </span>
          <span v-else>
            {{ $t('engagementReport.cover.byitems') }}
          </span>
        </span>
        <template v-slot:actions>
          <span
            v-if="thread.progressPercentage !== 100"
            class="caption d-inline-block mt-1 mr-2 grey--text text--darken-1 font-weight-bold"
          >
            {{ thread.progressPercentage }}%
          </span>
          <v-icon color="primary" v-if="thread.status === 'pending'">mdi-progress-clock</v-icon>
          <v-icon color="primary" v-else-if="thread.status === 'in_progress' || thread.status === 'in_action'">mdi-progress-alert</v-icon>
          <v-icon color="red" v-else-if="thread.status === 'failed'">mdi-alert-circle</v-icon>
          <v-icon color="primary" v-else>mdi-check-circle</v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row v-if="thread.reportType === 'by_demographic'">
          <v-col class="text-center">
            <v-chip
              class="ma-1 grey--text text--darken-3"
              v-for="(demographic, $i) in Object.keys(getDemographicChips(thread.criteria))"
              :key="$i"
            >
              <span class="font-weight-bold body-2">
                {{ demographic }}
              </span>
              <p
                v-for="(child, $j) in getDemographicChips(thread.criteria)[demographic].childs"
                :key="$j"
                class="mb-0 ml-1 caption"
              >
                ({{ child }})
              </p>
            </v-chip>
          </v-col>
        </v-row>
        <v-row v-if="thread.status !== 'completed'">
          <v-col cols="12">
            <v-progress-linear
              color="light-blue"
              height="10"
              :value="thread.progressPercentage"
              striped
            ></v-progress-linear>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <x-demographic-population
              v-if="thread.reportType === 'summary'"
              :poll-id="Number.parseInt($route.params.id)"
              :button-text="$t('demographicReport.demographic_population_button')"
              :thread="thread"
            ></x-demographic-population>
            <x-report
              v-else
              :poll-id="Number.parseInt($route.params.id)"
              :button-text="$t('engagementReport.items_button')"
              :thread="thread"
              :poll="poll"
              :report-type="thread.reportType"
            ></x-report>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<style lang="scss">
  .expansion-title > span {
    font-size: 14px !important;
  }
</style>

<script>
import XReport from './report.vue';
import XDemographicPopulation from './demograpich-population-report.vue'

export default {
  components: {
    XReport,
    XDemographicPopulation
  },
  props: {
    threads: Array,
    poll: Object,
    demographics: Object
  },
  data() {
    return {
      //
    };
  },
  watch: {
    //
  },
  methods: {
    camelToSnakeCase (str) {
      return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    },
    getTranslation (str) {
      const tag = this.camelToSnakeCase(str)
      if (this.$te(`settings.${tag}`)) {
        return this.$t(`settings.${tag}`).replace(/\.$/, "")
      } else {
        return this.$t(`pulses.${tag}`)
      }
    },
    getDemographicChips (criteria) {
      const labels = []
      const formattedCriteria = JSON.parse(criteria)
      if (formattedCriteria && formattedCriteria.filters) {
        const filters = formattedCriteria.filters
        for (const filter of Object.keys(filters)) {
          if ((filter === 'antiquity' && this.demographics['getSelectAntiquity']) ||
              (filter === 'age' && this.demographics['getSelectAge'])
          ) {
            let title, key
            if (filter === 'antiquity') {
              title = 'antiquity_title'
              key = 'getSelectAntiquity'
            } else {
              title = 'ages_title'
              key = 'getSelectAge'
            }
            const name = this.getTranslation(title)
            labels[name] = {
              childs: []
            }
            const match = this.demographics[key].find(fl =>
              fl.value.every(i =>
                filters[filter].includes(i)
              )
            )
            if (match) {
              labels[name].childs.push(match.text)
            }
          } else if (this.demographics[filter]) {
            const demographicToFilter = filter === 'headquarters' ?
              this.demographics['raw_headquarters'] :
              this.demographics[filter]
            const name = this.getTranslation(filter)
            labels[name] = {
              childs: []
            }
            const match = demographicToFilter.filter(fl => {
              return filter === 'genders' ?
                filters[filter] === fl.value :
                filter === 'headquarters' ?
                  filters[filter].includes(fl.id) :
                  Object.values(filters[filter]).includes(fl.value)
            }).map(x => {
              return filter === 'headquarters' ? x.name : x.text
            }).join(', ')
            if (match.length) {
              labels[name].childs.push(match)
            }
          }
        }
      }
      return labels
    }
  }
};
</script>
