
import { IMenuGroup } from './interfaces/menu-group';
import { IMenuLeaf } from './interfaces/menu-leaf';

const baseGroups: Array<IMenuGroup | IMenuLeaf> = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: 'dashboard',
  },
  {
    title: 'questionnaires',
    path: '/questionnaires',
    icon: 'poll',
    permission: 'questionnaires.list',
  },
  // {
  //   title: 'questionnairesPulse',
  //   path: '/questionnaires/pulse',
  //   icon: 'mdi-clipboard-pulse',
  //   permission: 'questionnaires.list',
  // },
  {
    title: 'committalQuestions',
    path: '/questions/edit/committal',
    icon: 'mdi-comment-question',
    permission: 'committalQuestions.edit',
  },
  {
    title: 'wordQuestions',
    path: '/questions/edit/word',
    icon: 'mdi-comment-question-outline',
    permission: 'wordQuestions.edit',
  },
  {
    title: 'engagement',
    path: '/engagement',
    icon: 'poll',
    permission: 'engagement.list',
  },
  {
    title: 'pulse',
    path: '/pulse',
    icon: 'mdi-clipboard-pulse',
    permission: 'pulse.list',
  },
  {
    title: 'suite',
    icon: 'mdi-apps',
    path: '/',
    goto: 'suite'
  }
];

export { baseGroups };
