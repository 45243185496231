
export default {
  notifications: 'Notificaciones',
  see_more: 'Ver todas las notificaciones.',
  plan_expires_today: 'Su licencia expira hoy, dirijase al módulo de actualizar plan para renovar su licencia.',
  one_month_left: 'Le resta un mes para el vencimiento de su licencia, dirijase al panel de actualización ' +
  'de plan para seguir usando nuestros servicios.',
  one_week_left: 'Le resta una semana para el vencimiento de su licencia, dirijase al panel de actualización ' +
  'de plan para seguir usando nuestros servicios.',
  one_month_expired: 'Hace un mes expiró su licencia, le invitamos a actualizar su plan para volver a usar nuestros' +
  'servicios.',
  demo_expired_yesterday: 'Su plan demo venció el día de ayer, le invitamos actualizar su plan para usar' +
  'cada uno de nuestros servicios.',
  pulse_month_ago: 'Tiene un mes sin hacer un Pulse. Si no posee los permisos le invitamos a que se dirija al panel ' +
  'de extension de plan.',
  engagement_closed: 'Su encuesta Engagement ha sido cerrada',
  pulse_closed: 'Su encuesta Pulse ha sido cerrada.',
  admin_plan_expires_today: 'La licencia de la empresa {enterprise} expira hoy.',
  admin_one_month_left: 'A la empresa {enterprise} le resta un mes para el vencimiento de su licencia.',
  admin_one_week_left: 'A la empresa {enterprise} le resta una semana para el vencimiento de su licencia.',
  admin_one_month_expired: 'Hace un mes expiró la licencia de la empresa {enterprise}.',
  admin_demo_expired_yesterday: 'El plan demo de la empresa {enterprise} venció ayer.',
  admin_enterprise_enabled: 'La empresa {enterprise} ha sido activada',
  admin_enterprise_disabled: 'La empresa {enterprise} ha sido desactivada',
  admin_engagement_closed: 'La encuesta Engagement {poll} de la empresa {enterprise} ha sido cerrada.',
  admin_pulse_closed: 'La encuesta pulse {poll} de la empresa {enterprise} ha sido cerrada.',
};
