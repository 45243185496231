
import store from '../../store';

export default (to: any, from: any, next: any) => {
  const redirectSuite = () => store.dispatch('session/getSuiteHost')
    .then((res) => {
      if (res && res.webHostUrl) {
        let urlSuite = res.webHostUrl;
        if (res.webHostPort && (res.webHostPort !== 80 || res.webHostPort !== 443)) {
          urlSuite = `${urlSuite}:${res.webHostPort}`;
        }
        location.replace(`${urlSuite}/auth/sso?product=pulse`);
      } else {
        next('/404');
      }
    });

  if (!store.state.session.token) {
    redirectSuite();
    return;
  }

  store.dispatch('session/isValid', to.path)
    .then((res) => {
      if (res) {
        if (store.state.session.user.role === 'customer' &&
          store.state.session.user.customer &&
          store.state.session.user.customer.type === 'commercial' &&
          !store.state.session.user.enterprise) {
          next('/invalid-enterprise');
          return;
        }
        if (store.state.session.user.role === 'customer' &&
          store.state.session.user.customer) {
          let hasProduct = false
          for (const customerProduct of store.state.session.user.customer.products) {
            if (customerProduct.productId === 1) {
              hasProduct = true
              break
            }
          }
          if (!hasProduct) {
            next('/ask-product')
            return
          }
        }
        next();
      }
    });
};
