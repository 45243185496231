
import Services from './base-services';

const service = new Services('power-bi');

export default {
  getEmbedData: () => {
    return service.get('get-embed-data');
  },
};
