
import mapParameters from '../utils/map-parameters';
import Services from './base-services';

const service = new Services('pulses');

interface IOptionsList {
  page?: number,
  itemsPerPage?: number,
  filter?: string,
  search?: string,
}

export default {
  getBalance: () => {
    return service.get('get-balance');
  },
  create: (data: object) => {
    return service.post('create', data);
  },
  list: (options: IOptionsList) => {
    const params = mapParameters({
      page: options.page,
      rowsPerPage: options.itemsPerPage,
      filter: options.filter,
      search: options.search,
    });
    return service.get('list', params);
  },
  verifyPollToken: (token: string) => {
    return service.post('verify-poll-token', { token });
  },
  saveAnswers: (answers: any, pollId: number, token: string) => {
    return service.post('save-answers', { answers, pollId, token });
  },
  saveEmployeeAnswers: (answers: string, token: string) => {
    return service.post('save-employee-answers', { answers, token });
  },
  fetchEmployeeAnswers: (token: string) => {
    return service.post('fetch-employee-answers', { token });
  },
  listByEmployee: () => {
    return service.get('list-by-employee');
  },
  getOneById: (id: number) => {
    return service.get(`${id}`);
  },
  update: (id: number, data: any) => {
    return service.put(`${id}`, data);
  },
  getAllPollEmployeeById: (pollId: number, type: string) => {
    return service.post(`poll-employee-all/${pollId}`, { type });
  },
  resend: (pollId: number) => {
    return service.post('resend-poll', { pollId });
  },
  getOlderPolls: (pulseId: number) => {
    return service.post('get-older-polls', { pulseId });
  },
  getOlderPollsNewModel: (pulseId: number) => {
    return service.post('get-older-pulse-polls', { pulseId });
  },
  getComparisonData: (
    data: Array<{
      olderPoll: Array<{ id: number, type: string }>,
      currentPulseId: number,
      comparison: boolean,
    }>,
  ) => {
    return service.post('get-comparison-data', { data });
  },
  getComparitionData: (
    data: Array<{
      olderPoll: Array<{ id: number, type: string }>,
      currentPulseId: number,
      comparition: boolean,
    }>,
  ) => {
    return service.post('get-comparition-data', { data });
  },
  getPollsRates: (pulseId: number) => {
    return service.post('get-polls-rates', { pulseId });
  },
  checkCreatedPulses: (id: number) => {
    return service.get(`check-created-pulses/${id}`);
  },
  close: (id: number) => {
    return service.get(`close/${id}`);
  },
  sendInvitationFiles: (id: number, data: object) => {
    return service.form(`save-invitation-files/${id}`, data);
  },
  sendReminderFiles: (id: number, data: object) => {
    return service.form(`save-reminder-files/${id}`, data);
  },
  getPollsAttributesRates: (pulseId: number) => {
    return service.post('get-polls-attributes-rates', { pulseId });
  },
  getOpenAnswersByQuestion: (questionId: number) => {
    return service.get(`get-open-answers-by-question/${questionId}`);
  },
  getAnswersCount (id: string) {
    return service.get(`get-total-answers/${id}`)
  },
  getAnswersWithParticipant (id: string, skip: number) {
    return service.get(`answers-with-participant/${id}/${skip}`)
  },
};
