
import Services from './base-services';

const service = new Services('attributes');

export default {
  listAll: (lang?: string) => {
    return service.get(lang ? `list-all?lang=${lang}` : 'list-all');
  },
};
