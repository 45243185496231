
import resolver from '@/utils/resolver'

import Services from './base-services';
import academicDegreeService from './academic-degrees'
import chargeService from './charges'
import countryService from './countries'
import departmentService from './departments'
import genderService from './genders'
import headquarterService from './headquarters'
import jobTypeService from './job-types'
import additionalDemographics1Service from './additional-demographics1'
import additionalDemographics2Service from './additional-demographics2'

const service = new Services('demographic-items');

export default {
  list: () => {
    return service.get('list');
  },
  listFromSuite: (lang: string) => {
    return service.suiteOperation(() => service.get('list', { params: { lang: lang } }))
  },
  getDemographicsItemsByEntreprise (lang: string): any {
    const demographicItemsData: any = {
      jobTypes: [],
      headquarter: [],
      gender: [],
      departments: [],
      country: [],
      charge: [],
      antiquity: [],
      age: [],
      academicDegree: [],
      optionalDemo1: [],
      optionalDemo2: []
    }
    let demographicItem: any[] = []

    return resolver.all({
      academicDegreesAll: academicDegreeService.listAll(),
      charges: chargeService.fetchByEnterprise(),
      countries: countryService.listByEnterprise(),
      demographicItem: this.listFromSuite(lang),
      departmentsAll: departmentService.listAll(),
      gendersAll: genderService.listAll(),
      headquarters: headquarterService.fetchByEnterprise(),
      jobTypesAll: jobTypeService.listAll(),
      additinalDemographics1All: additionalDemographics1Service.listAll(),
      additinalDemographics2All: additionalDemographics2Service.listAll()
    })
      .then((res) => {
        // TODO: falta lista de items demograficos
        demographicItem.push(...res.demographicItem.items)
        // LISTO grados academicos
        demographicItemsData.academicDegree = res.academicDegreesAll.items.map((academicDegrees: any) => ({
          ...academicDegrees,
          translate: { label: lang === 'es' ? academicDegrees.translations[0].label : academicDegrees.translations[1].label }
        }))
        // LISTO Departamentos
        demographicItemsData.departments = res.departmentsAll.items.map((departments: any) => ({
          ...departments,
          translate: { label: lang === 'es' ? departments.translations[0].label : departments.translations[1].label }
        }))
        // CHARGES
        demographicItemsData.charge = res.charges.items.map((charge: any) => ({
          ...charge,
          translate: { label: lang === 'es' ? charge.translations[0].label : charge.translations[1].label }
        }))
        // TODO: falta lista de countrys
        demographicItemsData.country = res.countries.items
        // GENDER
        demographicItemsData.gender = res.gendersAll.items.map((gender: any) => ({
          ...gender,
          translate: { label: lang === 'es' ? gender.translations[0].label : gender.translations[1].label }
        }))
        // HEADQUARTERS NO TIENE TRADUCCION EN BASE DE DATOS
        demographicItemsData.headquarter = res.headquarters
        // JOBTYPES
        demographicItemsData.jobTypes = res.jobTypesAll.items.map((jobTypes: any) => ({
          ...jobTypes,
          translate: { label: lang === 'es' ? jobTypes.translations[0].label : jobTypes.translations[1].label }
        }))

        // Additional Demographic 1
        demographicItemsData.optionalDemo1 = res.additinalDemographics1All.items.map((item: any) => ({
          ...item,
          translate: { label: lang === 'es' ? item.translations[0].label : item.translations[1].label }
        }))

        // Additional Demographic 2
        demographicItemsData.optionalDemo2 = res.additinalDemographics2All.items.map((item: any) => ({
          ...item,
          translate: { label: lang === 'es' ? item.translations[0].label : item.translations[1].label }
        }))

        // Filter optionals with no items
        demographicItem = demographicItem.filter(it => !it.optional || Boolean(demographicItemsData[it.code].length))

        return { demographicItem: demographicItem, data: demographicItemsData }
      })
  }
};
