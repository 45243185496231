
export default {
  generateTitle: (title: string, margin?: number[] | number, pageBreak?: string, fontSize?: number, color?: string, isToc = true, isSubToc = false, isBold = true): object => {
    return {
      pageBreak: pageBreak || '',
      layout: 'noBorders',
      table: {
        widths: [400, 0],
        body: [
          [
            {
              text: title,
              font: 'League Spartan',
              bold: isBold,
              color: color || '#222222',
              margin: margin || [0, 20],
              fontSize: fontSize || 37
            },
            // ToC text
            isToc ? {
              text: title,
              fontSize: 0,
              tocItem: isSubToc ? ['mainToc', 'subToc'] : 'mainToc',
              tocMargin: isSubToc ? [34, 8, 0, 0] : [0, 8, 0, 0]
            } : {}
          ]
        ]
      }
    }
  },
  // tslint:disable-next-line:max-line-length
  generateCenteredText: (text: string, fontSize?: number, bold?: boolean, styled?: boolean, italic?: boolean, pageBreak?: string): object[] => {
    const normal = [
      { width: '*', text: '' },
      {
        width: 'auto',
        text,
        color: '',
        fontSize: fontSize || 22,
        bold,
        italics: false,
        pageBreak,
      },
      { width: '*', text: '' },
    ];

    if (styled) {
      normal[1].color = '#1a98da';
    }

    if (italic) {
      normal[1].italics = true;
    }

    return normal;
  },
  generateTable: (
    widths: string[],
    rows: string[][],
    headers: string[],
    topTitle?: string,
  ): object => {
    const colSpaces = []
    for (let i = 0; i < headers.length - 1; i++) {
      colSpaces.push('')
    }
    const body = [];
    if (topTitle) {
      body.push([
        {
          text: topTitle,
          fillColor: '#FFFFFF',
          colSpan: headers.length,
          border: [false, false, false, false],
          margin: [-4, 0, 0, -2],
          fontSize: 20
        },
        // Reserved column spaces, necessary to render all columns filled correctly
        ...colSpaces
      ]);
    }
    body.push(
      headers.map((header) => {
        return {
          text: header,
          margin: [0, 1, 0, -1],
          fillColor: '#dcddf1',
          bold: true,
          alignment: 'center',
        };
      }),
      // Contenido
      ...rows,
    )
    return {
      headerRows: topTitle ? 2 : 1,
      widths,
      body: body,
    };
  },
  generateVariablesTable: (
    headers: any[],
    rows: any[],
    widths: string[],
  ): object => {
    const response = {
      headerRows: 1,
      widths,
      body: rows
    }

    if (headers.length !== 0) {
      response.body.unshift(headers.map((header) => {
        return {
          text: header.text || header,
          margin: header.margin || [0, 0],
          fontSize: header.fontSize || 11,
          bold: true,
          alignment: header.alignment || 'center',
          border: [false, false, false, false]
        }
      }))
    }

    return response
  },
  generateLegendTable: (rows: any[]): object => {
    return {
      widths: ['5%', '45%', '5%', '45%'],
      heights: '5%',
      body: [
        ...rows,
      ],
    };
  },
  generateTableBars: (result: number, prevResult: number, dimensionId: number): object => {
    /*
    const colors: any = {
      1: 'rgba(235, 96, 77, 0.8)',
      2: 'rgba(235, 96, 77, 0.8)',
      3: 'rgba(161, 228, 215, 0.8)',
      4: 'rgba(161, 228, 215, 0.8)',
      5: 'rgba(75, 155, 208, 0.8)',
      6: 'rgba(75, 155, 208, 0.8)',
    };

    if (oldResult === 0) { oldResult = 0.01; }
    if (newResult === 0) { newResult = 0.01; }
    */

    return {
      xAxis: {
        type: 'value',
        max: 100,
        show: false,
        axisLabel: {
          fontSize: 1
        }
      },
      yAxis: {
        type: 'category',
        show: false
      },
      series: [
        {
          type: 'bar',
          barWidth: 10,
          data: [0, result],
          color: '#1999da'
          // color: colors[dimensionId]
        },
        {
          type: 'bar',
          barWidth: 10,
          data: [prevResult, 0],
          color: '#000000'
        }
      ]
    }
  },
  setLayout: (): object => {
    return {
      hLineColor: '#BFBFBF',
      vLineColor: '#BFBFBF',
      fillColor: (rowIndex: number) => {
        return (rowIndex % 2 === 0) ? '#EEEEEE' : null;
      },
    };
  },
  getColor: (value: number): undefined|string => {
    if (value >= 0 && value <= 30) { return '#C05251'; }
    if (value > 30 && value <= 50) { return '#E0A9A8'; }
    if (value > 50 && value <= 70) { return '#EE9748'; }
    if (value > 70 && value <= 85) { return '#FFd87C'; }
    if (value > 85 && value <= 95) { return '#93D379'; }
    if (value > 95 && value <= 100) { return '#538853'; }
  },
  generateNote: (text: string): object => {
    return {
      margin: [0, 15, 0, 0],
      text,
      fontSize: 12,
    };
  },
};
