
export default {
  copyright: 'Copyright por OCC - Todos los derechos reservados',
  introduction: 'Introducción',
  model: 'El Modelo',
  results: 'Resultados',
  ranking: 'Ranking',
  open_questions: 'Preguntas abiertas',
  commitment_belonging: 'Compromiso+Pertenencia',
  index: 'Índice',
  topic: 'Tema',
  page: 'Página',
  our_recommendations: 'Nuestras recomendaciones',
  index_stack: [
    '1.    ¿Qué es Engagement y para qué medirlo?',
    '2.    ¿Cómo funciona nuestro modelo?',
    '3.    Metodología',
    '4.    Resultados',
    '4.1. Tasa de respuestas',
    '4.2. Resultados',
    '4.3. Resultados por dimensión',
    '4.4. Resultados por atributos',
    '5.    Ranking',
    '5.1. Ranking de las dimensiones',
    '5.2. Ranking de los atributos',
    '5.3. Ranking de las variables',
    '6.    Dispersión',
    '6.1. Dispersión por dimensiones',
    '6.2. Dispersión por atributos',
    '6.3. Dispersión por variables',
    '7.    Tendencias',
    '7.1. Tendencias por dimensiones',
    '7.2. Tendencias por atributos',
    '7.3. Tendencias por variables',
    '8.    Resumen de preguntas abiertas',
  ],
  index_stack_general: [
    '1.    ¿Qué es Engagement y para qué medirlo?',
    '2.    ¿Cómo funciona nuestro modelo?',
    '3.    Metodología',
    '4.    Resultados',
    '4.1. Resultados por dimensión',
    '4.2. Resultados por atributos',
    '5.    Ranking',
    '5.1. Ranking de las dimensiones',
    '5.2. Ranking de los atributos',
    '5.3. Ranking de las variables',
    '6.    Dispersión',
    '6.1. Dispersión por dimensiones',
    '6.2. Dispersión por atributos',
    '6.3. Dispersión por variables',
    '7.    Tendencias',
    '7.1. Tendencias por dimensiones',
    '7.2. Tendencias por atributos',
    '7.3. Tendencias por variables',
    '8.    Resumen de preguntas abiertas',
  ],
  recommendations_stack: [
    // tslint:disable-next-line:max-line-length
    'Asegúrese que se da la importancia estratégica al Engagement como una palanca para alcanzar resultados sobresalientes y sostenibles.',
    // tslint:disable-next-line:max-line-length
    'Recuerde que el Engagement y la Cultura Organizacional se complementan; gestionar ambos es vital para atraer y retener al talento.',
    // tslint:disable-next-line:max-line-length
    'Empodere a cada líder con la responsabilidad de gestionar el Engagement y la Cultura. Las áreas de gestión humana facilitan y apoyan.',
    // tslint:disable-next-line:max-line-length
    'A la hora de definir los planes para cerrar brechas, sea inclusivo y participativo. Movilizar la inteligencia colectiva genera mayor nivel de compromiso.',
    // tslint:disable-next-line:max-line-length
    'Es vital el seguimiento al proceso en ciclos cortos. Busque formas creativas de cerrar las brechas y mida con frecuencia. Nuestra herramienta Pulse está diseñada para esto.',
    // tslint:disable-next-line:max-line-length
    'Divulgue siempre los resultados con apertura y transparencia e inclúyalos en el proceso de gestión del desempeño de los líderes.',
    // tslint:disable-next-line:max-line-length
    'Finalmente, recuerde la importancia de mantener foco. Es mejor menos acciones de alto impacto que muchas acciones sin efectos positivos.',
  ],
  what_is_engagement: '¿Qué es Engagement y para qué medirlo?',
  // tslint:disable-next-line:max-line-length
  what_is_engagement_text: ['En OCC Solutions entendemos Engagement como alto nivel de compromiso emocional, sentido de pertenencia combinado con entusiasmo y pasión por el trabajo y por la Organización, que se manifiesta en un permanente deseo de dar siempre lo mejor.'],
  what_is_engagement_positive: [
    'Dar lo mejor de sí todos los días y tener pasión por el trabajo.',
    'Compromiso emocional con el propósito, los objetivos y valores de la Organización.',
    'Alto nivel de entusiasmo y dedicación que una persona siente hacia su trabajo.',
    'Confianza, integridad, compromiso bidireccional y comunicación entre la Organización y las personas.',
    'Motivación para contribuir con el éxito de la Organización con una mayor sensación de bienestar propio.'
  ],
  what_is_engagement_negative: [
    // tslint:disable-next-line:max-line-length
    'La felicidad, como reírse con compañeros del trabajo o disfrutar de una fiesta de la empresa, no dice nada de qué tan involucrados o comprometidos están con la empresa o qué tan duro están trabajando para lograr el propósito de la Organización. Es una medida a corto plazo que cambia rápidamente.',
    'Satisfacción de los empleados de sus necesidades sociales y personales.',
    'Un buen paquete de beneﬁcios y compensación.',
    // tslint:disable-next-line:max-line-length
    'Lo anterior solo indica qué tan feliz o contentos están los colaboradores, pero no aborda el nivel de motivación o compromiso emocional. La satisfacción puede ser suﬁciente para retener a los empleados, pero no garantiza la productividad.'
  ],
  // tslint:disable-next-line:max-line-length
  what_is_engagement_conclusion1: 'Los colaboradores que están altamente comprometidos logran mejores resultados, fidelizan clientes, rotan con menos frecuencia y contribuyen con la alineación de la cultura.',
  // tslint:disable-next-line:max-line-length
  what_is_engagement_conclusion2: 'Las organizaciones con mayor nivel de Engagement atraen y mantienen el mejor talento, implementan mejor los procesos de cambio y así logran resultados sobresalientes y sostenibles.',
  what_is_engagement_quote: '“Las organizaciones hoy compiten por el talento y, por consiguiente, el reto de crear condiciones que eleven los niveles de las personas es un imperativo, no una moda más.  Elevar el nivel de compromiso y sentido de pertenencia de las personas es un esfuerzo sistémico, que incluye tener un propósito superior inspirador, una cultura sana y admirable, grandes líderes, una experiencia del empleado memorable, promover el aprendizaje y el desarrollo integral, incentivar la innovación y el cambio continuo, incrementar la autonomía y la excelencia en la operación, por citar algunos aspectos”',
  how_does_our_model_works: '¿Cómo funciona nuestro modelo?',
  // tslint:disable-next-line:max-line-length
  how_does_our_model_works_text: 'Nuestro modelo es circular y está conformado por seis dimensiones . Cada dimensión a su vez tiene 3 atributos y cada atributo tiene 3 preguntas llamadas variables:',
  my_team: {
    title: 'Mi equipo',
    attributes: [
      'Dinámicas de equipo\n',
      'Comunicación y coordinación\n',
      'Agilidad y toma de decisiones'
    ],
    desc: 'Hago parte de un equipo\ncomprometido con la Organización'
  },
  my_development_and_learning: {
    title: 'Mi desarrollo y aprendizaje',
    attributes: [
      'Oportunidades de crecimiento en mi rol\n',
      'Oportunidades de crecimiento en la Organización\n',
      'Cultura de aprendizaje'
    ],
    desc: '\nMe veo creciendo en esta\nOrganización en el futuro'
  },
  opportunities_of_growth: 'Oportunidades de crecimiento en la Org.',
  positive_work_enviroment: {
    title: 'Ambiente laboral positivo',
    attributes: [
      'Calidad de las relaciones\n',
      'Trato de la gente\n',
      'Equidad y transparencia'
    ],
    desc: '\nLe recomendaría a amigo o familiar\nque trabaje en esta Organización'
  },
  the_leaders: {
    title: 'Los líderes',
    attributes: [
      'Mi líder\n',
      'Apoyo\n',
      'Nuestros líderes'
    ],
    desc: 'Los líderes en esta\nOrganización me inspiran'
  },
  my_inspiration: {
    title: 'Mi inspiración',
    attributes: [
      'Sentido de propósito\n',
      'Trabajo trascendental\n',
      'Me conocen'
    ],
    desc: 'Si me ofrecieran un\ntrabajo similar en otra empresa, me\nquedaría donde estoy'
  },
  my_job: {
    title: 'Mi trabajo',
    attributes: [
      'Reputación de la Organización\n',
      'Reputación de mi área\n',
      'Entorno de trabajo'
    ],
    desc: 'Siento compromiso y\norgullo de trabajar en esta empresa'
  },
  indicators: 'Indicadores de la medición',
  methodology: 'Metodología',
  // tslint:disable-next-line:max-line-length
  methodology_text: 'La encuesta contiene 54 afirmaciones en una escala de 1 a 5 que presenta las\nsiguientes opciones:',
  methodology_list: [
    'Estoy totalmente en desacuerdo',
    'Estoy parcialmente en desacuerdo',
    'Ni en desacuerdo, ni de acuerdo',
    'Estoy parcialmente de acuerdo',
    'Estoy totalmente de acuerdo',
  ],
  // tslint:disable-next-line:max-line-length
  enps_explanation1: 'El Employee Net Promoter Score se calcula con base a la pregunta, "Le recomendaría a un amigo o familiar que trabaje en esta Organización". Esta pregunta se responde en una escala de 0 a 10.\nEl eNPS varía de -100 (todos Detractores) a +100 (todos Promotores).',
  enps_explanation2_title: '\n¿Cómo se calcula?',
  // tslint:disable-next-line:max-line-length
  enps_explanation2: '\nSe obtiene restando el porcentaje de respuestas clasificadas como Detractores (puntuaciones de 0 a 6) del porcentaje de respuestas clasificadas como Promotores (puntuaciones de 9 y 10).',
  enps_explanation3_title: '\nEscala eNPS:',
  enps_explanation3: [
    'Por debajo de 0: Crítico',
    '0+: Aceptable',
    '0 a 30: Bueno',
    '30 a 50: Muy bueno',
    '50 a 70: Excelente',
    '70+: Referente global'
  ],
  // tslint:disable-next-line:max-line-length
  indicators_final_text: 'Por último, la encuesta tiene 3 preguntas abiertas que se muestran al ﬁnal del reporte en una nube de palabras.',
  // tslint:disable-next-line:max-line-length
  methodology_conclusion: 'Los puntajes de las diferentes dimensiones, variables y atributos son calculados por\nmedio del promedio simple de las respuestas.',
  results_text: 'En esta sección se encuentran los resultados de la encuesta.',
  total_obtained: 'Total obtenidas',
  total_sent: 'Total esperadas',
  answers_rate: 'Tasa de respuesta',
  answers_rate1: 'Tasa de',
  answers_rate2: 'respuesta',
  population_percentage: 'Porcentaje de la\nPoblación',
  // tslint:disable-next-line:max-line-length
  answers_rate_text: 'Esta tabla presenta la cantidad de personas que se incluyeron en el proceso y de ese grupo cuáles completaron la medición.',
  general_score: 'Resultados',
  results_by_dimension: 'Resultados por dimensión',
  results_by_dimension_legend: '* Incluye las preguntas del Índice de Engagement',
  results_by_attribute: 'Resultados por atributo',
  results_by_attribute_legend: '* No incluye las preguntas del Índice de Engagement',
  results_by_dim_attr: 'Resultados por atributos',
  results_detail_by_var: 'Resultados detallados por variables',
  total: 'Total',
  attribute: 'Atributo',
  current_population: 'Puntaje actual',
  current_population_desc: 'Promedio simple del total de preguntas',
  current_population_items: 'Puntaje población',
  weighted_score: 'Puntaje promedio',
  weighted_score_desc: 'Promedio ponderado de las 54 variables (50%) y el Índice de Engagement (50%)',
  enps_score_desc: 'Employee Net Promoter Score',
  engagement_index: 'Índice de Engagement',
  engagement_index_desc: 'Agrupa las 6 preguntas de compromiso Engagement',
  preview_population: 'Puntaje anterior',
  questionnarie_population: 'Resultado por cuestionario',
  committal_population: 'Resultado por Compromiso',
  rate: 'Tendencia',
  actual_organization: 'Puntaje Organización',
  gap: 'Brecha',
  culture_alignment: 'Alineación con la cultura',
  purpose: 'Propósito',
  future_vision: 'Visión futuro',
  autonomy: 'Autonomía',
  strengths_and_talents: 'Fortalezas y talentos',
  my_contribution: 'Mi aporte',
  motivation: 'Motivación',
  they_value_me: 'Me valoran',
  performance: 'Realización',
  recognized_organization: 'Organización reconocida',
  organization_performance: 'Desempeño de la Organización',
  pride_of_belonging_organization: 'Orgullo de pertenecer a la Organización',
  recognized_area: 'Área reconocida',
  area_performance: 'Desempeño del área',
  pride_of_being_part_area: 'Orgullo de hacer parte del área',
  materials_and_equipment: 'Materiales y equipos',
  offices_and_dress_code: 'Oficinas y códigos de vestimenta',
  balance_personal_professional: 'Balance entre la vida personal y profesional',
  being_myself: 'Ser yo mismo',
  freedom_of_expression: 'Libertad de expresión',
  interpersonal_relationships: 'Relaciones interpersonales',
  care_for_people: 'Cuidado por las personas',
  inclusive_workplace: 'Lugar de trabajo inclusivo',
  respectful_treatment: 'Trato respetuoso',
  equal_opportunities: 'Hay igualidad de oportunidades',
  salary_and_benefits: 'Salario y beneficios',
  recognition_culture: 'Cultura de reconocimiento',
  trust_quality_relationships: 'Confianza y calidad en la relaciones',
  responsibility_meeting_goals: 'Responsabilidad por cumplir las metas',
  workload_balance: 'Balance en la carga de trabajo',
  team_network: 'Red de equipos',
  communication_team: 'Comunicación en mi equipo',
  diversity: 'Diversidad',
  agility_processes: 'Agilidad en los procesos',
  innovation: 'Innovación',
  access_transparency_information: 'Acceso y transparencia de la información',
  autonomous_learning: 'Aprendizaje autónomo',
  development_potential: 'Desarrollo de mi potencial',
  expectations_role: 'Expectativas de mi rol',
  career_plan: 'Plan de carrera',
  future_organization: 'Futuro en la Organización',
  horizontal_mobility: 'Movilidad horizontal',
  learning_tracking: 'Seguimiento al aprendizaje',
  genuine_interest: 'Interés genuino',
  admiration: 'Admiración',
  transparency_honesty: 'Transparencia y honestidad',
  clear_transparent_objectives: 'Objetivos claros y transparentes',
  coaching_feedback: 'Coaching y retroalimentación',
  leader_access: 'Acceso al líder',
  systematic_thinking: 'Pensamiento sistemático',
  strategic_planning: 'Planeación estratégica',
  social_intelligence_collaboration: 'Inteligencia social y colaboración',
  ranking_of_dimensions: 'Ranking de las dimensiones',
  dimension: 'Dimensión',
  current_organization: 'Organización actual',
  dimensions_with_higher_score: 'Dimensiones con puntaje más alto',
  dimensions_with_lower_score: 'Dimensiones con puntaje más bajo',
  dimensions_with_higher_dispersion: 'Dimensiones con dispersión más alta',
  dimensions_with_lower_dispersion: 'Dimensiones con dispersión más baja',
  ranking_of_the_attributes: 'Ranking de los atributos',
  attributes_with_higher_score: 'Atributos con puntaje actual más alto',
  attributes_with_lower_score: 'Atributos con puntaje actual más bajo',
  ranking_of_the_variables: 'Ranking de las variables',
  variable: 'Variable',
  variables_with_higher_score: 'Variables con puntaje actual más alto',
  variables_with_lower_score: 'Variables con puntaje actual más bajo',
  dispersion: 'Dispersión',
  dispersion_desc: 'Los puntajes con mayor dispersión indican menor consenso y requieren análisis más detallado.',
  dispersion_by_attributes: 'Dispersión por atributos',
  dispersion_by_variables: 'Dispersión por variables',
  attributes_with_higher_dispersion: 'Atributos con dispersión más alta',
  attributes_with_lower_dispersion: 'Atributos con dispersión más baja',
  preview_dispersion: 'Dispersión anterior',
  current_dispersion: 'Dispersión actual',
  current_dispersion_items: 'Dispersión población',
  variables_with_higher_dispersion: 'Variables con dispersión más alta',
  variables_with_lower_dispersion: 'Variables con dispersión más baja',
  rates_by_dimension: 'Tendencias por dimensión',
  variation: 'Variación',
  rates: 'Tendencias',
  rates_desc: 'La tendencia significa la evolución de resultado, que tanto aumento o disminuyo el puntaje comparado contra la medición anterior de Engagement.',
  highest_positive_rate_attribute: 'Tendencias positivas más altas por atributo',
  highest_negative_rate_attribute: 'Tendencias negativas más altas por atributo',
  highest_positive_rate_variable: 'Tendencias positivas más altas por variable',
  highest_negative_rate_variable: 'Tendencias negativas más altas por variable',
  rates_by_variables: 'Tendencias por variables',
  rates_by_attributes: 'Tendencias por atributos',
  open_questions_summary: 'Gráficos de preguntas cerradas',
  word_questions_summary: 'Resumen de preguntas abiertas',
  reports: 'Reportes',
  general_title: 'Reporte General',
  general_desc: 'Este reporte agrupa las respuestas de todas las personas que respondieron la encuesta.',
  general_button: 'Generar reporte Engagement',
  items_button: 'Generar reporte Engagement - Población',
  open_pie_title: 'Preguntas Adicionales',
  open_pie_desc: 'En estas gráficas podrás encontrar los puntajes de las preguntas adicionales.',
  demographic_population_button: 'Generar reporte resumen por cortes demográficos',
  demographic_title: 'Por cortes demográficos',
  demographic_desc: 'En este reporte podrás escoger y filtrar los puntajes de los diferentes cortes demográficos que hayas configurado al momento de crear la medición.',
  // tslint:disable-next-line:max-line-length
  demographic_population_title: 'Reporte resumen por cortes demográficos',
  // tslint:disable-next-line:max-line-length
  demographic_desc2: 'Este reporte cuenta con los puntajes de la población filtrada por los cortes demográficos que selecciones a continuación:',
  demographic_button: 'Generar reporte Engagement por cortes demográficos',
  display_demographic: 'Mostrar cortes demográficos',
  generated_reports: 'Reportes Generados',
  generated_reports_desc: 'En esta sección encontrarás todos los reportes que has generado.<br>Dependiendo del número de participantes cada reporte puede tardarse en generar hasta 30 minutos. Puedes actualizar el progreso recargando la página.<br>Se recomienda generar  un máximo de 5 reportes a la vez, para mejorar el rendimiento de la plataforma.',
  already_generated_report: 'Este reporte ya ha sido generado. Puedes descargarlo en la parte inferior, donde se encuentran todos los reportes generados.',
  population: 'Población',
  sent: 'Enviadas',
  obtained: 'Obtenidas',
  AdditionalDemographic1: 'Demográfico Adicional 1',
  AdditionalDemographic2: 'Demográfico Adicional 2',
  AcademicDegree: 'Título académico',
  Age: 'Edad',
  Antiquity: 'Antigüedad',
  Charge: 'Cargo',
  Country: 'País',
  Headquarter: 'Sede',
  Department: 'Área / Departamento',
  Gender: 'Género',
  JobType: 'Tipo de contratación',
  years_range: 'Entre {min} y {max} años',
  dimention: 'Dimensión',
  my_inspiration_chart: 'Mi\ninspiración',
  my_job_chart: 'Mi\ntrabajo',
  work_enviroment_chart: 'Ambiente laboral\npositivo',
  my_team_chart: 'Mi\nequipo',
  my_team_chart2: 'Mi       \nequipo',
  my_development_and_learning_chart: 'Mi desarrollo \ny aprendizaje',
  the_leaders_chart: 'Los\nlíderes',
  the_leaders_chart2: 'Los     \nlíderes',
  cover: {
    byitems: 'Reporte por población',
    general: 'Reporte General',
  },
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  active_employees: 'Empleados activos',
  participants: 'Participantes',
  average: 'Promedio sector',
  people: 'personas',
  note: 'Notas:',
  colors: 'Colores',
  dim_color: 'Color dimensión',
  values: 'Valores',
  meaning: 'Significado',
  // tslint:disable-next-line:max-line-length
  heatmap_text: 'En la siguiente tabla se pueden apreciar los colores, rangos de valor y significado para el mapa de calor que será utilizado en todas las tablas del reporte.',
  previous_measurement: 'Medición anterior',
  strengths: 'Fortalezas',
  opportunities: 'Oportunidades de mejoras',
  feelings: 'Sentimientos',
  what_is_it: '¿Qué es?',
  what_is_not: '¿Qué no es?',
  critical_situation: 'Situación crítica',
  requires_attention: 'Requiere atención urgente',
  insufficient: 'Insuficiente',
  aceptable: 'Aceptable',
  very_good: 'Muy bien',
  remarkable: 'Sobresaliente',
  how_to_read: 'Cómo leer el reporte',
  title: 'OCC - Reporte Engagement',
  att_var: 'Atributo - Variable',
  // tslint:disable-next-line:max-line-length
  quote: ['"El Engagement se define como un estado mental positivo, gratificante y relacionado con el trabajo, que se caracteriza por el vigor (altos niveles de energía y capacidad de recuperación mental), dedicación (una participación excepcionalmente fuerte en el trabajo) y compromiso (estar totalmente comprometido con el trabajo)." Wilmar Schaufeli'],
  dispersion_by_dimensions: 'Dispersión por dimensiones',
  open_questions_button: 'Gráficas de preguntas adicionales',
  open_question_select_label: 'Seleccione una pregunta',
  org_dispersion: 'Dispersión Organización',
  positive_attribute_footer: 'Los siguientes atributos poseen un puntaje alto exactamente igual al atributo ',
  negative_attribute_footer: 'Los siguientes atributos poseen un puntaje bajo exactamente igual al atributo ',
  positive_variable_footer: 'Las siguientes variables poseen un puntaje alto exactamente igual a la variable ',
  negative_variable_footer: 'Las siguientes variables poseen un puntaje bajo exactamente igual a la variable ',
  no_answers_modal_msg: 'La encuesta actual no posee respuestas, por lo cual no será posible generar un reporte.',
  operation_init: 'Se ha dado inicio a un proceso de descarga "Reporte Engagement", este proceso puede tardar varios minutos, puede ver su progreso al refrescar la página',
  operation_init_items: 'Se ha dado inicio a un proceso de descarga "Reporte Engagement - Población", este proceso puede tardar varios minutos, puede ver su progreso al refrescar la página',
  input_generating_report: 'Generando reporte',
  input_update_progress: 'Actualizar progreso',
  input_failed_generation: 'Generación fallida',
  input_download_report: 'Descargar reporte',
  input_new_report: 'Crear nuevo reporte',
  select_country_first: 'Debe seleccionar al menos un (1) país.',
  confirm_create_title: 'Confirme la generación del reporte',
  report_cost: 'Costo del Reporte',
  balance_after: 'Saldo después de generar el reporte:',
};
