
<template>
  <v-card class="mt-1" flat>
    <v-container class="pt-1">
      <v-row row wrap justify="center">
        <v-col cols="12">
          <section class="enterprise-logo">
            <img :src="displayEnterpriseLogo" />
          </section>
        </v-col>

        <v-col cols="12" sm="8" class="text-center mt-3">
          <h4 class="display-1 break-name">{{ poll.displayName || poll.name }}</h4>
          <p class="mt-3">{{ $t('pulses.poll_text') }}</p>
        </v-col>

        <v-col cols="12" sm="8" class="mb-5 my-3">
          <v-progress-linear
            :value="progress"
            background-color="grey lighten-2"
            :color="percentColor"
            height="20"
            style="border-radius: 10px;"
          ></v-progress-linear>

          <h5 class="text-center headline font-weight-bold grey--text text--darken-1">
            {{ progress }}%
          </h5>
        </v-col>

        <v-col cols="12" v-for="(question, i) in dimentionsFiltered" :key="question.id">
          <v-card-text>
            <h5 class="text-center headline mb-3">
              {{ question.count }}.-
              <template v-if="question.type !== 'open'">
                {{ question.label[langSelected] }}
              </template>
              <template v-else>
                {{ question.label }}
              </template>
            </h5>
            <v-row justify="center" class="my-3 mb-4" v-if="question.type === 'committal' || question.type === 'questionnaire'">
              <!-- ENPS Question -->
              <template v-if="question.type === 'committal' && question.dimensionId === 3">
                <template v-for="(enpsFace, idx) in enpsFaces">
                  <v-col cols="12" sm="6" md="1"
                    :key="`enps-${i}-${idx}`"
                    class="text-center mx-auto"
                    style="user-select: none;"
                  >
                    <v-btn icon large
                      :class="selectedOption(question.questionId, question.type, enpsFace.value, true)"
                      class="mb-1"
                      @click="selectAnswer(question.questionId, question.type, enpsFace.value)"
                    >
                      <v-icon x-large color="white">{{ enpsFace.icon }}</v-icon>
                    </v-btn>
                    <br />
                    <span
                      v-html="enpsFace.label
                        ? enpsFace.value + '<br>' + $t(`engagement.${enpsFace.label}`)
                        : enpsFace.value
                      "
                      class="cursor-pointer"
                      @click="selectAnswer(question.questionId, question.type, enpsFace.value)"
                    ></span>
                  </v-col>
                </template>
              </template>
              <!-- Other Questions -->
              <template v-else>
                <template v-for="(face, $index) in faces">
                  <v-col cols="6" sm="2" class="text-center" :key="`f${$index}`">
                    <v-btn large icon
                      :class="selectedOption(question.questionId, question.type, face.value, false)"
                      @click="selectAnswer(question.questionId, question.type, face.value)"
                    >
                      <v-icon x-large color="white">{{ face.icon }}</v-icon>
                    </v-btn>
                    <div v-html="$t(`engagement.${face.label}`)"
                      class="cursor-pointer"
                      @click="selectAnswer(question.questionId, question.type, face.value)"
                    ></div>
                  </v-col>
                </template>
              </template>
            </v-row>
            <v-row justify="center" class="mt-3 mb-4" v-if="question.type === 'open'">
              <v-col cols="12">
                <v-radio-group v-model="selected[`${question.questionId}-${question.type}`]">
                  <v-radio
                    v-for="option in question.options"
                    :key="option"
                    :value="option"
                    :label="option"
                    @change="selectAnswer(question.questionId, question.type, option)"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>

        <v-col cols="12">
          <v-card-actions>
            <div style="margin: 0 auto;">
              <v-btn
                large
                v-if="currentPage !== 0"
                @click="handlepaginate(0)"
              >{{ $t('input.back') }}</v-btn>

              <v-btn
                large
                class="primary"
                v-if="currentPage !== pages.length - 1"
                :disabled="!pageCompleted"
                @click="handlepaginate(1)"
              >{{ $t('input.next') }}</v-btn>

              <x-async-btn
                v-if="currentPage === pages.length - 1 && this.progress === 100"
                :action="tryFinishPoll"
                type="submit"
                class="white--text"
                large
              >{{ $t('input.finish') }}</x-async-btn>
            </div>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-container>

    <x-dialog-entry
      :logo="displayEnterpriseLogo"
      :display.sync="entryDialog"
      :countQuestion="questionTotal"
      :count-minutes="Math.floor(questionTotal / 3)"
      :language="language"
      :languages="languages"
      @changeLang="$emit('langChanged', $event)"
      @continue-entry="continueEntry"
    ></x-dialog-entry>

    <x-dialog-random
      :display.sync="rndDialog"
      @continue-random="continueRandom"
      :image="dialogContent.image"
      :text="dialogContent.text"
      :progress="progress"
    ></x-dialog-random>

    <x-dialog-finish
      :logo="displayEnterpriseLogo"
      :name="poll.displayName || poll.name"
      :display.sync="finishDialog"
      :back-button="$route.path.startsWith('/outside') ? false : true"
      :code="enterprise.invitationCode"
    ></x-dialog-finish>

    <x-dialog-token
      :display.sync="tokenDialog"
      :message="tokenMessage"
      :back-button="$route.path.startsWith('/outside') ? false : true"
    ></x-dialog-token>

    <v-dialog
      v-model="conectionModal"
      fullscreen
      hide-overlay
    >
      <v-card>
        <v-container fluid>
          <v-row>
            <v-col
              offset-md="2"
              class="text-center grey--text text--darken-1"
              style="margin: 110px auto!important;"
            >
              <img src="/img/connection.png" width="220"/>
              <template v-if="conectionAttempt < 4">
                <h2 class="display-3 error--text mt-10">{{ $t('pulses.connection_interrupted') }}</h2>
                <p class="headline mt-5">
                  <span v-if="timeConectionAttempt > 0">
                    {{ $t('pulses.connection_interrupted_trying', {seconds: timeConectionAttempt}) }}
                  </span>
                  <span v-else>
                    {{ $t('pulses.connection_interrupted_connecting') }}
                  </span>
                </p>
              </template>
              <h2 class="display-3 error--text mt-10" v-else>{{ $t('pulses.connection_interrupted_out') }}</h2>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <x-loading
      :display.sync="loading"
    ></x-loading>

    <x-alert :alert="alert"></x-alert>

    <v-btn fab dark small fixed bottom right large
      :color="percentColor"
      class="mb-5"
      style="cursor: default!important;"
    >
      {{ progress }}%
    </v-btn>
  </v-card>
</template>

<script>

import { mapState } from 'vuex';
import pulsesService from '../../services/pulses';
import usersService from '../../services/users';
import authService from '../../services/auth'
import XDialogEntry from './dialogs/entry.vue';
import XDialogRandom from './dialogs/random.vue';
import XDialogFinish from './dialogs/finish.vue';
import XDialogToken from './dialogs/token.vue';

export default {
  components: {
    XDialogEntry,
    XDialogRandom,
    XDialogFinish,
    XDialogToken,
  },
  props: {
    language: String,
    languages: Array
  },
  data() {
    return {
      progress: 0,
      selected: {},
      currentPage: 0,
      scrollOptions: {
        duration: 1000,
        easing: 'easeInOutCubic',
      },
      questions: [],
      pages: [],
      questionTotal: 0,
      loading: true,
      entryDialog: false,
      rndDialog: false,
      rndOnce: true,
      finishDialog: false,
      tokenDialog: false,
      tokenMessage: '',
      poll: {
        id: '',
        name: '',
        displayName: '',
      },
      colors: {
        0: 'angry',
        1: 'sad',
        2: 'neutral',
        3: 'happy',
        4: 'veryhappy',
      },
      dialogContent: {},
      faces: [
        { value: 0, label: 'totally_disagree', icon: 'mdi-emoticon-angry-outline' },
        { value: 1, label: 'parcially_disagree', icon: 'mdi-emoticon-sad-outline' },
        { value: 2, label: 'nor_agree_disagree', icon: 'mdi-emoticon-neutral-outline' },
        { value: 3, label: 'parcially_agree', icon: 'mdi-emoticon-happy-outline' },
        { value: 4, label: 'totally_agree', icon: 'mdi-emoticon-outline' },
      ],
      enpsColors: {
        0: 'angry',
        1: 'angry',
        2: 'angry',
        3: 'angry',
        4: 'angry',
        5: 'angry',
        6: 'angry',
        7: 'neutral',
        8: 'neutral',
        9: 'veryhappy',
        10: 'veryhappy'
      },
      enpsFaces: [
        { value: 0, icon: 'mdi-emoticon-sad-outline', label: 'unlikely' },
        { value: 1, icon: 'mdi-emoticon-sad-outline' },
        { value: 2, icon: 'mdi-emoticon-sad-outline' },
        { value: 3, icon: 'mdi-emoticon-sad-outline' },
        { value: 4, icon: 'mdi-emoticon-sad-outline' },
        { value: 5, icon: 'mdi-emoticon-sad-outline' },
        { value: 6, icon: 'mdi-emoticon-sad-outline' },
        { value: 7, icon: 'mdi-emoticon-neutral-outline' },
        { value: 8, icon: 'mdi-emoticon-neutral-outline' },
        { value: 9, icon: 'mdi-emoticon-happy-outline' },
        { value: 10, icon: 'mdi-emoticon-happy-outline', label: 'very_likely' },
      ],
      langSelected: null,
      enterprise: {
        invitationCode: '',
        logo: ''
      },
      tempSelectedAnswers: {},
      conectionAttempt: 0,
      timeConectionAttempt: 0,
      conectionCallBack: null,
      conectionModal: false
    };
  },
  created() {
    // usersService.checkTokenActivity('Pulse', this.$route.params.token);
    this.verifyPollToken();
    this.randomDialogContent();
  },
  watch: {
    selected() {
      this.calcPercent();
    },
    entryDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    rndDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    finishDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    tokenDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    '$i18n.locale': {
      handler (val) {
        this.langSelected = val
      },
      immediate: true
    }
  },
  methods: {
    tryConection () {
      this.loading = true
      this.conectionAttempt++
      return this.conectionCallBack()
        .then((res) => {
          this.conectionModal = false
          this.conectionAttempt = 0
          this.timeConectionAttempt = 0
          return res
        })
        .catch(() => {
          this.conectionModal = true
          if (this.conectionAttempt < 4) {
            const time = this.timeConectionAttempt = this.conectionAttempt * 10
            setTimeout(() => this.tryConection(), time * 1000)
            const interval = setInterval(() => {
              this.timeConectionAttempt--
              if (this.timeConectionAttempt <= 0) {
                clearInterval(interval)
              }
            }, 1000)
          }
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    calcPercent() {
      this.progress = Math.ceil((Object.keys(this.selected).length / this.questionTotal) * 100);
      if (this.progress >= 50 && !this.rndOnce) {
        this.rndDialog = true;
        this.rndOnce = true;
      } else if (this.progress < 50) {
        this.rndOnce = false;
      }
    },
    verifyPollToken(onlyVerify) {
      return ((!Object.keys(this.user).length || !this.user.id)
        ? authService.verifyPollToken(this.$route.params.token)
        : pulsesService.verifyPollToken(this.$route.params.token))
        .then((res) => {
          return !onlyVerify ? this.afterVerifyPollToken(res) : Promise.resolve(true);
        })
        .catch((error) => {
          if (error && error.code) {
            if (typeof error.code === 'object') {
              this.tokenMessage = this.$t('errors.generic-error/retry');
            } else {
              this.tokenMessage = this.$t(`errors.${error.code}`);
            }
            this.tokenDialog = true;
          }
          throw error;
        });
    },
    afterVerifyPollToken(res) {
      this.questionTotal = res.totalQuestions;
      this.poll.id = res.poll.id;
      this.poll.name = res.poll.name;
      this.poll.displayName = res.poll.displayName;
      // Available Questionnaire languages
      this.$emit('questionnaireLangs', res.poll.questionnaireLanguages)
      this.pages = res.pages;
      let cnt = 1
      this.pages.map(p => {
        p.map(pc => {
          pc.count = cnt
          cnt++
        })
      })
      this.stopLoading()
      this.entryDialog = true;
      this.enterprise = res.enterprise;
      if (!Object.keys(this.user).length || !this.user.id) {
        return authService.fetchEmployeeAnswers(this.$route.params.token, 'Pulse')
          .then(res => this.afterFetchEmployeeAnswers(res));
      } else {
        return pulsesService.fetchEmployeeAnswers(this.$route.params.token)
          .then(res => this.afterFetchEmployeeAnswers(res));
      }
    },
    afterFetchEmployeeAnswers(res) {
      if (res.answers) {
        this.selected = JSON.parse(res.answers);
        this.entryDialog = false;
      }
    },
    handlepaginate(action) {
      if (action && this.currentPage !== this.pages.length - 1) {
        this.$vuetify.goTo(0, this.scrollOptions);
        this.currentPage += 1;
      } else if (!action && this.currentPage !== 0) {
        this.$vuetify.goTo(0, this.scrollOptions);
        this.currentPage -= 1;
      }
    },
    continueEntry() {
      this.entryDialog = false;
    },
    continueRandom() {
      this.rndDialog = false;
    },
    selectedOption(questionId, questionType, opt, isEnps) {
      const colors = isEnps ? this.enpsColors : this.colors
      return this.selected[`${questionId}-${questionType}`] === opt ? colors[opt] : 'grey';
    },
    selectAnswer(questionId, questionType, opt) {
      this.tempSelectedAnswers = JSON.parse(JSON.stringify(this.selected));
      this.tempSelectedAnswers[`${questionId}-${questionType}`] = opt
      this.conectionCallBack = this.saveCurrentAnswers
      this.tryConection()
    },
    saveCurrentAnswers() {
      if (!Object.keys(this.user).length || !this.user.id) {
        return authService.saveEmployeeAnswers(JSON.stringify(this.tempSelectedAnswers), this.$route.params.token, 'Pulse').then(() => this.setSavedAnswer() )
      } else {
        return pulsesService.saveEmployeeAnswers(JSON.stringify(this.tempSelectedAnswers), this.$route.params.token).then(() => this.setSavedAnswer() );
      }
    },
    setSavedAnswer () {
      this.selected = JSON.parse(JSON.stringify(this.tempSelectedAnswers));
      this.tempSelectedAnswers = {}
    },
    randomDialogContent() {
      const index = Math.floor(Math.random() * 2);
      this.dialogContent.image = `/img/engagement/img${index}.png`;
    },
    tryFinishPoll () {
      this.conectionCallBack = this.finishPoll
      return this.tryConection()
    },
    finishPoll() {
      return this.verifyPollToken(true)
        .then(() => {
          if (Object.keys(this.selected).length < this.questionTotal) {
            return this.$store.dispatch('alert/error', this.$t('errors.engagements/missing-questions'));
          }
          let saveAnswers;
          if (!Object.keys(this.user).length || !this.user.id) {
            saveAnswers = authService.saveAnswers
          } else {
            saveAnswers = pulsesService.saveAnswers
          }
          this.conectionCallBack = () => {
            return saveAnswers(
              this.selected,
              this.poll.id,
              this.$route.params.token,
              'Pulse'
            )
              .then(() => {
                this.finishDialog = true;
              })
              .catch((err) => {
                if (err.code === 'engagements/empty-answers') {
                  this.$store.dispatch('alert/error', this.$t('errors.engagements/missing-questions'));
                } else {
                  this.tokenMessage = this.$t(`errors.${err.code}`);
                  this.tokenDialog = true;
                }
              });
          }
          return this.tryConection()
        });
    },
    stopLoading () {
      if (this.loading) {
        setTimeout(() => this.loading = false, 240)
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user,
      alert: (state) => state.alert,
    }),
    dimentionsFiltered() {
      return this.pages[this.currentPage];
    },
    pageCompleted() {
      if (!this.pages[0]) {
        return false;
      }

      let count = this.pages[this.currentPage].length;
      for (let i = 0; i < this.currentPage; i++) {
        count += this.pages[i].length;
      }
      return Object.values(this.selected).length >= count;
    },
    percentColor() {
      if (this.progress === 0) return 'grey';
      else if (this.progress <= 24) return '#BB3E3E';
      else if (this.progress <= 40) return '#B8663D';
      else if (this.progress <= 74) return '#C2B147'
      else if (this.progress <= 99) return '#B6C144';
      else if (this.progress === 100) return '#44C156';
      return '#44C156';
    },
    displayEnterpriseLogo() {
      return !this.enterprise || !this.enterprise.logo ? '/img/20200301_occ_solution_logo.png' : this.enterprise.logo;
    },
  },
};
</script>
<style>
  .break-name {
    max-width: 100%;
    overflow-wrap: break-word;
  }
  .angry {
    background-color: #BB3E3E !important;
  }
  .sad {
    background-color: #B8663D !important;
  }
  .neutral {
    background-color: #C2B147 !important;
  }
  .happy {
    background-color: #B6C144 !important;
  }
  .veryhappy {
    background-color: #44C156 !important;
  }
</style>
