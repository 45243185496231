
export default {
  dashboard: 'Dashboard',
  engagements: 'Engagement',
  questionnaires: 'Questionnaires',
  questionnairesEngagement: 'Questionnaires Engagement',
  questionnairesPulse: 'Questionnaires Pulse',
  engagement: 'Engagement',
  pulse: 'Pulse',
  committalQuestions: 'Committal questions',
  wordQuestions: 'Open questions',
  suite: 'Suite'
};
