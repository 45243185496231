
<template>
  <div style="display:inline;">
    <h5 class="headline mb-2">{{ $t('engagementReport.open_pie_title') }}</h5>
    <p>{{ $t('engagementReport.open_pie_desc') }}</p>

    <v-btn
      color="primary"
      large
      :disabled="disableButton"
      @click="displayDialog = true"
      class="showRepBtn"
    >
      <v-icon>mdi-chart-pie</v-icon>
      {{ $t('engagementReport.open_questions_button') }}
    </v-btn>

    <v-dialog
      v-model="displayDialog"
      @keydown.esc="displayDialog = false"
      fullscreen
      hide-overlay
      scrollable
    >
      <v-card height="100vh">
        <v-toolbar dark max-height="64px" flat color="primary">
          <v-toolbar-title>
            {{ engagementName }}
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-toolbar-items>
            <v-select
              v-model="currentQuestion"
              :items="selectionItems"
              :label="$t('engagementReport.open_question_select_label')"
              class="mt-4"
              style="max-width: 30vw;"
              @change="generatePie($event)"
            ></v-select>

            <v-btn text
              :disabled="currentQuestion === ''"
              @click="downloadImage"
            >
              <v-icon class="mr-2">mdi-file-image</v-icon>
              <span v-if="$vuetify.breakpoint.smAndUp">
                Descargar imagen
              </span>
            </v-btn>
          </v-toolbar-items>

          <v-btn icon dark @click="displayDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="pt-4">
          <div
            id="canvasContainer"
            style="width:95vw;margin:0 auto;"
          >
            <canvas id="chartCanvas"></canvas>
          </div>

          <a ref="download" id="download" href="#" style="visibility:hidden;" download></a>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

const echarts = require('echarts');

export default {
  props: {
    disableButton: Boolean,
    openAnswers: Array,
    engagementName: String,
    questions: Array,
  },
  data() {
    return {
      answers: {},
      currentQuestion: '',
      displayDialog: false,
      selectionItems: [],
      defaultSelection: '',
    };
  },
  watch: {
    disableButton(val) {
      if (!val) {
        this.calculateResults();
        this.formatSelectionItems();
      }
    },
    displayDialog (val) {
      if (val) {
        setTimeout(() => this.generatePie(this.currentQuestion), 100)
      }
    }
  },
  methods: {
    calculateResults() {
      this.openAnswers.forEach((a) => {
        if (!this.answers.hasOwnProperty(`open_question_${a.questionId}`)) {
          this.answers[`open_question_${a.questionId}`] = {}
        }
        if (a.answer) {
          const key = this.formatName(a.answer)
          if (!this.answers[`open_question_${a.questionId}`].hasOwnProperty(key)) {
            this.answers[`open_question_${a.questionId}`][key] = 1;
          } else {
            this.answers[`open_question_${a.questionId}`][key] += 1;
          }
        }
      });
    },
    formatName(answer) {
      return answer.charAt(0).toUpperCase() + answer.slice(1).toLowerCase()
    },
    formatSelectionItems() {
      this.selectionItems = this.questions.map((q, i) => {
        const { question } = JSON.parse(q.question);
        return {
          text: question,
          value: i,
          id: q.id
        };
      });

      if (this.selectionItems[0]) {
        this.currentQuestion = this.selectionItems[0].value;
      }
    },
    generatePie(index) {
      const canvas = document.getElementById('chartCanvas');
      if (!canvas) {
        return;
      }
      canvas.width = window.innerWidth ? (window.innerWidth - 50) : 0;
      canvas.height = window.innerHeight ? (window.innerHeight - 150) : 0;

      this.chart = echarts.init(canvas);
      const openQuestion = this.answers[`open_question_${this.selectionItems[index].id}`];

      const question = JSON.parse(this.questions[index].question).question
      const title = question.slice(0,80)
      const subtitle = question.slice(80,-1)

      this.chart.setOption({
        backgroundColor: '#FFFFFF',
        title: {
          text: subtitle ? `${title}...` : title,
          subtext: subtitle ? `...${subtitle}` : '',
          x: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          bottom: 'bottom',
          data: Object.keys(openQuestion),
        },
        series: [
          {
            name: 'Serie',
            type: 'pie',
            radius: '55%',
            center: ['50%', '50%'],
            color: [
              '#F44336',
              '#2196F3',
              '#4CAF50',
              '#FFC107',
              '#607D8B',
            ],
            data: Object.keys(openQuestion).map((key) => {
              return {
                name: key,
                value: openQuestion[key],
              }
            }),
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
            label: {
              show: true,
              position: 'outside',
              formatter: '{b}: {c} ({d}%)',
            },
          },
        ],
      });
    },
    downloadImage() {
      const download = document.getElementById('download');

      const canvas = document.getElementById('chartCanvas');
      const dataURL = canvas.toDataURL('image/jpeg');

      download.setAttribute('href', dataURL);
      this.$refs.download.click();
    },
  },
};
</script>
