
<template>
  <div style="display:inline;">
    <v-btn large
      v-if="!thread"
      :disabled="lockPdfButton || !disableButton"
      :loading="lockPdfButton"
      color="primary"
      class="showRepBtn mb-6"
      @click="requestPdf"
    >
      <v-icon>mdi-file-pdf</v-icon>
      {{ buttonText }}
    </v-btn>
    <v-btn large
      v-if="thread"
      color="success"
      class="mb-6"
      :loading="lockPdfButton"
      :disabled="thread.status !== 'completed'"
      @click="getSummaryData(thread.id)"
    >
      <v-icon>mdi-file-pdf</v-icon>
      <span>{{ btnText }}</span>
    </v-btn>
    <img
      src="/img/occ-logo-small.png"
      style="visibility:hidden;"
      id="pulseLogo"
      width="0"
      height="0"
    />
    <img
      src="/img/occ-cover-general.jpg"
      style="visibility:hidden;"
      id="portadaGeneral"
      width="0"
      height="0"
    />
    <img
      src="/img/20200301_occ_solution_logo.png"
      style="visibility:hidden;"
      id="occLogo2"
      width="0"
      height="0"
    />
    <img
      src="/img/occ-grafico.jpg"
      style="visibility:hidden;"
      id="occGrafico"
      width="0"
      height="0"
    />
    <img
      src="/img/colors-occ.png"
      style="visibility:hidden;"
      id="occColors"
      width="0"
      height="0"
    />
    <img
      src="/img/20200301_occ_solution_logo.png"
      style="visibility:hidden;"
      id="enterpriseLogo"
      width="0"
      height="0"
    />
    <x-loading
      :display="loading"
    ></x-loading>

    <!-- Dialog already Generated Report -->
    <x-dialog-already-generated-report
      :show.sync="alreadyGeneratedModal"
      @close="alreadyGeneratedModal = false"
    ></x-dialog-already-generated-report>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import is from 'is_js';
import pdfUtil from './utils/pdf';

import engagementsService from "@/services/engagements";

import XDialogAlreadyGeneratedReport from './components/dialog-already-generated-report'

import initialCalcs from './mixins-demographic-population/initial-calcs';
import cover from './mixins-demographic-population/cover';
import result from './mixins-demographic-population/result';

const pdfmake = require('pdfmake/build/pdfmake');
const pdffonts = require('pdfmake/build/vfs_fonts.js');
pdfmake.vfs = pdffonts.pdfMake.vfs;

export default {
  name: 'demographic-population-report',
  components: {
    XDialogAlreadyGeneratedReport
  },
  mixins: [
    initialCalcs,
    cover,
    result
  ],
  props: {
    pollId: Number,
    alreadyGenerated: Boolean,
    disableButton: Boolean,
    buttonText: String,
    thread: Object
  },
  data() {
    return {
      pulseLogo: '',
      portadaGeneral: '',
      occLogo2: '',
      occGrafico: '',
      occColors: '',
      enterpriseLogo: '',
      pulseLogoBase64: '',
      portadaGeneralBase64: '',
      occLogoBase64: '',
      occGraficoBase64: '',
      occColorsBase64: '',
      enterpriseLogoBase64: '',
      alreadyGeneratedModal: false,
      lockPdfButton: false,
      loading: false
    };
  },
  mounted() {
    this.pulseLogo = document.getElementById('pulseLogo').src;
    this.portadaGeneral = document.getElementById('portadaGeneral').src;
    this.occLogo2 = document.getElementById('occLogo2').src;
    this.occGrafico = document.getElementById('occGrafico').src;
    this.occColors = document.getElementById('occColors').src;
    this.enterpriseLogo = document.getElementById('enterpriseLogo').src;
  },
  watch: {
    pulseLogo(newVal) {
      if (newVal) {
        this.toDataURL(this.pulseLogo, (dataURL) => {
          this.pulseLogoBase64 = dataURL;
        });
      }
    },
    portadaGeneral(newVal) {
      if (newVal) {
        this.toDataURL(this.portadaGeneral, (dataURL) => {
          this.portadaGeneralBase64 = dataURL;
        });
      }
    },
    occLogo2(newVal) {
      if (newVal) {
        this.toDataURL(this.occLogo2, (dataURL) => {
          this.occLogoBase64 = dataURL;
        });
      }
    },
    occGrafico(newVal) {
      if (newVal) {
        this.toDataURL(this.occGrafico, (dataURL) => {
          this.occGraficoBase64 = dataURL;
        });
      }
    },
    occColors(newVal) {
      if (newVal) {
        this.toDataURL(this.occColors, (dataURL) => {
          this.occColorsBase64 = dataURL;
        });
      }
    },
    enterpriseLogo(newVal) {
      if (newVal) {
        this.toDataURL(this.enterpriseLogo, (dataURL) => {
          this.enterpriseLogoBase64 = dataURL;
        });
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user,
    }),
    btnText() {
      switch (this.thread.status) {
        case 'pending':
          return this.$t('demographicReport.input_generating_report');
        case 'in_progress':
          return this.$t('demographicReport.input_generating_report') + ` ${this.thread.progressPercentage}%`;
        case 'failed':
          return this.$t('demographicReport.input_failed_generation');
        case 'completed':
          return this.$t('demographicReport.input_download_report');
      }
    }
  },
  methods: {
    requestPdf() {
      if (this.alreadyGenerated) {
        this.alreadyGeneratedModal = true
        return
      }
      this.loading = true;
      this.lockPdfButton = true;
      engagementsService.addReport({
        type: "Engagement",
        pollId: this.pollId,
        lang: this.user.lang,
        reportType: "summary"
      }).then(() => {
        this.$store.dispatch('alert/success', this.$t('demographicReport.operation_init'));
        this.loading = false;
        setTimeout(this.refreshPage, 3000);
      })
    },
    getSummaryData (reportRequestId) {
      this.loading = true
      this.lockPdfButton = true
      engagementsService.getSummaryReportAnswers(reportRequestId)
        .then(async (res) => {
          await this.$getSummaryCalcData(res.data)
          this.renderPdf()
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
        .finally(() => {
          this.loading = false
          this.lockPdfButton = false
        })
    },
    refreshPage() {
      window.location.reload()
    },
    writeRotatedText(text, isHorizontal) {
      const canvas = document.createElement('canvas');
      canvas.width = 50;
      canvas.height = 845;

      const ctx = canvas.getContext('2d');

      // Genera color de fondo
      ctx.fillStyle = '#2196F3';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.save();

      // Posiciona el elemento al costado derecho de la página
      ctx.translate(50, 845);
      ctx.rotate(-0.5 * Math.PI);

      // Formatea el texto
      ctx.font = '20pt Roboto';
      ctx.fillStyle = 'white';
      ctx.fillText(text.toUpperCase(), isHorizontal ? 454 : 320, -15);
      ctx.restore();

      return canvas.toDataURL();
    },
    calculatePages(isHorizontal) {
      const endPage = 1400
      const temp = [{text: this.$t('engagementReport.results'), sta: 1, end: endPage}];
      return {endPage, temp};
    },
    async renderPdf() {
      this.loading = true
      this.lockPdfButton = true
      const ttl = `${this.$t('engagementReport.title')} ${this.$t('engagementReport.demographic_title')}`
      const configuration = {
        pageSize: 'A4',
        info: {
          title: ttl,
          author: 'OCC',
          subject: ttl,
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Roboto',
          lineHeight: 1.2,
        },
        header: (currentPage, pageCount, pageSize) => {
          const isHorizontal = pageSize.orientation === 'landscape'

          const resultObj = {
            image: this.pulseLogoBase64,
            height: 28,
            width: 117,
            margin: [15, 15, 25, 15],
          };

          if (currentPage === 1) return [{}];

          if (isHorizontal) {
            resultObj.margin = [15, 545, 25, 15];
          }

          return [resultObj];
        },
        footer: (currentPage/*, pageCount, pageSize*/) => {
          return [
            {
              columns: pdfUtil.generateCenteredText(this.$t('engagementReport.copyright'), 10),
              color: 'grey',
            },
            {
              margin: [0, 0, 60, 0],
              text: currentPage > 1 ? currentPage.toString() : '',
              alignment: 'right',
              color: 'grey',
            },
          ];
        },
        background: (currentPage, pageSize) => {
          const isHorizontal = pageSize.orientation === 'landscape'
          const {endPage, temp} = this.calculatePages(isHorizontal);

          if (currentPage === 1) {
            const image = this.portadaGeneralBase64;
            return {
              image,
              aligment: 'center',
              absolutePosition: {x: -40, y: -30},
            };
          } else if (currentPage > endPage) {
            return {};
          }

          const pageCount = temp.find(t => t.sta <= currentPage && t.end >= currentPage);
          if (!pageCount) return {};

          const background = this.writeRotatedText(pageCount.text, isHorizontal);
          return {
            image: background,
            aligment: 'center',
            absolutePosition: {x: isHorizontal ? 792 : 545, y: 0},
          };
        },
        content: [
          // Portada
          await this.$generateSummaryCover(),
          // Resultados
          await this.$generateSummaryResults(),
        ]
      };

      if (is.edge() || is.ie()) {
        const pdfDocGenerator = pdfMake.createPdf(configuration)
        pdfDocGenerator.getBlob((blob) => {
          window.navigator.msSaveBlob(blob, `${this.nameReport}.pdf`)
          this.loading = false
          this.lockPdfButton = false
        });
      } else {
        pdfmake.createPdf(configuration).download(`${this.nameReport}.pdf`);
        this.loading = false
        this.lockPdfButton = false
      }
    },
    toDataURL(url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.open('get', url);
      xhr.responseType = 'blob';

      xhr.onload = function() {
        const fr = new FileReader()

        fr.onload = function() {
          callback(this.result)
        };

        fr.readAsDataURL(xhr.response)
      };

      xhr.send();
    }
  },
};
</script>
