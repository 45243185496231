
export default {
  add_enterprise: 'Add Company',
  admin_left_table_title: 'Companies',
  admin_bottom_table_title: 'Recent surveys',
  admin_bottom_table_header: [
    { text: 'Name', value: '', sortable: false, align: 'left' },
    { text: 'Company', value: '', sortable: false, align: 'center' },
    { text: 'Status', value: '', sortable: false, align: 'center' },
    { text: 'Release date', value: '', sortable: false, align: 'center' },
  ],
  admin_right_table_title: 'Companies with plans about to expire',
  admin_right_table_header: [
    { text: 'Company', value: '', sortable: false, align: 'center' },
    { text: 'Plan', value: '', sortable: false, align: 'center' },
    { text: 'Due date', value: '', sortable: false, align: 'center' },
  ],
  create_poll: 'Create survey',
  continue_poll: 'Continue survey',
  continue: 'Continue',
  employee_left_table_title: 'Surveys in progress',
  employee_progress: 'Progress:',
  employee_right_table_title: 'Surveys Asigned',
  enterprise_down_table_title: 'Surveys',
  enterprise_left_table_title: 'Account summary',
  enterprise_right_table_title: 'Current plan',
  employee_right_table_header: [
    { text: 'Name', value: '', sortable: false, align: 'center' },
    { text: 'Period', value: '', sortable: false, align: 'center' },
  ],
  enterprise_right_table_header: [
    { text: 'Name', value: '', sortable: false, align: 'left' },
    { text: 'Type', value: '', sortable: false, align: 'center' },
    { text: 'Respondents', value: '', sortable: false, align: 'center' },
    { text: 'Status', value: '', sortable: false, align: 'center' },
    { text: 'Details', value: '', sortable: false, align: 'center' },
  ],
  expired_plan: '(Expired plan)',
  general_list: 'General list',
  general_title: 'Dashboard',
  has_started: 'Has started',
  not_started: 'To be started',
  completed: 'Completed',
  init: 'Start:',
  end: 'End:',
  ended: 'Ended on:',
  left_days: 'Subscription days remaining',
  left_questions: 'Remaining questions:',
  finished: 'Finished',
  progress: 'In progress',
  period_divisor: 'to',
  poll: 'Survey:',
  poll_completed: 'Yes',
  poll_incompleted: 'No',
  poll_init_date: 'Start:',
  poll_end_date: 'Valid until:',
  qty_enterprises: 'Active companies',
  qty_employees: 'Employees',
  qty_licesenses: 'Licenses',
  team_trends: 'Team trends',
  trend_chart: 'Trend chart',
  update_plan: 'Update Plan',
  dimension_leaders: 'Leaders',
  dimension_inspiration: 'My inspiration',
  dimension_job: 'My job',
  dimension_environment: 'Positive work environment',
  dimension_team: 'My team',
  dimension_development: 'My development and learning',
  view_poll_details: 'See survey details',
  has_no_polls: 'At least 2 surveys need to be created in the same period to see the graph',
  start: 'Start',
  start_poll: 'Start survey',
};
