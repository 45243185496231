
<template>
  <v-stepper-content :step="step" class="cut-overflow">
    <v-col cols="12" class="pa-3 headline">
      {{ $t('pulses.receptors') }}
      <v-divider></v-divider>
      <v-spacer></v-spacer>
    </v-col>
    <v-container>
      <v-row>
        <v-col xs="6" sm="6">
          <x-inputs-autocomplete
            persistent-hint
            :readonly="isEdit"
            :items="getSelectType"
            :input="pulse.selectionType"
            @updateInput="($event) => pulse.selectionType = $event"
            light
            :label="$t('pulses.want_to_send')"
            rules="required"
            name="selection_type"
            :append-outer-icon="$t('help.icon')"
            :help-message="$t('help.pulse.create.selection')"
          ></x-inputs-autocomplete>
        </v-col>
      </v-row>

      <!------------------------------------->
      <!---------------- ALL ---------------->
      <!------------------------------------->
      <v-row v-if="pulse.selectionType === 1">
        <v-col cols="12" class="pa-3 headline">
          {{ $t('pulses.total_receptors', {n: `${employeesCount}`}) }}
          <v-spacer></v-spacer>
        </v-col>
      </v-row>

      <!------------------------------------->
      <!---------- DEMOGRAPHIC CUTS --------->
      <!------------------------------------->
      <v-container grid-list-md v-else-if="pulse.selectionType == 2">

        <v-row v-if="loadingDemographics">
          <v-col cols="12" class="pt-2 px-0 text-center">
            <v-progress-circular indeterminate
              :width="4"
              :size="54"
              color="primary"
            ></v-progress-circular>
            <p class="caption grey--text text--darken-2">
              {{ $t('input.please_wait') }}..
            </p>
          </v-col>
        </v-row>

        <v-row v-else align-center justify-center row wrap>

          <!-- Department -->
          <v-col
            cols="12"
            sm="6"
            v-if="(pulse.demographicItems.department || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete
                multiple
                chips
                outlined
                :disabled="isEdit"
                :filled="pulse.status !== 'pending'"
                v-bind:style="pulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                :readonly="pulse.status !== 'pending'"
                :items="departments"
                persistent-hint
                :input="pulse.departmentIds"
                @updateInput="($event) => pulse.departmentIds = $event"
                light
                :label="$t('pulses.departments')"
                name="departments"
                @change="getDemographicFilteredEmployees($event, 'departmentIds')"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span
                    v-if="index === 1"
                    class="grey--text caption"
                  >(+{{ pulse.departmentIds.length - 1 }} otros)</span>
                </template>
              </x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Charge -->
          <v-col
            cols="12"
            sm="6"
            v-if="(pulse.demographicItems.charge || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete
                multiple
                chips
                outlined
                :disabled="isEdit"
                :filled="pulse.status !== 'pending'"
                v-bind:style="pulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                :readonly="pulse.status !== 'pending'"
                :items="charges"
                persistent-hint
                :input="pulse.chargeIds"
                @updateInput="($event) => pulse.chargeIds = $event"
                light
                :label="$t('pulses.charges')"
                name="charges"
                @change="getDemographicFilteredEmployees($event, 'chargeIds')"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span
                    v-if="index === 1"
                    class="grey--text caption"
                  >(+{{ pulse.chargeIds.length - 1 }} otros)</span>
                </template>
              </x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Academic Degree -->
          <v-col
            cols="12"
            sm="6"
            v-if="(pulse.demographicItems.academicDegrees || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete
                multiple
                chips
                outlined
                :disabled="isEdit"
                :filled="pulse.status !== 'pending'"
                v-bind:style="pulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                :readonly="pulse.status !== 'pending'"
                :items="academicDegrees"
                persistent-hint
                :input="pulse.academicDegreeIds"
                @updateInput="($event) => pulse.academicDegreeIds = $event"
                light
                :label="$t('pulses.academic_degrees')"
                name="academic_degrees"
                @change="getDemographicFilteredEmployees($event, 'academicDegreeIds')"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span
                    v-if="index === 1"
                    class="grey--text caption"
                  >(+{{ pulse.academicDegreeIds.length - 1 }} otros)</span>
                </template>
              </x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Job Type -->
          <v-col
            cols="12"
            sm="6"
            v-if="(pulse.demographicItems.jobType || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete
                multiple
                chips
                outlined
                :disabled="isEdit"
                :filled="pulse.status !== 'pending'"
                v-bind:style="pulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                :readonly="pulse.status !== 'pending'"
                :items="jobTypes"
                persistent-hint
                :input="pulse.jobTypeIds"
                @updateInput="($event) => pulse.jobTypeIds = $event"
                light
                :label="$t('pulses.job_types')"
                name="job_types"
                @change="getDemographicFilteredEmployees($event, 'jobTypeIds')"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span
                    v-if="index === 1"
                    class="grey--text caption"
                  >(+{{ pulse.jobTypeIds.length - 1 }} otros)</span>
                </template>
              </x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Age -->
          <v-col
            cols="12"
            sm="6"
            v-if="(pulse.demographicItems.age || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete
                outlined
                :disabled="isEdit"
                :filled="pulse.status !== 'pending'"
                v-bind:style="pulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                :readonly="pulse.status !== 'pending'"
                :items="getSelectAge"
                :input="pulse.rangeAge"
                @updateInput="($event) => pulse.rangeAge = $event"
                persistent-hint
                light clearable
                :label="$t('pulses.select_age_range')"
                name="select_age_range"
                @change="getDemographicFilteredEmployees($event, 'rangeAge')"
              ></x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Antiquity -->
          <v-col
            cols="12"
            sm="6"
            v-ripple="{ center: true }"
            v-if="(pulse.demographicItems.antiquity || []).length > 0"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete
                outlined
                :disabled="isEdit"
                :filled="pulse.status !== 'pending'"
                v-bind:style="pulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                :readonly="pulse.status !== 'pending'"
                :items="getSelectAntiquity"
                :input="pulse.rangeAntiquity"
                @updateInput="($event) => pulse.rangeAntiquity = $event"
                persistent-hint
                light clearable
                :label="$t('pulses.select_antiquity_range')"
                name="select_antiquity_range"
                @change="getDemographicFilteredEmployees($event, 'rangeAntiquity')"
              ></x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Gender -->
          <v-col
            cols="12"
            sm="6"
            v-if="(pulse.demographicItems.gender || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete
                outlined
                :disabled="isEdit"
                :filled="pulse.status !== 'pending'"
                v-bind:style="pulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                :readonly="pulse.status !== 'pending'"
                :items="genders"
                persistent-hint
                :input="pulse.genderId"
                @updateInput="($event) => pulse.genderId = $event"
                light clearable
                :label="$t('pulses.genders')"
                name="genders"
                @change="getDemographicFilteredEmployees($event, 'genderId')"
              ></x-inputs-autocomplete>
            </template>
          </v-col>

          <v-col cols="12" sm="6"
            v-if="$vuetify.breakpoint.smAndUp"
            v-bind:style="'margin-right:auto'"
          ></v-col>
          <!-- Country -->
          <v-col
            cols="12"
            sm="6"
            v-if="(pulse.demographicItems.country || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete
                outlined
                chips
                multiple
                :disabled="isEdit"
                :filled="pulse.status !== 'pending'"
                v-bind:style="pulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                :readonly="pulse.status !== 'pending'"
                :items="countries"
                persistent-hint
                :input="pulse.countrySelect"
                @updateInput="($event) => pulse.countrySelect = $event"
                light
                :label="$t('pulses.countries')"
                name="countries"
                @change="getHeadquarters($event); getDemographicFilteredEmployees($event, 'countrySelect');"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span
                    v-if="index === 1"
                    class="grey--text caption"
                  >(+{{ pulse.countrySelect.length - 1 }} otros)</span>
                </template>
              </x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Headquarter -->
          <v-col
            cols="12"
            sm="6"
            v-if="(pulse.demographicItems.headquarter || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete
                outlined
                chips
                multiple
                :disabled="isEdit || pulse.countrySelect.length === 0"
                :filled="pulse.status !== 'pending'"
                v-bind:style="pulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                :readonly="pulse.status !== 'pending'"
                :items="headquarters"
                persistent-hint
                :input="pulse.headquarterSelect"
                @updateInput="($event) => pulse.headquarterSelect = $event"
                light
                :label="$t('input.headquarter')"
                name="headquarters"
                @change="getDemographicFilteredEmployees($event, 'headquarterSelect');"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span
                    v-if="index === 1"
                    class="grey--text caption"
                  >(+{{ pulse.headquarterSelect.length - 1 }} otros)</span>
                </template>
              </x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Additional Demographic 1 -->
          <v-col
            cols="12"
            sm="6"
            v-if="(pulse.demographicItems.optionalDemo1 || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete outlined chips multiple light
                name="additionalDemographics1"
                :disabled="isEdit"
                :filled="pulse.status !== 'pending'"
                :readonly="pulse.status !== 'pending'"
                :items="additionalDemographics1"
                :input="pulse.additionalDemographic1Ids"
                :label="$t('input.optionalDemo1')"
                v-bind:style="pulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                @updateInput="($event) => pulse.additionalDemographic1Ids = $event"
                @change="getDemographicFilteredEmployees($event, 'additionalDemographic1Ids');"
              ></x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Additional Demographic 2 -->
          <v-col
            cols="12"
            sm="6"
            v-if="(pulse.demographicItems.optionalDemo2 || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete outlined chips multiple light
                name="additionalDemographics2"
                :disabled="isEdit"
                :filled="pulse.status !== 'pending'"
                :readonly="pulse.status !== 'pending'"
                :items="additionalDemographics2"
                :input="pulse.additionalDemographic2Ids"
                :label="$t('input.optionalDemo2')"
                v-bind:style="pulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                @updateInput="($event) => pulse.additionalDemographic2Ids = $event"
                @change="getDemographicFilteredEmployees($event, 'additionalDemographic2Ids');"
              ></x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Total -->
          <v-col cols="12" class="py-0 headline">
            {{ $t('pulses.total_receptors', {n: `${totalFilteredEmployees}`}) }}
          </v-col>
        </v-row>
      </v-container>

      <!------------------------------------->
      <!-------------- RANDOM --------------->
      <!------------------------------------->
      <v-row v-if="pulse.selectionType === 3" class="mb-5">
        <v-subheader>{{ $t('pulses.percent_total')}}</v-subheader>
      </v-row>

      <v-row v-if="pulse.selectionType === 3">
        <v-col cols="12" class="pr-4">
          <v-slider
            v-model="pulse.percentTotal"
            class="align-center"
            :max="max"
            :min="min"
            hide-details
            :thumb-size="40"
            thumb-label="always"
            :readonly="isEdit"
          >
          <template v-slot:thumb-label="{ value }">
            {{ value }}%
          </template>
          </v-slider>
        </v-col>
      </v-row>

      <v-row v-if="pulse.selectionType === 3">
        <v-col xs="6" sm="6" class="pa-3 headline">
          {{ $t('pulses.total_receptors', {n: isEdit ? employeesCount : totalByPercent }) }}
          <v-spacer></v-spacer>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-btn block large @click="changeStep(+step - 1)">{{ $t('input.back') }}</v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <x-async-btn class="white--text" :action="runValidate" large block>{{ $t(nextAction) }}</x-async-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-stepper-content>
</template>

<script>

import { mapState } from "vuex";
import countriesService from "../../../services/countries";
import headquartersService from "../../../services/headquarters";
import academicDegreesService from "../../../services/academic-degrees";

import enterprisesService from "../../../services/enterprises";
import formatItemsGeneral from "../../../utils/form-format-items-list";

import resolver from "../../../utils/resolver";

import EmployeesServices from '../../../services/employees'

const formatItems = items => {
  return items.map(item => {
    return {
      value: item.id,
      text: item.name,
      detail: item.questionnaireDetail
    };
  });
};

const formatRes = items => {
  return items.map(item => {
    return {
      value: item.id,
      text: item.name,
      refId: item.countryId
    };
  });
};

const formatEnterprisesValue = items => {
  return items.map(item => {
    return {
      value: item.id,
      text: item.translate.label
    };
  });
};

export default {
  data() {
    return {
      loadingDemographics: false,
      totalFilteredEmployees: 0,
      genders: [],
      min: 0,
      max: 100,
      countries: [],
      headquarters: [],
      jobTypes: [],
      departments: [],
      charges: [],
      academicDegrees: [],
      getSelectAge: [],
      additionalDemographics1: [],
      additionalDemographics2: [],
      demographicFilter: {
        genderId: '',
        departmentIds: [],
        academicDegreeIds: [],
        jobTypeIds: [],
        countrySelect: [],
        headquarterSelect: [],
        chargeIds: [],
        rangeAge: [],
        rangeAntiquity: [],
        additionalDemographic1Ids: [],
        additionalDemographic2Ids: []
      }
    };
  },
  props: {
    pulse: Object,
    step: String,
    nextAction: String,
    prevAction: String,
    isEdit: Boolean,
    employeesCount: Number
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    }),
    totalByPercent() {
      this.pulse.totalReceivers = Math.floor((this.employeesCount * this.pulse.percentTotal) / 100)
      return this.pulse.percentTotal > 0 ? this.pulse.totalReceivers : 0
    },
    getSelectAntiquity() {
      return [
        {
          value: [0, 0.5],
          text: this.$t("pulses.antiquity_low", { n: 6 })
        },
        {
          value: [0.5, 1],
          text: this.$t("pulses.antiquity_range_single", { n: 6, p: 1 })
        },
        {
          value: [1, 3],
          text: this.$t("pulses.antiquity_range_one", { n: 1, p: 3 })
        },
        {
          value: [3, 5],
          text: this.$t("pulses.antiquity_range", { n: 3, p: 5 })
        },
        {
          value: [5, 10],
          text: this.$t("pulses.antiquity_range", { n: 5, p: 10 })
        },
        {
          value: [10, 20],
          text: this.$t("pulses.antiquity_range", { n: 10, p: 20 })
        },
        {
          value: [20, 200],
          text: this.$t("pulses.antiquity_upper", { n: 20 })
        }
      ]
    },
    getSelectType: function() {
      return [{
          value: 1,
          text: this.$t("pulses.to_all_my_organization")
        }, {
          value: 2,
          text: this.$t("pulses.to_demographic_cuts")
        }, {
          value: 3,
          text: this.$t("pulses.to_receivers_randomly")
      }]
    }
  },
  watch: {
    'pulse.selectionType': 'setTotalReceivers'
  },
  methods: {
    setTotalReceivers (newVal) {
      switch (newVal) {
        case 1: this.pulse.totalReceivers = this.employeesCount; break;
        case 2: this.pulse.totalReceivers = this.totalFilteredEmployees; break;
        case 3: this.pulse.totalReceivers = 0; break;
      }
    },
    iniGetSelectAge: function() {
      this.getSelectAge = [
        [0, 25],
        [25, 35],
        [35, 45],
        [45, 50],
        [50, 200]
      ].map(range => {
        const option = { value: range, text: "" };
        if (!range[0] || range[1] > 50) {
          option.text = this.$t(
            `pulses.${!range[0] ? "age_low" : "age_upper"}`,
            { n: range[0] || range[1] }
          );
        } else {
          option.text = this.$t("pulses.age_range", {
            n: range[0],
            p: range[1]
          });
        }
        return option;
      });
    },
    async getDemographicFilteredEmployees(event, key) {
      if (key === 'genderId') {
        this.demographicFilter[key] = event ? event : ''
      } else {
        this.demographicFilter[key] = event ? event : []
      }

      let flag = false
      for (const i in this.demographicFilter) {
        const filter = this.demographicFilter[i]
        if (typeof filter === 'number' && filter !== '') flag = true
        if (typeof filter === 'object' && filter.length) flag = true
        if (flag) break
      }

      if (flag) {
        this.$emit('parentLoading', true)
        await EmployeesServices.getDemographicParticipants(this.demographicFilter)
          .then((res) => {
            this.totalFilteredEmployees = res.total
          })
          .finally(() => {
            this.$emit('parentLoading', false)
          })
      } else {
        this.totalFilteredEmployees = 0
      }
      this.pulse.totalReceivers = this.totalFilteredEmployees
    },
    getHeadquarters(id) {
      headquartersService
        .list(id)
        .then(res => {
          this.headquarters = this.headquarters.concat(formatRes(res.items));
          this.headquarters = this.headquarters.filter(item =>
            this.pulse.countrySelect.includes(item.refId)
          );
        })
        .catch((this.headquarters = []));
    },
    changeStep(step) {
      this.$emit("changeStep", step);
    },
    runValidate() {
      return new Promise((resolve, reject) => {
        if (this.isEdit) this.pulse.totalReceivers = this.employeesCount
        if (this.pulse.totalReceivers <= 0) {
          this.$store.dispatch(
            "alert/error",
            this.$t("errors.validator/no_receivers")
          );
          return reject();
        }
        this.changeStep(+this.step + 1);
        return resolve();
      });
    },
    checkedDemographic () {
      const listItems = [
        { item: 'departmentIds', name: 'departments' },
        { item: 'chargeIds', name: 'charges' },
        { item: 'academicDegreeIds', name: 'academicDegrees' },
        { item: 'jobTypeIds', name: 'jobTypes' },
        { item: 'genderId', name: 'genders' },
        { item: 'rangeAntiquity', name: 'antiquity' },
        { item: 'rangeAge', name: 'age' },
        { item: 'countrySelect', name: 'countries' },
        { item: 'headquarterSelect', name: 'headquarters' },
        { item: 'additionalDemographic1Ids', name: 'additionalDemographic1' },
        { item: 'additionalDemographic2Ids', name: 'additionalDemographic2' },
      ];
      let k = -1;
      while (++k < listItems.length) {
        if (this.pulse[listItems[k].item] && this.pulse[listItems[k].item].length) {
          this.demographicFilter[listItems[k].name] = this.pulse[listItems[k].item];
        }
      }
      this.totalFilteredEmployees = this.employeesCount
    },
  },
  created() {
    this.loadingDemographics = true
    this.iniGetSelectAge();
    resolver
      .all({
        enterprise: enterprisesService.getProfile(),
        countries: countriesService.listByEnterprise(),
        academicDegrees: academicDegreesService.listFromSuite()
      })
      .then(res => {
        this.academicDegrees = formatEnterprisesValue(
          res.academicDegrees.items
        );
        this.countries = formatItemsGeneral(res.countries.items);
        const demoItems = res.enterprise.demographicItems.filter(
          item =>
            item.data === "master_reference" ||
            item.code === "age" ||
            item.code === "antiquity"
        );

        this.pulse.demographicItems = {
          gender: demoItems.filter(item => item.code === "gender"),
          age: demoItems.filter(item => item.code === "age"),
          antiquity: demoItems.filter(item => item.code === "antiquity"),
          country: demoItems.filter(item => item.code === "country"),
          headquarter: demoItems.filter(item => item.code === "headquarter"),
          charge: demoItems.filter(item => item.code === "charge"),
          department: demoItems.filter(item => item.code === "departments"),
          jobType: demoItems.filter(item => item.code === "jobTypes"),
          academicDegrees: demoItems.filter(item => item.code === "academicDegree"),
          optionalDemo1: demoItems.filter(item => item.code === "optionalDemo1"),
          optionalDemo2: demoItems.filter(item => item.code === "optionalDemo2")
        };
        this.genders = formatEnterprisesValue(res.enterprise.genders);
        this.jobTypes = formatEnterprisesValue(res.enterprise.jobTypes);
        this.departments = formatEnterprisesValue(res.enterprise.departments);
        this.charges = formatEnterprisesValue(res.enterprise.charges);
        this.additionalDemographics1 = formatEnterprisesValue(res.enterprise.additionalDemographic1);
        this.additionalDemographics2 = formatEnterprisesValue(res.enterprise.additionalDemographic2);
        this.setTotalReceivers(this.pulse.selectionType);
        if (this.isEdit) {
          this.checkedDemographic();
        }
      })
      .finally(() => {
        this.loadingDemographics = false
      })
  }
};
</script>

<style>
  .cut-overflow .v-select__selections {
    overflow: hidden;
  }
</style>
