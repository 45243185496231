
export default {
  titles: [
    '¿Sabías qué?',
    'Título aleatorio 2',
    'Título aleatorio 3',
  ],
  texts: [
    // tslint:disable-next-line:max-line-length
    'Un empleado engaged, posee una motivación elevada, lo cual se reflejará en una disminución de su ausentismo, en una mejorada calidad de su trabajo, y dará más de lo que se espera de él para lograr las metas de la empresa.',
    // tslint:disable-next-line:max-line-length
    'Este es el texto de prueba múmero 2. Los mensajes que se mostrarán en esta vista son seleccionados de forma aleatoria.',
    // tslint:disable-next-line:max-line-length
    'Este es el texto de prueba múmero 3. Los mensajes que se mostrarán en esta vista son seleccionados de forma aleatoria.',
  ],
};
